<template>
  <div class="share-details">
    <div class="shared-file">
      <div class="d-flex direction-row justify-content-between">
        <div>
          <div class="shared-file-caption">
            {{
              $t(
                currentlySharedFile.type === 'dir' ||
                  currentlySharedFile.isdir === 1
                  ? 'Shared Folder'
                  : 'Shared File'
              )
            }}
          </div>
          <div class="shared-file-name text-truncate">
            {{ shareProperties.sharelocation }}
          </div>
        </div>
        <shareSelectPreview
          v-if="
            !isPrivateShare &&
            currentlySharedFile.type == 'dir' &&
            currentlySharedFile.encryptedfolder !== 1
          "
          :path="shareProperties.sharelocation"
        />
      </div>
      <div
        class="tab-wrapper options-history"
        :class="{ ['user-group-selected ']: userGroupSelected }"
      >
        <div class="tab-header">
          <div
            v-a11ybutton="() => setActiveTab('options')"
            class="tab-header-title"
            tabindex="0"
            :class="{ ['active']: activeTab === 'options' }"
            @click="setActiveTab('options')"
          >
            {{ $t('Share Options') }}
          </div>
          <div
            v-a11ybutton="() => setActiveTab('history')"
            class="tab-header-title"
            tabindex="0"
            :class="{ ['active']: activeTab === 'history' }"
            @click="setActiveTab('history')"
          >
            {{ $t('Share History') }}
          </div>
        </div>
        <div v-if="activeTab === 'options'" class="tab-content">
          <div class="item-row">
            <div class="item-caption">{{ $t('Share Name') }}:</div>
            <div
              v-if="editingShareName"
              class="item-value"
              :class="{ 'd-flex align-items-center': !isMobile && !isTablet }"
            >
              <InputBox
                :id="'changeShareName'"
                v-model.trim="form.sharename"
                :placeholder="this.$t('Share Names')"
                :focus="true"
                :errors="{}"
                class="mr-auto col-sm-6"
                @keyup="handleShareNameUpdate"
              />

              <button
                :disabled="
                  !form.sharename ||
                  form.sharename === shareProperties.sharename
                "
                class="btn btn-primary"
                @click="saveShareName"
              >
                {{ $t('Save') }}
              </button>
            </div>
            <div
              v-else
              class="item-value"
              :class="{ 'd-flex align-items-center': !isMobile && !isTablet }"
            >
              {{ form.sharename }}
              <span
                v-if="!restrictSharenameChange && !readonly"
                v-a11ybutton="editShareName"
                tabindex="0"
                class="link pl-3"
                @click="editShareName"
              >
                {{ isMobile || isTablet ? $t('Edit') : $t('Change') }}
              </span>
            </div>
          </div>

          <div class="item-row">
            <div class="item-caption">{{ $t('Expires') }}:</div>
            <div
              v-if="editingExpiry"
              class="item-value"
              :class="{ 'd-flex align-items-center': !isMobile && !isTablet }"
            >
              <Radiobox
                v-model="form.willExpire"
                name="expires"
                radio-value="never-expires"
                :label="$t('Never Expires')"
                @change="updateExpiry"
              />
              <Radiobox
                v-model="form.willExpire"
                name="expires"
                radio-value="expires"
                :label="$t('Expires')"
                @change="updateExpiry"
              />
              <DatePicker
                v-if="form.willExpire === 'expires'"
                v-model="form.datePickerModel"
                type="date"
                only-date
                :format="dateFormat"
                :value-type="'format'"
                :default-value="form.datePickerModel"
                :placeholder="dateFormat"
                @change="updateExpiry('expires')"
              />

              <button class="btn btn-primary ml-auto" @click="saveExpiry">
                {{ $t('Save') }}
              </button>
            </div>
            <div
              v-else
              v-a11ybutton="editExpiry"
              :class="{ 'd-flex align-items-center': !isMobile && !isTablet }"
              class="item-value"
              tabindex="0"
              @click="editExpiry"
            >
              <span v-if="isMobile || isTablet">
                <span v-if="form.willExpire !== 'expires'"
                  >{{ $t('Never Expires') }}
                </span>
                <DateDisplay
                  v-else
                  class="d-inline"
                  :date="Date.parse(validityperiod) / 1000"
                  only-date
                />
              </span>
              <span>
                <span
                  v-if="!isMobile && !isTablet && form.willExpire !== 'expires'"
                  >{{ $t('Never Expires') }}</span
                >
                <DateDisplay
                  v-if="!isMobile && !isTablet && form.willExpire == 'expires'"
                  :date="Date.parse(validityperiod) / 1000"
                  only-date
                />
                <span
                  :class="{ link: !readonly }"
                  v-else
                  v-a11ybutton="editExpiry"
                  class="ml-2"
                  @click="editExpiry"
                  >{{ isMobile || isTablet ? $t('Edit') : $t('Change') }}</span
                >
              </span>
            </div>
          </div>

          <div v-if="showMaxDownload" class="item-row">
            <div class="item-caption">{{ $t('Max number of downloads') }}:</div>
            <div
              v-if="editingMaxDownload"
              class="item-value"
              :class="{ 'd-flex align-items-center': !isMobile && !isTablet }"
            >
              <Radiobox
                v-model="form.maxDownload"
                name="max-download"
                radio-value="no-restrictions"
                :label="$t('No Restrictions')"
                @change="updateMaxDownload"
              />
              <Radiobox
                v-model="form.maxDownload"
                name="max-download"
                radio-value="restricted"
                :label="$t('Restricted to')"
                @change="updateMaxDownload"
              />
              <InputBox
                :id="'maxDownload'"
                v-model="form.maxDownloadValue"
                :placeholder="'Max Download Allowed'"
                type="number"
                class="mr-auto col-sm-4"
                :errors="{}"
                @focus="updateMaxDownload('restricted')"
                @keyup="handleMaxDownloadUpdate"
                @input="handleMaxDownloadUpdate"
              />
              <button
                class="btn btn-primary"
                :disabled="Number(form.maxDownloadValue) < 1"
                @click="saveMaxDownload"
              >
                {{ $t('Save') }}
              </button>
            </div>
            <div
              v-else
              :class="{ 'd-flex align-items-center': !isMobile && !isTablet }"
              class="item-value"
              @click="editMaxDownload"
            >
              <span :class="{ link: !isMobile && !isTablet && !readonly }"
                >{{
                  form.maxDownload !== 'no-restrictions'
                    ? form.maxDownloadValue
                    : $t('No Restrictions')
                }}
              </span>
              <span
                v-if="
                  form.downloadCount >= 0 &&
                  form.maxDownload !== 'no-restrictions'
                "
                class="link"
              >
                {{
                  '(' +
                  $tc(
                    '{count} download so far | {count} downloads so far',
                    form.downloadCount
                  ) +
                  ')'
                }}
              </span>

              <span
                v-if="(isMobile || isTablet) && !readonly"
                v-a11ybutton="editMaxDownload"
                class="link"
                tabindex="0"
                @click="editMaxDownload"
                >{{ isMobile || isTablet ? $t('Edit') : $t('Change') }}</span
              >
            </div>
          </div>

          <div v-if="uploadLimitDisplay" class="item-row">
            <div class="item-caption">{{ $t('Upload Size Limit (MB)') }}:</div>
            <div
              v-if="editingsharesizelimit"
              class="item-value"
              :class="{ 'd-flex align-items-center': !isMobile && !isTablet }"
            >
              <Radiobox
                v-model="form.sharesizelimit"
                name="upload-limit"
                radio-value="unlimited"
                :label="$t('Unlimited')"
                @change="updatesharesizelimit"
              />

              <Radiobox
                v-model="form.sharesizelimit"
                name="upload-limit"
                radio-value="limited"
                :label="$t('Limited')"
                @change="updatesharesizelimit"
              />

              <InputBox
                :id="'sharesizelimitValue'"
                v-model="form.sharesizelimitValue"
                :placeholder="$t('Share Upload Limit')"
                type="number"
                :errors="{}"
                class="mr-auto col-sm-4"
                @focus="updatesharesizelimit('limited')"
                @keyup="handlesharesizelimitUpdate"
                @input="handlesharesizelimitUpdate"
              />
              <button
                class="btn btn-primary"
                :disabled="Number(form.sharesizelimitValue) < 1"
                @click="savesharesizelimit"
              >
                {{ $t('Save') }}
              </button>
            </div>
            <div
              v-else
              v-a11ybutton="editsharesizelimit"
              :class="{ 'd-flex align-items-center': !isMobile && !isTablet }"
              class="item-value"
              tabindex="0"
              @click="editsharesizelimit"
            >
              {{
                isMobile || isTablet
                  ? form.sharesizelimit !== 'unlimited'
                    ? form.sharesizelimitValue
                    : $t('Unlimited')
                  : null
              }}
              <span :class="{ link: !readonly }">
                <span v-if="!isMobile && !isTablet">{{
                  form.sharesizelimit !== 'unlimited'
                    ? form.sharesizelimitValue
                    : $t('Unlimited')
                }}</span>
                <span
                  v-else
                  v-a11ybutton="editsharesizelimit"
                  tabindex="0"
                  @click="editsharesizelimit"
                  >{{ isMobile || isTablet ? $t('Edit') : $t('Change') }}</span
                >
              </span>
            </div>
          </div>

          <div class="item-row">
            <div class="item-caption">
              {{ $t('Send Email Notifications') }}:
            </div>
            <div
              v-if="editingEmailNotification"
              class="item-value"
              :class="{ 'd-flex align-items-center': !isMobile && !isTablet }"
            >
              <Radiobox
                v-model="form.hidenotifications"
                name="sendEmail"
                radio-value="yes"
                :label="$t('Yes')"
                @change="updateEmail"
              />
              <Radiobox
                v-model="form.hidenotifications"
                :name="'sendEmail'"
                radio-value="no"
                :label="$t('No')"
                @change="updateEmail"
              />
              <button
                class="btn btn-primary ml-auto"
                @click="saveEmailNotification"
              >
                {{ $t('Save') }}
              </button>
            </div>
            <div
              v-else
              v-a11ybutton="editEmailNotification"
              class="item-value"
              :class="{ 'd-flex align-items-center': !isMobile && !isTablet }"
              tabindex="0"
              @click="editEmailNotification"
            >
              {{
                isMobile || isTablet
                  ? form.hidenotifications === 'yes'
                    ? $t('Yes')
                    : $t('No')
                  : null
              }}
              <span :class="{ link: !readonly }">
                <span v-if="!isMobile && !isTablet">{{
                  form.hidenotifications === 'yes' ? $t('Yes') : $t('No')
                }}</span>
                <span
                  v-else
                  v-a11ybutton="editEmailNotification"
                  tabindex="0"
                  @click="editEmailNotification"
                  >{{ isMobile || isTablet ? $t('Edit') : $t('Change') }}</span
                >
              </span>
            </div>
          </div>

          <div class="item-row">
            <div class="item-caption">
              {{ $t('Sharing Permissions') }}:
              <div
                v-if="form.allowPermission === 'allow-link'"
                class="copy-text"
              >
                <div>
                  {{ $t('This share is') }} <strong> {{ $t('Public') }}</strong>
                </div>
                <div>{{ $t('Anyone with a link can view.') }}</div>
              </div>
              <div
                v-if="form.allowPermission === 'allow-link-password'"
                class="copy-text"
              >
                <div>
                  {{ $t('This share is ') }}
                  <strong>{{ $t('Private.') }}</strong>
                </div>
                <div>{{ $t('Only users link and password can view.') }}</div>
              </div>
              <div
                v-if="form.allowPermission === 'allow-selected'"
                class="copy-text"
              >
                <div>
                  {{ $t('This share is ') }}
                  <strong>{{ $t('Private.') }}</strong>
                </div>
                <div>{{ $t('Only certain users/groups are allowed') }}</div>
              </div>
            </div>
            <div
              class="item-value"
              :class="{ 'd-flex align-items-center': !isMobile && !isTablet }"
            >
              <shareConfirm
                v-if="showAccessGroupModal.modal"
                @allow="updateAllowPerms(showAccessGroupModal.value)"
                @cancel="shareCancel()"
              />
              <div
                v-if="!readonly || shareSettingNotAllowed"
                class="radio-group"
              >
                <div v-if="!readonly && allowPublicSharing">
                  <Radiobox
                    v-tooltip="disalowPubShareText"
                    name="allow"
                    :value="form.allowPermission"
                    radio-value="allow_link"
                    :label="$t('Allow anyone with link')"
                    :disabled="
                      globalShareMode == 2 ||
                      mandatoryProtectedShare ||
                      disalowPubShare
                    "
                    @change="updateAllowPerms"
                  />
                  <AllowPermissionType
                    v-if="form.allowPermission === 'allow_link'"
                    v-model="permissionOp"
                    :permissions="permissions"
                    :upload-form="true"
                    :is-dir="!!shareProperties.isdir"
                    @input="onPermissionChange"
                    @showUploadFormDialog="showUploadFormDialog = true"
                  />
                </div>
                <div
                  v-if="
                    (!readonly ||
                      (shareSettingNotAllowed && isLinkAndPassword)) &&
                    allowPublicSharing
                  "
                >
                  <Radiobox
                    v-tooltip="disalowPubShareText"
                    name="allow"
                    :value="form.allowPermission"
                    radio-value="allow_link_password"
                    :label="$t('Allow anyone with link and a password')"
                    :disabled="globalShareMode == 2 || disalowPubShare"
                    @change="updateAllowPerms"
                  />
                  <div
                    v-if="isLinkAndPassword"
                    class="allow-with-password-dropdown"
                    :class="{ 'form-inline': !isMobile && !isTablet }"
                  >
                    <AllowPermissionType
                      v-if="isLinkAndPassword"
                      v-model="permissionOp"
                      :permissions="permissions"
                      :is-dir="!!shareProperties.isdir"
                      @showUploadFormDialog="showUploadFormDialog = true"
                      @input="onPermissionChange"
                    />
                    <InputBox
                      v-if="isLinkAndPassword"
                      v-model="form.sharepassword"
                      :type="passwordType"
                      :class="{
                        'd-inline-block col-sm-3': !isMobile && !isTablet,
                      }"
                      aria-label="Password"
                      autocomplete="new-password"
                      :placeholder="this.$t('Password')"
                      :errors="{}"
                      :appends="formWithAppends.passwordAppends"
                      @keyup="handlePassword"
                    />
                    <button
                      v-if="permissionOp.label == 'Upload Only'"
                      v-tooltip.right="$t('Upload Form')"
                      class="btn shadow-none btn-outline-secondary btn-sm ml-4"
                      @click="showUploadFormDialog = true"
                    >
                      <Icon name="file-import" family="fas" />
                    </button>
                    <div class="ml-auto">
                      <button
                        v-tooltip="$t('Share link and password')"
                        :aria-label="$t('Copy share link and password')"
                        class="btn btn-primary"
                        @click="copyLinkAndPassword"
                      >
                        <Icon name="share-alt" family="fas" />
                      </button>
                      <button
                        v-if="!readonly"
                        :disabled="
                          !form.sharepassword ||
                          actualPassword === form.sharepassword
                        "
                        class="btn btn-primary"
                        :class="{ 'ml-1': !isMobile && !isTablet }"
                        @click="savePasswordName"
                      >
                        {{ $t('Save') }}
                      </button>
                    </div>
                  </div>
                </div>
                <div v-if="!readonly">
                  <Radiobox
                    ref="groups"
                    name="allow"
                    radio-value="allow_users_groups"
                    :value="form.allowPermission"
                    :label="$t('Allow selected users or groups')"
                    @change="updateAllowPerms"
                  />
                  <div
                    v-if="form.allowPermission === 'allow_users_groups'"
                    class="sub-option"
                  >
                    <ShareUserGroup :shareid="shareProperties.shareid" />
                  </div>
                </div>
              </div>
              <span v-else>{{ permissionName }}</span>
            </div>
          </div>
          <div
            v-if="form.allowPermission === 'allow_users_groups' && readonly"
            class="sub-option"
          >
            <ShareUserGroup
              :shareid="shareProperties.shareid"
              :readonly="readonly"
              :shareSettingNotAllowed="shareSettingNotAllowed"
            />
          </div>
        </div>
        <ShareHistory
          v-if="activeTab === 'history'"
          :shareid="shareProperties.shareid"
        />
      </div>
    </div>
    <UploadForm
      v-if="showUploadFormDialog"
      :show-modal="showUploadFormDialog"
      @hideUploadForm="hideUploadForm"
    />
  </div>
</template>

<script>
import Radiobox from 'common/components/Radiobox';
import InputBox from 'common/components/InputBox';
import ShareUserGroup from './shareUserGroup';
import AllowPermissionType from './allowPermissionType';
import shareSelectPreview from './shareSelectPreview';
import UploadForm from '../Modals/UploadForm';
import ShareHistory from './shareHistory';
import sharePermission from '../../constants/publicSharePermissions';
import DatePicker from 'common/components/NewDatePicker.vue';
import Icon from 'common/components/Icon';
import DateDisplay from 'common/components/DateDisplay';
import shareConfirm from './shareConfirm';
import responsivenessMixin from '@/mixins/responsivenessMixin';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { mapGetters } from 'vuex';

// Allow custom parser string usage
dayjs.extend(customParseFormat);

export default {
  components: {
    shareSelectPreview,
    shareConfirm,
    Radiobox,
    InputBox,
    ShareUserGroup,
    AllowPermissionType,
    DatePicker,
    ShareHistory,
    Icon,
    UploadForm,
    DateDisplay,
  },
  mixins: [responsivenessMixin],
  props: {
    shareStatus: {
      type: Object,
      default: () => {},
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    shareSettingNotAllowed: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      radioShare: '',
      editingShareName: false,
      editingExpiry: false,
      editingMaxDownload: false,
      editingsharesizelimit: false,
      editingEmailNotification: false,
      showUploadFormDialog: false,
      showAccessGroupModal: {
        modal: false,
        value: '',
      },
      renderComponent: true,
      activeTab: 'options',
      sharepassword: '',
      permissionOp: sharePermission[0],
      form: {
        sharename: '',
        maxDownload: '1',
        maxDownloadValue: '1',
        validityperiod: null,
        hideNotificationval: '',
        sharesizelimit: '',
        sharesizelimitValue: '1',
        downloadCount: 0,
      },
      datePickerModel: dayjs(new Date()).format(this.dateFormat),
      permissions: sharePermission,
      actualPassword: false,
      passwordType: 'password',
      toastClipBoard: {
        message:
          '<b>' +
          this.$t('Copied') +
          '</b><p role="alert">' +
          this.$t('Share link and password copied.') +
          '.</p>',
        type: 'success',
      },
    };
  },
  computed: {
    ...mapGetters('core', ['isTrialMode', 'isHostedCloud']),
    meta() {
      return this.$store.getters['files/getPathMeta'](
        this.currentlySharedFile.dirpath
      );
    },
    isTeamfolderRoot() {
      return !!this.meta?.teamfolderroot;
    },
    allowPublicSharing() {
      return (
        !this.isTeamFolderRoot ||
        this.systemStatus.allowPublicShareTopLevelTeamFolder
      );
    },
    systemStatus() {
      return this.$store.state.core.fullSystemStatus;
    },
    dateFormat() {
      if (typeof this.systemStatus.dateformat === 'undefined') {
        // Default date format
        return 'DD-MM-YYYY';
      }
      return this.systemStatus.dateformat
        ? this.systemStatus.dateformat.toUpperCase()
        : this.systemStatus.defaultdateformat.toUpperCase();
    },
    uploadLimitDisplay() {
      return (
        this.currentlySharedFile.type !== 'file' &&
        (this.permissionOp.allowpublicuploadonly ||
          this.permissionOp.allowpublicupload ||
          !this.shareProperties.allowpublicaccess)
      );
    },
    showMaxDownload() {
      return (
        !this.isPrivateShare &&
        (this.currentlySharedFile.type === 'file' ||
          this.currentlySharedFile.isdir == 0)
      );
    },
    isLinkAndPassword() {
      return this.form.allowPermission === 'allow_link_password';
    },
    shareProperties() {
      return this.$store.state.share.shareProperties;
    },
    currentlySharedFile() {
      return this.$store.state.share.shareFile;
    },
    restrictSharenameChange() {
      return this.$store.state.core.customization.RESTRICTSHARENAMECHANGE === 1;
    },
    filteredPermissions() {
      return this.currentlySharedFile?.encryptedfolder === 1
        ? this.permissions.filter((item) => !item.hideForEncryptedFolder)
        : this.permissions;
    },
    disalowPubShare() {
      return (
        this.isTrialMode &&
        this.isHostedCloud &&
        !this.currentlySharedFile.isdir
      );
    },
    disalowPubShareText() {
      return this.disalowPubShare
        ? this.$t(
            'Sharing of a single file during trial is disabled due to potential misuse for phishing. It will be fully available after purchase.'
          )
        : '';
    },
    permissionName() {
      let n = '';
      switch (this.form.allowPermission) {
        case 'allow_link':
          n = this.$t('Anyone with a link can view.');
          break;
        case 'allow_link_password':
          n = this.$t('Only users link and password can view.');
          break;
        case 'allow_users_groups':
          n = this.$t('Only certain users/groups are allowed');
          break;
        default:
          break;
      }

      return n;
    },
    mandatoryProtectedShare() {
      return (
        this.$store.state.core.fullSystemStatus
          .mandatorypasswordprotectedshare == 1
      );
    },
    maxdownloads() {
      let { maxdownloads } = this.shareProperties;
      return maxdownloads ? maxdownloads : 'no-restrictions';
    },
    sharesizelimit() {
      let { sharesizelimit } = this.shareProperties;
      return sharesizelimit ? sharesizelimit : 'unlimited';
    },
    validityperiod() {
      let { validityperiod } = this.shareProperties;
      return validityperiod && Object.keys(validityperiod).length
        ? validityperiod
        : 'Never';
    },
    formWithAppends() {
      return {
        passwordAppends: [
          {
            icon: this.passwordType == 'password' ? 'eye' : 'eye-slash',
            iconBold: true,
            action: this.togglePasswordType,
          },
        ],
      };
    },
    userGroupSelected() {
      return this.form.allowPermission === 'allow_users_groups';
    },
    globalShareMode() {
      return this.$store.state.core.systemstatus.SHAREMODE;
    },
    isPrivateShare() {
      return this.form.allowPermission === 'allow_users_groups';
    },
  },
  mounted() {
    this.populateForm();
    this.populatePermission();
    if (this.shareProperties.ispublicsecure) {
      this.getsharepassword();
    }
  },
  methods: {
    resetUpdateSharePropertiesValue() {
      this.$store.dispatch('share/resetUpdateSharePropertiesValue');
    },
    setUpdateSharePropertiesValue(payload) {
      this.$store.dispatch('share/setUpdateSharePropertiesValue', payload);
    },
    populateForm() {
      this.datePickerModel =
        this.validityperiod === 'Never'
          ? dayjs(new Date()).format(this.dateFormat)
          : this.dateFormatted(this.shareProperties.validityperiod);

      let password = this.form.sharepassword || '';
      this.actualPassword = password;
      this.resetUpdateSharePropertiesValue();
      this.form = {
        sharename: this.shareProperties.sharename,
        willExpire:
          this.validityperiod === 'Never' ? 'never-expires' : 'expires',
        allowPermission: this.shareStatus.key,
        datePickerModel: this.datePickerModel,
        maxDownload:
          this.maxdownloads === 'no-restrictions'
            ? 'no-restrictions'
            : 'restricted',
        maxDownloadValue:
          this.maxdownloads === 'no-restrictions' ? '1' : this.maxdownloads,
        downloadCount:
          this.maxdownloads === 'no-restrictions'
            ? '0'
            : this.shareProperties.downloadcount,
        sharesizelimit:
          this.sharesizelimit === 'unlimited' ? 'unlimited' : 'limited',
        sharesizelimitValue:
          this.sharesizelimit === 'unlimited'
            ? '1'
            : this.sharesizelimit / 1000,
        hidenotifications: this.shareProperties.hidenotifications
          ? 'no'
          : 'yes',
        sharepassword: password,
      };
    },
    shareCancel() {
      this.$refs.groups.$el.click();
      this.showAccessGroupModal.modal = false;
    },
    async updateAllowPerms(value) {
      if (this.form.allowPermission === value) return;
      if (
        this.form.allowPermission === 'allow_users_groups' &&
        this.showAccessGroupModal.modal === false
      ) {
        this.showAccessGroupModal.modal = true;
        this.showAccessGroupModal.value = value;
        return;
      }
      this.showAccessGroupModal.modal = false;
      const oldAllowPermission = this.form.allowPermission;
      this.form.allowPermission = value;
      let allowpublicaccess = 0;
      let sharepassword = '';
      switch (value) {
        case 'allow_link':
          allowpublicaccess = 1;
          break;
        case 'allow_users_groups':
          allowpublicaccess = 0;
          break;
        case 'allow_link_password': {
          allowpublicaccess = 1;
          const response = await this.$store.dispatch(
            'share/getRandomPassword'
          );
          if (response.data.result) {
            sharepassword = response.data.message;
          }
          break;
        }
      }
      // update sharepassword in form, share and delta
      this.form.sharepassword = sharepassword;

      this.$store.state.share.updateShareProperties.sharepassword =
        sharepassword;
      this.$store.state.share.shareProperties.sharepassword = sharepassword;

      const permissionsResponse = await this.$store.dispatch(
        'share/addSharePermissions',
        {
          shareid: this.shareProperties.shareid,
          allowpublicaccess: allowpublicaccess,
          allowpublicupload: 0,
          allowpublicviewonly: 0,
          allowpublicuploadonly: 0,
          sharepassword: this.form.sharepassword,
        }
      );

      if (!permissionsResponse.ok || permissionsResponse.data.meta.error) {
        this.$toast.open({
          message: `<b>${this.$t('Error')}</b><p role="alert">${
            permissionsResponse.error || permissionsResponse.data.meta.error
          }</p>`,
          type: 'error',
        });
        this.form.allowPermission = oldAllowPermission;
        return;
      }

      if (value === 'allow_link' || value === 'allow_link_password') {
        this.permissionOp = sharePermission[0];
      }

      if (allowpublicaccess) {
        this.$emit('saveChanges');
      } else {
        await this.$store.dispatch('share/getShareForId', {
          shareid: this.shareProperties.shareid,
        });
        this.populateForm();
      }
    },
    populatePermission() {
      let selvalue = 0;
      if (this.shareProperties.allowpublicuploadonly) {
        selvalue = 4;
      } else if (this.shareProperties.allowpublicviewonly) {
        //view only
        if (this.shareProperties.allowpublicupload) {
          //view + upload
          selvalue = 2;
        } else {
          //view + no upload
          selvalue = 3;
        }
      } else {
        if (this.shareProperties.allowpublicupload) {
          selvalue = 1;
        }
      }
      this.permissionOp = this.permissions[selvalue];
    },
    async getsharepassword() {
      let response = await this.$store.dispatch('share/getSharePassword', {
        shareid: this.shareProperties.shareid,
      });
      if (response.data.result) {
        this.form.sharepassword = response.data.message;
        this.$store.state.share.shareProperties.sharepassword =
          this.form.sharepassword;
        this.actualPassword = this.form.sharepassword;
      }
    },
    async onPermissionChange() {
      await this.$store.dispatch('share/addSharePermissions', {
        shareid: this.shareProperties.shareid,
        allowpublicaccess: 1,
        allowpublicupload: this.permissionOp.allowpublicupload,
        allowpublicviewonly: this.permissionOp.allowpublicviewonly,
        allowpublicuploadonly: this.permissionOp.allowpublicuploadonly,
        sharepassword: this.form.sharepassword,
      });
    },
    editShareName() {
      this.editingShareName = true;
    },
    handleShareNameUpdate(e) {
      if (e.key == 'Escape') {
        this.editingShareName = false;
        this.form.sharename = this.$store.state.share.shareProperties.sharename;
      } else if (e.key == 'Enter') {
        this.saveShareName();
      }
      this.setUpdateSharePropertiesValue({
        sharename: this.form.sharename,
      });
    },
    saveShareName() {
      this.editingShareName = false;
      this.$store.state.share.shareProperties.sharename = this.form.sharename;
      this.$emit('saveChanges');
    },
    savePasswordName() {
      this.$store.state.share.shareProperties.sharepassword =
        this.form.sharepassword;
      this.setUpdateSharePropertiesValue({
        sharepassword: this.form.sharepassword,
      });
      this.$emit('saveChanges');
    },
    editExpiry() {
      if (!this.readonly) {
        this.editingExpiry = true;
      }
    },
    saveExpiry() {
      this.editingExpiry = false;
      this.$emit('saveChanges');
    },
    updateExpiry(value) {
      this.setUpdateSharePropertiesValue({
        validityperiod:
          value === 'expires'
            ? dayjs(this.form.datePickerModel, this.dateFormat).format(
                'YYYY-MM-DD HH:mm:ss'
              )
            : '',
      });
    },
    editMaxDownload() {
      if (!this.readonly) {
        this.editingMaxDownload = true;
      }
    },
    saveMaxDownload() {
      this.editingMaxDownload = false;
      this.$emit('saveChanges');
    },
    updateMaxDownload(value) {
      if (value === 'restricted') {
        this.form.maxDownload = value;
        this.setUpdateSharePropertiesValue({
          maxdownloads: this.form.maxDownloadValue,
        });
      } else {
        this.setUpdateSharePropertiesValue({
          maxdownloads: null,
        });
      }
    },

    editsharesizelimit() {
      if (!this.readonly) {
        this.editingsharesizelimit = true;
      }
    },
    savesharesizelimit() {
      this.editingsharesizelimit = false;
      this.$emit('saveChanges');
    },
    updatesharesizelimit(value) {
      if (value === 'limited') {
        this.form.sharesizelimit = value;
        this.setUpdateSharePropertiesValue({
          sharesizelimit: this.form.sharesizelimitValue,
        });
      } else {
        this.setUpdateSharePropertiesValue({
          sharesizelimit: null,
        });
      }
    },

    handleMaxDownloadUpdate() {
      this.setUpdateSharePropertiesValue({
        maxdownloads: this.form.maxDownloadValue,
      });
    },
    handlesharesizelimitUpdate() {
      this.setUpdateSharePropertiesValue({
        sharesizelimit: this.form.sharesizelimitValue,
      });
    },
    editEmailNotification() {
      if (!this.readonly) {
        this.editingEmailNotification = true;
      }
    },
    saveEmailNotification() {
      this.editingEmailNotification = false;
      this.$emit('saveChanges');
    },
    updateEmail(value) {
      this.form.hidenotifications = value;
      this.setUpdateSharePropertiesValue({
        hidenotifications: this.form.hidenotifications === 'no' ? 1 : 0,
      });
    },
    setActiveTab(tab) {
      this.activeTab = tab;
    },
    handlePassword(event) {
      const password = event.target.value;
      if (password != this.actualPassword) {
        this.setUpdateSharePropertiesValue({
          sharepassword: password,
        });
      } else {
        this.$store.dispatch(
          'share/removeUpdateSharePropertiesValue',
          'sharepassword'
        );
      }
    },
    notBeforeToday(date) {
      return date < new Date(new Date().setHours(0, 0, 0, 0));
    },
    hideUploadForm() {
      this.showUploadFormDialog = false;
    },
    togglePasswordType() {
      this.passwordType =
        this.passwordType === 'password' ? 'text' : 'password';
    },
    dateFormatted(when) {
      return dayjs(when).format(this.dateFormat);
    },
    async copyLinkAndPassword() {
      const text = `${this.shareProperties.shareurl}\npassword: ${this.shareProperties.sharepassword}`;
      let toasts = this.$toast;
      let toastClipBoard = this.toastClipBoard;
      this.$emit('copyUrlPassword', text, toasts, toastClipBoard);
    },
  },
};
</script>
