<template>
  <li class="transactions-list-item">
    <div class="transaction-icon">
      <Icon family="fas" name="edit" />
      <div
        v-if="
          transaction.status !== 'processing' &&
          transaction.status !== 'preparing'
        "
        class="transaction-icon-status"
        :class="{
          [`transaction-icon-status--error`]: transaction.status === 'failed',
          [`transaction-icon-status--cancelled`]:
            transaction.status === 'cancelled',
        }"
      >
        <Icon
          v-if="transaction.status === 'failed'"
          family="fas"
          name="times"
        />
        <Icon
          v-else-if="transaction.status === 'cancelled'"
          family="fas"
          name="ban"
        />
        <Icon v-else family="fas" name="check" />
      </div>
    </div>
    <div class="transaction-info">
      <span class="transaction-info-title" v-tooltip="{ content: tooltipContent }">
        {{ currentPath !== transaction.dirpath ? `${transaction.dirpath}/` : ''
        }}{{ transaction.to }}
        <small v-if="transaction.status === 'processing'" role="status">{{
          transaction.undo ? $t('Undoing Rename') : $t('Renaming')
        }}</small>
        <small v-else></small>
      </span>
      <div v-if="transaction.status === 'processing'" class="progress">
        <div
          class="progress-bar w-10 indeterminate"
          role="progressbar"
          aria-valuenow="75"
          aria-valuemin="0"
          aria-valuemax="100"
          :aria-valuetext="$t('Renaming...')"
        ></div>
      </div>
      <span v-else-if="transaction.message" class="description text-truncate" role="status">{{
        transaction.message
      }}</span>
      <span
        v-else-if="transaction.status === 'failed'"
        class="description"
        role="alert"
      >
        {{ $t('Error while renaming') + ' ' }}
        <span v-if="!isMobile">{{
          $t('from "{from}"', { from: transaction.from })
        }}</span>
      </span>
      <span v-else-if="transaction.undo" class="description text-truncate" role="alert">
        {{ $t('Successfully renamed') + ' ' }}
        <span v-if="!isMobile">{{
          $t('back from "{from}"', { from: transaction.from })
        }}</span>
      </span>
      <span v-else class="description text-truncate" role="alert">
        {{ $t('Successfully renamed') + ' ' }}
        <span v-if="!isMobile">{{
          $t('from "{from}"', { from: transaction.from })
        }}</span>
      </span>
    </div>
    <div v-if="transaction.status === 'completed'" class="transaction-actions">
      <slot></slot>
    </div>
    <div v-else class="transaction-actions">
      <button
        v-if="transaction.status === 'completed' && !transaction.undo"
        v-tooltip="{ delay: 1000, content: 'Undo operation' }"
        class="btn btn-icon"
        :aria-valuetext="$t('Undo operation...')"
        @click="undo"
      >
        <Icon family="fas" name="undo" />
        <Icon class="icon-overlay" family="fas" name="undo" />
      </button>
    </div>
  </li>
</template>
<script>
import Icon from 'common/components/Icon';
import responsivenessMixin from '@/mixins/responsivenessMixin';

export default {
  components: {
    Icon,
  },
  mixins: [responsivenessMixin],
  props: {
    transaction: {
      type: Object,
      default: () => {},
    },
    currentPath: {
      type: String,
      default: '',
    },
  },
  methods: {
    undo() {
      this.$store.dispatch('files/undoTransaction', {
        id: this.transaction.id,
      });
    },
  },
  computed: {
    tooltipContent() {
      if (this.currentPath !== this.transaction.dirpath) {
        return `${this.transaction.dirpath}/`;
      } else {
        return `${this.transaction.to}`;
      }
    },
  },
};
</script>
