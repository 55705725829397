let schemas = {};

schemas['core/sample-endpoint'] = {
  'resources.IsLoggedIn': {
    type: 'boolean',
  },
  'resources.SystemName': {
    type: 'string',
    default: '',
  },
  'resources.List': {
    isArray: true,
    default: '',
    type: 'string',
  },
  'resources.SubItems': {
    isArray: true,
  },
  'resources.EmptyList': {
    isArray: true,
  },
};
//quickshare
schemas['app/websharepro/quickshare'] = {
  'shares.share.shareid': {
    type: 'string',
    default: '',
  },
};
//getshareforpath
schemas['core/getshareforpath'] = {
  'shares.share.shareid': {
    type: 'string',
    default: '',
  },
};
//getshareforid
schemas['app/websharepro/getshareforid'] = {
  'shares.share.shareid': {
    type: 'string',
    default: '',
  },
};

// ADMINS
// admin getroleusers
schemas['admin/getroleusers'] = {
  'role_users.role_user.username': {
    type: 'string',
    empty: false,
  },
  'role_users.role_user.displayname': {
    type: 'string',
    empty: false,
  },
};

// admin groups admins
schemas['admin/getgroupadmins'] = {
  'groupadmin.admin.username': {
    type: 'string',
    empty: false,
  },
};

// admin search
schemas['admin/search'] = {
  'users.user.username': {
    type: 'string',
    empty: false,
  },
  'users.user.displayname': {
    type: 'string',
    empty: false,
  },
};

// GROUPS
// admin groups
schemas['admin/getmembersforgroup'] = {
  'members.member.name': {
    type: 'string',
    empty: false,
  },
  'members.member.displayname': {
    type: 'string',
    empty: false,
  },
};
// admin groups
schemas['admin/updategroup'] = {
  'groups.group.groupname': {
    type: 'string',
    empty: false,
  },
  'groups.group.groupid': {
    type: 'string',
    empty: false,
  },
};
// admin groups
schemas['admin/getgroupbyname'] = {
  'groups.group.groupname': {
    type: 'string',
    empty: false,
  },
  'groups.group.groupid': {
    type: 'string',
    empty: false,
  },
};
// admin groups
schemas['admin/getgroups'] = {
  'groups.group.groupname': {
    type: 'string',
    empty: false,
  },
  'groups.group.groupid': {
    type: 'string',
    empty: false,
  },
};
// admin /admin/getadgroupmembers
schemas['admin/getadgroupmembers'] = {
  'entries.entry.name': {
    type: 'string',
    empty: false,
  },
};
// admin /admin/getadgroupmembers
schemas['admin/getadgroups'] = {
  'entries.entry.group': {
    type: 'string',
    empty: false,
  },
};

// SYSTEM
// admin getsystemstatus
schemas['admin/getsystemstatus'] = {
  'systemstatus.status.peerid': {
    type: 'string',
    empty: false,
  },
  'systemstatus.status.displayname': {
    type: 'string',
    empty: false,
  },
  'systemstatus.status.currentprofile': {
    type: 'string',
    empty: false,
  },
};

// admin admin/getadminusers
schemas['admin/getadminusers'] = {
  'adminusers.adminuser.name': {
    type: 'string',
    empty: false,
  },
};

// admin /admin/dlpgetallrules
schemas['admin/dlpgetallrules'] = {
  'rules1.rule.rulename': {
    type: 'string',
    empty: false,
  },
};

export default schemas;
