import Vue from 'vue';
import VueObserveVisibility from 'vue-observe-visibility';
import VueClickOutside from 'v-click-outside';
import VueTooltip from 'v-tooltip';
import VueShortkey from 'vue-shortkey';
import VueToast from 'vue-toast-notification';
import VueHeadful from 'vue-headful';
import Mentionable from 'vue-mention';
import 'vue-toast-notification/dist/theme-sugar.css';
import VueContentPlaceholders from 'vue-content-placeholders';
import VueContext from 'vue-context';
import vSelect from 'vue-select';
import Croppa from 'vue-croppa';
import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';
import 'common/filecloud/scss/base.scss';
import 'vue-select/dist/vue-select.css';
import 'vue-croppa/dist/vue-croppa.css';
import 'animate.css/animate.min.css';
import Credits from './credits.js';
import VueClipboard from 'vue-clipboard2';
import VueDatetimeJs from 'vue-datetime-js';

import store from './store';
import router from './router';

import { i18n } from './i18n.js';
import { isMobileDevice } from 'common/utils/responsive.js';
import {
  a11yButtonPressDirective,
  addA11yClassListener,
} from 'common/utils/a11yUtils';

import App from './App.vue';
import PortalVue from 'portal-vue';

Vue.config.productionTip = false;

vSelect.props.components.default = () => ({
  OpenIndicator: { render: (c) => c('span', '') },
});

Vue.use(PortalVue);

Vue.use(VueObserveVisibility);
Vue.use(VueClickOutside);
Vue.use(VueTooltip, {
  defaultBoundariesElement: 'window',
});
Vue.use(VueTelInput);

Vue.component('new-date-picker', VueDatetimeJs);

Vue.use(VueShortkey, {
  prevent: ['input', 'textarea', '.editr--content', 'div[contenteditable]'],
});
Vue.use(Mentionable);
Vue.use(VueContext);
Vue.use(VueContentPlaceholders);
Vue.use(VueClipboard);
Vue.use(Croppa);

Vue.use(VueToast, {
  position: isMobileDevice() ? 'bottom' : 'top-right',
  duration: 5000,
  dismissible: true,
  queue: false,
});

Vue.component('v-select', vSelect);
Vue.component('vue-headful', VueHeadful);

if (isMobileDevice) addA11yClassListener();

Vue.directive('a11ybutton', a11yButtonPressDirective);

//if (process.env.NODE_ENV !== 'production') {
// const VueAxe = require('vue-axe').default;
// Vue.use(VueAxe, {
//   // clearConsoleOnUpdate: true
// });
//}

Vue.use(Credits, { i18n });

new Vue({
  i18n,
  render: (h) => h(App),
  router,
  store,
}).$mount('#app');
