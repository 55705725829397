export const fileEventParamsGroups = [
  {
    label: 'If file is created',
    value: 'ADDFILE,ADDEXTERNALFILE',
  },
  {
    label: 'If file is created or updated',
    value: 'ADDFILE,ADDEXTERNALFILE,UPDATEFILE,UPDATEEXTERNALFILE',
  },
  {
    label: 'If file is updated',
    value: 'UPDATEFILE,UPDATEEXTERNALFILE',
  },
  {
    label: 'If folder is created',
    value: 'ADDFOLDER,ADDEXTERNALFOLDER',
  },
  {
    label: 'If file is downloaded or previewed',
    value: 'DOWNLOADFILE',
  },
];

export const shareEventParamsGroups = [
  {
    label: 'If a share is created or updated',
    value: 'CREATESHARE',
  },
];

export const nodeTypes = {
  waitForApproval: 'WAIT_FOR_APPROVAL_STEP',
  waitForShareApproval: 'SHARE_APPROVAL_STEP',
  conditional: 'CONDITION_STEP',
};

export const nodeTriggers = {
  fileEvent: 'FILE_EVENT_TRIGGER',
  shareEvent: 'SHARE_EVENT_TRIGGER',
  timeBased: 'TIME_TRIGGER',
};

export const nodeTypesParams = {
  customMessage: 'CUSTOM_MESSAGE_PARAMETER',
  approvalCount: 'APPROVAL_COUNT_PARAMETER',
  periodic: 'PERIODIC_PARAMETER',
  endDateTime: 'END_DATETIME_PARAMETER',
};

export const periodicTriggerOptions = {
  once: 'Run Once',
  daily: 'Daily',
  weekly: 'Weekly',
};

export const validationSchema = {
  new_variable: {
    type: { type: 'string', empty: false },
    name: {
      type: 'string',
      max: 255,
      empty: false,
      pattern: /^$|^[a-zA-Z0-9_-]+$/,
    },
  },
};

export default {
  fileEventParamsGroups,
  shareEventParamsGroups,
  nodeTypes,
  nodeTypesParams,
  nodeTriggers,
  validationSchema,
};
