<template>
  <div>
    <label v-if="label" :for="id">{{ label }}{{ required ? '*' : '' }}</label>
    <wysiwyg
      v-model="editorContent"
      :class="{
        'editr--toolbarless': !showToolbar,
        'editr--wraptoolbar': wrapToolbar,
      }"
      :placeholder="placeholder"
      @change="onUpdate"
    />
  </div>
</template>

<script>
import '../scss/vendors/_wysiwyg.scss';

export default {
  props: {
    id: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    errors: {
      type: Object,
      default: () => {
        return {};
      },
    },
    showToolbar: {
      type: Boolean,
      default: false,
      required: false,
    },
    wrapToolbar: {
      type: Boolean,
      default: false,
      required: false,
    },
    required: {
      type: Boolean,
      default: false,
      required: false,
    },
    type: {
      type: String,
      required: false,
      default: () => '',
    },
  },
  data() {
    return {
      content: this.value,
      editorContent: '',
    };
  },
  watch: {
    value() {
      // on value change, update the content
      this.content = this.getDocumentFromHTMLString(this.value);
      this.editorContent = this.content.body.innerHTML;
    },
  },
  mounted() {
    // Parse initial content to a dom document
    this.content = this.getDocumentFromHTMLString(this.value);
    // Set initial editor's content to only it's body innet html
    this.editorContent = this.content.body.innerHTML;

    this.$nextTick(() => {
      if (this.type) {
        const element = this.$el.querySelector('.editr--content');
        element.dataset.type = this.type;
      }
    });
  },
  methods: {
    onUpdate(newValue) {
      // Update the reference body innerHTML
      this.content.body.innerHTML = newValue;
      // Emmit the entire HTML
      this.$emit('input', this.content.documentElement.outerHTML);
    },
    getDocumentFromHTMLString(string) {
      const parser = new DOMParser();
      return parser.parseFromString(string, 'text/html');
    },
  },
};
</script>
