<template>
  <div
    ref="wrapper"
    v-click-outside="() => (hasFocus = false)"
    v-shortkey="shortkeys"
    class="file-list-wrapper"
    :class="{
      ['multiple-file-selected']: multipleFileSelected,
      ['p-0']: isLockedFiles || isDRMAccessFiles || isAclpermissionFiles,
    }"
    @click="() => (hasFocus = true)"
    @contextmenu.prevent="emptyContextMenu"
    @shortkey="onShortcut"
  >
    <!-- region Custom List -->
    <div v-if="hasCustomList && !isModal" class="d-flex custom-list-header">
      <transition name="niceDrop" mode="out-in">
        <h3 v-if="!selectedFiles.length || true">
          <Icon family="fas" :name="headerIcon" />
          {{ headerText }}
          <a
            v-if="(isMobile || isTablet) && customFilesTotal > 3 && !loading"
            class="header-collapse"
            href="#"
            @click="$emit('toggleHeader')"
            >{{ headerCollapsed ? $t('Show all') : $t('Show less') }}</a
          >
        </h3>
        <div
          v-else
          v-a11ybutton="onSelectAll"
          class="selected-count-wrap col-checkbox d-flex align-items-center"
          tabindex="0"
          @click="onSelectAll"
        >
          <Checkbox
            tabindex="-1"
            :checked="
              selectedFiles.length > 0 &&
              selectedFiles.length === actualFiles.length
            "
            :label="$t('Select all')"
          />
          <span class="selected-count">{{ selectedCount }}</span>
        </div>
      </transition>
      <transition name="fade">
        <div
          v-if="!!selectedFiles.length && false"
          class="selection-action d-flex"
        >
          <button class="btn btn-primary" @click="executeBulk('download')">
            {{ $t('Download') }}
          </button>
        </div>
      </transition>
    </div>
    <!-- endregion -->

    <!-- region Inputs -->
    <input
      ref="file"
      type="file"
      multiple
      hidden
      :aria-label="$t('Upload Files')"
      @change="(e) => onChooseFile(e, onClearSelect)"
    />
    <input
      ref="folder"
      type="file"
      webkitdirectory
      allowdirs
      hidden
      :aria-label="$t('Upload Folder')"
      @change="(e) => onChooseFolder(e, onClearSelect)"
    />
    <!-- endregion -->

    <Drop
      v-cloak
      drop-effect="copy"
      class="file-list-content"
      :class="{ [`is-dragging`]: isDragActive }"
      @drop="onDropFile"
      @dragenter="onDragEnter"
      @dragleave="onDragLeave"
    >
      <FileListEmpty
        v-if="noListToDisplay"
        :list-name="customListName"
        :folder-has-error="folderHasError"
        :folder-error-message="folderErrorMessage"
        :is-recycle-bin="isRecycleBin"
        :can-upload="!!canUpload"
        :filter-value.sync="filterValue"
      />
      <div v-else-if="meta && meta.canupload" class="droppable-wrapper">
        <div class="droppable-box"></div>
      </div>
      <!-- endregion -->

      <div
        class="file-list"
        role="grid"
        aria-readonly="true"
        aria-hidden="true"
        :class="{
          'file-list--auto-scrolling':
            isAutomaticallyScrolling && shouldFadeScrolling,
          'file-list--animated': isExpanding,
          'has-custom-list': hasCustomList,
          'selection-made': !!selectedFiles.length,
          'file-list--modal': isModal,
          'file-list--drm': isDRMAccessFiles,
        }"
      >
        <SharedByMeHeader
          v-if="
            isSharedByMe &&
            !isPicker &&
            (files.length || filterValue !== '' || loading)
          "
          :is-mobile="isMobile"
          :is-tablet="isTablet"
          :selected-files="selectedFiles"
          :errors="errors"
          :actual-files="actualFiles"
          :filter-value.sync="filterValue"
          @onFilter="onFilter"
          @onSelectAll="onSelectAll"
        />
        <FileListHeader
          v-else-if="
            !hasCustomList &&
            !isPicker &&
            (files.length || filterValue !== '' || loading)
          "
          :is-mobile="isMobile"
          :is-tablet="isTablet"
          :is-public-share-view="isPublicShareView"
          :sort="sort"
          :errors="errors"
          :selected-files="selectedFiles"
          :actual-files="actualFiles"
          :current-path="currentPath"
          :filter-value.sync="filterValue"
          @onSelectAll="onSelectAll"
          @onFilter="onFilter"
          @onToggleGrid="toggleGrid"
          @onSort="onSort"
        />
        <FileListPickerHeader
          v-else-if="
            !hasCustomList &&
            isPicker &&
            (files.length || filterValue !== '' || loading)
          "
          :is-mobile="isMobile"
          :sort="sort"
          :meta="meta"
          :error="errors"
          @onFilter="onFilter"
          @onSort="onSort"
        />
        <div
          v-if="isCreatingNewItem && newItemPath === currentPath"
          class="file-list-body file-list--new-folder"
        >
          <div role="listbox" class="file-list-row">
            <div
              class="file-list-item file-list-item--file no-hover"
              role="row"
            >
              <div class="col-title">
                <Icon
                  v-if="isCreatingNewFolder"
                  class="file-icon file-icon--folder"
                  family="fas"
                  name="folder"
                />
                <FileIcon v-else :name="newItemName" />

                <input
                  ref="newItemInput"
                  v-model="newItemName"
                  type="text"
                  class="form-control rename-input"
                  :disabled="
                    $store.state.loading['files/createFolder'] ||
                    $store.state.loading['files/createFile']
                  "
                  @blur="onCreateItem"
                  @keyup.escape="onCancelCreateItem"
                  @keyup.enter="onCreateItem"
                />
              </div>

              <div class="col-info text-danger">
                {{ newItemError }}
              </div>
            </div>
          </div>
        </div>
        <div v-if="loading || fileOperationLoader" class="file-list-body">
          <FileListLoader :is-custom-list="hasCustomList" />
        </div>
        <!-- region Desktop File List  -->
        <RecycleScroller
          v-else-if="!isMobile && !isTablet"
          v-slot="{ item }"
          class="file-list-body"
          :class="{ 'file-list-body--grid': getIsGrid }"
          :items="files"
          :buffer="filelistScrollBuffer"
          key-field="id"
          size-field="height"
          :item-size="!getIsGrid ? null : getLayout.height"
          page-mode
          role="presentation"
          aria-hidden="true"
        >
          <!-- region Grid View -->
          <div v-if="getIsGrid" class="file-list-row">
            <div
              v-for="gridItem in item.chunk"
              :key="gridItem.id"
              :class="layoutType"
              class="file-list-column"
              :style="{
                width: `${100 / itemsPerRow}%`,
                maxWidth: `${100 / itemsPerRow}%`,
              }"
            >
              <FileItemLoader v-if="!gridItem.type" get-is-grid />
              <FileGridItem
                v-else
                :ref="`griditem` + gridItem.id"
                :selection="selectedFiles"
                :custom-list-name="customListName"
                :has-custom-list="hasCustomList"
                :item="gridItem"
                :is-public-share="isPublicShareView"
                :is-picker="isPicker"
                :is-backup-folder-sibling="isBackupFolderParent"
                :disable-hover="disableHover"
                :from-home="fromHome"
                :is-kb-focused="currentFocusedId === gridItem.id"
                :is-compact-mode="isCompactMode"
                @selected="onSelect"
                @clearSelect="onClearSelect"
                @preview="onPreview"
                @expanded="onExpand"
                @browsed="browse"
                @downloadFile="onDownloadFile"
                @lockFile="onLockFile"
                @unlockFile="unlockFile"
                @copyFile="onCopyFile"
                @commentFile="onCommentFile"
                @moveFile="onMoveFile"
                @restoreFile="onRestoreFile"
                @versions="onVersions"
                @getDirectLink="onGetDirectLink"
                @runQuickEdit="runQuickEdit"
                @context-menu="contextMenu"
                @refresh="$emit('refresh')"
                @refreshFolder="refreshCurrentFolder"
                @toggleMoreOptions="toggleMoreOptions"
                @sendForApproval="sendForApproval"
                @renameShare="(params) => renameShare(params, 'griditem')"
                @shareNonSharedRootFolder="setSharingRootFolder"
              />
            </div>
          </div>
          <!-- endregion -->

          <!-- region List View -->
          <div
            v-else
            class="file-list-row"
            role="rowgroup"
            style="height: 100px"
          >
            <div v-if="!item.type && isTeamFolder"></div>
            <FileItemLoader
              v-else-if="!item.type && !isSharedByMe && !isTeamFoldersPage"
            />
            <SharedByMe
              v-else-if="isSharedByMe"
              :selection="selectedFiles"
              :custom-list-name="customListName"
              :has-custom-list="hasCustomList"
              :item="item"
              :is-picker="isPicker"
              :from-home="fromHome"
              :is-kb-focused="currentFocusedId === item.id"
              :is-compact-mode="isCompactMode"
              @selected="onSelect"
              @clearSelect="onClearSelect"
              @preview="onPreview"
              @expanded="onExpand"
              @browsed="browse"
              @downloadFile="onDownloadFile"
              @lockFile="onLockFile"
              @unlockFile="unlockFile"
              @commentFile="onCommentFile"
              @copyFile="onCopyFile"
              @moveFile="onMoveFile"
              @restoreFile="onRestoreFile"
              @versions="onVersions"
              @getDirectLink="onGetDirectLink"
              @context-menu="contextMenu"
              @runQuickEdit="runQuickEdit"
              @deletedPermission="onRemovePermission"
              @sendForApproval="sendForApproval"
              @renameShare="renameShare"
              @shareNonSharedRootFolder="setSharingRootFolder"
            />
            <FileListItem
              v-else
              :ref="`listitem` + item.id"
              :selection="selectedFiles"
              :custom-list-name="customListName"
              :has-custom-list="hasCustomList"
              :item="item"
              :is-public-share="isPublicShareView"
              :is-picker="isPicker"
              :is-backup-folder-sibling="isBackupFolderParent"
              :disable-hover="disableHover"
              :from-home="fromHome"
              :is-kb-focused="currentFocusedId === item.id"
              :is-compact-mode="isCompactMode"
              @selected="onSelect"
              @clearSelect="onClearSelect"
              @preview="onPreview"
              @expanded="onExpand"
              @browsed="browse"
              @downloadFile="onDownloadFile"
              @commentFile="onCommentFile"
              @lockFile="onLockFile"
              @unlockFile="unlockFile"
              @copyFile="onCopyFile"
              @moveFile="onMoveFile"
              @restoreFile="onRestoreFile"
              @versions="onVersions"
              @refresh="$emit('refresh')"
              @refreshFolder="refreshCurrentFolder"
              @context-menu="contextMenu"
              @runQuickEdit="runQuickEdit"
              @toggleMoreOptions="toggleMoreOptions"
              @deletedPermission="onRemovePermission"
              @sendForApproval="sendForApproval"
              @renameShare="(params) => renameShare(params, 'listitem')"
              @shareNonSharedRootFolder="setSharingRootFolder"
            />
          </div>
          <!-- endregion -->
        </RecycleScroller>
        <!-- endregion -->

        <!-- region Mobile File List  -->
        <DynamicScroller
          v-else
          v-slot="{ item }"
          class="file-list-body"
          :class="{ 'file-list-body--grid': getIsGrid }"
          :items="files"
          :min-item-size="58"
          :item-size="!getIsGrid ? null : getLayout.height"
        >
          <div v-if="getIsGrid" class="file-list-row">
            <div
              v-for="gridItem in item.chunk"
              :key="gridItem.id"
              class="file-list-column"
              :class="layoutType"
              :style="{
                width: `${100 / itemsPerRow}%`,
                maxWidth: `${100 / itemsPerRow}%`,
              }"
            >
              <FileItemLoader v-if="!gridItem.type" get-is-grid />
              <DynamicScrollerItem
                :item="item"
                :active="false"
                style="height: 100%"
              >
                <FileGridItem
                  :ref="`griditem` + gridItem.id"
                  :selection="selectedFiles"
                  :custom-list-name="customListName"
                  :has-custom-list="hasCustomList"
                  :item="gridItem"
                  :is-public-share="isPublicShareView"
                  :is-picker="isPicker"
                  :is-backup-folder-sibling="isBackupFolderParent"
                  :disable-hover="disableHover"
                  :from-home="fromHome"
                  :is-kb-focused="currentFocusedId === item.id"
                  :is-compact-mode="isCompactMode"
                  @selected="onSelect"
                  @preview="onPreview"
                  @expanded="onExpand"
                  @browsed="browse"
                  @clearSelection="onClearSelect"
                  @downloadFile="onDownloadFile"
                  @lockFile="onLockFile"
                  @unlockFile="unlockFile"
                  @copyFile="onCopyFile"
                  @commentFile="onCommentFile"
                  @moveFile="onMoveFile"
                  @restoreFile="onRestoreFile"
                  @versions="onVersions"
                  @getDirectLink="onGetDirectLink"
                  @runQuickEdit="runQuickEdit"
                  @context-menu="contextMenu"
                  @refresh="$emit('refresh')"
                  @refreshFolder="refreshCurrentFolder"
                  @toggleMoreOptions="toggleMoreOptions"
                  @sendForApproval="sendForApproval"
                  @renameShare="(params) => renameShare(params, 'griditem')"
                  @shareNonSharedRootFolder="setSharingRootFolder"
                />
              </DynamicScrollerItem>
            </div>
          </div>
          <div v-else class="file-list-row">
            <FileItemLoader
              v-if="
                !item.type &&
                !isLockedFiles &&
                !isDRMAccessFiles &&
                !isAclpermissionFiles
              "
            />
            <div v-else>
              <DynamicScrollerItem :item="item" role="rowgroup" :active="false">
                <SharedByMe
                  v-if="
                    isSharedByMe || isDRMAccessFiles || isAclpermissionFiles
                  "
                  :selection="selectedFiles"
                  :custom-list-name="customListName"
                  :has-custom-list="hasCustomList"
                  :item="item"
                  :is-picker="isPicker"
                  :from-home="fromHome"
                  :is-kb-focused="currentFocusedId === item.id"
                  :is-compact-mode="isCompactMode"
                  @selected="onSelect"
                  @preview="onPreview"
                  @expanded="onExpand"
                  @browsed="browse"
                  @downloadFile="onDownloadFile"
                  @lockFile="onLockFile"
                  @unlockFile="unlockFile"
                  @commentFile="onCommentFile"
                  @clearSelection="onClearSelect"
                  @copyFile="onCopyFile"
                  @moveFile="onMoveFile"
                  @restoreFile="onRestoreFile"
                  @versions="onVersions"
                  @getDirectLink="onGetDirectLink"
                  @context-menu="contextMenu"
                  @runQuickEdit="runQuickEdit"
                  @deletedPermission="onRemovePermission"
                  @sendForApproval="sendForApproval"
                  @renameShare="renameShare"
                  @shareNonSharedRootFolder="setSharingRootFolder"
                />
                <FileListItem
                  v-else
                  :ref="`listitem` + item.id"
                  :selection="selectedFiles"
                  :custom-list-name="customListName"
                  :has-custom-list="hasCustomList"
                  :item="item"
                  :is-public-share="isPublicShareView"
                  :is-picker="isPicker"
                  :is-backup-folder-sibling="isBackupFolderParent"
                  :disable-hover="disableHover"
                  :from-home="fromHome"
                  :is-kb-focused="currentFocusedId === item.id"
                  :is-compact-mode="isCompactMode"
                  @selected="onSelect"
                  @preview="onPreview"
                  @expanded="onExpand"
                  @browsed="browse"
                  @downloadFile="onDownloadFile"
                  @commentFile="onCommentFile"
                  @lockFile="onLockFile"
                  @unlockFile="unlockFile"
                  @copyFile="onCopyFile"
                  @moveFile="onMoveFile"
                  @restoreFile="onRestoreFile"
                  @clearSelection="onClearSelect"
                  @versions="onVersions"
                  @refresh="$emit('refresh')"
                  @refreshFolder="refreshCurrentFolder"
                  @context-menu="contextMenu"
                  @runQuickEdit="runQuickEdit"
                  @toggleMoreOptions="toggleMoreOptions"
                  @deletedPermission="onRemovePermission"
                  @sendForApproval="sendForApproval"
                  @renameShare="(params) => renameShare(params, 'listitem')"
                  @shareNonSharedRootFolder="setSharingRootFolder"
                />
              </DynamicScrollerItem>
            </div>
          </div>
        </DynamicScroller>

        <!-- endregion  -->
        <div
          v-if="meta && totalPages > 1 && !isPicker && !isMobile && !isTablet"
          class="file-list-pagination"
          :class="{
            [`file-list-pagination--elevated`]: transactionsBar.visible,
            [`file-list-pagination--hidden`]:
              transactionsBar.visible && transactionsBar.expanded,
          }"
        >
          <div
            v-click-outside="onClickOutsidePagination"
            class="pagination-content"
          >
            {{ $t('Page') }}
            <button
              id="pagination-left"
              title="pagination-left-arrow"
              class="btn btn-sm ml-2"
              :disabled="currentPage === 1"
              @click="scrollTo(currentPage - 1)"
            >
              <Icon family="far" name="arrow-left" />
            </button>
            <input
              aria-label="current page of file listing"
              type="number"
              min="1"
              :max="totalPages"
              :value="currentPage"
              @change="(e) => scrollTo(e.target.value)"
            />
            <button
              id="pagination-right"
              title="pagination-right-arrow"
              class="btn btn-sm mr-2"
              :disabled="currentPage === totalPages"
              @click="scrollTo(currentPage + 1)"
            >
              <Icon family="far" name="arrow-right" />
            </button>
            of {{ totalPages }}
            <a class="pagination-button" @click="togglePagination"
              ><PaginationIcon
            /></a>

            <transition name="fade-up">
              <div v-if="isPaginationOpen" class="pagination-list-wrapper">
                <ul class="pagination-list">
                  <li v-for="index in totalPages" :key="index">
                    <a
                      class="pagination-list-item"
                      :class="{ active: currentPage === index }"
                      @click="scrollTo(index)"
                    >
                      <span class="number">{{ index }}</span>
                      <span class="semi-row"></span>
                      <span class="full-row"></span>
                    </a>
                  </li>
                </ul>
              </div>
            </transition>
          </div>
        </div>
      </div>
    </Drop>

    <!-- region Modals -->
    <FileVersionsModal ref="fileVersionsModal" />

    <LockFileModal ref="lockFileModal" @refresh="$emit('refresh')" />

    <FilePickerModal
      v-if="!isPicker"
      ref="filePickerModal"
      :is-public-share="isPublicShareView"
      :visible="isPickerModalOpen"
      @refreshFolder="refreshCurrentFolder"
    />

    <PropertiesModal
      ref="propertiesModal"
      :path="propertiesPath.path"
      :path-name="propertiesPath.pathName"
    />

    <ShareOptionsModal
      ref="shareOptionsModal"
      @refreshFolder="refreshCurrentFolder"
    />

    <OpenSharedEncryptedFolder
      ref="openEncryptedFolderModal"
      :action="currentEncryptedPasswordAction"
      :folder-name="(currentFile && currentFile.name) || currentPathName"
      @proccessAction="proccessEncryptedFolderAction"
      @cancelEncryptedFolder="cancelEncryptedFolderAction"
    />

    <ClearIncompleteModal
      ref="clearIncompleteModal"
      @clearIncomplete="clearIncomplete"
    />

    <RenameShareModal ref="renameShareModal" @renameFeedback="renameFeedback" />

    <SendForApprovalModal ref="sendForApprovalModal" />

    <ShareNonSharedRootModal
      v-if="sharingNonSharedRootFolder"
      @share="$store.dispatch('share/openShare', currentSharingRootFolder)"
      @close="sharingNonSharedRootFolder = false"
    />

    <CreateEncryptedFolder
      ref="createEncryptedFolderModal"
      :path="currentPath"
      @onSecureFolderCreated="onSecureFolderCreated"
    />
    <!-- endregion -->

    <VueContext ref="menu" tag="div" @open="onContextMenuOpen">
      <template slot-scope="child">
        <ContextMenu
          v-if="child && child.data"
          class="dropdown-wrapper"
          :selected-files="selectedFiles"
          :child="child.data"
          :is-in-recycle-folder="isInRecycleFolder"
          :is-limited-user="isLimitedUser"
          :is-public-share-view="isPublicShareView"
          :selected-files-downloadable="selectedFilesDownloadable"
          @onItemContextClick="onItemContextClick"
        />
        <div v-else class="dropdown-wrapper">
          <div
            v-for="(item, id) in newFileActions"
            v-if="
              !(
                item.hidden &&
                item.hidden({
                  customization,
                  isPublicShareView,
                  systemStatus,
                  isLimitedUser,
                  fullSystemStatus,
                  isMobile,
                  isEncryptedFolder,
                })
              )
            "
            :key="id"
            v-close-popover
            class="dropdown-item"
            :class="{ 'has-seperator': item.seperator === true }"
            role="menuitem"
            @click="onEmptyContextClick(item.action, item.params)"
          >
            <Icon
              v-if="item.icon"
              :name="item.icon"
              :family="item.iconFamily || 'fal'"
            />
            <span class="popover-text"
              >{{ $t(item.name) }}
              <span
                v-if="item.shortKey"
                class="shortkey float-right text-capitalize"
              >
                {{ item.shortKey }}</span
              >
            </span>
          </div>
        </div>
      </template>
    </VueContext>
  </div>
</template>
<script>
import _ from 'lodash';

import { RecycleScroller } from 'vue-virtual-scroller';
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';
import 'vue-context/dist/css/vue-context.css';
import FileListPickerHeader from './FileList/FileListHeaders/FileListPickerHeader';
import FileListEmpty from './FileList/FileListEmpty';
import ColorTag from './FileList/partials/ColorTag';
import ShareOptionsModal from './Modals/ShareOptionsModal';
import RenameShareModal from './Modals/RenameShareModal';
import ClearIncompleteModal from './Modals/ClearIncompleteModal';
import ShareNonSharedRootModal from './Modals/ShareNonSharedRootModal';
import OpenSharedEncryptedFolder from '@/components/Modals/OpenSharedEncryptedFolder';
import CreateEncryptedFolder from '@/components/Modals/CreateEncryptedFolder';
import { getParams } from 'common/utils/files';
import { mapState, mapGetters } from 'vuex';
import { decodeAll, navigateToEncodedURL } from 'common/utils/URLUtils';

import FileListMixin from '../mixins/FileListMixin.js';
import responsivenessMixin from '@/mixins/responsivenessMixin';
import SharedByMeHeader from '@/components/FileList/FileListHeaders/SharedByMeHeader';
import FileListHeader from '@/components/FileList/FileListHeaders/FileListHeader';
import FileChunks from 'common/utils/fileChunks';

export default {
  name: 'FileList',
  components: {
    FileListHeader,
    SharedByMeHeader,
    RecycleScroller,
    ColorTag,
    FileListEmpty,
    FileListPickerHeader,
    ShareOptionsModal,
    ClearIncompleteModal,
    RenameShareModal,
    CreateEncryptedFolder,
    ShareNonSharedRootModal,
    OpenSharedEncryptedFolder,
  },
  mixins: [FileListMixin, responsivenessMixin],
  props: {
    customFilesTotal: {
      type: Number,
      default: null,
    },

    isAclpermissionFiles: {
      type: Boolean,
      default: false,
    },

    isModal: {
      type: Boolean,
      default: false,
    },
    fromHome: {
      type: Boolean,
      default: false,
    },
    keepSelection: {
      type: Boolean,
      default: false,
    },
    isTeamFolder: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedFilesDownloadable: false,
      newItemPath: null,
      currentFocusedId: null,
      currentFocusedIndex: null,
      dragCounter: 0,
      disableHover: false,
      folderHasError: false,
      folderErrorMessage: '',
      sharingNonSharedRootFolder: false,
      isAskingEncryptedPassword: false,
      currentEncryptedPasswordAction: 'browse',
      currentSharingRootFolder: {},
    };
  },
  computed: {
    ...mapState('files', ['layoutType']),
    ...mapGetters('files', ['getLayout']),
    loading() {
      if (
        this.isLockedFiles ||
        this.isDRMAccessFiles ||
        this.isAclpermissionFiles
      )
        return false;
      return (
        (this.$store.state.loading['files/list'] &&
          this.files &&
          this.files.length === 0) ||
        (this.actualFiles &&
          this.meta &&
          this.actualFiles.length !== this.meta.total) ||
        this.$store.state.loading['files/favoritesInNamedList'] ||
        this.isAskingEncryptedPassword
      );
    },
    isBackupFolderParent() {
      const allowBackup = this.$store.state.core.systemstatus.ALLOWBACKUP;

      // false if backup not allowed or path not available
      if (!allowBackup || !this.backupPath) return false;
      const backupPathMatch = this.backupPath.match(/.*\//);

      // false if no backup path match
      if (!backupPathMatch) return false;
      const parentBackupFolder = backupPathMatch[0];
      const currentPath = this.currentPath + '/';
      return parentBackupFolder === currentPath;
    },
    isSidebarOpenned() {
      if (
        this.isMobile ||
        this.isTablet ||
        this.$route.name?.includes('workflows') ||
        this.$route.name?.includes('webeditor')
      ) {
        return this.sidebar.open;
      }

      if (!this.$route.name?.includes('files')) {
        return true;
      }

      return this.$route.name === 'files'
        ? !this.$store.state.core.customization.SIDEPANEL_RIGHT_COLLAPSE
        : !this.$store.state.core.customization.SIDEPANEL_EXPLORER_COLLAPSE;
    },
    backupPath() {
      return this.$store.state.core.systemstatus.BACKUPPATH;
    },
    currentFile() {
      return this.$store.state.files.currentFile;
    },
    filesMeta() {
      return this.$store.state.files.filesMeta;
    },
    noListToDisplay() {
      return (
        this.files.length === 0 &&
        !this.loading &&
        !this.fileOperationLoader &&
        (!this.isCreatingNewItem || !this.isCreatingNewFolder)
      );
    },
    canUpload() {
      return this.meta?.canupload;
    },
    encryptedFolderPasswords() {
      return this.$store.state.files.encryptedFolderPasswords;
    },
    isEncryptedFolder() {
      return (
        this.meta?.encryptedfolder === 1 ||
        this.currentFile?.encryptedfolder === 1
      );
    },
    propertiesPath() {
      const propertiesPath = {
        path: this.currentPath,
        pathName: this.currentPathName,
      };

      if (this.singleSelection && this.selectedFiles[0].type === 'dir') {
        propertiesPath.path = this.selectedFiles[0].path;
        propertiesPath.pathName = this.selectedFiles[0].name;
      }

      return propertiesPath;
    },
    sidebar() {
      return this.$store.getters['files/getSidebarState']();
    },
    uploadStatus() {
      return this.$store.getters['files/getTransactions'];
    },
  },
  mounted() {
    let { filter } = getParams();

    if (!this.hasCustomList) {
      if (this.initialPath !== '/') {
        if (!_.isEmpty(filter)) {
          this.filterValue = filter;
          const url = new URL(window.location.href);
          url.searchParams.delete('filter');
          window.history.replaceState(null, null, url);
        }

        // Clear filter when browsing to different places
        // @todo: URL Query Param will still be set!
        this.browse(this.initialPath, true);
      }
    }

    this.$root.$on('browseToRecycleBin', () => this.goToRecyclebin());

    this.$root.$on('triggerFilesAction', (action, param) =>
      this.triggerAction(action, param)
    );
    this.$root.$on('refreshFileList', () => {
      this.refreshCurrentFolder();
    });
  },
  methods: {
    calculateStickySpace() {
      const id = this.isPublicShareView ? 'filePreviewHeader' : 'navbar';
      const headerHeight = document.getElementById(id).offsetHeight;
      const diffSpace = this.isPublicShareView ? 0 : 2;
      const secondBarHeight =
        document.getElementById('fcExplorerHeader').offsetHeight;

      this.headerOffset = headerHeight + secondBarHeight - diffSpace + 'px';
    },
    async browse(item, onMounted = false) {
      this.folderHasError = false;
      this.folderErrorMessage = '';

      let path = null;
      if (typeof item !== 'string') {
        path = item.path || item.filepath;
      } else {
        path = item;
      }

      if (path !== '/') {
        const { name, breadcrumb } = this.parsePath(path);

        if (name === '._cloud') {
          return this.browse(path.replace('/._cloud', ''));
        }

        this.filterValue = onMounted ? this.filterValue : '';
        this.isScrolling = true;
        this.currentPath = path;
        this.breadcrumb = breadcrumb;
        this.currentPathName = name;
        this.lastSelectedFile = null;

        if (this.hasHistory) {
          navigateToEncodedURL(this.$router, path, '/expl-tabl.');
        }

        this.$emit('browsed', { path, name, breadcrumb });

        this.$store.commit('files/addToStore', {
          key: 'searchLastBrowsed',
          value: { path, name },
        });

        if (!this.isPicker) {
          this.onClearSelect();
        }

        this.currentExpanded = null;
        this.isScrolling = false;

        this.currentFocusedId = null;
        this.currentFocusedIndex = null;

        if (this.hasCustomList && this.customListAction) {
          await this.$store.dispatch(this.$store.state.files.customListAction);
        } else {
          if (this.hasSidebarStore) {
            this.$store.commit('files/setSidebar', {
              key: 'name',
              value: name,
            });
            this.$store.commit('files/setSidebar', {
              key: 'path',
              value: path,
            });
          }

          if (this.fullSystemStatus['encryptedfolderpasswordsession']) {
            const hasTokenSaved =
              this.encryptedFolderPasswords[path] !== undefined;

            if (hasTokenSaved) {
              await this.$store.dispatch('files/saveEncryptedFolderPass', {
                folder: path,
                password: this.encryptedFolderPasswords[path],
              });
            } else {
              let foundPass = null;
              Object.entries(this.encryptedFolderPasswords).forEach(
                ([key, value]) => {
                  if (path.includes(key)) {
                    foundPass = value;
                  }
                }
              );

              if (foundPass) {
                await this.$store.dispatch(
                  'files/setEncryptionHeader',
                  foundPass
                );
              }
            }
          }

          this.currentEncryptedPasswordAction = 'browse';

          // remove current expanded file
          let response = await this.$store.dispatch('files/list', {
            path,
            sort: this.sort,
            filter: this.filterValue,
          }); // get initial files if not in custom list action

          const failMsg = 'Failed to get file listing.';

          if (Array.isArray(response) && response.includes(failMsg)) {
            this.folderHasError = true;
            this.folderErrorMessage = this.$t(response);
            this.$toast.open({
              message:
                '<b>' +
                this.$t('Error') +
                '</b><p role="alert">' +
                this.$t(response) +
                '</p>',
              type: 'error',
            });
          } else if (response === 'Missing encrypted folder password.') {
            this.isAskingEncryptedPassword = true;
            this.$refs.openEncryptedFolderModal.open();
          } else if (
            (!response || typeof response === 'string') &&
            !this.isPicker
          ) {
            this.folderHasError = true;

            this.$store.dispatch('files/dropEncryptedFolderpass', path);

            this.folderErrorMessage = response;

            this.$toast.open({
              message:
                '<b>' +
                this.$t('Error') +
                '</b><p role="alert">' +
                this.$t(response) +
                '</p>',
              type: 'error',
            });

            //reset files in memory to avoid showing wrong data
            if (this.files.length > 0 && response === 'Unauthorized') {
              this.$store.commit('files/dropFiles', {
                path: path,
              });
            }
          } else if (typeof response !== 'boolean' && !response.ok) {
            this.folderHasError = true;

            this.folderErrorMessage = this.$t('Failed to retrieve files list');

            this.$toast.open({
              message:
                '<b>' +
                this.$t('Error') +
                '</b><p role="alert">' +
                this.folderErrorMessage +
                '</p>',
              type: 'error',
            });
          } else {
            this.$root.$emit(
              'announceScreenReader',
              this.filesMeta && this.filesMeta.total > 0
                ? this.$tc(
                    '{count} file loaded successfully | {count} files loaded successfully. Press Insert + Z to turn off virtual cursor and navigate through files with arrow keys.',
                    this.filesMeta.total
                  )
                : this.$t(
                    'Folder is empty, type Alt + N for new folder or press U to upload new files'
                  )
            );

            // if the filter was applied from mounting (direct links)
            // we open the previewer
            if (onMounted && this.filterValue) {
              const items = this.files;
              this.onPreview(items.find((el) => el.name == this.filterValue));
            }
          }
        }
      } else {
        this.$router.push('/expl-tabl.');
      }
    },
    async onDownloadFile(item) {
      // if trying to download a folder and folder download is disabled
      if (item.type === 'dir' && this.customization.DISABLEFOLDERDOWNLOAD === 1)
        return;
      let response;
      try {
        response = await this.$store.dispatch('files/download', {
          ...item,
          fromCustomList: this.hasCustomList,
        });
      } catch (e) {
        response = e;
      }
      if (typeof response !== 'undefined' && !response.ok) {
        if (item.encryptedfolder === 1) {
          this.$toast.open({
            message:
              '<b>' +
              this.$t('Error') +
              '</b><p role="alert">' +
              this.$t('Unauthorized') +
              '</p>',
            type: 'error',
          });
        } else {
          window.response = response;
          let errorMsg =
            response.message &&
            response.message.data.message.includes('!DOCTYPE html')
              ? this.$t('Download failed')
              : response.message.data.message;
          this.$toast.open({
            message:
              '<b>' +
              this.$t('Error') +
              '</b><p role="alert">' +
              errorMsg +
              '</p>',
            type: 'warning',
          });
        }
      } else {
        this.$root.$emit(
          'announceScreenReader',
          this.$t('Download has started')
        );
      }
    },
    async onCommentFile(item) {
      this.onSelect({ item });
      this.$store.commit('files/setSidebar', { key: 'open', value: true });
      this.$store.dispatch('core/setCustomizations', {
        name: 'SIDEPANEL_EXPLORER_COLLAPSE',
        value: 0,
      });
      this.$store.commit('files/setSidebar', {
        key: 'current',
        value: 'comments',
      });
    },
    onCopyFile(item) {
      this.$store.commit('files/setMobileMenu', {
        key: 'open',
        value: false,
      });

      this.$store.commit('files/hideMobileOverlay');
      this.$refs.filePickerModal.open('copy', item.dirpath, (path) => {
        this.$store.dispatch('files/copyFile', {
          item,
          to: path,
        });

        this.$root.$emit(
          'announceScreenReader',
          this.$t('File copied successfully')
        );
      });
    },
    onMoveFile(item, folderpath = null) {
      this.$store.commit('files/setMobileMenu', {
        key: 'open',
        value: false,
      });

      this.$store.commit('files/hideMobileOverlay');

      if (folderpath === null) {
        this.$refs.filePickerModal.open('move', item.dirpath, (path) => {
          this.$store.dispatch('files/moveFile', {
            item: item,
            to: path,
          });
          this.$root.$emit(
            'announceScreenReader',
            this.$t('File moved successfully')
          );
        });
      } else {
        this.$store.dispatch('files/moveFile', {
          item: item,
          to: folderpath,
        });

        this.$root.$emit(
          'announceScreenReader',
          this.$t('File moved successfully')
        );
      }
    },
    parsePath(path) {
      let [root, type, ...dirTree] = path.split('/');

      let breadcrumb = [];
      let currentRoute = `/${type}`;

      if (path === '') {
        return {
          name: this.$t('All Files'),
          breadcrumb: [],
        };
      }

      const baseNames = {
        [`${this.user.peerid}`]: 'My Files',
      };

      breadcrumb.push({
        name: baseNames[type] ? baseNames[type] : type,
        path: currentRoute,
      });

      breadcrumb = [
        ...breadcrumb,
        ...dirTree.map((dir) => {
          currentRoute += `/${dir}`;

          return {
            name: dir,
            path: currentRoute,
          };
        }),
      ];

      if (this.isPublicShareView) {
        breadcrumb = breadcrumb.slice(3, breadcrumb.length);

        breadcrumb.unshift({
          name: `Shared with me (${path.split('/').slice(0, 4)[3]})`,
          path: path.split('/').slice(0, 4).join('/'),
          isPublicShareRoot: true,
        });
      }

      return {
        name: breadcrumb[breadcrumb.length - 1].name,
        root,
        breadcrumb,
      };
    },
    onClearSelect(shouldEmit = true) {
      this.selectedFiles = [];
      this.lastSelectedFile = null;

      if (shouldEmit) {
        this.onEmitSelect([]);

        this.$store.commit('files/setSidebar', { key: 'selected', value: [] });
        this.$store.commit('files/setSidebar', {
          key: 'lastSelected',
          value: null,
        });

        this.$root.$emit(
          'announceScreenReader',
          this.$t('Selection cleared successfully')
        );
      }
    },
    onSelectAll(e) {
      if (e) {
        e.preventDefault();
        e.stopPropagation();
      }

      if (this.selectedFiles.length !== this.actualFiles.length) {
        this.selectedFiles = [...this.actualFiles];
        this.onEmitSelect(this.selectedFiles);

        this.$root.$emit('announceScreenReader', this.$t('All files selected'));
      } else {
        this.onClearSelect();
      }
    },
    onPreview(item, isShiftPressed) {
      if ((this.hasCustomList || this.isSharedByMe) && !this.fromHome) {
        this.$preview.open(this.customFileList, item.id, isShiftPressed);
      } else if (this.fromHome) {
        this.$preview.open(item, undefined, isShiftPressed);
      } else {
        this.$preview.open(this.currentPath, item.id, isShiftPressed);
      }
    },
    async goToRecyclebin() {
      await this.$store.dispatch('files/removeEncryptionHeader');

      let path = '';
      let cPath = window.location.href.split('#expl-tabl.')[1];
      if (cPath?.indexOf('EXTERNAL') > -1) {
        if (cPath.indexOf('recyclebin') === -1) {
          path = decodeAll(cPath) + '/._cloud/recyclebin';
          await this.browse(path);
        }
      } else {
        path = '/' + this.user.profile + '/recyclebin';
        let response = await this.$store.dispatch(
          'files/getSingleFileInfo',
          path
        );

        if (response.ok && response.data.entry) {
          await this.browse(response.data.entry);
        } else {
          await this.browse(path);
        }
      }
    },
    async fetchCurrentFileData(item = null) {
      this.resetManagedShares();
      if (this.hasCustomList) return;

      // load parent folder data
      if (item === null && this.meta) {
        const parentPathItems = this.$store.getters['files/getFilesInPath'](
          this.meta.parentpath
        );
        const entryInParent = _.find(parentPathItems, {
          path: this.meta.realpath,
        });

        // if item is already in memory
        if (entryInParent) {
          item = entryInParent;
        } else {
          let name = this.meta.realpath.split('/');
          name = name[name.length - 1];
          item = {
            name: name,
            type: 'dir',
            dirpath: this.meta.parentpath,
            path: this.meta.realpath,
            cansetacls: this.meta.cansetacls,
          };
        }

        // if meta is not loaded yet
      } else if (item === null && !this.meta) {
        let name = this.currentPath.split('/');
        name = name[name.length - 1];

        item = {
          name: name,
          type: 'dir',
          path: this.currentPath,
        };
      }

      this.$store.commit('files/addToStore', {
        key: 'currentFile',
        value: item,
      });

      if (this.isSharedPage) return;

      let path = '';
      if (process.env.NODE_ENV === 'production') {
        path = this.$route?.path;
      } else {
        path = this.$route?.path || '/expl-tabl./demo';
      }
      const isExternalRootPath =
        path == '/expl-tabl./SHARED' || path == '/expl-tabl./EXTERNAL';
      if (!this.isPublicShareView && !isExternalRootPath) {
        if (this.user.userlevel !== 'LIMITED' && this.isSidebarOpenned) {
          this.$store.dispatch('files/shareInfoForFile');
          if (item && item.canmanageshare === 1) {
            this.$store.dispatch('files/getManageableShares', item.path);
          }
        }
      }
    },
    async openNewFolder() {
      this.isCreatingNewItem = true;
      this.isCreatingNewFolder = true;
      this.newItemPath = this.currentPath;
      this.newItemError = null;

      this.$nextTick(() => {
        if (this.$refs.newItemInput) {
          this.$refs.newItemInput.focus();
        }
      });
    },
    async openNewEncryptedFolder() {
      this.$refs.createEncryptedFolderModal.open();
    },
    openNewItem(suffix, mime) {
      if (suffix === 'README.md') {
        this.newItemName = suffix;
        this.newItemMimeType = mime;
        this.isCreatingNewFolder = false;
        this.newItemPath = this.currentPath;
        this.onCreateItem();
        return;
      }

      this.isCreatingNewItem = true;
      this.isCreatingNewFolder = false;
      this.newItemName = `.${suffix}`;
      this.newItemMimeType = mime;
      this.newItemPath = this.currentPath;
      this.newItemError = null;

      this.$nextTick(() => {
        if (this.$refs.newItemInput) {
          this.$refs.newItemInput.focus();
          this.$refs.newItemInput.setSelectionRange(0, 0);
        }
      });
    },
    async onSecureFolderCreated(folderPath) {
      await this.browse(folderPath);
    },
    async onCreateItem() {
      if (this.$store.state.loading['files/createFolder']) return;
      if (this.$store.state.loading['files/createFile']) return;

      if (this.newItemPath !== this.currentPath || this.newItemName === '') {
        this.onCancelCreateItem();
        return;
      }

      this.newItemError = null;

      if (this.isCreatingNewFolder) {
        if (!this.isEncryptedFolder) {
          await this.$store.dispatch('files/removeEncryptionHeader');
        }

        const response = await this.$store.dispatch('files/createFolder', {
          name: this.newItemName,
          path: this.currentPath,
          date: new Date().toISOString(),
        });

        if (response.ok) {
          this.$root.$emit(
            'announceScreenReader',
            this.$t('{folder} created successfully', {
              folder: this.newItemName,
            })
          );

          this.browse(`${this.currentPath}/${this.newItemName}`);

          this.isCreatingNewItem = false;
          this.newItemName = '';
          this.newItemError = null;
          if (!this.keepSelection) {
            this.onClearSelect();
          }
        } else {
          this.newItemError = response.error;
          this.$root.$emit('announceScreenReader', this.$t(this.newItemError));

          if (this.$refs.newItemInput) {
            this.$refs.newItemInput.focus();
            this.$refs.newItemInput.select();
          }
        }
      } else {
        if (
          !(
            this.newItemName === 'README.md' &&
            this.meta &&
            this.meta.aboutfile === 'README.md'
          )
        ) {
          const response = await this.$store.dispatch('files/createFile', {
            name: this.newItemName,
            path: this.currentPath,
            mime: this.newItemMimeType,
          });

          if (response.ok) {
            this.$store.dispatch('files/list', {
              path: this.currentPath,
            });

            this.$root.$emit(
              'announceScreenReader',
              this.$t('{file} created successfully', { file: this.newItemName })
            );
          } else {
            this.newItemError = response.data.message;
            this.$root.$emit(
              'announceScreenReader',
              this.$t(this.newItemError)
            );
            if (this.$refs.newItemInput) {
              this.$refs.newItemInput.focus();
              this.$refs.newItemInput.select();
            }
            return;
          }
        }

        // if it's a text file
        if (this.newItemMimeType === 'text/plain') {
          this.$router.push({
            name: 'webeditor',
            query: { filepath: `${this.currentPath}/${this.newItemName}` },
          });
        } else if (
          this.customization.ENABLEWOPIEDIT &&
          typeof this.customization.WOPICLIENT == 'string'
        ) {
          this.$store.dispatch('files/wopiEdit', {
            path: `${this.currentPath}/${this.newItemName}`,
            is_new: true,
          });
        }

        this.isCreatingNewItem = false;
        this.newItemName = '';
        this.newItemError = null;
      }

      return;
    },
    async onDropFile(ext, e) {
      if (ext || this.hasCustomList || (this.meta && !this.meta.canupload))
        return;

      e.preventDefault();
      e.stopPropagation();

      let droppedItems = e.dataTransfer.items;

      if (droppedItems.length > 0) {
        this.$store.dispatch('files/upload', {
          items: droppedItems,
          path: this.currentPath,
        });
        this.onClearSelect();
      }

      this.dragCounter = 0;
      this.isDragActive = false;
    },
    onDragEnter(ext) {
      if (ext || this.hasCustomList || (this.meta && !this.meta.canupload))
        return;

      this.dragCounter++;
      this.isDragActive = true;

      this.$store.commit('files/setTransactionsBar', {
        key: 'expanded',
        value: false,
      });
    },
    onDragLeave(ext) {
      if (ext || this.hasCustomList || (this.meta && !this.meta.canupload))
        return;

      this.dragCounter--;
      if (this.dragCounter === 0) {
        this.isDragActive = false;
      }
    },
    onSort(key, order, e) {
      if (e && e.target.nodeName === 'INPUT') return;
      if (order === null && this.sort.field === key) {
        this.sort.type = this.sort.type === 1 ? -1 : 1;
      } else {
        this.sort = {
          field: key,
          type: order === null ? -1 : order,
        };
        this.$store.dispatch('core/setCustomizations', {
          name: 'DEFAULT_SORT_BY',
          value: key,
          local: this.isPublicShareView,
        });
      }

      this.$store.dispatch('core/setCustomizations', {
        name: 'DEFAULT_SORT_DIR',
        value: this.sort.type,
        local: this.isPublicShareView,
      });

      this.$store.dispatch('files/list', {
        path: this.currentPath,
        sort: this.sort,
        filter: this.filterValue,
      });

      this.$root.$emit(
        'announceScreenReader',
        this.$t('Sorting by {sort}', { sort: key })
      );

      this.onClearSelect();
    },
    /**
     * Perform fltering on file list
     * @param {String} value - string to filter
     * @param {Boolean} onMounted - flag to check if filter is on mounted
     * The usecase for onMounted is resolving direct links with the filter param
     */
    onFilter: _.debounce(function (value) {
      this.filterValue = value;
      if (this.hasCustomList || this.customListName === 'sharedbyme') {
        this.$emit('filter', value);
      } else {
        this.$store.dispatch('files/list', {
          path: this.currentPath,
          sort: this.sort,
          filter: value,
        });
      }

      if (!this.keepSelection) {
        this.onClearSelect();
      }
    }, 500),
    async executeBulk(type) {
      let operationQueue = null;
      let response;
      const selectedFiles = [...this.selectedFiles];

      switch (type) {
        case 'copy':
        case 'move':
          this.$refs.filePickerModal.open(
            type,
            this.currentPath,
            async (path) => {
              operationQueue = await this.$store.dispatch('files/bulk', {
                items: selectedFiles,
                path: this.currentPath,
                type,
                onTick: (file, queue) => {
                  this.$store.dispatch(`files/${type}File`, {
                    item: file,
                    to: path,
                    queue,
                  });
                },
              });
              operationQueue.resume();
              this.onClearSelect();
            }
          );
          break;
        case 'download':
          if (
            this.filesMeta.encryptedfolder === 1 ||
            (this.currentFile.encryptedfolder === 1 &&
              this.selectedFiles.length <= 1)
          ) {
            this.checkEncryptedToken(type);
          } else {
            await this.downloadFileOp();
          }
          break;
        case 'requestForDRMFiles':
          this.$store.dispatch('files/exportAsDRMFilesTX', {
            items: selectedFiles,
          });
          break;
        case 'downloadPDF':
          this.$store.dispatch('files/getDocConvertPreviewURL', selectedFiles);
          break;
        case 'shareOptions':
          this.$refs.shareOptionsModal.open(
            selectedFiles[0],
            this.breadcrumb.length == 2
          );
          break;
        case 'delete':
          if (
            this.filesMeta?.encryptedfolder === 1 ||
            this.currentFile.encryptedfolder === 1
          ) {
            await this.checkEncryptedToken(type);
          } else {
            this.onClearSelect();
            operationQueue = await this.$store.dispatch('files/bulk', {
              items: selectedFiles,
              path: this.currentPath,
              type,
              onTick: (file, queue) => {
                const itemRef = this.$refs[`listitem${file.id}`];
                if (itemRef) {
                  itemRef.setIsDeleting(true);
                }

                this.$store.dispatch('files/deleteFile', {
                  item: file,
                  transactionQueue: queue,
                });
                this.onClearSelect();
              },
            });
            this.$root.$emit(
              'announceScreenReader',
              this.$t('File deleted successfully')
            );
            operationQueue.resume();
            await operationQueue.drain();
            await this.refreshCurrentFolder();
          }
          break;
        case 'clearDeleted':
          this.fileOperationLoader = true;
          response = await this.$store.dispatch('files/emptyRecycleBin');
          if (response.ok) {
            const message = response.data.message.length
              ? response.data.message
              : 'Deleted files cleared successfully';
            this.$toast.open({
              message:
                '<b>' +
                this.$t('Success') +
                '</b><p role="alert">' +
                this.$t(message) +
                '</p>',
              type: 'success',
            });
            await this.refreshCurrentFolder();
          } else {
            this.$toast.open({
              message:
                '<b>' +
                this.$t('Error') +
                '</b><p role="alert">' +
                this.$t(response.error) +
                '</p>',
              type: 'error',
            });
          }
          break;
        case 'history':
          this.fileOperationLoader = true;
          response = await this.$store.dispatch('files/deleteAllVersions');
          await this.refreshCurrentFolder();
          this.$root.$emit(
            'announceScreenReader',
            this.$t('All versions deleted successfully')
          );
          if (response.ok) {
            const message = response.data.message.length
              ? response.data.message
              : 'All versions deleted successfully';
            this.$toast.open({
              message:
                '<b>' +
                this.$t('Success') +
                '</b><p role="alert">' +
                this.$t(message) +
                '</p>',
              type: 'success',
            });
          }
          break;
        case 'clearIncomplete':
          this.$refs.clearIncompleteModal.open();
          break;
        case 'properties':
          this.openPropertiesModal();
          break;
        case 'runQuickEdit':
          this.runQuickEdit(this.lastSelectedFile.path);
          break;
        case 'deleteFolder':
          if (this.filesMeta.encryptedfolder === 1) {
            this.checkEncryptedToken(type);
          } else {
            this.deleteFolderOp();
          }
          break;
        case 'shareFolder':
          if (
            this.filesMeta?.encryptedfolder === 1 ||
            this.currentFile.encryptedfolder === 1
          ) {
            await this.checkEncryptedToken(type);
          } else {
            await this.shareFileOp();
          }
          break;
        case 'downloadFolder':
          if (this.filesMeta.encryptedfolder === 1) {
            this.checkEncryptedToken(type);
          } else {
            await this.$store.dispatch('files/downloadMultiple', {
              items: this.currentFile,
              path: this.currentPath,
            });
          }
          break;
        case 'goToDeletedFiles':
          this.goToRecyclebin();
          break;
        case 'restoreItems':
          this.bulkRestoreItems(this.selectedFiles);
          break;
        default:
      }
    },
    focusFile(dir) {
      // if not files, return
      if (!this.files.length) return;

      const lastFileIndex = this.files.length - 1;

      if (this.currentFocusedId === null) {
        this.currentFocusedId = this.files[0].id;
        this.currentFocusedIndex = 0;
      } else {
        const focusedIndex = this.files.findIndex(
          (item) => item.id === this.currentFocusedId
        );

        if (dir === 'down' && focusedIndex < lastFileIndex) {
          this.currentFocusedId = this.files[focusedIndex + 1].id;
          this.currentFocusedIndex = focusedIndex + 1;
        } else if (dir === 'up' && this.currentFocusedIndex !== 0) {
          this.currentFocusedId = this.files[focusedIndex - 1].id;
          this.currentFocusedIndex = focusedIndex - 1;
        }
      }
    },
    onShortcut(key) {
      switch (key.srcKey) {
        case 'navigate-up': {
          if (!this.disableKbNavigation) {
            this.focusFile('up');
          }
          break;
        }
        case 'navigate-down': {
          if (!this.disableKbNavigation) {
            this.focusFile('down');
          }
          break;
        }
        case 'page-down':
          if (this.currentPage < this.totalPages) {
            this.scrollTo(this.currentPage + 1);
          }
          break;

        case 'page-up':
          if (this.currentPage > 1) {
            this.scrollTo(this.currentPage - 1);
          }
          break;

        case 'page-home':
          this.scrollTo(1);
          break;

        case 'page-end':
          this.scrollTo(this.totalPages);
          break;

        case 'select-all':
          this.onSelectAll();
          break;

        case 'download-item':
          if (this.singleSelection && this.selectedFiles.length) {
            this.onDownloadFile(this.selectedFiles[0]);
          } else if (this.multipleFileSelected) {
            this.selectedFiles.length
              ? this.executeBulk('download')
              : this.showSelectionInfoToast('Download');
          }
          break;

        case 'toggle-lock':
          if (this.singleSelection && this.selectedFiles[0].locked) {
            this.unlockFile(this.selectedFiles[0]);
          } else if (this.singleSelection) {
            this.onLockFile(this.selectedFiles[0]);
          } else {
            this.$showSelectionInfoToast('Lock', true);
          }
          break;

        case 'share-item':
          if (this.singleSelection && this.selectedFiles.length) {
            this.$store.dispatch('share/openShare', this.selectedFiles[0]);
          } else {
            this.showSelectionInfoToast('Share', true);
          }
          break;

        case 'open-menu':
          if (this.singleSelection) {
            const selectedtItemId = this.selectedFiles[0].id;
            const itemRef = this.$refs[`listitem${selectedtItemId}`];
            itemRef.triggerShowMoreOptions();
          }
          break;

        case 'folder-up': {
          const currentPath = this.currentPath
            .split('/')
            .filter((item) => item !== '');

          if (currentPath.length > 1) {
            currentPath.pop();
            const newPath = `/${currentPath.join('/')}`;
            this.browse(newPath);
          } else {
            this.browse('/');
          }
          break;
        }

        case 'folder-properties':
          this.openPropertiesModal();
          break;

        case 'open-deleted': {
          this.goToRecyclebin();
          break;
        }

        case 'filter-file': {
          document.querySelector('#filter-files').focus();
          break;
        }
        case 'add-comment':
          if (this.singleSelection) {
            this.$store.dispatch('core/setCustomizations', {
              name: 'SIDEPANEL_EXPLORER_COLLAPSE',
              value: 0,
            });
            this.$store.commit('files/setSidebar', {
              key: 'current',
              value: 'comments',
            });
          }
          break;

        case 'copy-item':
          if (
            this.selectedFiles.length === 0 ||
            window.getSelection().type === 'Range'
          ) {
            document.execCommand('copy');
            return;
          }
          if (this.singleSelection && this.selectedFiles.length) {
            this.onCopyFile(this.selectedFiles[0]);
          } else {
            this.selectedFiles.length
              ? this.executeBulk('copy')
              : this.showSelectionInfoToast('Copy');
          }
          break;

        case 'move-item':
          if (this.singleSelection && this.selectedFiles.length) {
            this.onMoveFile(this.selectedFiles[0]);
          } else {
            this.selectedFiles.length
              ? this.executeBulk('move')
              : this.showSelectionInfoToast('Move');
          }
          break;

        case 'rename-item':
          if (this.singleSelection && this.selectedFiles.length) {
            this.$refs[`listitem${this.selectedFiles[0].id}`].onClickRename();
          } else {
            this.showSelectionInfoToast('Rename', true);
          }
          break;

        case 'delete-item':
          if (this.singleSelection && this.selectedFiles.length) {
            this.$refs[
              `listitem${this.selectedFiles[0].id}`
            ].showDelConf = true;
          } else {
            this.selectedFiles.length
              ? this.executeBulk('delete')
              : this.showSelectionInfoToast('Delete');
          }
          break;
      }
    },
    showSelectionInfoToast(action, single = false) {
      let message = single
        ? this.$t('Please select one item to')
        : this.$t('Please select items to');

      message = `${message} ${this.$t(action).toLowerCase()}`;

      this.$toast.open({
        message: `<b>
          ${this.$t('Selection')}
          </b><p role="alert">
          ${message}
          </p>`,
        type: 'info',
      });
    },
    onGetDirectLink() {},
    toggleMoreOptions(val) {
      this.disableHover = val;
    },
    resetManagedShares() {
      this.$store.state.files.managedShareInfo = [];
    },
    async onRemovePermission() {
      let userid = this.$store.state.auth.user.profile;
      await this.$store.dispatch('files/getAllAcls', { userid });
      this.$emit('deletedPermission');
    },
    async clearIncomplete(includeInTransit) {
      if (includeInTransit) {
        const fileChunks = new FileChunks();
        fileChunks.deleteAllChunks();
      }
      this.fileOperationLoader = true;
      await this.$store.dispatch(
        'files/deletePartialUploads',
        includeInTransit
      );
      await this.refreshCurrentFolder();
    },
    async bulkRestoreItems(items) {
      this.fileOperationLoader = true;

      items.forEach(async (item) => {
        const path =
          item.dirpath.indexOf('._cloud') > -1
            ? item.dirpath.replace('/._cloud/recyclebin', '')
            : item.dirpath.replace('recyclebin/', '');

        await this.$store.dispatch('files/moveFile', {
          item: item,
          to: path,
        });
      });

      this.$root.$emit(
        'announceScreenReader',
        this.$t('Files restored successfully')
      );

      this.onClearSelect();
      await this.$store.dispatch('files/list', this.currentPath);
      this.fileOperationLoader = false;
    },
    onKbPreviewItem() {
      if (this.currentFocusedIndex !== null) {
        const kbFocusedFile = this.files[this.currentFocusedIndex];
        if (kbFocusedFile.type === 'dir') {
          this.browse(kbFocusedFile);
        } else {
          this.onPreview(kbFocusedFile);
        }
      } else if (this.singleSelection) {
        if (this.selectedFiles[0]) {
          this.browse(this.selectedFiles[0]);
        } else {
          this.onPreview(this.selectedFiles[0]);
        }
      }
    },
    onSpacebarSelection() {
      if (this.currentFocusedIndex !== null) {
        const kbFocusedFile = this.files[this.currentFocusedIndex];
        this.onFocus({ item: kbFocusedFile });
      }
    },
    onContextMenuOpen(event, data, top, left) {
      // Adjust top if less than navbar height
      if (!this.isMobile && top < 64) {
        const contextElem = document.querySelector('.v-context');
        this.$nextTick(() => {
          contextElem.style.top = '64px';
        });
      }
    },
    renameShare(item, source) {
      this.$refs.renameShareModal.open(item, source);
    },
    renameFeedback({ item, renameItem, renameShare, source }) {
      let component = this.$refs[`${source}${item.id}`];
      if (Array.isArray(component)) {
        // only one item can be renamed at a time.
        component = this.$refs[`${source}${item.id}`][0];
      }
      component.onClickRename(renameItem, renameShare);
    },
    setSharingRootFolder(item) {
      this.currentSharingRootFolder = item;
      this.sharingNonSharedRootFolder = true;
    },
    announce() {
      if (
        this.uploadStatus?.upload &&
        this.uploadStatus.upload[0]?.status === 'completed'
      ) {
        this.$root.$emit(
          'announceScreenReader',
          this.$t('Successfully uploaded file')
        );
      }
    },
  },
  watch: {
    uploadStatus(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.announce();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'common/scss/_colors.scss';

i.inline-icon {
  color: var(--text-ultra-light);
  margin-right: 5px;
}

.custom-list-header {
  background-color: var(--bg-medium);
  border-top: 1px solid var(--border-color);
  border-bottom: 1px solid var(--border-color);
  height: 50px;
  display: flex;
  align-items: center;
  padding: 15px;

  @media screen and (min-width: 768px) {
    background-color: transparent;
    border-top: 0;
    padding: 0;
  }

  @media screen and (min-width: 1200px) {
    margin: 0 30px;
  }

  h3 {
    color: var(--text-dark);
    font-size: 14px;
    font-weight: 600;
    width: 100%;

    @media screen and (max-width: 767px) {
      margin-bottom: 0;
    }

    @media screen and (min-width: 768px) {
      padding-left: 21px;
    }

    .header-collapse {
      float: right;
      font-weight: 500;
      color: var(--text-light);
    }
  }
}
.selected-count-wrap {
  padding-left: 24px;
}
.selection-action {
  margin-left: auto;
  .btn {
    font-size: 13px;
  }
}
.selected-count {
  color: $text-light-9;
  font-size: 0.813rem;
  padding-left: 13px;
}
.filter input {
  max-width: 135px;
}
.no-shared-files {
  min-height: 1px;
}
.height-200 {
  height: 200px;
}
</style>
