<template>
  <div
    ref="item"
    v-click-outside="() => (hasFocus = false)"
    class="file-list-item file-list-item--file"
    role="row"
    :data-path="item.path"
    :class="{
      'file-list-item--selected': isSelected,
      'file-list-item--dragging': draggingOver,
      'file-list-item--focused': hasFocus,
      'file-list-item--expanded': expanded,
      'file-list-item--compact': isCompactMode,
      'selection-made': hasSelection,
      'file-list-item--deleting': isDeleting,
      'file-list-item--search': hasSearchResults,
      'file-list-item--highlight': hasHighlight,
      'file-list-item--kb-focused': isKbFocused,
      'no-hover': isPicker,
      'pointer-none': disableHover,
    }"
  >
    <!-- adding stars-->
    <div
      class="col-pre-icons d-none d-xl-flex"
      :class="{
        'pre-icons-search': hasSearchResults,
      }"
    >
      <Drag
        v-if="!isPicker"
        :effect-allowed="['move']"
        :transfer-data="selection.length > 0 ? selection : item"
        class="pre-icon drag-indication"
        @contextmenu.prevent="contextMenu"
        @dragstart="onDragStart"
        @dragend="onDragEnd"
      >
        <Icon name="grip-vertical" family="fas" />
        <template slot="image">
          <div class="file-list-item-transfer">
            <span v-if="selection.length < 2"
              >{{ $t('Move') }}
              <strong class="item-name">{{ item.name }}</strong></span
            >
            <span v-else>
              {{ $t('Move') }} <strong>{{ selection.length }}</strong>
              {{ $t('files') }}
            </span>
          </div>
        </template>
      </Drag>
      <div
        v-if="
          !isPicker &&
          !item.isStarring &&
          item.canfavorite &&
          !isPublicShare &&
          user.userlevel !== 'LIMITED'
        "
        v-a11ybutton="() => toggleStarred(item)"
        class="pre-icon starred-indication"
        :class="{ starred: typeof item.favoritelistid === 'string' }"
        @contextmenu.prevent="contextMenu"
        @click="toggleStarred(item)"
      >
        <Icon
          name="star"
          :family="typeof item.favoritelistid === 'string' ? 'fas' : 'fal'"
        />
      </div>
      <span v-if="item.isStarring" class="pre-icon spinner-border" />
    </div>
    <!-- /adding stars-->
    <!-- select checkbox-->
    <div
      v-if="!isPicker && !isMobile && !isTablet"
      ref="selectCheckbox"
      tabindex="0"
      class="col-checkbox"
      @contextmenu.prevent="contextMenu"
      @click="select"
      @keydown.space.stop.prevent="select"
      @keydown.enter.stop.prevent="previewFile"
    >
      <Checkbox
        :checked="isSelected"
        :label="$t('Press tab for file operations on') + ' ' + item.name"
        tabindex="-1"
        role="cell"
      />
    </div>
    <!-- /select checkbox-->
    <!-- Icon an name o file-->
    <div
      class="col-title"
      role="gridcell"
      :aria-label="item.name"
      :class="{
        'col-title-wider': isPicker,
        'col-title--mobile': isMobile || isTablet,
        'col-title--highlight': hasHighlight,
      }"
      @contextmenu.prevent="contextMenu"
      @click="expand"
    >
      <Drop
        class="col-title-droppable"
        @drop="onDropFile"
        @dragenter="onDragEnter"
        @dragleave="onDragLeave"
        @dragover="onDragOver"
      >
        <div
          v-if="(isMobile || isTablet) && isSelected"
          class="col-checkbox"
          @contextmenu.prevent="contextMenu"
          @click="select"
        >
          <Checkbox :checked="isSelected" :label="$t('Select')" />
        </div>
        <Icon
          v-if="
            (item.type === 'dir' && getIsGrid) ||
            (item.type === 'dir' && !getIsGrid && !isSelected)
          "
          class="file-icon file-icon--folder"
          :subicon="itemIcon(item)"
          family="fas"
          :name="
            isSharedWithMeHome ? 'user' : isBackupFolder ? 'archive' : 'folder'
          "
        />
        <FileIcon
          v-else-if="getIsGrid || (!isSelected && !getIsGrid)"
          ref="fileIcon"
          :name="item.name"
          :item="item"
          :format="item.format"
          :path="item.path"
          :subicon="itemIcon(item)"
          :size="thumbSize"
        />
        <input
          v-if="isRenaming"
          ref="filenameInput"
          v-model="newName"
          :aria-label="$t('New file name')"
          type="text"
          class="form-control rename-input"
          @blur="onRename"
          @keyup.escape="onCancelRename"
          @keyup.enter="onRename"
        />
        <div
          ref="titlendetails"
          v-tooltip="isEllipsisActive ? item.name : ''"
          v-a11ybutton="previewFile"
          role="row"
          class="title-n-details"
          :tabindex="isPicker && isKbFocused ? '0' : '-1'"
        >
          <VPopover
            v-if="item.encryptedfolder === 1 && !isRenaming"
            offset="16"
            trigger="manual"
            :open="isEncryptedFolderAlertEnabled"
            @hide="isEncryptedFolderAlertEnabled = false"
          >
            <Themed
              ref="file-title"
              as="h4"
              text-colorr
              class="tooltip-target"
              @click="checkSecureFolderToken"
            >
              <span role="gridcell" :aria-label="item.name">{{
                item.name
              }}</span>
              <FileListItemColorDot :color="item.colortag" />
              <small v-if="!getIsGrid" class="d-md-none">
                {{ item.size && item.size.length > 0 ? item.size + ' - ' : '' }}
                {{ mobileDateFormat }}
              </small>
            </Themed>

            <Alert
              slot="popover"
              button-type="primary"
              class="alert-dialog--secureFolder"
              :buttons="[
                {
                  label: encryptedFolderOperation.label,
                  disabled: !validEncryptedFolderPass,
                  callback: function () {
                    proceedEncryptedFolderOp();
                  },
                },
                {
                  label: 'Cancel',
                  outline: true,
                  callback: function () {},
                },
              ]"
            >
              <div class="alert-dialog__header">
                <h1 tabindex="0" class="text-truncate mr-4">
                  {{ encryptedFolderOperation.title }}
                </h1>
              </div>
              <form class="form form-secure-folder" @submit.prevent>
                <div class="row mb-4">
                  <div
                    class="
                      col-md-2
                      d-flex
                      justify-content-center
                      align-items-center
                    "
                  >
                    <h1><Icon name="key" class="fs-1" /></h1>
                  </div>
                  <div class="col-md-10">
                    <p v-html="encryptedFolderOperation.content" />
                  </div>
                </div>
                <div class="mb-1 form-group">
                  <InputBox
                    id="encryptedFolderPassID"
                    ref="encryptedFolderPass"
                    v-model="encryptedFolderPassword"
                    :type="passwordType"
                    :aria-label="$t('Enter Password')"
                    autocomplete="off"
                    class="p-0"
                    :placeholder="$t('Enter Password')"
                    :appends="formWithAppends.passwordAppends"
                    onpaste="return false;"
                    ondrop="return false;"
                    @keypress.enter.native="proceedEncryptedFolderOp"
                  />
                </div>
              </form>
            </Alert>
          </VPopover>
          <Themed
            v-else-if="!isRenaming"
            ref="file-title"
            as="h4"
            text-colorr
            @click.stop="previewFile"
          >
            <span role="gridcell" :aria-label="item.name">{{ item.name }}</span>
            <FileListItemColorDot :color="item.colortag" />
            <small v-if="!getIsGrid" class="d-md-none">
              {{ item.size && item.size.length > 0 ? item.size + ' - ' : '' }}
              {{ mobileDateFormat }}
            </small>
          </Themed>
          <div
            v-if="hasHighlight"
            class="file-list-item--highlight pt-4 w-100"
            :class="{
              'text-truncate':
                !hasSearchResults || (hasSearchResults && !isTablet),
            }"
            v-html="item.highlight"
          >
            {{ highlightText }}
          </div>
          <div v-if="hasSearchResults">
            <a @click="browse">
              <ol
                class="breadcrumb pb-0"
                :class="hasHighlight ? 'pt-4' : 'pt-3'"
              >
                <li class="breadcrumb-item active fc-explorer--home">
                  <div>
                    <Icon family="fas" name="home" />
                  </div>
                </li>
                <li
                  v-for="pathElement in separatePaths(item)"
                  :key="pathElement"
                  class="breadcrumb-item"
                >
                  <Icon
                    family="fas"
                    name="chevron-right"
                    class="breadcrumb-seperator small-chevron pl-0 pr-0"
                  />
                  <a class="not-link pointer-link pl-0 ml-0">
                    {{ pathElement }}
                  </a>
                </li>
              </ol>
            </a>
          </div>
          <div class="file-list-item-details">
            <span
              v-if="typeof item.size === 'string'"
              role="gridcell"
              :aria-label="'file size:' + item.size"
              class="file-list-item-details-size"
              >{{ item.size }} {{ getIsGrid ? '' : ' - ' }}
            </span>
            <span
              v-if="typeof item.modified === 'string'"
              role="gridcell"
              :aria-label="'Last modified: ' + hijriDate(item)"
              class="file-list-item-details-modified"
            >
            </span>
          </div>
          <ul
            v-if="
              (isSelected && isMobile) ||
              (isTablet && hasSearchResults && !isTablet)
            "
            class="file-details"
          >
            <li
              v-if="item.canmanageshare"
              v-tooltip="item.canmanageshare ? $t('Managed Share') : ''"
              class="roll-over"
              :class="{ active: item.canmanageshare }"
            >
              <Icon name="share-alt-square" family="fas" />
            </li>
            <li
              v-else
              v-tooltip="$t(getShareInfo())"
              class="roll-over"
              :class="{ active: typeof item.isshared === 'string' }"
            >
              <Icon name="share-alt" family="fas" />
            </li>
            <li
              v-tooltip="
                item.tagged
                  ? $t('Item has') +
                    ' ' +
                    item.tagged +
                    ' ' +
                    $t('metadata sets')
                  : ''
              "
              class="roll-over"
              :class="{ active: item.tagged }"
            >
              <Icon name="tag" family="fas" />
            </li>
            <li
              v-tooltip="
                item.hasnotificationsrule ? $t('Notification rule added') : ''
              "
              class="roll-over"
              :class="{ active: item.hasnotificationsrule }"
            >
              <Icon name="bell" family="fas" />
            </li>
            <li
              v-tooltip="
                item.commented
                  ? $tc(
                      'Item has {count} comment | Item has {count} comments',
                      item.commented
                    )
                  : ''
              "
              class="roll-over"
              :class="{ active: item.commented }"
            >
              <Icon name="comment" family="fas" />
            </li>
            <li
              v-tooltip="lockedRead(item)"
              class="roll-over"
              :class="{ active: item.locked }"
            >
              <Icon name="lock" family="fas" />
            </li>
          </ul>
        </div>
      </Drop>
    </div>
    <!-- /Icon an name o file-->
    <!-- Hover menu-->
    <div
      v-if="!isPicker && !isMobile && !isTablet && !disableHoverContext"
      class="file-list-actions"
      @contextmenu.prevent="contextMenu"
      @click.stop
    >
      <div
        v-if="
          (!isCompactMode && hasCustomList) ||
          (isCompactMode && !fromHome) ||
          !isCompactMode
        "
        class="btn-group actuals"
        role="cell"
      >
        <span>
          <button
            v-tooltip="
              item.type === 'dir'
                ? $t('Open') + ' [Enter]'
                : $t('Preview') + ' [Enter]'
            "
            class="btn btn-sm btn-icon"
            :aria-label="
              item.type === 'dir'
                ? $t('Open') + ' [Enter]'
                : $t('Preview') + ' [Enter]'
            "
            :disabled="docPreview || item.encryptedfolder === 1"
            @click="previewFile"
          >
            <Icon name="eye" family="fal" />
            <Icon class="icon-overlay" name="eye" family="fas" />
          </button>
        </span>
        <span v-if="hasCustomList">
          <button
            v-tooltip="$t('Go to location')"
            class="btn btn-sm btn-icon"
            :aria-label="$t('Go to location')"
            @click="browse"
          >
            <Icon name="folder-open" family="fal" />
            <Icon class="icon-overlay" name="folder-open" family="fas" />
          </button>
        </span>
        <span>
          <button
            v-tooltip="
              isReadLocked ? lockMessage('Download') : $t('Download') + ' [D]'
            "
            class="btn btn-sm btn-icon"
            :aria-label="$t('Download') + ' [D]'"
            :disabled="
              isReadLocked ||
              !item.candownload ||
              (item.type === 'dir' && customization.DISABLEFOLDERDOWNLOAD === 1)
            "
            @click="downloadFile"
          >
            <span class="invisible">{{
              isReadLocked ? lockMessage('Download') : $t('Download') + ' [D]'
            }}</span>
            <Icon name="download" family="fal" />
            <Icon class="icon-overlay" name="download" family="fas" />
          </button>
        </span>
        <span v-if="canEditFile">
          <!-- Multiple edit clients -->
          <VPopover
            v-if="editorClientLength > 1"
            @show="
              () => {
                hasFocus = true;
              }
            "
          >
            <button
              v-tooltip="$t('Edit Online')"
              :aria-label="$t('Edit Online')"
              class="btn btn-sm btn-icon"
            >
              <span class="invisible">{{ $t('Edit Online') }}</span>
              <Icon name="edit" family="fal" />
              <Icon class="icon-overlay" name="edit" family="fas" />
            </button>
            <Alert
              slot="popover"
              button-type="primary"
              class="p-0 webedit-popover"
            >
              <div class="list-group list-group-flush cursor-pointer">
                <!-- Desktop -->
                <span
                  v-if="
                    isWebEditEnabled &&
                    canEditFileType(item.ext, 'desktop', allowQuickEditAll) &&
                    item.showquickedit &&
                    !(customization.ALLOWQUICKEDITALL && isExtensionBlackListed(item.ext))
                  "
                  v-close-popover
                  class="list-group-item list-group-item-action text-left"
                  @click="runQuickEdit"
                >
                  <span class="d-flex align-items-center">
                    <div class="fc-desktop-icon pr-1" />
                    {{
                      $t('file_list.web_edit.open_client', {
                        client: desktopEditorClientName,
                      })
                    }}
                  </span>
                </span>

                <!-- Web edit -->
                <span
                  v-if="isWOPIEditEnabled && canEditFileType(item.ext, 'web')"
                  v-close-popover
                  class="list-group-item list-group-item-action text-left"
                  @click="quickEditFile"
                >
                  <span>
                    <Icon name="edit" family="fal" class="pr-1" />
                    {{ $t('Edit Online') }}
                  </span>
                </span>

                <!-- Google Apps -->
                <span
                  v-if="
                    isWOPIEditEnabled &&
                    isGoogleDocsEnabled &&
                    canEditFileType(item.ext, 'google')
                  "
                  v-close-popover
                  class="list-group-item list-group-item-action text-left"
                  @click="openInGoogleDocs"
                >
                  <span class="d-flex align-items-center">
                    <div :class="googleAppsClient.iconClass" />
                    <span>{{ $t(googleAppsClient.fullLabel) }}</span>
                  </span>
                </span>
                <span
                  v-if="
                    isWOPIEditEnabled &&
                    WOPIClient &&
                    canEditFileType(item.ext, WOPIClient.key)
                  "
                  v-close-popover
                  class="list-group-item list-group-item-action text-left"
                  @click="quickEditWopi"
                >
                  <span class="d-flex align-items-center">
                    <div :class="WOPIClient.iconClass" />
                    <span>{{ $t(WOPIClient.fullLabel) }}</span>
                  </span>
                </span>
              </div>
            </Alert>
          </VPopover>
          <!-- Web Edit -->
          <span
            v-else-if="isWOPIEditEnabled && canEditFileType(item.ext, 'web')"
            list-only
          >
            <button
              v-tooltip="$t('Edit Online')"
              :aria-label="$t('Edit Online')"
              class="btn btn-sm btn-icon"
              @click="quickEditFile"
            >
              <Icon name="edit" family="fal" />
              <Icon class="icon-overlay" name="edit" family="fas" />
            </button>
          </span>

          <!-- Desktop Editor -->
          <span
            v-else-if="
              isWebEditEnabled &&
              canEditFileType(item.ext, 'desktop', allowQuickEditAll) &&
              item.showquickedit
            "
            list-only
          >
            <button
              v-tooltip="
                `<i class='fc-desktop-icon'></i>${$t(
                  'file_list.web_edit.open_client',
                  {
                    client: desktopEditorClientName,
                  }
                )}`
              "
              :aria-label="
                $t('file_list.web_edit.open_client', {
                  client: desktopEditorClientName,
                })
              "
              class="btn btn-sm btn-icon"
              @click="runQuickEdit"
            >
              <Icon name="edit" family="fal" />
              <Icon class="icon-overlay" name="edit" family="fas" />
            </button>
          </span>

          <!-- WOPI Client -->
          <span
            v-else-if="
              isWOPIEditEnabled &&
              WOPIClient &&
              canEditFileType(item.ext, WOPIClient.key)
            "
            list-only
          >
            <button
              v-tooltip="
                `<i class='${WOPIClient.iconClass}'></i> ${$t(
                  WOPIClient.fullLabel
                )}`
              "
              :aria-label="$t(WOPIClient.fullLabel)"
              class="btn btn-sm btn-icon"
              @click="quickEditWopi"
            >
              <Icon name="edit" family="fal" />
              <Icon class="icon-overlay" name="edit" family="fas" />
            </button>
          </span>

          <!-- Google Docs -->
          <span
            v-else-if="
              isWOPIEditEnabled &&
              isGoogleDocsEnabled &&
              canEditFileType(item.ext, 'google')
            "
            list-only
          >
            <button
              v-tooltip="
                `<i class='${googleAppsClient.iconClass}'></i> ${$t(
                  googleAppsClient.fullLabel
                )}`
              "
              class="btn btn-sm btn-icon"
              @click="openInGoogleDocs"
            >
              <Icon name="edit" family="fal" />
              <Icon class="icon-overlay" name="edit" family="fas" />
            </button>
          </span>
        </span>

        <!-- Web Edit -->
        <span v-else-if="!canEditFile" list-only>
          <button disabled class="btn btn-sm btn-icon">
            <Icon name="edit" family="fal" />
          </button>
        </span>
        <span v-if="!isInRecycleFolder && systemStatus.sharemode != 3">
          <VPopover :disabled="!isNonSharedRootFolder">
            <button
              v-tooltip="
                isShareDisabled || !item.isshareable
                  ? $t('Share is disabled')
                  : $t('Share') + ' [.]'
              "
              :aria-label="
                isShareDisabled ? $t('Share is disabled') : $t('Share') + ' [.]'
              "
              :disabled="isShareDisabled || !item.isshareable"
              class="btn btn-sm btn-icon"
              @click="() => (isNonSharedRootFolder ? null : shareFile())"
            >
              <Icon name="share-alt" family="fal" />
              <Icon class="icon-overlay" name="share-alt" family="fas" />
            </button>
            <Alert
              v-if="isNonSharedRootFolder"
              slot="popover"
              ref="alert"
              button-type="primary"
              :buttons="[
                {
                  label: `Share`,
                  callback: function () {
                    shareFile(true);
                  },
                },
                {
                  label: `Cancel`,
                  outline: true,
                  callback: function () {
                    isDeleting = false;
                  },
                },
              ]"
            >
              <span role="alert">
                {{ $t('Are you sure you want to share a root folder?') }}
              </span>
            </Alert>
          </VPopover>
        </span>
        <span v-if="isInRecycleFolder">
          <button
            v-tooltip="$t('Restore')"
            class="btn btn-sm btn-icon"
            :aria-label="$t('Restore')"
            @click="restoreFile(item)"
          >
            <Icon name="trash-undo" family="fal" />
            <Icon class="icon-overlay" name="trash-undo" family="fas" />
          </button>
        </span>

        <VPopover
          v-if="!isInRecycleFolder"
          :disabled="!hasDirectLink || isPublicShare"
          @apply-hide="
            () => {
              hasFocus = false;
            }
          "
          @show="onDirectLinkPopoverShow"
        >
          <button
            v-tooltip="
              hasDirectLink ? $t('Direct Link') : $t('Direct Link is disabled')
            "
            :disabled="!hasDirectLink || isPublicShare"
            :aria-label="
              hasDirectLink || !isPublicShare
                ? $t('Direct Link')
                : $t('Direct Link is disabled')
            "
            class="btn btn-sm btn-icon"
            list-only
          >
            <Icon name="link" family="fal" />
            <Icon class="icon-overlay" name="link" family="fas" />
          </button>
          <Alert
            v-if="hasDirectLink"
            slot="popover"
            button-type="primary"
            :buttons="[
              {
                label: `Copy Link`,
                callback: function () {
                  copyDirectLink();
                },
              },
              { label: `Cancel`, outline: true, callback: function () {} },
            ]"
          >
            <span role="alert">
              <span v-if="!isDirectLinkReady">
                <Loader full loading />
                {{ $t('Loading Direct Link') }}
              </span>
              <span v-else>
                {{ $t('Direct Link is Ready') }}
              </span>
            </span>
          </Alert>
        </VPopover>

        <span list-only>
          <button
            v-tooltip="
              isLockedByOthers
                ? lockMessage('Copy')
                : $t('Copy') + ' [' + metaKey + '+ C]'
            "
            class="btn btn-sm btn-icon"
            :aria-label="$t('Copy') + ' [' + metaKey + '+ C]'"
            :disabled="
              isLockedByOthers ||
              (item.isroot && item.type === 'dir') ||
              !item.candownload ||
              !user.authenticated ||
              item.encryptedfolder === 1
            "
            @click="copyFile"
          >
            <Icon name="clone" family="fal" />
            <Icon class="icon-overlay" name="clone" family="fas" />
          </button>
        </span>
        <span list-only>
          <button
            v-tooltip="
              isLockedByOthers
                ? lockMessage('Move')
                : $t('Move') + ' [' + metaKey + '+ X]'
            "
            class="btn btn-sm btn-icon"
            :aria-label="$t('Copy') + ' [' + metaKey + '+ C]'"
            :disabled="
              isLockedByOthers ||
              ((item.isroot || !item.canupload) && !isInRecycleFolder) ||
              item.encryptedfolder === 1
            "
            @click="moveFile"
          >
            <Icon name="long-arrow-right" family="fal" />
            <Icon class="icon-overlay" name="long-arrow-right" family="fas" />
          </button>
        </span>
        <VPopover
          :open="showDelConf"
          :disabled="
            isLockedByOthers ||
            item.isroot === 1 ||
            (!item.canupload && !isInRecycleFolder) ||
            (systemStatus.clearrecyclebin === 0 && isInRecycleFolder)
          "
          @show="
            () => {
              hasFocus = true;
              isDeleteAlertEnabled = true;
            }
          "
          @hide="
            () => {
              isDeleteAlertEnabled = false;
            }
          "
        >
          <button
            v-tooltip="
              isLockedByOthers
                ? lockMessage('Delete')
                : isInRecycleFolder
                ? $t('Permanently Delete') + ' [Del]'
                : $t('Delete') + ' [Del]'
            "
            class="btn btn-sm btn-icon"
            :aria-label="
              isInRecycleFolder
                ? $t('Permanently Delete') + ' [Del]'
                : $t('Delete') + ' [Del]'
            "
            list-only
            :disabled="
              isLockedByOthers ||
              item.isroot === 1 ||
              (!item.canupload && !isInRecycleFolder) ||
              (systemStatus.clearrecyclebin === 0 && isInRecycleFolder)
            "
          >
            <Icon name="trash-alt" family="fal" />
            <Icon class="icon-overlay" name="trash-alt" family="fas" />
          </button>
          <Alert
            slot="popover"
            button-type="danger"
            :visible="isDeleteAlertEnabled"
            :buttons="[
              {
                label: `Delete`,
                callback: function () {
                  doDeleteFile();
                },
              },
              { label: `Cancel`, outline: true, callback: function () {} },
            ]"
          >
            <span role="alert">
              {{
                item.encryptedfolder === 1
                  ? $t('Are you sure you want to permanently delete')
                  : $t('Are you sure you want to delete')
              }}
              {{ item.name }}?
            </span>
          </Alert>
        </VPopover>
        <VPopover
          v-if="!isInRecycleFolder && item.locked"
          :disabled="
            isLockedByOthers ||
            (item.isroot === 1 && !item.dirpath.startsWith('/SHARED/')) ||
            isPublicShare ||
            isLimitedUser ||
            !item.showlockunlock ||
            item.encryptedfolder === 1
          "
          @show="
            () => {
              hasFocus = true;
            }
          "
          @update:open="
            (isOpen) => {
              isUnlockAlertOpen = isOpen;
            }
          "
        >
          <button
            v-tooltip="
              isLockedByOthers ? lockMessage('Unlock') : $t('Unlock') + ' [L]'
            "
            :disabled="
              isLockedByOthers ||
              (item.isroot && !item.dirpath.startsWith('/SHARED/')) ||
              isPublicShare ||
              isLimitedUser ||
              !item.showlockunlock ||
              item.encryptedfolder === 1
            "
            :aria-label="$t('Unlock') + ' [L]'"
            class="btn btn-sm btn-icon"
            list-only
          >
            <Icon name="unlock" family="fal" />
            <Icon class="icon-overlay" name="unlock" family="fas" />
          </button>
          <Alert
            slot="popover"
            button-type="danger"
            :visible="isUnlockAlertOpen"
            :buttons="[
              {
                label: $t('Unlock'),
                callback: function () {
                  unlockFile();
                },
              },
              { label: `Cancel`, outline: true, callback: function () {} },
            ]"
          >
            <span role="alert">
              {{ $t('Are you sure you want to unlock') + ' ' }}
              {{ item.name }}?
            </span>
          </Alert>
        </VPopover>
        <span v-if="!isInRecycleFolder && !item.locked" list-only>
          <button
            v-tooltip="$t('Lock') + ' [L]'"
            class="btn btn-sm btn-icon"
            :aria-label="$t('Lock') + ' [L]'"
            :disabled="
              (item.isroot && !item.dirpath.startsWith('/SHARED/')) ||
              isPublicShare ||
              isLimitedUser ||
              !item.showlockunlock ||
              item.encryptedfolder === 1 ||
              isLockDisabled
            "
            @click="lockFile"
          >
            <Icon name="lock" family="fal" />
            <Icon class="icon-overlay" name="lock" family="fas" />
          </button>
        </span>
      </div>
      <div
        v-if="!isInRecycleFolder && !isMobile && !isTablet"
        class="btn-group more-options"
        role="cell"
      >
        <VPopover
          ref="popover"
          :popover-class="'dropdown'"
          :class="'sample'"
          :container="'body'"
          :placement="'bottom'"
          :offset="3"
          @show="
            () => {
              hasFocus = true;
              contextMenuOpen = true;
              this.$emit('toggleMoreOptions', true);
            }
          "
          @hide="
            () => {
              this.$emit('toggleMoreOptions', false);
              contextMenuOpen = false;
              hasFocus = false;
            }
          "
        >
          <button
            ref="showMoreButton"
            v-tooltip="$t('More')"
            :aria-label="$t('More')"
            class="btn btn-sm btn-icon"
          >
            <Icon name="ellipsis-h" family="far" />
            <Icon class="icon-overlay" name="ellipsis-h" family="fas" />
          </button>
          <template slot="popover">
            <ContextMenu
              class="dropdown-wrapper"
              :child="this"
              :local="true"
              :is-in-recycle-folder="isInRecycleFolder"
              :is-limited-user="isLimitedUser"
              :is-public-share-view="isPublicShare"
              :is-msteams="msTeams"
              :open="contextMenuOpen"
              :is-compact-mode="isCompactMode"
              @onItemContextClick="triggerAction"
            />
          </template>
        </VPopover>
      </div>
    </div>
    <!-- /Hover menu-->
    <!-- Last Modify info for search-->
    <div
      v-if="
        hasCustomList &&
        hasSearchResults &&
        typeof item.modified == 'string' &&
        !isMobile
      "
      role="gridcell"
      :aria-label="'Last modified: ' + hijriDate(item)"
      class="col-info m-text"
      :class="{
        'recent-mod': recentlyModified(item.modified),
        'd-flex': disableHoverContext,
      }"
      @click="expand"
      @contextmenu.prevent="contextMenu"
    >
      <h4>
        <DateDisplay :date="hijriDate(item)" class="d-inline" />

        <span class="recent-dot"></span>
        <small
          v-if="item.lastmodifiedby && typeof item.lastmodifiedby === 'string'"
          class="small-text"
          >{{ $t('by') }}
          {{
            item.lastmodifiedby === user.peerid
              ? $t('you')
              : item.lastmodifiedby
          }}</small
        >
      </h4>
    </div>
    <!-- /Last Modify info for search--->
    <!-- Last Modify info -->
    <div
      v-if="!hasCustomList && typeof item.modified == 'string' && !isMobile"
      class="col-info m-text"
      role="gridcell"
      :aria-label="'Last modified: ' + hijriDate(item)"
      :class="{
        'recent-mod': recentlyModified(item.modified),
        'd-flex': disableHoverContext,
      }"
      @click="expand"
      @contextmenu.prevent="contextMenu"
    >
      <h4>
        <DateDisplay :date="hijriDate(item)" class="d-inline" />
        <span class="recent-dot"></span>
        <small
          v-if="item.lastmodifiedby && typeof item.lastmodifiedby === 'string'"
          class="small-text"
          >{{ $t('by') }}
          {{
            item.lastmodifiedby === user.peerid
              ? $t('you')
              : item.lastmodifiedby
          }}</small
        >
      </h4>
    </div>
    <!-- /Last Modify info -->
    <!-- size info -->
    <div
      v-if="!hasCustomList && !getIsGrid && !isMobile && !isPicker"
      class="col-info"
      role="gridcell"
      :aria-label="'file size:' + item.size"
      :class="{
        'd-flex': disableHoverContext,
      }"
      @contextmenu.prevent="contextMenu"
      @click="expand"
    >
      {{ item.size && item.size.length > 0 ? item.size : '' }}
    </div>
    <!-- /size info -->
    <!-- custom list or search results -->
    <div
      v-if="
        hasCustomList &&
        hasSearchResults &&
        !getIsGrid &&
        !isMobile &&
        !isPicker
      "
      class="col-info"
      role="gridcell"
      :aria-label="'file size:' + item.size"
      @contextmenu.prevent="contextMenu"
      @click="expand"
    >
      {{ item.size && item.size.length > 0 ? item.size : '' }}
    </div>
    <!-- /custom list or search results -->
    <!-- info column if not picker -->
    <div
      v-if="!isPicker"
      class="col-properties col-info"
      :class="{
        'col-properties--mobile': isMobile || isTablet,
        'd-none': hasCustomList && !isMobile && !isTablet,
        'd-block': hasCustomList && hasSearchResults,
      }"
      @contextmenu.prevent="contextMenu"
      @click="expand"
    >
      <ul
        v-if="
          canShowProps(item) && !isMobile && !isTablet && !disableHoverContext
        "
        class="properties-row"
        role="cell"
      >
        <li class="main-icon">
          <Icon name="info-circle" family="fas" />
        </li>
        <li
          v-if="lockedInfo(item)"
          v-tooltip="lockedRead(item)"
          class="main-icon active"
        >
          <Icon name="lock" family="fas" />
        </li>
        <li
          v-if="item.canmanageshare"
          v-tooltip="item.canmanageshare ? $t('Managed Share') : ''"
          class="roll-over"
          :class="{ active: item.canmanageshare }"
        >
          <Icon name="share-alt-square" family="fas" />
        </li>
        <li
          v-else
          v-tooltip="$t(getShareInfo())"
          class="roll-over"
          :class="{ active: typeof item.isshared === 'string' }"
        >
          <Icon name="share-alt" family="fas" />
        </li>
        <li
          v-tooltip="
            item.tagged
              ? $t('Item has') + ' ' + item.tagged + ' ' + $t('metadata sets')
              : ''
          "
          class="roll-over"
          :class="{ active: item.tagged }"
        >
          <Icon name="tag" family="fas" />
        </li>
        <li
          v-tooltip="
            item.hasnotificationsrule ? $t('Notification rule added') : ''
          "
          class="roll-over"
          :class="{ active: item.hasnotificationsrule }"
        >
          <Icon name="bell" family="fas" />
        </li>
        <li
          v-tooltip="
            item.commented
              ? $tc(
                  'Item has {count} comment | Item has {count} comments',
                  item.commented
                )
              : ''
          "
          class="roll-over"
          :class="{ active: item.commented }"
        >
          <Icon name="comment" family="fas" />
        </li>
        <li
          v-tooltip="lockedRead(item)"
          class="roll-over"
          :class="{ active: item.locked }"
        >
          <Icon name="lock" family="fas" />
        </li>
      </ul>

      <div
        v-else-if="isMobile || (isTablet && !isPicker)"
        class="file-details cursor-pointer"
        @click="openSidebarForFile"
      >
        <Icon name="info-circle" family="fas" />
      </div>
    </div>
    <!-- /info column if not picker -->
    <!-- mobile more options -->
    <div
      v-if="(isMobile || isTablet) && !isPicker"
      class="col-more-options--mobile cursor-pointer"
      role="group"
      @click="openMobileMenu"
    >
      <Icon :name="'ellipsis-v'" class="more-options" />
    </div>
    <!-- / mobile more options -->
  </div>
</template>
<script>
import fileListItemMixin from '../../mixins/fileListItemMixin';
import lockReadMixin from '../../mixins/lockReadMixin';
import responsivenessMixin from '@/mixins/responsivenessMixin';
import Themed from './../Themed';
import FileListItemColorDot from './FileListItemColorDot';
import LockIcon from './partials/LockIcon';
import ContextMenu from './ContextMenu';
import fileActions from '../../constants/fileActions';
import { PREVIEWABLEDOC_CLOUD } from 'common/utils/fileExtensions';
import InputBox from 'common/components/InputBox';
import DropDownList from 'common/components/DropDownList';
import DateDisplay from 'common/components/DateDisplay';
import GoogleSheets from 'common/assets/icons/fcgoogle-sheets.svg';
import GoogleDocs from 'common/assets/icons/fcgoogle-docs.svg';
import GoogleSlides from 'common/assets/icons/fcgoogle-slides.svg';
import OfficeOnline from 'common/assets/office.svg';

import Checkbox from 'common/components/Checkbox.vue';
import FileIcon from 'common/components/FileIcon';
import Icon from 'common/components/Icon';
import Alert from 'common/components/Alert';
import Loader from 'common/components/Loader';
import { sizes } from 'constants/sizes';
import 'vue-context/dist/css/vue-context.css';
import { canEditFileType, getParams, getFileType } from 'common/utils/files';
import { WOPIClients, GoogleDocsFormats } from '../../constants/WOPI.js';

import { Drag, Drop } from 'vue-drag-drop';
import { VPopover } from 'v-tooltip';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import * as microsoftTeams from '@microsoft/teams-js';
dayjs.extend(relativeTime);

export default {
  components: {
    LockIcon,
    Drag,
    Drop,
    Checkbox,
    FileIcon,
    Icon,
    Themed,
    Alert,
    Loader,
    ContextMenu,
    FileListItemColorDot,
    InputBox,
    VPopover,
    DropDownList,
    DateDisplay,
    GoogleSheets,
    GoogleDocs,
    GoogleSlides,
    OfficeOnline,
  },
  mixins: [fileListItemMixin, responsivenessMixin, lockReadMixin],
  props: {
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
    isPicker: Boolean,
    // currently selected items
    selection: {
      type: Array,
      default: () => {
        return [];
      },
    },
    expanded: {
      type: Boolean,
      default: false,
    },
    hasCustomList: {
      type: Boolean,
      default: false,
    },
    hasSearchResults: {
      type: Boolean,
      default: false,
    },
    hasSelection: {
      type: Boolean,
      default: false,
    },
    isSharedByMe: {
      type: Boolean,
      default: false,
    },
    isPublicShare: {
      type: Boolean,
      default: false,
    },
    isBackupFolderSibling: {
      type: Boolean,
      default: false,
    },
    disableHover: {
      type: Boolean,
      default: false,
    },
    disableHoverContext: {
      type: Boolean,
      default: false,
    },
    fromHome: {
      type: Boolean,
      default: false,
    },
    isKbFocused: {
      type: Boolean,
      default: false,
    },
    isCompactMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isDragActive: false,
      clickTimeout: null,
      isRenaming: false,
      newName: '',
      isDeleting: false,
      isInRecycleFolder: false,
      hasFocus: false,
      directLink: '',
      draggingOver: false,
      counter: 0,
      showDelConf: false,
      isEllipsisActive: false,
      fileActions,
      contextMenuOpen: false,
      isDeleteAlertEnabled: false,
      isUnlockAlertOpen: false,
      encryptedFolderPassword: '',
      renameShare: false,
      isEncryptedFolderAlertEnabled: false,
      passwordType: 'password',
      isSharingRootFolder: false,
      currentEncryptedFolderOp: 'preview',
      renameFromContext: false,
      renameItem: false,
      renameShareOut: false,
      isParentValidEncryptedFolder: false,
      WOPIClients,
      GoogleDocsFormats,
    };
  },
  computed: {
    ...mapGetters('core', [
      'isLockDisabled',
      'WOPIClient',
      'isGoogleDocsEnabled',
      'isWOPIEditEnabled',
      'isWebEditEnabled',
      'getEditorClientName',
      'isLimitedUser',
      'allowQuickEditAll',
    ]),
    ...mapGetters('files', ['getIsGrid']),
    desktopEditorClientName() {
      return this.getEditorClientName(this.item);
    },
    docPreview() {
      return (
        !this.customization.ENABLEDOCPREVIEW &&
        PREVIEWABLEDOC_CLOUD.includes(this.item.ext)
      );
    },
    mobileMenu() {
      return this.$store.getters['files/getMobileMenuState']();
    },
    msTeams() {
      return this.$store.state.auth.msteams;
    },
    highlightText() {
      return this.item.highlight
        .replace(/<hlt[^>]*>/g, '<strong>')
        .replace(/<\/hlt>/g, '</strong>');
    },
    hasHighlight() {
      return !_.isEmpty(this.item.highlight);
    },
    hasDirectLink() {
      // If secure flag is set,
      // then password protected share view
      // Does not have direct link when in password protected
      // Disable DL then
      let { secure } = getParams();
      return (
        (this.item.fullfilename.startsWith('/EXTERNAL') ||
          this.isPublicShare ||
          this.item.fullfilename.startsWith('/SHARED')) &&
        _.isEmpty(secure) &&
        !this.shouldHideShareDirectLinkOption
      );
    },
    isShareDisabled() {
      return this.$store.state.core.systemstatus.SHAREMODE === 3;
    },
    shouldHideShareDirectLinkOption() {
      return (
        this.$store.state.core.systemstatus.HIDESHAREDIRECTLINKOPTION === 1
      );
    },
    uploading() {
      return this.$store.state.loading['file.upload'];
    },
    user() {
      return this.$store.state.auth.user;
    },
    encryptedFolderPasswords() {
      return this.$store.state.files.encryptedFolderPasswords;
    },
    filesMeta() {
      return this.$store.state.files.filesMeta;
    },
    parentPath() {
      let dirpath =
        this.item.type === 'dir' && this.filesMeta.encryptedfolder === 0
          ? this.item.path
          : this.item.dirpath;

      if (!this.isPublicShare && !this.isSharedWithMe) {
        return this.item.type === 'dir' && this.filesMeta.encryptedfolder === 0
          ? dirpath
          : dirpath.substring(0, dirpath.length - 1);
      } else {
        return this.isPublicShare ? this.path : this.item.path;
      }
    },
    isSelected() {
      return _.findIndex(this.selection, { id: this.item.id }) > -1;
    },
    thumb() {
      return this.item.format && this.item.format === 'img' ? 'THUMB' : null;
    },
    thumbSize() {
      return this.getIsGrid ? sizes.gridThumb : sizes.listThumb;
    },
    itemsSelected() {
      return this.selection ? this.selection.length : 0;
    },
    canEdit() {
      return this.$store.state.core.systemstatus.ENABLEWEBEDIT;
    },
    formWithAppends() {
      return {
        passwordAppends: [
          {
            icon: this.passwordType === 'password' ? 'eye' : 'eye-slash',
            iconBold: true,
            action: this.togglePasswordType,
          },
        ],
      };
    },
    isOffice() {
      if (!this.item || !this.item.format) return false;
      const officeFileTypes = ['word', 'powerpoint', 'excel'];
      return officeFileTypes.indexOf(this.item.format) > -1;
    },
    customization() {
      return this.$store.state.core.customization;
    },
    systemStatus() {
      return this.$store.state.core.fullSystemStatus;
    },
    metaKey() {
      return window.navigator.platform === 'MacIntel' ? 'Cmd' : 'Ctrl';
    },
    dateFormat() {
      if (typeof this.systemStatus.dateformat === 'undefined') {
        // Default date format
        return 'MMM DD, YYYY';
      }

      return this.systemStatus.dateformat
        ? this.systemStatus.dateformat.toUpperCase()
        : this.systemStatus.defaultdateformat.toUpperCase();
    },
    timeFormat() {
      return this.systemStatus.timeformat
        ? this.systemStatus.timeformat
        : this.systemStatus.defaulttimeformat
        ? this.systemStatus.defaulttimeformat
        : 'HH:mm:ss';
    },
    mobileDateFormat() {
      if (this.item.modifiedepochutc > 0) {
        return dayjs(this.item.modifiedepochutc * 1000).fromNow();
      } else return '';
    },
    validEncryptedFolderPass() {
      return (
        this.encryptedFolderPassword.length > 4 ||
        this.isParentValidEncryptedFolder
      );
    },
    backupPath() {
      return this.$store.state.core.systemstatus.BACKUPPATH;
    },
    isBackupFolder() {
      if (this.isBackupFolderSibling || this.hasCustomList)
        return this.item.fullfilename === this.backupPath;
      return false;
    },
    isSharedWithMeHome() {
      return this.$route.fullPath === '/expl-tabl./SHARED';
    },
    metaSets() {
      return this.$store.state.core.metaSets;
    },
    currentEncryptedHeader() {
      return this.$store.state.files.currentEncryptedHeader;
    },
    isLockedByOthers() {
      return !!(this.item.locked && this.item.lockuserid !== this.user.profile);
    },
    isImplicitlyLocked() {
      return this.item.implicitlock;
    },
    isReadLocked() {
      return this.isLockedByOthers && this.item.lockreadlock;
    },
    path() {
      const { pathMatch: path } = this.$router.history.current.params;
      return `/${path ? path : this.user.peerid}`;
    },
    isSharedWithMe() {
      return this.path.indexOf('/SHARED') > -1;
    },
    isNonSharedRootFolder() {
      return (
        this.item.isroot &&
        typeof this.item.isshared !== 'string' &&
        !this.isSharedWithMe
      );
    },
    encryptedFolderOperation() {
      let operation = {
        label: '',
        title: '',
        content: '',
      };

      switch (this.currentEncryptedFolderOp) {
        case 'preview':
          operation.label = this.$t('Open');
          operation.title = this.$t('Access Encrypted Content');
          operation.content = this.$t(
            'Enter your password to access encrypted content.<br/><br/>You will not be able to access the content if an incorrect password is provided.'
          );
          break;
        case 'download':
          operation.label = this.$t('Download');
          operation.title = this.$t('Download Encrypted Content');
          operation.content = this.$t(
            'Enter your password to download the encrypted content.<br/><br/>You will not be able to access the content if an incorrect password is provided.'
          );
          break;
        case 'delete':
          operation.label = this.$t('Delete');
          operation.title = this.$t('Delete Encrypted Content');
          operation.content = this.$t(
            'Enter your password to delete the encrypted content.<br/><br/>You will not be able to delete the content if an incorrect password is provided.'
          );
          break;
        case 'rename':
          operation.label = this.$t('Rename');
          operation.title = this.$t('Rename Encrypted Content');
          operation.content = this.$t(
            'Enter your password to rename the encrypted content.<br/><br/>You will not be able to rename the content if an incorrect password is provided.'
          );
          break;
        case 'share':
          operation.label = this.$t('Share');
          operation.title = this.$t('Share Encrypted Content');
          operation.content = this.$t(
            'Enter your password to share the encrypted content.<br/><br/>You will not be able to rename the content if an incorrect password is provided.'
          );
          break;
      }

      return operation;
    },
    canEditFile() {
      return (
        !this.communityEdition &&
        !this.isInRecycleFolder &&
        (this.isWOPIEditEnabled || this.isWebEditEnabled) &&
        this.systemStatus.enablewebedit &&
        !(this.isLockedByOthers && !this.isImplicitlyLocked) &&
        this.item.candownload &&
        this.item.canupload &&
        this.item.type === 'file' &&
        ((this.WOPIClient &&
          this.canEditFileType(this.item.ext, this.WOPIClient.key)) ||
          (this.isWebEditEnabled &&
            this.canEditFileType(
              this.item.ext,
              'desktop',
              this.allowQuickEditAll
            ) &&
            this.item.showquickedit) ||
          (canEditFileType(this.item.ext, 'google') &&
            this.isGoogleDocsEnabled &&
            this.googleAppsClient) ||
          this.canEditFileType(this.item.ext, 'web'))
      );
    },
    googleAppsClient() {
      return this.GoogleDocsFormats[
        this.itemFormat(this.item.ext || this.item.name)
      ];
    },
    editorClientLength() {
      //this.isWebEditEnabled
      let clients = 0;

      // WOPI
      if (
        this.isWOPIEditEnabled &&
        this.WOPIClient &&
        canEditFileType(this.item.ext, this.WOPIClient.key)
      ) {
        clients++;
      }

      //Google Apps
      if (
        this.isGoogleDocsEnabled &&
        this.isOffice &&
        this.googleAppsClient &&
        this.isWOPIEditEnabled
      )
        clients++;

      //Web Edit
      if (this.canEditFileType(this.item.ext, 'web') && this.isWOPIEditEnabled)
        clients++;

      //Desktop edit
      if (
        this.isWebEditEnabled &&
        this.canEditFileType(
          this.item.ext,
          'desktop',
          this.allowQuickEditAll
        ) &&
        this.item.showquickedit
      )
        clients++;

      return clients;
    },
    communityEdition() {
      return this.customization && this.customization.isCommunityEdition();
    },
  },
  watch: {
    item(newItem, oldItem) {
      if (this.isDeleting && newItem.name !== oldItem.name) {
        this.isDeleting = false;
      }
    },
    'item.name'() {
      this.$nextTick(() => {
        this.isEllipsisActive = this.evaluateIsEllipsisActive();
      });
    },
    $route(to) {
      const nextPath = `/${to.params.pathMatch}`;
      this.isInRecycleFolder = nextPath.indexOf('recyclebin') > -1;
    },
    isKbFocused(isFocused) {
      if (isFocused) {
        this.$nextTick(() => {
          const elToFocus =
            this.$refs.selectCheckbox || this.$refs.titlendetails;
          elToFocus.focus();
        });
      }
    },
    contextMenuOpen(isOpen) {
      if (isOpen) {
        this.onContextMenuOpen();
      }
    },
  },
  mounted() {
    this.isInRecycleFolder = this.$route.fullPath.indexOf('recyclebin') > -1;

    this.isEllipsisActive = this.evaluateIsEllipsisActive();
  },
  methods: {
    ...mapActions('files', [
      'openDeleteConfirmationModal',
      'closeDeleteConfirmationModal',
    ]),
    hijriDate({ modifiedepochutc, modifiediso }) {
      return !this.isPublicShare && typeof modifiedepochutc == 'number'
        ? modifiedepochutc
        : Date.parse(modifiediso) / 1000;
    },
    itemFormat(ext) {
      return getFileType(ext);
    },
    setIsDeleting(isDeleting) {
      this.isDeleting = isDeleting;
    },
    itemIcon({ encryptedfolder, locked }) {
      const encryptedIcon = encryptedfolder ? 'fas fa-key' : '';
      const lockedIcon = locked ? 'fas fa-lock' : '';
      const icon = encryptedIcon || lockedIcon;
      return icon;
    },
    lockedInfo({ zerotrust, locked }) {
      return zerotrust && locked ? locked : false;
    },
    separatePaths(item) {
      return item.path.split('/').filter((e) => e !== '');
    },
    setFocus(focused) {
      this.hasFocus = focused;
    },
    contextMenu(e) {
      this.hasFocus = true;
      this.$emit('context-menu', { e, entry: this });
    },
    async requestForDRMFile() {
      this.$store.dispatch('files/exportAsDRMFilesTX', {
        items: [this.item],
      });
    },
    async metaColor(color) {
      const payload = {
        fullpath: this.item.path,
        setid: this.metaSets.setId,
        attribute0_attributeid: this.metaSets.attributeId,
        attribute0_value: color.value,
        attributes_total: 1,
      };
      const response = await this.$store.dispatch(
        'files/saveMetadataAttr',
        payload
      );
      if (response.ok) {
        this.$set(this.item, 'colortag', color.value);
        const msg = this.item.type === 'dir' ? 'Folder' : 'File';
        const successMessage = this.$t('Success');
        this.$toast.open({
          message: `<b>${successMessage}</b>
                    <p role="alert">${msg} <b>${this.item.name}</b> is color tagged with <b>${color.label}</b></p>`,
          type: 'success',
        });
      } else {
        this.$toast.open({
          message: `<b>${this.$t('Error')}</b><p role="alert">${
            response.data.message
          }</p>`,
          type: 'error',
        });
      }
    },
    togglePasswordType() {
      this.passwordType =
        this.passwordType === 'password' ? 'text' : 'password';
    },
    async removeMetaColor() {
      const res = await this.$store.dispatch('files/removeMetadataSet', {
        path: this.item.path,
        setid: this.metaSets.setId,
      });
      if (res.ok) {
        const successMessage = this.$t('Success');
        const msg = this.item.type === 'dir' ? 'Folder' : 'File';
        this.$toast.open({
          message: `<b>${successMessage}</b>
                    <p role="alert">Color tag is removed from ${msg} <b>${this.item.name}</b></p>`,
          type: 'success',
        });
        this.item.colortag = 'transparent';
      } else {
        this.$toast.open({
          message: `<b>${this.$t('Error')}</b>
                    <p role="alert">${res.error}</p>`,
          type: 'error',
        });
      }
    },
    triggerAction(action, item, param) {
      this.hasFocus = false;
      this.$refs.popover.hide();
      this.$emit('toggleMoreOptions', false);
      this[action](param);
    },
    select(e) {
      const { ctrlKey, shiftKey, metaKey } = e;
      this.$emit('selected', { item: this.item, ctrlKey, shiftKey, metaKey });
    },
    async openSidebarForFile() {
      this.$emit('clearSelection');

      this.$store.commit('files/setSidebar', {
        key: 'selected',
        value: [this.item],
      });
      this.$store.dispatch('files/setSelectedItem', this.item);
      this.$store.dispatch('files/showItemDetailsSidebar', this.item);
      this.$store.commit('files/setSidebar', {
        key: 'lastSelected',
        value: this.item,
      });
      this.$store.commit('files/setSidebar', {
        key: 'open',
        value: true,
      });
    },
    expand(e) {
      let isTargetTitle =
        e.target.className === 'col-title' ||
        e.target.className === 'col-title-droppable' ||
        this.getIsGrid;

      const { ctrlKey, shiftKey, metaKey } = e;

      if (!this.isMobile) {
        if (!this.clickTimeout) {
          this.$emit('expanded', this.item);
          this.$emit(
            'selected',
            { item: this.item, ctrlKey, shiftKey, metaKey },
            isTargetTitle
          );

          // doubleclick tolerance
          this.clickTimeout = setTimeout(() => {
            this.clickTimeout = null;
          }, 250);

          if (this.getIsGrid && !this.isTablet) {
            this.previewFile();
          }
        } else {
          clearTimeout(this.clickTimeout);
          this.clickTimeout = null;
          if (!this.isTablet) {
            this.previewFile();
          }
        }
      } else {
        this.$emit('expanded', this.item);
        this.$emit(
          'selected',
          { item: this.item, ctrlKey, shiftKey, metaKey },
          isTargetTitle
        );
      }
    },
    preview(e) {
      if (!this.isRenaming) {
        const isShiftPressed = e && e.shift;

        if (!this.picker) {
          this.$emit('preview', this.item, isShiftPressed);
        } else {
          this.select(e);
        }
      }
    },
    async browse() {
      if (this.item.encryptedfolder !== 1) {
        await this.$store.dispatch('files/removeEncryptionHeader');
      }
      this.$emit(
        'browsed',
        this.item.type !== 'dir' ? this.item.dirpath : this.item
      );
    },
    async previewFile(event) {
      if (!this.isRenaming) {
        const isShiftPressed = event && event.shiftKey;

        if (this.item.type === 'dir') {
          await this.browse();
        } else {
          this.preview({
            shift: isShiftPressed,
          });
        }

        this.$nextTick(() => {
          this.encryptedFolderPassword = '';
          this.isEncryptedFolderAlertEnabled = false;
        });
      }
    },
    async saveEncryptedFolderPass() {
      await this.$store.dispatch('files/saveEncryptedFolderPass', {
        folder: this.parentPath,
        password: this.encryptedFolderPassword,
      });
    },
    async proceedEncryptedFolderOp() {
      if (!this.validEncryptedFolderPass) return;

      if (!this.isParentValidEncryptedFolder) {
        await this.saveEncryptedFolderPass();
      }

      switch (this.currentEncryptedFolderOp) {
        case 'preview':
          if (this.item.type === 'dir') {
            await this.browse();
          } else {
            this.$emit('downloadFile', this.item);
          }
          break;
        case 'download':
          this.$emit('downloadFile', this.item);
          break;
        case 'delete':
          await this.deleteOperation();
          break;
        case 'rename':
          await this.renameOperation();
          break;
        case 'share':
          await this.shareFileOp();
          break;
      }

      this.isEncryptedFolderAlertEnabled = false;

      setTimeout(() => {
        if (this.currentEncryptedFolderOp !== 'rename' && !this.renameShare) {
          this.encryptedFolderPassword = '';
        } else {
          this.currentEncryptedFolderOp = 'preview';
        }
      }, 1000);
    },
    async checkSecureFolderToken(defaultOp = true) {
      if (defaultOp) {
        this.currentEncryptedFolderOp = 'preview';
      }

      if (this.systemStatus['encryptedfolderpasswordsession']) {
        const hasTokenSaved =
          this.encryptedFolderPasswords[this.item.path] !== undefined;

        if (hasTokenSaved) {
          this.encryptedFolderPassword =
            this.encryptedFolderPasswords[this.item.path];

          if (this.encryptedFolderPassword) {
            await this.$store.dispatch('files/saveEncryptedFolderPass', {
              folder: this.parentPath,
              password: this.encryptedFolderPassword,
            });
            await this.proceedEncryptedFolderOp();
          }
        } else {
          let foundPass = null;
          Object.entries(this.encryptedFolderPasswords).forEach(
            ([key, value]) => {
              if (this.item.path.includes(key)) {
                foundPass = value;
              }
            }
          );

          if (foundPass) {
            await this.$store.dispatch('files/setEncryptionHeader', foundPass);
            this.isParentValidEncryptedFolder = true;
            await this.proceedEncryptedFolderOp();
          } else {
            this.isEncryptedFolderAlertEnabled = true;
            setTimeout(() => {
              document.getElementById('encryptedFolderPassID').focus();
            }, 500);
          }
        }
      } else {
        if (
          this.currentEncryptedHeader !== this.encryptedFolderPassword ||
          this.encryptedFolderPassword.length === 0
        ) {
          this.isEncryptedFolderAlertEnabled = true;
          setTimeout(() => {
            document.getElementById('encryptedFolderPassID').focus();
          }, 500);
        } else {
          await this.proceedEncryptedFolderOp();
        }
      }
    },
    deleteFile() {
      this.openDeleteConfirmationModal([
        {
          label: 'Delete',
          outline: false,
          danger: true,
          callback: () => {
            this.doDeleteFile();
            this.closeDeleteConfirmationModal();
          },
        },
      ]);
    },
    quickEditFile() {
      this.$router.push({
        name: 'webeditor',
        query: { filepath: this.item.path, fromhome: this.fromHome },
      });
    },
    openInGoogleDocs() {
      this.quickEditWopi({ googleEdit: true });
    },
    quickEditWopi(params) {
      let hasError = false;

      if (this.item.canupload !== 1) {
        // if powerpoint, locked by others and readonly, show warning
        if (
          this.isOffice &&
          this.isLockedByOthers &&
          !this.isImplicitlyLocked
        ) {
          hasError = true;
        }
      }

      if (hasError) {
        const title = this.$t('Error');
        const message = this.$t('Editing is blocked as the file is locked.');
        this.$toast.open({
          message: `<b>${title}</b><p role="alert">${message}</p>`,
          type: 'warning',
        });
      } else {
        this.item.googleEdit = params?.googleEdit || false;
        this.$store.dispatch('files/wopiEdit', this.item);
        delete this.item.googleEdit;
      }
    },
    async downloadFile() {
      if (this.item['encryptedfolder']) {
        this.currentEncryptedFolderOp = 'download';
        await this.checkSecureFolderToken(false);
      } else {
        await this.$store.dispatch('files/removeEncryptionHeader');
        this.$emit('downloadFile', this.item);
      }
    },
    lockFile() {
      this.$emit('lockFile', this.item);
    },
    unlockFile() {
      this.$emit('unlockFile', this.item);
    },
    restoreFile() {
      this.$emit('restoreFile', this.item);
    },
    moveFile() {
      // toggle selection
      if (this.isSelected) {
        this.select({});
      }

      this.$emit('moveFile', this.item);
    },
    notifications() {
      this.$root.$emit('pathRuleNotification', this.item.path);
    },
    runQuickEdit() {
      console.log("run");
      this.$emit('runQuickEdit', this.item);
    },
    commentFile() {
      this.$emit('commentFile', this.item);
    },
    // rename
    async onClickRename(renameItem = false, renameShare = false) {
      this.renameItem = renameItem;
      this.renameShare = renameShare;
      this.renameFromContext = true;

      if (this.item['encryptedfolder']) {
        if (this.renameItem) {
          await this.renameOperation();
        } else {
          this.currentEncryptedFolderOp = 'rename';
          await this.checkSecureFolderToken(false);
        }
      } else {
        await this.$store.dispatch('files/removeEncryptionHeader');
        await this.renameOperation();
      }
    },

    async onCancelRename() {
      this.isRenaming = false;
      this.$root.$emit(
        'announceScreenReader',
        this.$t('File rename cancelled')
      );
    },

    async renameOperation() {
      if (!this.renameFromContext) {
        if (!this.isRenaming) return;

        this.isRenaming = false;

        if (this.item.name !== this.newName) {
          // toggle selection
          if (this.isSelected) {
            this.select({});
          }

          const oldItemPath = this.item.path;

          const response = await this.$store.dispatch('files/renameFile', {
            path: this.item.dirpath,
            id: this.item.id,
            name: this.item.name,
            newname: this.newName,
            item: this.item,
          });

          if (!response.ok && this.item.encryptedfolder === 1) {
            await this.$store.dispatch(
              'files/dropEncryptedFolderpass',
              this.item.path
            );
            this.encryptedFolderPassword = '';
          } else {
            this.$emit('refreshFolder');

            if (this.item.encryptedfolder === 1) {
              const hasTokenSaved =
                this.encryptedFolderPasswords[oldItemPath] !== undefined;

              if (hasTokenSaved) {
                await this.$store.dispatch(
                  'files/dropEncryptedFolderpass',
                  oldItemPath
                );

                await this.$store.dispatch('files/saveEncryptedFolderPass', {
                  folder: this.item.path,
                  password: this.encryptedFolderPassword,
                });
              }
            }
          }

          this.$root.$emit(
            'announceScreenReader',
            this.$t('File renamed to {newname} successfully', {
              newname: this.newName,
            })
          );
          if (this.renameShare) {
            await this.$store.dispatch('share/openShare', {
              ...this.item,
              renameShare: true,
            });
            await this.$store.dispatch('share/setUpdateSharePropertiesValue', {
              sharename: this.newName,
            });
            await this.$store.dispatch('share/updateShare');
            this.$store.dispatch('files/shareInfoForFile');
          }

          if (this.item.type !== 'dir') {
            setTimeout(() => {
              this.$refs.fileIcon.refresh();
            }, 500);
          }
        }
      } else {
        if (!_.isEmpty(this.item.isshared) && this.renameItem != true) {
          await this.$emit('renameShare', this.item);
          return;
        } else {
          setTimeout(() => {
            this.isRenaming = true;
            this.newName = this.item.name;

            this.$nextTick(() => {
              this.$refs.filenameInput.focus();

              // find file . index
              const extIndex = this.item.name.lastIndexOf('.');

              if (extIndex > 0 && this.item.type !== 'dir') {
                this.$refs.filenameInput.setSelectionRange(0, extIndex);
              } else {
                this.$refs.filenameInput.select();
              }
            });
          }, 500);
        }
      }
    },

    async onRename() {
      if (this.item['encryptedfolder']) {
        this.currentEncryptedFolderOp = 'rename';
        this.renameFromContext = false;
        await this.checkSecureFolderToken(false);
      } else {
        await this.$store.dispatch('files/removeEncryptionHeader');
        this.renameFromContext = false;
        await this.renameOperation();
      }
    },
    async deleteOperation() {
      this.isDeleting = true;

      // toggle selection
      if (this.isSelected) {
        this.select({});
      }

      await this.$store.dispatch('files/deleteFile', {
        item: this.item,
        callback: (response) => {
          if (!response.ok && this.item.encryptedfolder === 1) {
            this.$store.dispatch(
              'files/dropEncryptedFolderpass',
              this.item.path
            );
            this.encryptedFolderPassword = '';
          } else {
            const hasTokenSaved =
              this.encryptedFolderPasswords[this.item.path] !== undefined;

            if (hasTokenSaved) {
              this.$store.dispatch(
                'files/dropEncryptedFolderpass',
                this.item.path
              );
            }
            this.$emit('refresh');
          }
        },
      });
      this.isDeleting = false;
      this.$root.$emit(
        'announceScreenReader',
        this.$t('File deleted successfully')
      );
    },
    async doDeleteFile() {
      if (this.item['encryptedfolder']) {
        this.currentEncryptedFolderOp = 'delete';
        await this.checkSecureFolderToken(false);
      } else {
        await this.$store.dispatch('files/removeEncryptionHeader');
        await this.deleteOperation();
      }
    },
    async toggleStarred(
      item,
      isStarred = typeof item.favoritelistid === 'string'
    ) {
      item.isStarring = true;
      let endPoint = isStarred ? 'files/unsetfavorite' : 'files/setfavorite';
      const response = await this.$store.dispatch(endPoint, {
        name: item.fullfilename,
      });

      if (response.ok) {
        if (this.hasCustomList) {
          this.$store.dispatch('files/favoritesInNamedList', 'default');
          this.$store.dispatch('files/favoritesInNamedList');
        } else {
          if (this.mobileMenu.open) {
            this.$store.commit('files/setMobileMenu', {
              key: 'favorite',
              value: !isStarred,
            });
          }
          this.$emit('refresh');
        }

        this.$root.$emit(
          'announceScreenReader',
          isStarred
            ? this.$t('File removed from favorites successfully')
            : this.$t('File add to favorites successfully')
        );
      } else {
        item.isStarring = false;
        let errorMessage = this.$t('Something went wrong');
        if (response.data && response.data.message) {
          errorMessage = response.data.message;
        }

        this.$toast.open({
          message: `<b>${this.$t(
            'Error'
          )}</b><p role="alert">${errorMessage}</p>`,
          type: 'error',
        });
      }
    },

    canShowProps(item) {
      return (
        typeof item.isshared === 'string' ||
        item.tagged ||
        item.hasnotificationsrule ||
        item.commented ||
        item.locked ||
        item.canmanageshare
      );
    },
    async shareFile(direct) {
      if (this.item['encryptedfolder']) {
        this.currentEncryptedFolderOp = 'share';
        await this.checkSecureFolderToken(false);
      } else {
        await this.$store.dispatch('files/removeEncryptionHeader');
        await this.shareFileOp(direct);
      }
    },
    async shareFileOp(direct) {
      if (this.isNonSharedRootFolder && !direct) {
        this.$emit('shareNonSharedRootFolder', this.item);
      } else {
        await this.$store.dispatch('share/openShare', this.item);
      }
    },
    onDragStart(transferData, e) {
      this.$store.dispatch('files/setDraggingFile', true);
    },
    onDragEnter(transferData, e) {
      if (this.item.type === 'dir' && transferData) {
        e.preventDefault();
        e.stopPropagation();

        this.counter++;

        this.draggingOver = true;
      }
    },
    onDragLeave(transferData, e) {
      if (this.item.type === 'dir' && transferData) {
        e.preventDefault();
        e.stopPropagation();

        this.counter--;

        if (this.counter === 0) {
          this.draggingOver = false;
        }
      }
    },
    onDragOver(transferData, e) {
      if (this.item.type === 'dir' && transferData) {
        e.preventDefault();
        e.stopPropagation();
      }
    },
    async onDropFile(ext, e) {
      // if there is extension data, it's a move event
      if (this.item.type === 'dir' && ext) {
        e.preventDefault();
        e.stopPropagation();

        if (ext.length > 0) {
          await ext.forEach((file) => {
            this.$emit('moveFile', file, this.item.path);
          });
        } else {
          await this.$emit('moveFile', ext, this.item.path);
        }

        setTimeout(() => {
          this.$emit('clearSelect');
        }, 200);

        this.draggingOver = false;
      }
    },
    onDragEnd() {
      this.$store.dispatch('files/setDraggingFile', false);
      this.draggingOver = false;
    },
    canEditFileType,
    openMobileMenu(e) {
      let item = this.item;
      let isInRecycleFolder = this.isInRecycleFolder;
      let limitedUser = this.isLimitedUser;
      let isPublicShare = this.isPublicShare;
      let systemStatus = this.$store.state.core.systemstatus;
      let customization = this.customization;
      let msteams = this.msTeams;

      if (e) {
        this.select(e);
      }

      this.$store.commit('files/setMobileMenu', {
        key: 'favorite',
        value: typeof item.favoritelistid === 'string',
      });
      this.$store.commit('files/setMobileMenu', {
        key: 'open',
        value: true,
      });
      this.$store.commit('files/setMobileMenu', {
        key: 'item',
        value: item,
      });
      this.$store.commit('files/setMobileMenu', {
        key: 'component',
        value: this,
      });
      this.$store.commit('files/setMobileMenu', {
        key: 'actions',
        value: this.fileActions.filter(
          (action) => action.action !== 'runQuickEdit'
        ),
      });
      this.$store.commit('files/setMobileMenu', {
        key: 'target',
        value: {
          item,
          isInRecycleFolder,
          isLimitedUser: limitedUser,
          isPublicShareView: isPublicShare,
          systemStatus: systemStatus,
          customization,
          msteams,
        },
      });
      this.$store.commit('files/setMobileMenu', {
        key: 'isRoot',
        value: false,
      });

      this.$store.commit('files/showMobileOverlay');
    },
    evaluateIsEllipsisActive() {
      let element = this.$refs['file-title'].$el;
      return element.clientWidth < element.scrollWidth;
    },
    lockMessage(action) {
      let message = action ? this.$t(action) + this.$t(' disabled. ') : '';
      let readAllowed = this.isReadLocked
        ? '(read blocked) by '
        : '(read allowed) by ';
      return (
        message +
        this.$t('This file is locked ' + readAllowed) +
        this.item.lockuserid
      );
    },
    triggerShowMoreOptions() {
      this.$refs.showMoreButton.click();
    },
    sendForApproval() {
      this.$emit('sendForApproval', this.item);
    },
    webEditOptionsClick(action, params) {
      this[action](params);
    },
    onDirectLinkPopoverShow() {
      this.hasFocus = true;
      if (!this.directLink) {
        this.fetchDirectLink();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.file-list-item.file-list-item--file {
  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }
}
</style>
<style>
.m-text {
  clear: both;
  white-space: nowrap;
}
.popover {
  opacity: 1;
}
.content-highlight {
  font-size: 13px;
  font-weight: 400;
  margin: 0;
  padding: 10;
  float: right;
  line-height: 21px;
}
.small-chevron {
  font-size: 10px;
}
.pointer-link {
  cursor: pointer !important;
}
.item-list--icon {
  position: relative;
}
.hover .item-list--icon {
  display: none;
}
.locked-item .file-icon {
  opacity: 0.35;
  filter: grayscale(100%);
}
</style>
