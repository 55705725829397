<template>
  <DialogModal
    ref="shareModal"
    :stick-top="true"
    :visible="true"
    :title="sharedFileName"
    :size="size"
    :class-name="'share-dialog'"
    :buttons="buttonsArray"
    @close="cancelShare"
    @userFeedback="userFeedback"
  >
    <Loader :loading="loading" full />
    <div
      v-if="shareProperties.waitforapproval"
      class="share-alert alert-warning"
      role="alert"
    >
      {{
        $t(
          'This share will only be available after an automation workflow approval.'
        )
      }}
    </div>
    <div
      v-if="shareProperties.defaultdisallowsharechange"
      class="share-alert alert-warning"
      role="alert"
    >
      {{
        $t(
          'Share settings cannot be changed. Users and groups can be invited. For more information contact your administrator.'
        )
      }}
    </div>

    <h1
      v-if="!loading && !showOptions && !isSharedAlready"
      class="new-share-created"
    >
      {{ $t('Your share has been created!') }}
    </h1>
    <transition name="fade">
      <div v-if="shareProperties" class="shareDialogWrapper">
        <div class="caption">{{ $t('Share Link') }}</div>

        <div class="shareLink-details" :class="{ 'd-flex': !isMobile }">
          <div
            v-if="!readonly"
            ref="shareURL"
            class="share-link"
            :class="{ 'share-link--updating': shareLinkUpdating }"
            @click="$refs.editUrlInput.select()"
          >
            <span>{{ shareUrlFirst }}</span>
            <input
              ref="editUrlInput"
              v-model.trim="editedShareUrl"
              class="input-hidden"
              @keydown.esc="editedShareUrl = shareUrlSecond"
              @keydown.enter="saveShareLink"
            />
            <span v-if="shareLinkUpdating" class="spinner-border"></span>
            <a
              v-else-if="!shareLinkUpdating && !isMobile && !readonly"
              class="modify-save"
              tabindex="0"
              @click.stop="saveShareLink"
              @v-a11ybutton="saveShareLink"
              @keydown.enter="saveShareLink"
            >
              {{
                !editedShareUrl
                  ? ''
                  : editedShareUrl === shareUrlSecond
                  ? this.$t('Modify Link')
                  : this.$t('Save')
              }}
            </a>
            <a
              v-else-if="!shareLinkUpdating && isMobile"
              class="modify-save"
              @click.stop="saveShareLink"
            >
              <Icon
                v-if="editedShareUrl && editedShareUrl === shareUrlSecond"
                name="edit"
                family="fas"
              />
              <Icon v-else-if="editedShareUrl" name="save" family="fas" />
            </a>
          </div>
          <div v-else class="share-link">{{ shareProperties.shareurl }}</div>
          <div class="shareLink-actions">
            <button
              v-if="showMobileShareButton"
              v-tooltip="$t('Share Link')"
              :aria-label="$t('Share Link')"
              class="btn btn-primary"
              @click="mobileSharing"
            >
              <Icon name="share-alt" family="fas" />
            </button>
            <button
              v-tooltip="$t('Copy Link to clipboard')"
              :aria-label="$t('Copy Link to clipboard')"
              class="btn btn-primary"
              @click="copyToClipBoard"
            >
              <Icon name="clipboard-list" family="fas" />
            </button>
            <button
              v-tooltip="$t('Open Link in new window')"
              :aria-label="$t('Open Link in new window')"
              class="btn btn-primary"
              @click="openLink"
            >
              <Icon name="external-link-alt" family="fas" />
            </button>
            <button
              v-if="!shareProperties.hidesendshareviaemail"
              v-tooltip="$t('Email Link')"
              :aria-label="$t('Email Link')"
              class="btn btn-primary"
              @click="sendEmail"
            >
              <Icon name="envelope" family="fas" />
            </button>
            <button
              v-tooltip="$t('Download QR code')"
              :aria-label="$t('Download QR code')"
              class="btn btn-primary"
              @click="downloadQRCode(shareUrlFirst + editedShareUrl)"
            >
              <Icon name="qrcode" family="fas" />
            </button>
          </div>
        </div>

        <!-- simple share -->
        <div v-if="!showOptions" class="share-summary d-flex">
          <div class="share-status-indicator">
            <Icon name="globe-americas" />
          </div>
          <div class="share-summary-text">
            <div class="">
              {{ $t('This share is') }}
              <strong>{{ $t(shareStatus.label) }}</strong>
            </div>
            <div class="subtext">
              <span>{{ $t(shareStatus.copytext) }}</span>
              <a
                v-a11ybutton="triggerShowOptions"
                tabindex="0"
                @click.stop="triggerShowOptions"
              >
                {{ readonly ? $t('Details') : $t('Change') }}
              </a>
            </div>
          </div>
        </div>

        <!-- Advanced  share share options -->
        <shareAdvanced
          v-if="showOptions"
          ref="shareAdvanced"
          :share-status="shareStatus"
          :readonly="readonly"
          :shareSettingNotAllowed="shareSettingNotAllowed"
          @saveChanges="updateShare(true)"
          @copyUrlPassword="copyUrlPassword"
        />
        <div class="d-none">
          {{ usersInShare.length }}
          {{ groupsInShare.length }}
        </div>
      </div>
    </transition>
  </DialogModal>
</template>
<script>
import responsivenessMixin from '@/mixins/responsivenessMixin';

import Loader from 'common/components/Loader';
import DialogModal from 'common/components/DialogModal';
import Icon from 'common/components/Icon';
import shareAdvanced from './shareAdvanced';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import * as microsoftTeams from '@microsoft/teams-js';
import { mapGetters } from 'vuex';
import { downloadQRCode } from '@/utils/downloadQRCode';

// Allow custom parser string usage
dayjs.extend(customParseFormat);

export default {
  components: {
    Loader,
    DialogModal,
    Icon,
    shareAdvanced,
  },
  mixins: [responsivenessMixin],
  props: {
    readonly: {
      type: Boolean,
      default: false,
    },
    shareSettingNotAllowed: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      toastClipBoard: {
        message:
          '<b>' +
          this.$t('Success') +
          '</b><p role="alert">' +
          this.$t('Path copied successfully') +
          '.</p>',
        type: 'success',
      },
      toastRemoveShareSuccess: {
        message:
          '<b>' +
          this.$t('Success') +
          '</b><p role="alert">' +
          this.$t('Share removed successfully.') +
          '</p>',
        type: 'success',
      },
      toastRemoveShareError: {
        message:
          '<b>' +
          this.$t('Error') +
          '</b><p role="alert">' +
          this.$t('Unable to remove share') +
          '</p>',
        type: 'error',
      },
      toastCloseSuccess: {
        message:
          '<b>' +
          this.$t('Success') +
          '</b><p role="alert">' +
          this.$t('Share updated successfully.') +
          '</p>',
        type: 'success',
      },
      toastCloseError: {
        message:
          '<b>' +
          this.$t('Error') +
          '</b><p role="alert">' +
          this.$t('Unable to update share') +
          '</p>',
        type: 'error',
      },
      toastShareLinkSuccess: {
        message:
          '<b>' +
          this.$t('Success') +
          '</b><p role="alert">' +
          this.$t('Share link updated successfully.') +
          '</p>',
        type: 'success',
      },
      showAdvanced: false,
      editedShareUrl: '',
      baseButtons: [
        {
          label: this.$t('Remove Share'),
          link: true,
          outline: false,
          left: true,
          key: 'removeShare',
          hasConfirm: true,
          confirmBtn1: this.$t('Remove'),
          confirmBtn2: this.$t('Cancel'),
          confirmLabel: this.$t('Are you sure you want to remove the share'),
          callback: () => this.removeShare(),
        },
        {
          label: this.$t('Share Options'),
          outline: true,
          hideduringShowOptions: true,
          callback: () => this.triggerShowOptions(),
        },
        {
          label: this.$t('OK'),
          callback: this.updateShare,
          right: true,
        },
      ],
    };
  },
  computed: {
    ...mapGetters('core', ['isTrialMode', 'isHostedCloud']),
    loading() {
      return (
        this.$store.state.loading['share/openShare'] ||
        this.$store.state.loading['share/removeShare'] ||
        this.$store.state.loading['share/updateShare'] ||
        this.$store.state.loading['share/getRandomPassword'] ||
        this.$store.state.loading['share/addSharePermissions'] ||
        this.$store.state.loading['share/getSharePassword'] ||
        this.$store.state.loading['share/getShareForId'] ||
        this.$store.state.loading['share/importShareUsers']
      );
    },
    shareLinkUpdating() {
      return this.$store.state.loading['share/updateShareLink'];
    },
    shareProperties() {
      return this.$store.state.share.shareProperties;
    },
    updateShareProperties() {
      return this.$store.state.share.updateShareProperties;
    },
    validityPeriodComputed() {
      const form = this.$refs.shareAdvanced.form;
      return form.willExpire === 'expires'
        ? dayjs(form.datePickerModel, this.dateFormat).format(
            'YYYY-MM-DD HH:mm:ss'
          )
        : '';
    },
    isSharedAlready() {
      return this.$store.state.share.isSharedAlready;
    },
    msTeams() {
      return this.$store.state.auth.msteams;
    },
    showOptions() {
      return (
        this.isSharedAlready ||
        this.showAdvanced ||
        this.shareProperties.showadvancedsharedialog
      );
    },
    file() {
      return this.$store.state.share.shareFile;
    },
    buttonsArray() {
      let buttonsShown = [];
      if (!this.showOptions) {
        buttonsShown.push(this.baseButtons[1]); //share options
      }
      if (this.msTeams) {
        buttonsShown.push({
          label: this.$t('Post Share Link'),
          callback: this.mobileSharing,
          key: 'sharelink',
          right: true,
        });
      }
      if (!this.readonly) {
        buttonsShown.push(this.baseButtons[0]);
      }

      let okButton = {
        label: this.$t('OK'),
        callback: this.updateShare,
        right: true,
      };

      if (!this.shareProperties.ispublicsecure) {
        delete this.updateShareProperties.sharepassword;
      }

      const hasChangesToSave =
        Object.keys(this.updateShareProperties).length !== 0;

      if (hasChangesToSave) {
        okButton = {
          label: this.$t('OK'),
          callback: this.updateShare,
          right: true,
          hasConfirm: true,
          confirmBtn1: this.$t('Save'),
          confirmBtn2: this.$t("Don't Save"),
          confirmLabel: this.$t('Your changes are not saved'),
        };
      } else if (this.shareStatus.key === 'allow_users_groups') {
        if (this.usersInShare.length === 0 && this.groupsInShare.length === 0) {
          okButton = {
            label: this.$t('OK'),
            callback: this.updateShare,
            right: true,
            hasConfirm: true,
            confirmBtn1: this.$t('Save'),
            confirmBtn2: this.$t("Don't Save"),
            confirmLabel:
              'Users and Groups empty. Are you sure you want to save?',
          };
        }
      }

      buttonsShown.push(okButton);
      return buttonsShown;
    },
    size() {
      return this.showOptions ? 'medium' : 'small';
    },
    disallowPublicShare() {
      return this.isHostedCloud && this.isTrialMode && this.file.type !== 'dir';
    },
    shareStatus() {
      if (!this.shareProperties) return {};
      if (!this.shareProperties.allowpublicaccess || this.disallowPublicShare) {
        return {
          label: 'Visible to specific Groups/User',
          copytext: 'Only logged in Users can view',
          key: 'allow_users_groups',
        };
      } else if (this.shareProperties.ispublicsecure) {
        return {
          label: 'Password Protected',
          copytext: 'Only users with link and password can view',
          key: 'allow_link_password',
        };
      } else {
        return {
          label: 'Public',
          copytext: 'Anyone with link can view',
          key: 'allow_link',
        };
      }
    },
    usersInShare() {
      return this.$store.state.share.usersInShare;
    },
    groupsInShare() {
      return this.$store.state.share.groupsInShare;
    },
    sharedFileName() {
      return this.file
        ? `${
            this.file.type === 'dir'
              ? this.$t('Share link for folder')
              : this.$t('Share link for file')
          }
          ${this.file.sharedobjname || this.file.name}`
        : this.$t('Loading file...');
    },
    shareUrlSecond() {
      if (!this.shareProperties && !this.shareProperties.shareurl) return '';
      let url = this.shareProperties.shareurl;
      let second = url ? url.split('url/')[1] : '';
      this.editedShareUrl = second;
      return second;
    },
    shareUrlFirst() {
      if (!this.shareUrlSecond) return '';
      return this.shareProperties.shareurl.replace(this.shareUrlSecond, '');
    },
    showMobileShareButton() {
      return this.isMobile && !this.msTeams;
    },
    systemStatus() {
      return this.$store.state.core.fullSystemStatus;
    },
    dateFormat() {
      if (typeof this.systemStatus.dateformat === 'undefined') {
        // Default date format
        return 'MMM DD, YYYY';
      }

      return this.systemStatus.dateformat
        ? this.systemStatus.dateformat.toUpperCase()
        : this.systemStatus.defaultdateformat.toUpperCase();
    },
    user() {
      return this.$store.state.auth.user;
    },
  },
  methods: {
    triggerShowOptions() {
      this.showAdvanced = true;
      this.isLargeDialog = true;
    },
    copyToClipBoard() {
      const text = `${this.shareProperties.shareurl}`;

      let toasts = this.$toast;
      let toastClipBoard = this.toastClipBoard;
      this.$copyText(text, this.$refs.shareModal.$el).then(function () {
        toasts.open(toastClipBoard);
      });
    },
    openLink() {
      window.open(this.shareProperties.shareurl, '_blank');
    },
    sendEmail() {
      this.$store.state.share.emailShareLinkDialogOpen = true;
    },
    async removeShare() {
      const response = await this.$store.dispatch('share/removeShare');
      response.ok
        ? this.$toast.open(this.toastRemoveShareSuccess)
        : this.$toast.open(this.toastRemoveShareError);
      this.$store.dispatch('files/shareInfoForFile');
    },
    async updateShare(preventClose) {
      let payload = this.$store.state.share.updateShareProperties;
      this.$root.$emit('updatePermissions');

      if (Object.keys(payload).length === 0) {
        //nothing to update
        if (!preventClose) this.cancelShare();
        return;
      } else {
        let form = this.$refs.shareAdvanced.form;
        payload = {
          shareid: this.shareProperties.shareid,
          sharename:
            form.allowPermission === 'allow_users_groups' || payload.sharename
              ? form.sharename
              : '',
          validityperiod: this.validityPeriodComputed,
          sharesizelimit:
            form.sharesizelimit === 'limited' ? form.sharesizelimitValue : '',
          maxdownloads:
            form.maxDownload === 'restricted' ? form.maxDownloadValue : '',
          hidenotifications: form.hidenotifications === 'no' ? 1 : 0,
          sharepassword: form.sharepassword,
        };
        this.$store.state.share.updateShareProperties = payload;
      }
      const response = await this.$store.dispatch(
        'share/updateShare',
        preventClose
      );
      if (preventClose) {
        this.$refs.shareAdvanced.populateForm();
      }
      this.$root.$emit('updatedSharedExpiry');
      !response.ok
        ? this.$toast.open({
            message:
              '<b>' +
              this.$t('Error') +
              '</b><p role="alert">' +
              response.error +
              '</p>',
            type: 'error',
          })
        : this.$toast.open(this.toastCloseSuccess);
    },
    userFeedback(feedback) {
      if (feedback === 'close') {
        return;
      } else {
        this.updateShare();
      }
    },
    cancelShare() {
      this.$store.dispatch('share/cancelShare');
    },
    async saveShareLink() {
      if (this.shareLinkUpdating || !this.editedShareUrl) return;
      if (this.editedShareUrl === this.shareUrlSecond) {
        this.$refs.editUrlInput.select();
        return;
      }
      const response = await this.$store.dispatch('share/updateShareLink', {
        editedShareUrl: this.editedShareUrl,
        shareUrlSecond: this.shareUrlSecond,
        shareid: this.shareProperties.shareid,
        oldsharelink: 'url' + this.shareUrlSecond,
        newsharelink: 'url' + this.editedShareUrl,
      });
      if (response.ok) {
        this.$toast.open(this.toastShareLinkSuccess);
      } else {
        this.$toast.open({
          message: `<b>${this.$t('Error')}</b><p role="alert">${
            response.error
          }</p>`,
          type: 'error',
        });
        this.editedShareUrl = this.shareUrlSecond;
        this.$nextTick = () => this.$refs.editUrlInput.select();
      }
    },
    async mobileSharing() {
      if (this.msTeams) {
        let share = {
          userId: this.user.peerid,
          botop: 'share',
          params: {
            path: this.file.path,
            shareId: this.shareProperties.shareid,
          },
        };
        microsoftTeams.initialize();
        microsoftTeams.tasks.submitTask(share);
        return true;
      } else {
        const shareData = {
          title: this.$t(
            `File shared: ${this.file.sharedobjname || this.file.name}`
          ),
          text: this.$t(
            'Hey! I have shared a file with you, can you take a look?'
          ),
          url: this.shareUrlFirst,
        };

        try {
          await navigator.share(shareData);
        } catch (err) {
          console.log(err);
        }
      }
    },
    async copyUrlPassword(text, toasts, toastClipBoard) {
      this.$copyText(text, this.$refs.shareModal.$el).then(function () {
        toasts.open(toastClipBoard);
      });
    },
    downloadQRCode,
  },
};
</script>
