var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.hideNav)?_c('nav',{staticClass:"navbar navbar-main p-0",attrs:{"id":"navbar","role":"navigation"}},[_c('div',{staticClass:"navbar-toggler col-2 col-md-3"},[_c('button',{attrs:{"type":"button","aria-label":"open menu"},on:{"click":function($event){return _vm.$emit('openMobileNavigation')}}},[_c('Icon',{attrs:{"name":"bars","family":"fas"}})],1)]),_c('div',{staticClass:"navbar-brand col-4 col-md-3"},[_c('img',{attrs:{"src":_vm.logo,"alt":_vm.customization.PRODUCT_NAME},on:{"click":_vm.gotoDashboard}})]),_c('div',{staticClass:"navbar-buttons col-6 col-md-3"},[_c('button',{staticClass:"btn",attrs:{"type":"button","aria-label":"open search bar"},on:{"click":_vm.openSearchbar}},[_c('Icon',{attrs:{"name":"search","family":"fas"}})],1),_c('v-popover',{directives:[{name:"close-popover",rawName:"v-close-popover",value:(_vm.notificationsClosed),expression:"notificationsClosed"}],staticClass:"btn bell-notif",attrs:{"popover-class":"notif-popover","offset":"16"}},[(!_vm.msTeams)?_c('div',{staticClass:"tooltip-target",on:{"click":_vm.notifications}},[_c('div',{staticClass:"icon-wrapper"},[(_vm.hasNewToasts)?_c('span',{staticClass:"badge"}):_vm._e(),_c('Icon',{staticClass:"fa icon-grey text-black-5 notification-icon",attrs:{"name":"bell"}})],1)]):_vm._e(),_c('Notifications',{staticClass:"tooltip-content notification-popover",attrs:{"slot":"popover"},slot:"popover"})],1),_c('DropDownList',{staticClass:"btn bell-notif",attrs:{"items":_vm.actions,"target":{
        systemStatus: _vm.systemStatus,
        isHighContrast: _vm.customization.HIGH_CONTRAST_MODE,
        isDRMExportHidden: _vm.customization.HIDESECUREDOCS,
        isDRMExportDisabled: !_vm.fullSystemStatus.isdrmenabled === 1,
        isNoTechSupport: _vm.isNoTechSupport,
        isEncryptedFolderEnabled: _vm.fullSystemStatus.encryptedfolders === 1,
        isMobileDevice: true,
        isFileCloud: _vm.customization.PRODUCT_NAME === 'FileCloud',
        isLockDisabled: _vm.isLockDisabled,
        isLimitedUser: _vm.isLimitedUser,
      }},on:{"triggerDDAction":_vm.triggerAction}},[_c('button',{staticClass:"btn btn-default d-flex dropdown-btn p-0"},[_c('Avatar',{attrs:{"size":"xs"}}),_c('Icon',{staticClass:"text-black-5 pt-1 ml-2",attrs:{"name":"caret-down","family":"fas"}})],1)])],1)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }