<template>
  <DialogModal
    class="dialog-modal-form"
    color="gray"
    size="xsmall"
    :visible="isOpen"
    :stick-top="false"
    :title="encryptedFolderOperation.title"
    :buttons="[
      {
        label: $t('Cancel'),
        outline: true,
        callback: cancelFolder,
      },
      {
        label: encryptedFolderOperation.label,
        outline: false,
        disabled: !validEncryptedFolderPass,
        callback: proccedWithAction,
      },
    ]"
    @close="cancelFolder"
  >
    <form @submit.prevent>
      {{ encryptedFolderOperation.content }}
      <div class="mb-1 mt-4 form-group">
        <InputBox
          id="encryptedFolderPassID"
          ref="encryptedFolderPass"
          v-model="password"
          :type="passwordType"
          :label="$t('Enter Password')"
          :aria-label="$t('Enter Password')"
          autocomplete="off"
          class="p-0"
          :placeholder="$t('Enter Password')"
          :appends="formWithAppends.passwordAppends"
          onpaste="return false;"
          ondrop="return false;"
          @keypress.enter.native="proccedWithAction"
        />
      </div>
    </form>
  </DialogModal>
</template>

<script>
import DialogModal from 'common/components/DialogModal';
import InputBox from 'common/components/InputBox';

export default {
  name: 'OpenSharedEncryptedFolder',
  components: {
    DialogModal,
    InputBox,
  },
  props: {
    folderName: {
      type: String,
      default: '',
    },
    action: {
      type: String,
      default: 'browse',
    },
  },
  data() {
    return {
      isOpen: false,
      password: '',
      passwordType: 'password',
    };
  },
  computed: {
    formWithAppends() {
      return {
        passwordAppends: [
          {
            icon: this.passwordType === 'password' ? 'eye' : 'eye-slash',
            iconBold: true,
            action: this.togglePasswordType,
          },
        ],
      };
    },
    encryptedFolderOperation() {
      let operation = {
        label: '',
        title: '',
        content: '',
      };

      switch (this.action) {
        case 'browse':
          operation.label = this.$t('Access Folder');
          operation.title = this.$t('Access Encrypted Folder');
          operation.content = this.$t(
            '{folderName} is encrypted. Please provide password to access the files.',
            { folderName: this.folderName }
          );
          break;
        case 'download':
          operation.label = this.$t('Download File');
          operation.title = this.$t('Download Encrypted Content');
          operation.content = this.$t(
            '{folderName} is encrypted. Please provide password to download the content.',
            { folderName: this.folderName }
          );
          break;
        case 'downloadFolder':
          operation.label = this.$t('Download Folder');
          operation.title = this.$t('Download Encrypted Folder');
          operation.content = this.$t(
            '{folderName} is encrypted. Please provide password to download the content.',
            { folderName: this.folderName }
          );
          break;
        case 'delete':
        case 'deleteFolder':
          operation.label = this.$t('Delete Folder');
          operation.title = this.$t('Delete Encrypted Folder');
          operation.content = this.$t(
            '{folderName} is encrypted. Please provide password to delete the content.',
            { folderName: this.folderName }
          );
          break;
        case 'uploadFile':
        case 'uploadFolder':
          operation.label = this.$t('Upload');
          operation.title = this.$t('Upload Content');
          operation.content = this.$t(
            '{folderName} is encrypted. Please provide password to upload the content.',
            { folderName: this.folderName }
          );
          break;
        case 'shareFolder':
          operation.label = this.$t('Share Folder');
          operation.title = this.$t('Share Encrypted Folder');
          operation.content = this.$t(
            '{folderName} is encrypted. Please provide password to share the content.',
            { folderName: this.folderName }
          );
          break;
      }

      return operation;
    },
    validEncryptedFolderPass() {
      return this.password.length > 4;
    },
  },
  methods: {
    open() {
      this.isOpen = true;
      setTimeout(() => {
        this.$refs.encryptedFolderPass.$refs.encryptedFolderPassID.focus();
      }, 500);
      this.password = '';
      this.passwordType = 'password';
    },
    closeModal() {
      this.isOpen = false;
      this.password = '';
      this.passwordType = 'password';
    },
    togglePasswordType() {
      this.passwordType =
        this.passwordType === 'password' ? 'text' : 'password';
    },
    proccedWithAction() {
      this.$emit('proccessAction', this.password, this.action);
      this.closeModal();
    },
    cancelFolder() {
      this.$emit('cancelEncryptedFolder', this.action);
      this.closeModal();
    },
  },
};
</script>
