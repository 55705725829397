<template>
  <form class="container-fluid" @submit="onSubmit">
    <Loader v-if="loading" :loading="loading" class="pt-4 mt-4" full />
    <div>
      <transition name="fade">
        <InfoBox
          v-if="InfoBoxMessage && InfoBoxMessage.message"
          :type="InfoBoxMessage.type"
        >
          {{ InfoBoxMessage.message }}
          <br />
          <span v-if="InfoBoxMessage && InfoBoxMessage.attemptMessage">
            {{ InfoBoxMessage.attemptMessage }}
          </span>
        </InfoBox>
      </transition>

      <div class="row">
        <InputBox
          :id="'userid'"
          v-model="form.userid"
          :label="$t('Account')"
          :placeholder="$t(emailPlaceholder)"
          :aria-label="$t('Login : Enter username or email')"
          :errors="errorsObj"
          :focus="true"
          :disabled="disableLoginInput"
          autocapitalize="none"
          class="col-sm-12 col-lg-6"
          @blur="checkEmail"
        />
        <InputBox
          :id="'password'"
          v-model="form.password"
          v-if="showPassword"
          :label="$t('Password')"
          :placeholder="'**********'"
          :aria-label="$t('Enter password')"
          :type="form.passwordType"
          :errors="errorsObj"
          :appends="formWithAppends.passwordAppends"
          class="col-sm-12 col-lg-6"
        />
      </div>
      <div class="row d-flex justify-content-center mt-3 mb-4" v-if="systemStatus.CAPTCHA_ENABLED == 1">
        <VueRecaptcha
          :sitekey="user.recaptchakey"
          :load-recaptcha-script="true"
          @verify="onVerify"
        ></VueRecaptcha>
      </div>

      <hr />

      <div
        class="row no-gutters flex-row-reverse d-sm-flex justify-content-between mx-5 mb-3 mx-lg-0"
      >
        <div class="col-sm-12 col-lg-4">
          <button type="submit" class="btn btn-primary mb-3 mb-lg-0 col-12">
            {{ $t(loginBtn) }}
          </button>
        </div>
        <ForgotPasswordButton
          v-if="isShowMoreLoginOptionsEnabled || isCommunityEdition"
          @resetPassword="resetPassword"
        />
      </div>

      <hr v-if="showCustomLogin" />

      <div
        v-if="showCustomLogin"
        class="row no-gutters flex-row-reverse d-sm-flex justify-content-center mx-5 mx-lg-0"
      >
        <div class="col-sm-12 col-lg-4 mb-3 text-center">
          <a
            :href="customization.USERLOGINCUSTOMOPTIONURL"
            class="custom-login btn btn-sm btn-outline-primary col-12"
          >
            {{ customization.USERLOGINCUSTOMOPTIONLABEL }}
            <Icon class="pl-1 fa-external-link-alt" name="angle-left" />
          </a>
        </div>
      </div>
      <div class="p-4" />
      <div v-if="showSSO" class="login__sso mx-5">
        <div class="ssodivider">
          <span class="ssotitle">{{ $t('Or use your SSO') }}</span>
        </div>
        <button
          type="button"
          class="btn btn-sm btn btn-outline-primary col-sm-12 col-lg-6"
          value="Login with SSO"
          @click="gotoSSO"
        >
          {{ $t('Log In with SSO') }}
        </button>
      </div>
    </div>
  </form>
</template>
<script>
import Loader from 'common/components/Loader';
import InputBox from 'common/components/InputBox';
import InfoBox from 'common/components/InfoBox';
import Icon from 'common/components/Icon.vue';
import serverURL from 'common/filecloud/properties/serverURL';
import { getParams } from 'common/utils/files';
import ForgotPasswordButton from 'common/components/Login/ForgotPasswordButton';

import {
  isValidEmail,
  getEmailDomain,
  isSamlSSODomain,
} from 'common/utils/forminput';
import VueRecaptcha from 'vue-recaptcha';
import loginErrorsMixin from '@/mixins/loginErrorsMixin';
import mfaMixin from '@/mixins/mfaMixin';
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'LoginForm',
  components: {
    Loader,
    InputBox,
    InfoBox,
    Icon,
    VueRecaptcha,
    ForgotPasswordButton,
  },
  mixins: [loginErrorsMixin, mfaMixin],
  data() {
    return {
      errors: {},
      form: {
        userid: '',
        password: '',
        passwordType: 'password',
        'g-recaptcha-response': '',
      },
      loginBtn: 'Log In',
      disableLoginInput: false,
      showPassword: true,
      timerId: null,
      remainingTime: null,
      intervalStartTime: null,
    };
  },
  computed: {
    ...mapGetters({ authErrors: 'auth/errors' }),
    ...mapGetters('core', [
      'isShowMoreLoginOptionsEnabled',
      'isCommunityEdition',
    ]),
    ...mapState('core', ['fullSystemStatus']),
    currentPage() {
      return this.$store.state.auth.pageId;
    },
    msTeams() {
      return this.$store.state.auth.msteams;
    },
    user() {
      return this.$store.state.auth.user;
    },
    customization() {
      return this.$store.state.core.customization;
    },
    systemStatus() {
      return this.$store.state.core.systemstatus;
    },
    showCustomLogin() {
      let cust = this.customization;
      return (
        this.objCheck(cust.USERLOGINCUSTOMOPTIONLABEL) &&
        this.objCheck(cust.USERLOGINCUSTOMOPTIONURL)
      );
    },
    showSSO() {
      return this.customization && this.customization.showSSO();
    },
    loading() {
      return (
        this.$store.state.loading['auth/login'] ||
        this.$store.state.loading['auth/relogin'] ||
        this.$store.state.loading['auth/authenticate'] ||
        this.$store.state.loading['core/getCustomizations']
      );
    },
    emailPlaceholder() {
      return this.systemStatus.ENFORCEEMAILLOGIN ? 'Enter email' : 'Username or Email';
    },
    samlDomains() {
      const samlDomain = JSON.stringify(this.user.samldomainsallowed) !== '{}' ? this.user.samldomainsallowed : '';
      this.showPassword = samlDomain === '';
      return samlDomain;
    },
    formWithAppends() {
      return {
        ...this.form,
        passwordAppends: [
          {
            icon: this.form.passwordType == 'password' ? 'eye' : 'eye-slash',
            iconBold: true,
            action: this.togglePasswordType,
          },
        ],
      };
    },
    noOfAttempts() {
      return this.fullSystemStatus.passwordlockoutattempts;
    },
    lockOutTime() {
      return this.fullSystemStatus.passwordlockoutlength;
    },
  },
  created() {
    const { mode, emailid } = getParams();
    if (mode === 'unsubscribe') {
      this.form.userid = emailid;
      this.disableLoginInput = true;
    }
  },
  mounted() {
    this.remainingTime = localStorage.getItem('remainingTime');
    if (this.remainingTime) {
      this.infoMessage();
      if (this.remainingTime > 0) this.timerId = this.startOrRestoreInterval();
    }
  },
  methods: {
    objCheck(obj) {
      return typeof obj == 'string' && obj != '';
    },
    checkForSSO() {
      let email = this.form.userid;
      if (
        isValidEmail(email) &&
        isSamlSSODomain(getEmailDomain(email), this.samlDomains)
      ) {
        this.errors = 'SSO email detected, Redirecting ...';
        this.alertType = 'info';
        setTimeout(function () {
          const { msteams, msteamsauthflow } = getParams();
          let url = serverURL.sso + '?email=' + email;
          if(msteams) {
            window.location.assign(url + '&msteamsauthflow='+msteamsauthflow+'&msteams='+msteams);
          } else window.location.assign(url);
        }, 1000);
      } else {
        this.showPassword = true;
      }
    },
    gotoSSO() {
      const { msteams, msteamsauthflow } = getParams();
      let url = serverURL.sso;
      if(msteams) {
        window.location.assign(url + '?msteamsauthflow='+msteamsauthflow+'&msteams='+msteams);
      } else window.location.assign(url);
    },
    checkEmail(){
      let email = this.form.userid;
      if (
        email 
        && this.systemStatus.ENFORCEEMAILLOGIN
        && !isValidEmail(email)
      ) {
        this.errors = 'Login with username is blocked. Please provide a valid email address.';
        this.alertType = 'alert';
      } else {
        this.errors = '';
        this.alertType = '';
      }
      if (this.samlDomains) {
        this.checkForSSO();
      }
    },
    async onSubmit(e) {
      e.preventDefault();
      e.stopPropagation();
      if (this.loading || !this.showPassword) return;
      this.errors = {};
      this.alertType = '';

      const response = await this.$store.dispatch('auth/login', this.form);
      // SMS, TOTP and DUO have a token starting with 2fa_ . Email uses a 9 character long token so only way to see if email
      // is to check length
      if (!response.ok && !response.data) {
        this.errors = response.error;
        return;
      }
      if (!response.ok && response.data.result !== 3) {
        this.errors = response.error;
        this.alertType = 'alert';

        const maxAttempts = this.noOfAttempts;
        const lockOutTimeInSeconds = this.lockOutTime * 60;
        const storedAttempt = parseInt(localStorage.getItem('attempt'));
        const storedRemainingTime = parseInt(
          localStorage.getItem('remainingTime')
        );

        if (storedAttempt > maxAttempts - 1) {
          if (this.remainingTime === null && !storedRemainingTime) {
            this.remainingTime = lockOutTimeInSeconds;
          }
          this.infoMessage();
          this.timerId = this.startOrRestoreInterval();
        }
        return;
      } else if (response.data.result === 1) {
        await this.$store.dispatch('auth/relogin', response);
      }
      if (
        response.data.result === 3 &&
        response.data.message !== null &&
        (response.ok ||
          response.data.message.toString().startsWith('2fa_') ||
          response.data.message.length === 10)
      ) {
        response.data.userid = this.form.userid;
        if (
          response.data.message.toString().startsWith('2fa_sms') ||
          response.data.message.length === 10
        ) {
          this.$store.state.auth.showResend = true;
        } else {
          this.$store.state.auth.showResend = false;
        }
        await this.$store.dispatch('auth/relogin', response);
      } else {
        this.errors = response.error;
        this.alertType = 'alert';
      }
    },
    resetPassword() {
      this.$store.dispatch('auth/gotoPage', 'forgotPassword');
    },
    onVerify: function (response) {
      this.form['g-recaptcha-response'] = response;
    },
    togglePasswordType() {
      this.form.passwordType =
        this.form.passwordType === 'password' ? 'text' : 'password';
    },
    startOrRestoreInterval() {
      if (this.timerId) {
        clearInterval(this.timerId);
      }
      this.timerId = setInterval(() => {
        this.remainingTime--;
        localStorage.setItem('remainingTime', this.remainingTime);
        if (this.remainingTime <= 0) {
          clearInterval(this.timerId);
          localStorage.removeItem('attempt');
          localStorage.removeItem('intervalStartTime');
          localStorage.removeItem('remainingTime');
          this.remainingTime = null;
        }
      }, 1000);

      return this.timerId;
    },
    infoMessage() {
      const currentNoOfAttempts = localStorage.getItem('attempt');
      const remainingTime = Math.ceil(this.remainingTime / 60);
      const currentAttempt = this.noOfAttempts - currentNoOfAttempts;
      const lockoutMessage = this.$t(
        'Your account has been locked due to {0} failed attempts. It will be unlocked after {1} minutes',
        [this.noOfAttempts, remainingTime]
      );
      const attemptMessage = this.$t(
        'Attempts remaining: {0}  After {1} consecutive attempts, account will be locked.',
        [currentAttempt, this.noOfAttempts]
      );
      this.InfoBoxMessage.attemptMessage =
        currentNoOfAttempts > this.noOfAttempts - 1
          ? lockoutMessage
          : attemptMessage;
    },
  },
};
</script>

<style lang="scss" scoped>
.loader {
  min-height: 100%;
}
</style>
