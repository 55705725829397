import Vue from 'vue';
import LoginMethods from '../../constants/LoginMethods';
import { doNotRedirectAfterAuth } from '../../constants/noRedirectAfterAuth';
import router from '../../router';
import { getParams } from 'common/utils/files';
import {
  getTwoFaMethodFromToken,
  isTwoFaSetupMode,
} from '../../utils/loginUtils';
import _ from 'lodash';
import dayjs from 'dayjs';
import * as microsoftTeams from '@microsoft/teams-js';

export default {
  namespaced: true,
  state: {
    errors: {},
    pageId: 'login',
    SSORedirect: false,
    userSSOType: '',
    setupMode: '',
    user: {
      authenticated: false,
      token: '',
      userid: '',
    },
    ready: false,
    showResend: false,
    showLogoutModal: false,
    msteams: false,
    twofaphonenumber: '',
    profileImageVersion: 1,
  },
  created() {
    this.$store.registerModule('LoginForm', this.state);
    this.$store.registerModule('LoginTwoFaForm', this.state);
    this.$store.registerModule('SSODirectForm', this.state);
    this.$store.registerModule('PasswordProtectedShare', this.state);
  },
  mutations: {
    set(state, { key, value }) {
      Vue.set(state, key, value);
    },
    clearErrors(state) {
      Vue.set(state, 'errors', {});
    },
  },
  getters: {
    twofaphonenumber: (state) => state.twofaphonenumber,
    twofamethod: (state) => state.twofamethod,
    msteams: (state) => state.msteams,
    page: (state) => state.pageId,
    userid: (state) => state.user.userid,
    errors: (state) => state.errors,
    user: (state) => state.user,
    SSORedirect: (state) => state.SSORedirect,
  },
  actions: {
    async unsubscribe(context, payload) {
      const { ok } = await this.state.core.client.post(
        'core/unsubscribe',
        payload
      );
      if (ok) {
        Vue.$toast.open({
          message:
            '<b>Unsubscribed email address</b> <p role="alert">Your email address has been unsubscribed.</p>',
          type: 'success',
        });
      } else {
        Vue.$toast.open({
          message:
            '<b>Something went wrong</b> <p role="alert">Email address is not unsubscribed</p>',
          type: 'warning',
        });
      }
    },
    setShowLogoutModal(context, visible) {
      context.commit('set', { key: 'showLogoutModal', value: visible });
    },
    storePrevPath(context, payload) {
      if (!_.isEmpty(payload) && payload.path !== '/') {
        let { filter } = getParams();

        sessionStorage.setItem(
          'prevPath',
          JSON.stringify({
            route: {
              path: payload.path,
              query: {
                ...payload.query,
                filter,
              },
            },
            expirity: new dayjs().add(5, 'm').format(),
          })
        );
      }
    },
    async redirectToDashboard(context) {
      const response = await this.state.core.client.get(
        'core/getauthenticationinfo'
      );
      if (response.ok) {
        const { info } = response.data;
        if (info.authenticated !== 1) {
          this.userSSOType = info.userssotype;
          if (info.ssodirectonly) {
            context.commit('set', { key: 'pageId', value: 'ssoDirect' });
          }
          router.push('/').catch(() => {});
        } else {
          router.push('/expl-tabl.').catch(() => {});
        }
      }
    },
    async authenticate(context) {
      // support for logging in via jwt token
      if (window.location.hash.indexOf('token=') > -1) {
        const [hash, jwttoken] = window.location.hash.split('token='); // DO NOT REMOVE THE HASH- USED FOR AUTO LOGIN
        await context.dispatch('login', { jwttoken });
      }

      const response = await this.state.core.client.get(
        'core/getauthenticationinfo'
      );

      this.dispatch('files/clearSecureFolderTokens');

      if (response.ok) {
        const { info } = response.data;
        if (info.forcepasswordchange) {
          // Force password change enabled
          context.commit('set', {
            key: 'pageId',
            value: 'forceResetPassword',
          });
          return;
        }

        context.commit('set', { key: 'user', value: info });

        const { mode, msteams, msteamsauthflow } = getParams();

        if (mode !== 'public' && mode !== 'upload') {
          // redirect in case of authenticated routes
          if (info.authenticated !== 1) {
            this.userSSOType = info.userssotype;
            if (info.ssodirectonly) {
              context.commit('set', { key: 'SSORedirect', value: true });
              context.commit('set', { key: 'pageId', value: 'ssoDirect' });
            }

            /*  
              Only push the route after the router is done with the first routing
              otherwise it will ovewrite the first navigation registry from null,
              losing the previous path reference
              *Wrapped in a promise to stop execution while waiting*
             */
            await new Promise((resolve) => {
              router.onReady(() => {
                router.push('/').catch(() => {});
                resolve();
              });
            });
          } else {
            const consent = await this.dispatch('core/getUserTOS');
            if (consent.getuserconsent?.showdialog === 1) {
              this.pageId = 'tos';
              context.commit('set', { key: 'pageId', value: 'tos' });
            } else {
              this.dispatch('core/getColorTags');

              const redirect = !doNotRedirectAfterAuth.some((route) =>
                window.location.href.includes(route)
              );

              if (mode === 'unsubscribe') {
                const { emailid } = getParams();
                await context.dispatch('unsubscribe', {
                  emailid,
                });
              }
              const customization = await this.state.core.customization;
              if (msteams) {
                microsoftTeams.initialize();
                if (msteamsauthflow) {
                  const result = await context.dispatch('msteamsToken');
                  microsoftTeams.authentication.notifySuccess(
                    result.data.token
                  );
                }
                context.commit('set', { key: 'msteams', value: true });
              }
              this.dispatch('core/getEditors');
              const initialPath =
                customization && customization.INITIALHISTORYSTRING
                  ? customization.INITIALHISTORYSTRING.replace('#', '')
                  : '';
              if (redirect)
                router.push(initialPath ? initialPath : '/expl-tabl.');
            }
          }
        }
      }
    },
    async readyState(context) {
      context.commit('set', { key: 'ready', value: true });

      const previousPath = JSON.parse(sessionStorage.getItem('prevPath'));
      sessionStorage.removeItem('prevPath');

      if (previousPath) {
        const expirityDate = new dayjs(previousPath.expirity);
        const isExpired = expirityDate.diff(new dayjs()) < 0;
        if (isExpired) return;

        // Navigate back to path
        if (previousPath.route && previousPath.route !== '/') {
          router.push(previousPath.route).catch(() => {
            // navigation cancelled because of the router.beforeEach is replacing the hash, try again in 500ms
            setTimeout(router.push(previousPath.route), 500);
          });
        }
      }
    },
    async login(context, payload) {
      context.commit('clearErrors');

      if (localStorage.getItem('logincurrentLang') != 1) {
        localStorage.removeItem('currentLang');
      }

      const response = await this.state.core.client.post('core/loginguest', {
        ...payload,
        tfa: 1,
      });
      if(response.ok) {
        localStorage.removeItem("attempt");
        return response;
      } else {
        let count = parseInt(localStorage.getItem("attempt")) || 0;
        localStorage.setItem("attempt", parseInt(count)+1)
        return response;
      }
    },
    async msteamsToken(context) {
      const response = await this.state.core.client.get(
        'core/msteamsauthsuccesstoken'
      );
      return response;
    },
    logintwofa(context, payload) {
      // Primary point for logging in with 2fa
      return this.state.core.client.post('core/2falogin', {
        ...payload,
        tfa: 1,
      });
    },
    async generatetotpsecret(context, payload) {
      const response = await this.state.core.client.post(
        'core/generatetotpsecret',
        payload
      );

      if (!response.ok) {
        context.dispatch('throwLoginError', {
          error: 'Failed to generate QR Code. Contact an administrator.',
          redirect: true,
        });
      }

      return response;
    },
    async get2fagasecret(context, payload) {
      // Primary point for logging in with 2fa
      const response = await this.state.core.client.post(
        'core/get2fagasecret',
        payload
      );
      return response;
    },
    async set2fasmsphonenumber(context, payload) {
      // Primary point for logging in with 2fa
      const response = await this.state.core.client.post(
        'core/set2fasmsnumber',
        payload
      );
      if (response.ok) {
        context.commit('set', {
          key: 'twofaphonenumber',
          value: payload.phonenumber,
        });
        context.commit('clearErrors');
      }
      return response;
    },
    async send2faotp(context, payload) {
      const response = await this.state.core.client.post(
        'core/send2faotp',
        payload
      );

      return response;
    },
    async loginProtectedShare(context, payload) {
      const response = await this.state.core.client.post(
        'core/loginprotectedshare',
        payload
      );
      return response;
    },
    async relogin(context, payload) {
      context.commit('clearErrors');
      // Entry point for relogin-based (2fa) methods

      switch (payload.data.result) {
        case LoginMethods.REGULAR: {
          this.pageId = 'login';

          if (payload.ok) {
            this.dispatch('core/getSystemStatus');
            await this.dispatch('auth/authenticate');
            this.dispatch('core/getCustomizations');
          } else {
            context.commit('set', {
              key: 'errors',
              value: payload.error,
            });
          }
          break;
        }
        case LoginMethods.TWOFA: {
          if (
            typeof payload.data.code === 'undefined' &&
            payload.data.result === 3
          ) {
            // Switch page, do not perform login
            // Commit token and userid to the store
            let user = context.state.user;
            user.token = payload.data.message;
            user.userid = payload.data.userid;

            context.commit('set', { key: 'user', value: user });
            // Commit current pageId for auth
            let method = getTwoFaMethodFromToken(user.token);

            if (isTwoFaSetupMode(user.token)) {
              let value = '';
              if (method == 'sms') {
                value = 'twofausersetupsms';
              } else if (method == 'totp') {
                value = 'twofausersetuptotp';
              } else if (method == 'duo') {
                value = 'twofausersetupduo';
              } else {
                value = 'twofausersetup';
              }
              context.commit('set', {
                key: 'pageId',
                value,
              });
              context.commit('set', { key: 'setupMode', value: method });
              break;
            } else {
              context.commit('set', { key: 'pageId', value: 'logintwofa' });
              context.commit('set', { key: 'twofamethod', value: method });
              this.pageId = 'logintwofa';
            }
          } else {
            if (payload.ok) {
              await this.dispatch('auth/authenticate');
            } else {
              context.commit('set', {
                key: 'errors',
                value: payload.error,
              });
            }
          }
          break;
        }
      }
    },
    gotoLogin(context) {
      context.commit('set', { key: 'pageId', value: 'login' });
    },
    gotoPage(context, value = 'login') {
      context.commit('set', { key: 'pageId', value });
    },
    async logout(context) {
      const response = await this.state.core.client.get('core/locksession');
      this.dispatch('files/clearSecureFolderTokens');

      // @todo: Add this to mixin
      let url = new URL(window.location.href);
      // Remove filter if there is any in url
      url.searchParams.delete('filter');
      // Clear FS path from URL
      const substringIndex =
        url.href.indexOf('#expl-tabl') !== -1
          ? url.href.indexOf('#expl-tabl')
          : url.href.indexOf('#');

      url.href = url.href.substring(0, substringIndex);
      window.history.replaceState(null, null, url);
      if (response) {
        //SSO logout
        if (
          context.state.user.userssotype !== 'NONE' &&
          typeof this.state.core.systemstatus.SAMLIDPLOGOUTURL === 'string'
        ) {
          let ssoLogout = this.state.core.systemstatus.SAMLIDPLOGOUTURL.trim();
          window.location.replace(ssoLogout);
        } else {
          router.go('/');
        }
      } else {
        router.go('/');
      }
    },
    async searchUsers(context, payload) {
      const response = await this.state.core.client.post(
        'core/searchprofiles',
        { filter: payload }
      );
      return response;
    },
    async searchGroups(context, payload) {
      const response = await this.state.core.client.post('core/searchgroups', {
        filter: payload,
      });
      return response;
    },
    async getDuoQrCode(context, payload) {
      let response = await this.state.core.client.get(
        'core/get2faqrcode',
        payload
      );

      if (!response.ok) {
        context.dispatch('throwLoginError', {
          error: Vue.$t(
            'Failed to generate DUO QR Code. Contact an administrator.'
          ),
          redirect: true,
        });
      }

      return response;
    },
    throwLoginError(context, payload) {
      const { error, redirect } = payload;

      context.commit('set', {
        key: 'errors',
        value: error,
      });

      if (redirect === true) {
        context.dispatch('gotoLogin');
      } else if (typeof redirect === 'string') {
        context.dispatch('gotoPage', redirect);
      }
    },
    updateProfileImageVersion({ commit, state }) {
      commit('set', {
        key: 'profileImageVersion',
        value: state.profileImageVersion + 1,
      });
    },
  },
};
