<template>
  <div id="app" :role="core.ready ? false : 'main'" :class="{ rtl: useRtl }">
    <theme-provider :theme="$store.getters['themes/themeScheme']">
      <Loader :title="randomHelp" :loading="!core.ready || loading" full />

      <component :is="banner" v-if="core.ready" />

      <component
        :is="componentLoader"
        v-if="core.ready"
        :is-password-protected="isPasswordProtected"
        :is-public-share-view="isPublic"
      />

      <component
        :is="onboarding"
        v-if="
          core.ready &&
          systemStatus &&
          systemStatus.TOURENABLED &&
          customization &&
          customization.userCustomizationLoaded &&
          auth.user &&
          auth.user.authenticated &&
          !isOnAuthPage &&
          !loading
        "
      />
    </theme-provider>
  </div>
</template>

<script>
import Loader from 'common/components/Loader.vue';
import Main from './layouts/Main';
import Auth from './pages/Auth';
import { getParams } from 'common/utils/files';
import { ThemeProvider } from 'vue-styled-components';
import {
  isMobileDevice,
  isTabletDevice,
  isAndroidDevice,
} from 'common/utils/responsive.js';
import _ from 'lodash';
import responsivenessMixin from '@/mixins/responsivenessMixin';

import { detectAnyAdblocker } from 'vue-adblock-detector';

export default {
  name: 'App',
  components: {
    Loader,
    Auth,
    Main,
    'theme-provider': ThemeProvider,
  },
  mixins: [responsivenessMixin],
  computed: {
    isOnAuthPage() {
      return this.$route.name === 'auth';
    },
    banner() {
      if (
        this.isMobile &&
        this.isAndroidDevice &&
        this.customization.MOBILESMARTBANNERDISABLED !== 1
      ) {
        return () => import('./components/Mobile/SmartBanner');
      }
      return '';
    },
    customization() {
      return this.$store.state.core.customization;
    },
    systemStatus() {
      return this.$store.state.core.systemstatus;
    },
    componentLoader() {
      return !this.isPublic
        ? this.auth.user && this.auth.user.authenticated
          ? 'Main'
          : 'Auth'
        : () => import('./layouts/SingleFileView');
    },
    core() {
      return this.$store.state.core;
    },
    auth() {
      return this.$store.state.auth;
    },
    themes() {
      return this.$store.state.themes;
    },
    useRtl() {
      return this.$store.state.core.useRtl;
    },
    msTeams() {
      return this.$store.state.auth.msteams;
    },
    randomHelp() {
      let messages = [
        'You can toggle the sidebar and activity bar using ] and [ keys',
        'Use pageup and pagedown keys to navigate up and down pages',
        'Use alt/option + up keys to go to parent folder',
        'You can drag and drop files or folders to upload',
        'Upload an image copied to your clipboard by using ctrl/cmd+v',
        'Move files or folders faster by dragging and dropping to the side panel',
        'Switch to dark mode by changing the themes to suit your mood',
        'Share directly after upload by using the handy shortcuts in the File Operations window',
        'Guide others by creating folder readme files in markdown format',
        'Make it personal by uploading a profile picture',
      ];
      return messages[Math.floor(Math.random() * messages.length)];
    },
    isPublic() {
      const { mode } = getParams();
      return (
        mode && (mode === 'public' || mode === 'single' || mode === 'upload')
      );
    },
    isFiltered() {
      const { filter } = getParams();
      return typeof filter !== 'undefined';
    },
    isPasswordProtected() {
      const { secure } = getParams();
      if (secure && secure === '1') {
        this.$store.dispatch('auth/gotoPage', 'securedshare');
        return true;
      } else return false;
    },
    loading() {
      return this.$store.state.loading['auth/redirectToDashboard'];
    },
    onboarding() {
      return () => import('./components/Onboarding/Onboarding.vue');
    },
    isMobileDevice,
    isTabletDevice,
    isAndroidDevice,
  },
  watch: {
    $route(to, from) {
      if (this.$store.state.auth.user.authenticated === 0) {
        if (from.fullPath.slice(-1) === '/') {
          // Paths routed need to have trailing slash removed.
          // The reason is that it breaks network folders for S3 and azure
          const trimmedPath = from.fullPath.substring(
            0,
            from.fullPath.length - 1
          );

          let clonedFrom = _.cloneDeep(from);
          clonedFrom.path = trimmedPath;
          this.$store.dispatch('auth/storePrevPath', clonedFrom);
        } else {
          this.$store.dispatch('auth/storePrevPath', from);
        }
        // Store previous path only for unlogged state
      }
    },
  },
  mounted() {
    this.$store.dispatch('core/init');
    if (!this.isPublic) {
      this.customScrollbars();
    }

    detectAnyAdblocker().then((detected) => {
      if (detected) {
        this.$store.dispatch('core/setUsingAdBlocker');
      }
    });
  },
  created: function () {
    //logout on 401
    this.$store.state.core.client.timeoutSession(this.$store);
    window.addEventListener(
      'resize',
      _.throttle(this.handleWindowResize, 20),
      false
    );
    this.handleWindowResize();
  },
  destroyed() {
    window.removeEventListener('resize', this.handleWindowResize);
  },
  methods: {
    customScrollbars() {
      let css =
        '/* width */\n\
        ::-webkit-scrollbar {width: 10px;}\n\
         /*Track*/ ::-webkit-scrollbar-track {background: #f1f1f1;}\n\
        /* Handle */::-webkit-scrollbar-thumb {background: #a1a6ab;}\n\
        /* Handle on hover */::-webkit-scrollbar-thumb:hover {background: #888;}\n\
        body{overflow: hidden;}';
      let head = document.head || document.getElementsByTagName('head')[0],
        style = document.createElement('style');

      head.appendChild(style);

      style.type = 'text/css';
      style.appendChild(document.createTextNode(css));
    },
    handleWindowResize() {
      this.$store.dispatch('core/setWindowHeight', window.innerHeight);
      const width = window.innerWidth;
      const mobile = this.isMobileDevice || width <= 767;
      const tablet = this.isTabletDevice || (width > 767 && width < 1200);
      this.$store.dispatch('core/isMobile', mobile);
      this.$store.dispatch('core/isTablet', tablet);
      this.$store.dispatch(
        'core/isSmallScreen',
        !tablet && !mobile && width > 1200 && width <= 1440
      );

      const { msteams } = getParams();
      if (width < 1200) {
        this.$store.state.auth.msteams = msteams;
      }
    },
  },
};
</script>
