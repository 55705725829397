var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"file-list-actions",on:{"contextmenu":function($event){$event.preventDefault();return _vm.contextMenu.apply(null, arguments)},"click":function($event){$event.stopPropagation();}}},[(
      (!_vm.isCompactMode && _vm.hasCustomList) ||
      (_vm.isCompactMode && !_vm.fromHome) ||
      !_vm.isCompactMode
    )?_c('div',{staticClass:"btn-group actuals",attrs:{"role":"cell"}},[_c('span',[_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
          _vm.item.type === 'dir' || _vm.item.ext === 'zip'
            ? _vm.$t('Open') + ' [Enter]'
            : _vm.$t('Preview') + ' [Enter]'
        ),expression:"\n          item.type === 'dir' || item.ext === 'zip'\n            ? $t('Open') + ' [Enter]'\n            : $t('Preview') + ' [Enter]'\n        "}],staticClass:"btn btn-sm btn-icon",attrs:{"aria-label":_vm.item.type === 'dir'
            ? _vm.$t('Open') + ' [Enter]'
            : _vm.$t('Preview') + ' [Enter]',"disabled":_vm.docPreview || _vm.item.encryptedfolder === 1},on:{"click":function($event){return _vm.$emit('previewFile')}}},[_c('Icon',{attrs:{"name":"eye","family":"fal"}}),_c('Icon',{staticClass:"icon-overlay",attrs:{"name":"eye","family":"fas"}})],1)]),(_vm.hasCustomList)?_c('span',[_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t('Go to location')),expression:"$t('Go to location')"}],staticClass:"btn btn-sm btn-icon",attrs:{"aria-label":_vm.$t('Go to location')},on:{"click":function($event){return _vm.$emit('browse')}}},[_c('span',{staticClass:"invisible"},[_vm._v(_vm._s(_vm.$t('Go to location')))]),_c('Icon',{attrs:{"name":"folder-open","family":"fal"}}),_c('Icon',{staticClass:"icon-overlay",attrs:{"name":"folder-open","family":"fas"}})],1)]):_vm._e(),_c('span',[_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
          _vm.isReadLocked ? _vm.lockMessage('Download') : _vm.$t('Download') + ' [D]'
        ),expression:"\n          isReadLocked ? lockMessage('Download') : $t('Download') + ' [D]'\n        "}],staticClass:"btn btn-sm btn-icon",attrs:{"aria-label":_vm.$t('Download') + ' [D]',"disabled":_vm.isReadLocked ||
          !_vm.item.candownload ||
          (_vm.item.type === 'dir' && _vm.customization.DISABLEFOLDERDOWNLOAD === 1)},on:{"click":function($event){return _vm.$emit('downloadFile')}}},[_c('span',{staticClass:"invisible"},[_vm._v(_vm._s(_vm.isReadLocked ? _vm.lockMessage('Download') : _vm.$t('Download') + ' [D]'))]),_c('Icon',{attrs:{"name":"download","family":"fal"}}),_c('Icon',{staticClass:"icon-overlay",attrs:{"name":"download","family":"fas"}})],1)]),(_vm.canEditFile)?_c('span',[(_vm.editorClientLength > 1)?_c('VPopover',{on:{"show":() => {
            _vm.$emit('changeFocus', true);
          }}},[_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t('Edit Online')),expression:"$t('Edit Online')"}],staticClass:"btn btn-sm btn-icon",attrs:{"aria-label":_vm.$t('Edit Online'),"disabled":!!(_vm.customization.ALLOWQUICKEDITALL && _vm.isExtensionBlackListed(_vm.item.ext))}},[_c('span',{staticClass:"invisible"},[_vm._v(_vm._s(_vm.$t('Edit Online')))]),_c('Icon',{attrs:{"name":"edit","family":"fal"}}),_c('Icon',{staticClass:"icon-overlay",attrs:{"name":"edit","family":"fas"}})],1),_c('Alert',{staticClass:"p-0 webedit-popover",attrs:{"slot":"popover","button-type":"primary"},slot:"popover"},[_c('div',{staticClass:"list-group list-group-flush cursor-pointer"},[(
                _vm.isWebEditEnabled &&
                _vm.canEditFileType(_vm.item.ext, 'desktop', _vm.allowQuickEditAll) &&
                _vm.item.showquickedit &&
                !(_vm.customization.ALLOWQUICKEDITALL && _vm.isExtensionBlackListed(_vm.item.ext))
              )?_c('span',{directives:[{name:"close-popover",rawName:"v-close-popover"}],staticClass:"list-group-item list-group-item-action text-left",on:{"click":function($event){return _vm.$emit('runQuickEdit')}}},[_c('span',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"fc-desktop-icon pr-1"}),_vm._v(" "+_vm._s(_vm.$t('file_list.web_edit.open_client', { client: _vm.desktopEditorClientName, }))+" ")])]):_vm._e(),(_vm.isWOPIEditEnabled && 
              _vm.canEditFileType(_vm.item.ext, 'web') &&
              !(_vm.customization.ALLOWQUICKEDITALL && _vm.isExtensionBlackListed(_vm.fileExtension)))?_c('span',{directives:[{name:"close-popover",rawName:"v-close-popover"}],staticClass:"list-group-item list-group-item-action text-left",on:{"click":function($event){return _vm.$emit('quickEditFile')}}},[_c('span',[_c('Icon',{staticClass:"pr-1",attrs:{"name":"edit","family":"fal"}}),_vm._v(" "+_vm._s(_vm.$t('Edit Online'))+" ")],1)]):_vm._e(),(
                _vm.isWOPIEditEnabled &&
                _vm.isGoogleDocsEnabled &&
                _vm.canEditFileType(_vm.item.ext, 'google')
              )?_c('span',{directives:[{name:"close-popover",rawName:"v-close-popover"}],staticClass:"list-group-item list-group-item-action text-left",on:{"click":_vm.openInGoogleDocs}},[_c('span',{staticClass:"d-flex align-items-center"},[_c('div',{class:_vm.googleAppsClient.iconClass}),_c('span',[_vm._v(_vm._s(_vm.$t(_vm.googleAppsClient.fullLabel)))])])]):_vm._e(),(
                _vm.isWOPIEditEnabled &&
                _vm.WOPIClient &&
                _vm.canEditFileType(_vm.item.ext, _vm.WOPIClient.key)
              )?_c('span',{directives:[{name:"close-popover",rawName:"v-close-popover"}],staticClass:"list-group-item list-group-item-action text-left",on:{"click":_vm.quickEditWopi}},[_c('span',{staticClass:"d-flex align-items-center"},[_c('div',{class:_vm.WOPIClient.iconClass}),_c('span',[_vm._v(_vm._s(_vm.$t(_vm.WOPIClient.fullLabel)))])])]):_vm._e()])])],1):(_vm.isWOPIEditEnabled && _vm.canEditFileType(_vm.item.ext, 'web'))?_c('span',{attrs:{"list-only":""}},[_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t('Edit Online')),expression:"$t('Edit Online')"}],staticClass:"btn btn-sm btn-icon",attrs:{"aria-label":_vm.$t('Edit Online')},on:{"click":function($event){return _vm.$emit('quickEditFile')}}},[_c('span',{staticClass:"invisible"},[_vm._v(_vm._s(_vm.$t('Edit Online')))]),_c('Icon',{attrs:{"name":"edit","family":"fal"}}),_c('Icon',{staticClass:"icon-overlay",attrs:{"name":"edit","family":"fas"}})],1)]):(
          _vm.isWebEditEnabled &&
          _vm.canEditFileType(_vm.item.ext, 'desktop', _vm.allowQuickEditAll) &&
          _vm.item.showquickedit
        )?_c('span',{attrs:{"list-only":""}},[_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
            `<i class='fc-desktop-icon'></i>${_vm.$t(
              'file_list.web_edit.open_client',
              {
                client: _vm.desktopEditorClientName,
              }
            )}`
          ),expression:"\n            `<i class='fc-desktop-icon'></i>${$t(\n              'file_list.web_edit.open_client',\n              {\n                client: desktopEditorClientName,\n              }\n            )}`\n          "}],staticClass:"btn btn-sm btn-icon",attrs:{"aria-label":_vm.$t('file_list.web_edit.open_client', {
              client: _vm.desktopEditorClientName,
            }),"disabled":!!(_vm.customization.ALLOWQUICKEDITALL && _vm.isExtensionBlackListed(_vm.item.ext))},on:{"click":function($event){return _vm.$emit('runQuickEdit')}}},[_c('span',{staticClass:"invisible"},[_vm._v(_vm._s(_vm.$t('file_list.web_edit.open_client', { client: _vm.desktopEditorClientName, })))]),_c('Icon',{attrs:{"name":"edit","family":"fal"}}),_c('Icon',{staticClass:"icon-overlay",attrs:{"name":"edit","family":"fas"}})],1)]):(
          _vm.isWOPIEditEnabled &&
          _vm.WOPIClient &&
          _vm.canEditFileType(_vm.item.ext, _vm.WOPIClient.key)
        )?_c('span',{attrs:{"list-only":""}},[_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
            `<i class='${_vm.WOPIClient.iconClass}'></i> ${_vm.$t(
              _vm.WOPIClient.fullLabel
            )}`
          ),expression:"\n            `<i class='${WOPIClient.iconClass}'></i> ${$t(\n              WOPIClient.fullLabel\n            )}`\n          "}],staticClass:"btn btn-sm btn-icon",attrs:{"aria-label":_vm.$t(_vm.WOPIClient.fullLabel)},on:{"click":_vm.quickEditWopi}},[_c('span',{staticClass:"invisible"},[_vm._v(_vm._s(_vm.$t(_vm.WOPIClient.fullLabel)))]),_c('Icon',{attrs:{"name":"edit","family":"fal"}}),_c('Icon',{staticClass:"icon-overlay",attrs:{"name":"edit","family":"fas"}})],1)]):(
          _vm.isWOPIEditEnabled &&
          _vm.isGoogleDocsEnabled &&
          _vm.canEditFileType(_vm.item.ext, 'google')
        )?_c('span',{attrs:{"list-only":""}},[_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
            `<i class='${_vm.googleAppsClient.iconClass}'></i> ${_vm.$t(
              _vm.googleAppsClient.fullLabel
            )}`
          ),expression:"\n            `<i class='${googleAppsClient.iconClass}'></i> ${$t(\n              googleAppsClient.fullLabel\n            )}`\n          "}],staticClass:"btn btn-sm btn-icon",on:{"click":_vm.openInGoogleDocs}},[_c('span',{staticClass:"invisible"},[_vm._v(_vm._s(_vm.$t(_vm.WOPIClient.fullLabel)))]),_c('Icon',{attrs:{"name":"edit","family":"fal"}}),_c('Icon',{staticClass:"icon-overlay",attrs:{"name":"edit","family":"fas"}})],1)]):_vm._e()],1):(!_vm.canEditFile)?_c('span',{attrs:{"list-only":""}},[_c('button',{staticClass:"btn btn-sm btn-icon",attrs:{"disabled":""}},[_c('span',{staticClass:"invisible"},[_vm._v(_vm._s(_vm.$t('Edit is disabled')))]),_c('Icon',{attrs:{"name":"edit","family":"fal"}})],1)]):_vm._e(),(!_vm.isInRecycleFolder && _vm.systemstatus.sharemode != 3)?_c('span',[_c('VPopover',{attrs:{"disabled":!_vm.isNonSharedRootFolder}},[_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
            _vm.isShareDisabled || !_vm.item.isshareable
              ? _vm.$t('Share is disabled')
              : _vm.$t('Share') + ' [.]'
          ),expression:"\n            isShareDisabled || !item.isshareable\n              ? $t('Share is disabled')\n              : $t('Share') + ' [.]'\n          "}],staticClass:"btn btn-sm btn-icon",attrs:{"aria-label":_vm.isShareDisabled ? _vm.$t('Share is disabled') : _vm.$t('Share') + ' [.]',"disabled":_vm.isShareDisabled || !_vm.item.isshareable},on:{"click":() => (_vm.isNonSharedRootFolder ? null : this.$emit('shareFile'))}},[_c('Icon',{attrs:{"name":"share-alt","family":"fal"}}),_c('Icon',{staticClass:"icon-overlay",attrs:{"name":"share-alt","family":"fas"}})],1),(_vm.isNonSharedRootFolder)?_c('Alert',{ref:"alert",attrs:{"slot":"popover","button-type":"primary","buttons":[
            {
              label: `Share`,
              callback: () => {
                this.$emit('shareFile', true);
              },
            },
            {
              label: `Cancel`,
              outline: true,
              callback: () => {
                _vm.isDeleting = false;
              },
            },
          ]},slot:"popover"},[_c('span',{attrs:{"role":"alert"}},[_vm._v(" "+_vm._s(_vm.$t('Are you sure you want to share a root folder?'))+" ")])]):_vm._e()],1)],1):_vm._e(),(_vm.isInRecycleFolder)?_c('span',[_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t('Restore')),expression:"$t('Restore')"}],staticClass:"btn btn-sm btn-icon",attrs:{"aria-label":_vm.$t('Restore')},on:{"click":function($event){return _vm.restoreFile(_vm.item)}}},[_c('Icon',{attrs:{"name":"trash-undo","family":"fal"}}),_c('Icon',{staticClass:"icon-overlay",attrs:{"name":"trash-undo","family":"fas"}})],1)]):_vm._e(),(!_vm.isInRecycleFolder)?_c('VPopover',{attrs:{"disabled":!_vm.hasDirectLink || _vm.isPublicShare},on:{"show":_vm.onDirectLinkPopoverShow,"apply-hide":function($event){return _vm.$emit('changeFocus', false)}}},[_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
          _vm.hasDirectLink ? _vm.$t('Direct Link') : _vm.$t('Direct Link is disabled')
        ),expression:"\n          hasDirectLink ? $t('Direct Link') : $t('Direct Link is disabled')\n        "}],staticClass:"btn btn-sm btn-icon",attrs:{"disabled":!_vm.hasDirectLink || _vm.isPublicShare,"aria-label":_vm.hasDirectLink || !_vm.isPublicShare
            ? _vm.$t('Direct Link')
            : _vm.$t('Direct Link is disabled'),"list-only":""}},[_c('Icon',{attrs:{"name":"link","family":"fal"}}),_c('Icon',{staticClass:"icon-overlay",attrs:{"name":"link","family":"fas"}})],1),(_vm.hasDirectLink)?_c('Alert',{attrs:{"slot":"popover","button-type":"primary","buttons":[
          {
            label: `Copy Link`,
            callback: function () {
              _vm.copyDirectLink();
            },
          },
          { label: `Cancel`, outline: true, callback: function () {} },
        ]},slot:"popover"},[_c('span',{attrs:{"role":"alert"}},[(!_vm.isDirectLinkReady)?_c('span',[_c('Loader',{attrs:{"full":"","loading":""}}),_vm._v(" "+_vm._s(_vm.$t('Loading Direct Link'))+" ")],1):_c('span',[_vm._v(" "+_vm._s(_vm.$t('Direct Link is Ready'))+" ")])])]):_vm._e()],1):_vm._e(),_c('span',{attrs:{"list-only":""}},[_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
          _vm.isLockedByOthers
            ? _vm.lockMessage('Copy')
            : _vm.$t('Copy') + ' [' + _vm.metaKey + '+ C]'
        ),expression:"\n          isLockedByOthers\n            ? lockMessage('Copy')\n            : $t('Copy') + ' [' + metaKey + '+ C]'\n        "}],staticClass:"btn btn-sm btn-icon",attrs:{"aria-label":_vm.$t('Copy') + ' [' + _vm.metaKey + '+ C]',"disabled":_vm.isLockedByOthers ||
          (_vm.item.isroot && _vm.item.type === 'dir') ||
          !_vm.item.candownload ||
          !_vm.user.authenticated ||
          _vm.item.encryptedfolder === 1},on:{"click":function($event){return _vm.$emit('copyFile')}}},[_c('Icon',{attrs:{"name":"clone","family":"fal"}}),_c('Icon',{staticClass:"icon-overlay",attrs:{"name":"clone","family":"fas"}})],1)]),_c('span',{attrs:{"list-only":""}},[_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
          _vm.isLockedByOthers
            ? _vm.lockMessage('Move')
            : _vm.$t('Move') + ' [' + _vm.metaKey + '+ X]'
        ),expression:"\n          isLockedByOthers\n            ? lockMessage('Move')\n            : $t('Move') + ' [' + metaKey + '+ X]'\n        "}],staticClass:"btn btn-sm btn-icon",attrs:{"aria-label":_vm.$t('Copy') + ' [' + _vm.metaKey + '+ C]',"disabled":_vm.isLockedByOthers ||
          ((_vm.item.isroot || !_vm.item.canupload) && !_vm.isInRecycleFolder) ||
          _vm.item.encryptedfolder === 1},on:{"click":function($event){return _vm.$emit('moveFile')}}},[_c('Icon',{attrs:{"name":"long-arrow-right","family":"fal"}}),_c('Icon',{staticClass:"icon-overlay",attrs:{"name":"long-arrow-right","family":"fas"}})],1)]),_c('VPopover',{attrs:{"open":_vm.showDelConf,"disabled":_vm.isLockedByOthers ||
        _vm.item.isroot === 1 ||
        (!_vm.item.canupload && !_vm.isInRecycleFolder) ||
        (_vm.systemstatus.clearrecyclebin === 0 && _vm.isInRecycleFolder)},on:{"show":() => {
          _vm.$emit('changeFocus', true);
          _vm.isDeleteAlertEnabled = true;
        },"hide":() => {
          _vm.isDeleteAlertEnabled = false;
        }}},[_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
          _vm.isLockedByOthers
            ? _vm.lockMessage('Delete')
            : _vm.isInRecycleFolder
            ? _vm.$t('Permanently Delete') + ' [Del]'
            : _vm.$t('Delete') + ' [Del]'
        ),expression:"\n          isLockedByOthers\n            ? lockMessage('Delete')\n            : isInRecycleFolder\n            ? $t('Permanently Delete') + ' [Del]'\n            : $t('Delete') + ' [Del]'\n        "}],staticClass:"btn btn-sm btn-icon",attrs:{"aria-label":_vm.isInRecycleFolder
            ? _vm.$t('Permanently Delete') + ' [Del]'
            : _vm.$t('Delete') + ' [Del]',"list-only":"","disabled":_vm.isLockedByOthers ||
          _vm.item.isroot === 1 ||
          (!_vm.item.canupload && !_vm.isInRecycleFolder) ||
          (_vm.systemstatus.clearrecyclebin === 0 && _vm.isInRecycleFolder)}},[_c('Icon',{attrs:{"name":"trash-alt","family":"fal"}}),_c('Icon',{staticClass:"icon-overlay",attrs:{"name":"trash-alt","family":"fas"}})],1),_c('Alert',{attrs:{"slot":"popover","button-type":"danger","visible":_vm.isDeleteAlertEnabled,"buttons":[
          {
            label: `Delete`,
            callback: function () {
              _vm.$emit('doDeleteFile');
            },
          },
          { label: `Cancel`, outline: true, callback: function () {} },
        ]},slot:"popover"},[_c('span',{attrs:{"role":"alert"}},[_vm._v(" "+_vm._s(_vm.item.encryptedfolder === 1 ? _vm.$t('Are you sure you want to permanently delete') : _vm.$t('Are you sure you want to delete'))+" "+_vm._s(_vm.item.name)+"? ")])])],1),(!_vm.isInRecycleFolder && _vm.item.locked)?_c('VPopover',{attrs:{"disabled":_vm.isLockedByOthers ||
        (_vm.item.isroot === 1 && !_vm.item.dirpath.startsWith('/SHARED/')) ||
        _vm.isPublicShare ||
        _vm.isLimitedUser ||
        !_vm.item.showlockunlock ||
        _vm.item.encryptedfolder === 1},on:{"show":() => {
          _vm.$emit('changeFocus', true);
        },"update:open":(isOpen) => {
          _vm.isUnlockAlertOpen = isOpen;
        }}},[_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
          _vm.isLockedByOthers ? _vm.lockMessage('Unlock') : _vm.$t('Unlock') + ' [L]'
        ),expression:"\n          isLockedByOthers ? lockMessage('Unlock') : $t('Unlock') + ' [L]'\n        "}],staticClass:"btn btn-sm btn-icon",attrs:{"disabled":_vm.isLockedByOthers ||
          (_vm.item.isroot && !_vm.item.dirpath.startsWith('/SHARED/')) ||
          _vm.isPublicShare ||
          _vm.isLimitedUser ||
          !_vm.item.showlockunlock ||
          _vm.item.encryptedfolder === 1,"aria-label":_vm.$t('Unlock') + ' [L]',"list-only":""}},[_c('Icon',{attrs:{"name":"unlock","family":"fal"}}),_c('Icon',{staticClass:"icon-overlay",attrs:{"name":"unlock","family":"fas"}})],1),_c('Alert',{attrs:{"slot":"popover","button-type":"danger","visible":_vm.isUnlockAlertOpen,"buttons":[
          {
            label: _vm.$t('Unlock'),
            callback: function () {
              _vm.$emit('unlockFile');
            },
          },
          { label: `Cancel`, outline: true, callback: function () {} },
        ]},slot:"popover"},[_c('span',{attrs:{"role":"alert"}},[_vm._v(" "+_vm._s(_vm.$t('Are you sure you want to unlock') + ' ')+" "+_vm._s(_vm.item.name)+"? ")])])],1):_vm._e(),(!_vm.isInRecycleFolder && !_vm.item.locked)?_c('span',{attrs:{"list-only":""}},[_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t('Lock') + ' [L]'),expression:"$t('Lock') + ' [L]'"}],staticClass:"btn btn-sm btn-icon",attrs:{"aria-label":_vm.$t('Lock') + ' [L]',"disabled":(_vm.item.isroot && !_vm.item.dirpath.startsWith('/SHARED/')) ||
          _vm.isPublicShare ||
          _vm.isLimitedUser ||
          !_vm.item.showlockunlock ||
          _vm.item.encryptedfolder === 1 ||
          _vm.isLockDisabled},on:{"click":function($event){return _vm.$emit('lockFile')}}},[_c('Icon',{attrs:{"name":"lock","family":"fal"}}),_c('Icon',{staticClass:"icon-overlay",attrs:{"name":"lock","family":"fas"}})],1)]):_vm._e()],1):_vm._e(),(!_vm.isInRecycleFolder && !_vm.isMobile && !_vm.isTablet)?_c('div',{staticClass:"btn-group more-options",attrs:{"role":"cell"}},[_c('VPopover',{ref:"popover",class:'sample',attrs:{"popover-class":'dropdown',"container":'body',"boundaries-element":"viewport","placement":'right-start',"offset":3},on:{"show":() => {
          _vm.$emit('changeFocus', true);
          _vm.contextMenuOpen = true;
          this.$emit('toggleMoreOptions', true);
        },"hide":() => {
          this.$emit('toggleMoreOptions', false);
          _vm.contextMenuOpen = false;
          _vm.$emit('changeFocus', false);
        }}},[_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t('More')),expression:"$t('More')"}],ref:"showMoreButton",staticClass:"btn btn-sm btn-icon",attrs:{"aria-label":_vm.$t('More')}},[_c('Icon',{attrs:{"name":"ellipsis-h","family":"far"}}),_c('Icon',{staticClass:"icon-overlay",attrs:{"name":"ellipsis-h","family":"fas"}})],1),_c('template',{slot:"popover"},[_c('ContextMenu',{staticClass:"dropdown-wrapper",attrs:{"child":{ item: this.item },"local":true,"is-in-recycle-folder":_vm.isInRecycleFolder,"is-limited-user":_vm.isLimitedUser,"is-public-share-view":_vm.isPublicShare,"is-msteams":_vm.msteams,"open":_vm.contextMenuOpen,"hasSearchResults":_vm.hasSearchResults,"is-compact-mode":_vm.isCompactMode},on:{"onItemContextClick":_vm.triggerAction,"open":_vm.onContextMenuOpen}})],1)],2)],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }