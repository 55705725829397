<template>
  <DialogModal
    class="dialog-modal-table workflow-wizard"
    color="gray"
    position="top"
    size="small"
    :visible="isOpen"
    :title="$t('Create Encrypted Folder')"
    :buttons="[
      {
        label: $t('Close'),
        outline: true,
        callback: closeModal,
      },
      {
        label: $t('Create'),
        outline: false,
        disabled: !isValid,
        callback: createFolder,
      },
    ]"
    @close="closeModal"
  >
    <Loader v-if="isCreatingFolder" :loading="isCreatingFolder" full />
    <div v-else>
      <div class="sub-body">
        <form class="form">
          <div class="row mb-4">
            <div
              class="col-md-2 d-flex justify-content-center align-items-center"
            >
              <h1><Icon name="key" class="fs-1" /></h1>
            </div>
            <div class="col-md-10">
              {{
                $t(
                  'Files stored in this folder are encrypted using your password.'
                )
              }}<br />
              {{
                $t(
                  'The encrypted files can only be accessed in the future if the same password is provided.'
                )
              }}<br /><br />
              {{
                $t(
                  'Encrypted folders are only available via the web browser and not available via other clients or mobile apps.'
                )
              }}
            </div>
          </div>
          <div class="row">
            <div
              class="col-md-12 form-group"
              :class="{
                'is-invalid': error.name.length > 0,
              }"
            >
              <InputBox
                id="folderNameID"
                ref="folderName"
                v-model="secureFolder.name"
                :label="$t('Folder Name')"
                class="p-0"
                type="text"
                :aria-label="$t('Folder Name')"
                :placeholder="$t('Name')"
                @input="clearErrors"
              />
            </div>
          </div>
          <div class="row">
            <div
              class="col-md-6 form-group"
              :class="{
                'is-invalid':
                  error.password.length > 0 || error.passConf.length > 0,
              }"
            >
              <InputBox
                v-model="secureFolder.password"
                :label="$t('Folder Password')"
                :type="passwordType"
                :aria-label="$t('Password')"
                autocomplete="off"
                class="p-0"
                :placeholder="$t('Password')"
                :appends="formWithAppends.passwordAppends"
                onpaste="return false;"
                ondrop="return false;"
                @input="clearErrors"
                @blur="validatePass"
              />
            </div>
            <div
              class="col-md-6 form-group"
              :class="{
                'is-invalid': error.passConf.length > 0,
              }"
            >
              <InputBox
                v-model="secureFolder.passConf"
                :label="$t('Confirm Password')"
                :type="passwordType"
                :aria-label="$t('Confirm Password')"
                autocomplete="off"
                class="p-0"
                :placeholder="$t('Confirm Password')"
                :errors="{}"
                :appends="formWithAppends.passwordAppends"
                onpaste="return false;"
                ondrop="return false;"
                @input="clearErrors"
                @blur="validatePass"
                @keydown.enter.native="createFolder"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <ul v-if="error.name.length > 0" class="text-danger error">
                <li v-for="(err, index) in error.name" :key="index">
                  {{ err }}
                </li>
              </ul>
              <ul v-if="error.password.length > 0" class="text-danger error">
                <li v-for="(err, index) in error.password" :key="index">
                  {{ err }}
                </li>
              </ul>
            </div>
          </div>
        </form>
      </div>
    </div>
  </DialogModal>
</template>

<script>
import DialogModal from 'common/components/DialogModal';
import Icon from 'common/components/Icon';
import Loader from 'common/components/Loader';
import InputBox from 'common/components/InputBox';

export default {
  name: 'CreateEncryptedFolder',
  components: {
    DialogModal,
    Icon,
    Loader,
    InputBox,
  },
  props: {
    path: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isOpen: false,
      isCreatingFolder: false,
      error: {
        name: [],
        password: [],
        passConf: [],
      },
      secureFolder: {
        name: '',
        password: '',
        passConf: '',
      },
      passwordType: 'password',
    };
  },
  computed: {
    formWithAppends() {
      return {
        passwordAppends: [
          {
            icon: this.passwordType === 'password' ? 'eye' : 'eye-slash',
            iconBold: true,
            action: this.togglePasswordType,
          },
        ],
      };
    },
    systemStatus() {
      return this.$store.state.core.fullSystemStatus;
    },
    passMinLength() {
      return this.systemStatus.passwordminlength;
    },
    strongPassCheck() {
      return this.systemStatus.strongpasswordchecks;
    },
    isValid() {
      return (
        this.isPasswordMinCharsMatch &&
        this.isPasswordEquals &&
        this.isStrongPassword
      );
    },
    isPasswordEquals() {
      return this.secureFolder.password === this.secureFolder.passConf;
    },
    isPasswordMinCharsMatch() {
      return this.secureFolder.password.length >= this.passMinLength;
    },
    isStrongPassword() {
      if (this.strongPassCheck) {
        let regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{2,}$/;
        return regex.test(this.secureFolder.password);
      } else {
        return true;
      }
    },
  },
  methods: {
    open() {
      this.isOpen = true;
      setTimeout(() => {
        this.$refs.folderName.$refs.folderNameID.focus();
      }, 100);
      this.resetWizard();
    },
    closeModal() {
      this.isOpen = false;
      this.resetWizard();
    },
    togglePasswordType() {
      this.passwordType =
        this.passwordType === 'password' ? 'text' : 'password';
    },
    resetWizard() {
      this.secureFolder.name = '';
      this.secureFolder.password = '';
      this.secureFolder.passConf = '';
      this.clearErrors();
    },
    clearErrors() {
      this.error.name = [];
      this.error.password = [];
      this.error.passConf = [];
    },
    validatePass() {
      this.clearErrors();

      if (!this.isPasswordEquals && this.secureFolder.passConf.length > 0) {
        this.error.passConf.push(this.$t('Both passwords must match'));
        this.error.password.push(this.$t('Both passwords must match'));
      }

      if (!this.isPasswordMinCharsMatch) {
        this.error.password.push(
          this.$tc('forms.validation.password_min_length', this.passMinLength)
        );
      }

      if (!this.isStrongPassword) {
        this.error.password.push(
          this.$t(
            'Password should contain at least one uppercase, lowercase, number and a special character'
          )
        );
      }
    },
    async createFolder() {
      this.isCreatingFolder = true;
      const folderFullPath = `${this.path}/${this.secureFolder.name}`;

      const response = await this.$store.dispatch(
        'files/createEncryptedFolder',
        {
          name: this.secureFolder.name,
          path: this.path,
          folderpass: this.secureFolder.password,
        }
      );

      if (response.ok) {
        this.$root.$emit(
          'announceScreenReader',
          this.$t('{folder} created successfully', {
            folder: this.secureFolder.name,
          })
        );

        this.closeModal();

        this.$emit('onSecureFolderCreated', folderFullPath);

        this.isCreatingFolder = false;
      } else {
        this.$toast.open({
          message: response.error,
          type: 'error',
        });
        this.isCreatingFolder = false;
      }
    },
  },
};
</script>
