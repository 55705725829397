var render = function render(){var _vm=this,_c=_vm._self._c;return _c('DialogModal',{staticClass:"dialog-modal-form",attrs:{"color":"gray","size":"xsmall","visible":_vm.isOpen,"stick-top":false,"title":_vm.encryptedFolderOperation.title,"buttons":[
    {
      label: _vm.$t('Cancel'),
      outline: true,
      callback: _vm.cancelFolder,
    },
    {
      label: _vm.encryptedFolderOperation.label,
      outline: false,
      disabled: !_vm.validEncryptedFolderPass,
      callback: _vm.proccedWithAction,
    },
  ]},on:{"close":_vm.cancelFolder}},[_c('form',{on:{"submit":function($event){$event.preventDefault();}}},[_vm._v(" "+_vm._s(_vm.encryptedFolderOperation.content)+" "),_c('div',{staticClass:"mb-1 mt-4 form-group"},[_c('InputBox',{ref:"encryptedFolderPass",staticClass:"p-0",attrs:{"id":"encryptedFolderPassID","type":_vm.passwordType,"label":_vm.$t('Enter Password'),"aria-label":_vm.$t('Enter Password'),"autocomplete":"off","placeholder":_vm.$t('Enter Password'),"appends":_vm.formWithAppends.passwordAppends,"onpaste":"return false;","ondrop":"return false;"},nativeOn:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.proccedWithAction.apply(null, arguments)}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }