<template>
  <aside
    v-shortkey="{
      'toggle-activity-pane': [']'],
    }"
    class="activity-sidebar"
    :class="{
      active: isOpenned,
      'activity-sidebar--global': !showNavbarHead,
      'activity-sidebar--tablet': isTablet,
    }"
    @shortkey="onShortcut"
  >
    <a
      v-if="(!isMobile && !isTablet) || ((isMobile || isTablet) && !isOpenned)"
      v-a11ybutton="toggle"
      class="activity-sidebar-toggle"
      :class="{ lower: smartbannerVisible === true }"
      aria-label="Expand activity/file details panel"
      tabindex="0"
      @click="toggle"
    >
      <Icon
        v-if="!isMobile && !isTablet"
        :name="isOpenned ? 'arrow-to-right' : 'arrow-to-left'"
        family="fas"
      />

      <Icon
        v-else
        :name="!showNavbarHead ? 'heart-rate' : 'info-circle'"
        family="fas"
      />
    </a>
    <!--<perfect-scrollbar>-->
    <transition
      v-if="isAnimated || isOpenned"
      enter-active-class="animate__animated animate__slideInRight"
      leave-active-class="animate__animated animate__slideOutRight"
    >
      <div v-if="isOpenned" class="activity-sidebar-wrapper">
        <div v-if="isMobile || isTablet" class="activity-sidebar-mobile-header">
          <div class="close-sidebar" @click="toggle">
            <Icon name="times" family="fal" />
          </div>
          <h3 class="title-sidebar" role="heading">
            {{ !showNavbarHead ? $t('Activities') : $t('Info') }}
          </h3>
        </div>
        <div
          v-if="showNavbarHead && !isWorkflowPage"
          class="activity-sidebar-header"
        >
          <ul
            class="activity-sidebar-menu"
            role="tablist"
            :aria-label="$t('Right sidebar tabs')"
          >
            <li
              v-if="aboutEnabled"
              id="aboutTab"
              v-a11ybutton="() => switchTab('about')"
              role="tab"
              :aria-selected="sidebar.current === 'about'"
              :aria-label="$t('About')"
              :aria-controls="sidebar.current === 'about' ? 'aboutContent' : ''"
              tabindex="0"
              :class="{ active: sidebar.current === 'about' }"
            >
              <a @click="switchTab('about')">
                <Icon name="eye" family="fas" />
                {{ $t('About') }}
              </a>
            </li>
            <li
              v-if="showPreview"
              id="previewTab"
              v-a11ybutton="() => switchTab('preview')"
              role="tab"
              :aria-selected="sidebar.current === 'preview'"
              :aria-label="$t('Preview')"
              :aria-controls="
                sidebar.current === 'preview' ? 'previewContent' : ''
              "
              tabindex="0"
              :class="{ active: sidebar.current === 'preview' }"
            >
              <a @click="switchTab('preview')">
                <Icon name="eye" family="fas" />
                {{ $t('Preview') }}
              </a>
            </li>
            <li
              v-if="$route.name !== 'webeditor'"
              id="detailsTab"
              v-a11ybutton="() => switchTab('details')"
              role="tab"
              :aria-selected="sidebar.current === 'details'"
              :aria-label="$t('Details')"
              :aria-controls="
                sidebar.current === 'details' ? 'detailsContent' : ''
              "
              tabindex="0"
              :class="{ active: sidebar.current === 'details' }"
            >
              <a @click="switchTab('details')">
                <Icon name="info-circle" family="fas" />
                {{ $t('Details') }}
              </a>
            </li>
            <li
              v-if="activityEnabled"
              id="activityTab"
              v-a11ybutton="() => switchTab('activity')"
              role="tab"
              :aria-selected="sidebar.current === 'activity'"
              :aria-label="$t('Activity')"
              :aria-controls="
                sidebar.current === 'activity' ? 'activityContent' : ''
              "
              :class="{
                active: sidebar.current === 'activity',
                disable: sidebar.selected.length > 1,
              }"
              tabindex="0"
            >
              <a @click="switchTab('activity')">
                <Icon name="heart-rate" family="far" />
                {{ $t('Activity') }}
              </a>
            </li>
            <li
              v-if="metadataEnabled"
              id="metadataTab"
              v-a11ybutton="() => switchTab('metadata')"
              role="tab"
              :aria-selected="sidebar.current === 'metadata'"
              :aria-label="$t('Metadata')"
              :aria-controls="
                sidebar.current === 'metadata' ? 'metadataContent' : ''
              "
              :class="{
                active: sidebar.current === 'metadata',
              }"
              tabindex="0"
            >
              <a @click="switchTab('metadata')">
                <Icon name="clipboard" family="far" />
                {{ $t('Metadata') }}
              </a>
            </li>
            <li
              v-if="securityEnabled"
              id="securityTab"
              v-a11ybutton="() => switchTab('security')"
              role="tab"
              :aria-selected="sidebar.current === 'security'"
              :aria-label="$t('Security')"
              :aria-controls="
                sidebar.current === 'security' ? 'securityContent' : ''
              "
              :class="{
                active: sidebar.current === 'security',
                disable: sidebar.selected.length > 1,
              }"
              tabindex="0"
            >
              <a @click="switchTab('security')">
                <Icon name="shield-alt" family="far" />
                {{ $t('Security') }}
              </a>
            </li>
            <li
              v-if="canShowComments"
              id="commentsTab"
              v-a11ybutton="() => switchTab('comments')"
              role="tab"
              :aria-selected="sidebar.current === 'comments'"
              :aria-label="$t('Comments')"
              :aria-controls="
                sidebar.current === 'comments' ? 'commentsContent' : ''
              "
              :class="{
                active: sidebar.current === 'comments',
                disable: sidebar.selected.length > 1,
              }"
              tabindex="0"
            >
              <a @click="switchTab('comments')">
                <Icon name="comment-alt-check" family="far" />
                {{ $t('Comments') }}
              </a>
            </li>
          </ul>
        </div>

        <div
          class="activity-sidebar-body"
          :class="['activity-sidebar-body--' + sidebar.current]"
        >
          <Loader v-if="loading" :loading="loading" full />
          <component
            :is="`${sidebar.current}Tab`"
            v-else
            :id="`${sidebar.current}Content`"
            role="tabpanel"
            tabindex="0"
            :aria-label="`${sidebar.current}Tab`"
            :preview-content="sidebar.previewContent"
            :path="sidebarPath"
            :selected="sidebar.selected"
            :last-selected="sidebar.lastSelected"
            @startDragging="$emit('startDragging')"
            @stopDragging="$emit('stopDragging')"
          />
        </div>
      </div>
    </transition>
  </aside>
</template>

<script>
import Icon from 'common/components/Icon';
import Loader from 'common/components/Loader';
import DetailsTab from './FileSidebar/Details';
import AboutTab from './FileSidebar/About';
import ActivityTab from './FileSidebar/Activity';
import MetadataTab from './FileSidebar/Metadata';
import SecurityTab from './FileSidebar/SecurityTab';
import PreviewTab from './FileSidebar/Preview';
import CommentsTab from './FileSidebar/CommentsTab';
import WorkflowComponentsTab from './FileSidebar/WorkflowComponents';
import WorkflowNodeTab from './FileSidebar/WorkflowNodeParams';
import responsivenessMixin from '@/mixins/responsivenessMixin';
import { mapGetters } from 'vuex';
import { isAndroidDevice } from 'common/utils/responsive.js';

export default {
  components: {
    Icon,
    DetailsTab,
    AboutTab,
    ActivityTab,
    MetadataTab,
    CommentsTab,
    SecurityTab,
    PreviewTab,
    WorkflowComponentsTab,
    WorkflowNodeTab,
    Loader,
  },
  mixins: [responsivenessMixin],
  props: {
    previewContent: {
      type: String,
      default: '',
    },
    showPreview: {
      type: Boolean,
      default: false,
    },
    isAnimated: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activityLoading: true,
      showNavbarHead: false,
      renderKey: 0,
      smartbannerVisible: false,
    };
  },
  computed: {
    ...mapGetters('core', ['isLimitedUser']),
    isOpenned() {
      if (
        this.isMobile ||
        this.isTablet ||
        this.$route.name?.includes('workflows') ||
        this.$route.name?.includes('webeditor')
      ) {
        return this.sidebar.open;
      }

      if (!this.$route.name?.includes('files')) {
        return true;
      }

      return this.$route.name === 'files'
        ? !this.$store.state.core.customization.SIDEPANEL_RIGHT_COLLAPSE
        : !this.$store.state.core.customization.SIDEPANEL_EXPLORER_COLLAPSE;
    },
    sidebar() {
      return this.$store.getters['files/getSidebarState']();
    },
    meta() {
      return this.$store.getters['files/getPathMeta'](this.sidebar.path);
    },
    loading() {
      return (
        (this.$store.state.loading['files/getGlobalActivities'] ||
          this.$store.state.loading['files/getShareInfo'] ||
          (!this.currentFile.name &&
            this.$route.name !== 'files' &&
            this.$route.name !== 'shared-files')) &&
        !this.isWorkflowPage
      );
    },
    isSharedPage() {
      return this.$store.state.files.sharePageActive;
    },
    canUploadToPath() {
      return this.meta && this.meta.canupload;
    },
    currentFile() {
      return this.$store.state.files.currentFile || {};
    },
    metadataEnabled() {
      return (
        !this.isLimitedUser &&
        !this.isWebeditorPage &&
        !this.$store.state.core.systemstatus.DISABLEMETADATA
      );
    },
    isSharedWithMe() {
      return this.path.indexOf('/SHARED') > -1;
    },
    activityEnabled() {
      // disabled if DISABLEACTIVITY is set
      if (this.$store.state.core.systemstatus.DISABLEACTIVITY !== 0) {
        return false;
      }
      // disabled for limited users with disablelimiteduseractivity set
      if (
        this.isLimitedUser &&
        this.$store.state.core.fullSystemStatus.disablelimiteduseractivity
      ) {
        return false;
      }
      // disabled for SharedWithMe root folder
      if (this.isSharedWithMe && this.meta && this.meta.isroot) {
        return false;
      }

      // disabled for Shared by Me root folder
      if (this.isSharedPage) {
        return false;
      }

      // disabled if not webeditor and is root
      if (this.isWebeditorPage) {
        return false;
      }
      return true;
    },
    aboutEnabled() {
      return (
        this.$route.name === 'files-sub' &&
        this.meta &&
        typeof this.meta.aboutfile === 'string' &&
        this.sidebar.selected.length === 0
      );
    },
    securityEnabled() {
      let permission =
        this.$store.state.files.permissionOfFile[this.path]?.permission;

      const canManageAcl = permission && permission.canmanageacl;

      return (
        (this.meta && this.meta.cansetacls) ||
        canManageAcl ||
        (!this.isLimitedUser &&
          !this.isSharedWithMe &&
          this.currentFile &&
          this.currentFile.type === 'dir' &&
          this.currentFile.cansetacls)
      );
    },
    isWebeditorPage() {
      return this.$route.name === 'webeditor';
    },
    isWorkflowPage() {
      return this.$route.name === 'workflows-sub';
    },
    user() {
      return this.$store.state.auth.user;
    },
    path() {
      const { pathMatch: path } = this.$router.history.current.params;
      return `/${path ? path : this.user.peerid}`;
    },
    sidebarPath() {
      return this.sidebar.selected.length
        ? this.sidebar.selected[0].path
        : this.sidebar.path;
    },
    canShowComments() {
      return (
        !this.isLimitedUser &&
        this.meta &&
        (!this.meta.isroot || this.sidebar.selected.length)
      );
    },
    isAndroidDevice,
  },
  watch: {
    $route(to, from) {
      if (to.name === 'files' || to.name === 'shared-files') {
        this.switchTab(this.activityEnabled ? 'activity' : 'details');
        this.showNavbarHead = false;
        this.sidebar.open = this.isOpenned;
        if (this.sidebar.selected.length > 0) {
          this.$store.commit('files/setSidebar', {
            key: 'selected',
            value: [],
          });
          this.$store.commit('files/addToStore', {
            key: 'currentFile',
            value: null,
          });
          this.$store.commit('files/setSidebar', {
            key: 'lastSelected',
            value: null,
          });
        }
      } else if (
        from.name === 'files' &&
        to.name === 'files-sub' &&
        this.sidebar.current === 'activity'
      ) {
        this.showNavbarHead = true;
        this.switchTab('details');
      } else if (
        to.name === 'files-sub' &&
        this.meta &&
        typeof this.meta.aboutfile === 'string'
      ) {
        this.showNavbarHead = true;
        this.switchTab('about');
      } else {
        this.showNavbarHead = true;
        this.switchTab('details');
      }
    },
    meta(to, from) {
      if (to && typeof to.aboutfile === 'string' && this.aboutEnabled) {
        this.switchTab('about');
      }
    },
    sidebar: {
      handler(newSidebarState, oldSidebarState) {
        if (
          newSidebarState.selected.length > 1 &&
          newSidebarState.current !== 'details' &&
          newSidebarState.current !== 'metadata'
        ) {
          this.switchTab('details');
        }

        if (
          newSidebarState.selected.length > 0 &&
          newSidebarState.current === 'about' &&
          !this.isWebeditorPage
        ) {
          this.switchTab('details');
        }

        document.getElementsByTagName('body')[0].style.overflowY =
          (this.isMobile || this.isTablet) && newSidebarState.open
            ? 'hidden'
            : 'auto';
      },
      deep: true,
    },
    canShowComments(showComments) {
      if (this.sidebar.current === 'comments' && !showComments) {
        this.switchTab('details');
      }
    },
  },
  mounted() {
    if (
      (this.$route.name === 'files' && this.activityEnabled) ||
      this.isSharedPage
    ) {
      this.switchTab(this.isSharedPage ? 'details' : 'activity');
      this.showNavbarHead = false;
    } else {
      this.showNavbarHead = true;
      this.activityLoading = false;
    }

    this.$root.$on('overlayClicked', () => this.toggle);

    if (this.isMobile || (this.isTablet && this.isAndroidDevice)) {
      this.smartbannerVisible =
        localStorage.getItem('showAndSmartBanner') !== 'false'; //its stored as a string

      this.$root.$on(
        'onSmartBannerClosed',
        () => (this.smartbannerVisible = false)
      );
    }
  },
  methods: {
    async toggle() {
      if (this.$route.name === 'files') {
        this.$store.dispatch('core/setCustomizations', {
          name: 'SIDEPANEL_RIGHT_COLLAPSE',
          value: this.isOpenned ? 1 : 0,
        });
      } else {
        this.$store.dispatch('core/setCustomizations', {
          name: 'SIDEPANEL_EXPLORER_COLLAPSE',
          value: this.isOpenned ? 1 : 0,
        });
      }
      this.$store.commit('files/toggleSidebar');
    },
    switchTab(tab) {
      this.$store.commit('files/setSidebar', { key: 'current', value: tab });
    },
    onShortcut(key) {
      if (key.srcKey === 'toggle-activity-pane') {
        this.toggle();
      }
    },
  },
};
</script>
<style>
.ps {
  height: 85%;
  margin-top: -4rem;
}
</style>
