import { last24hours } from 'common/utils/DateUtils';
import * as microsoftTeams from '@microsoft/teams-js';

export default {
  computed: {
    isDirectLinkReady() {
      return (
        !!this.directLink && !this.$store.state.loading['files/getDirectLink']
      );
    },
    msTeams() {
      return this.$store.state.auth.msteams;
    },
  },
  methods: {
    async openSidebarForFile() {
      this.$emit('clearSelection');
      this.$store.commit('files/setSidebar', {
        key: 'selected',
        value: [this.item],
      });
      this.$store.dispatch('files/setSelectedItem', this.item);
      this.$store.dispatch('files/showItemDetailsSidebar', this.item);
      this.$store.commit('files/setSidebar', {
        key: 'lastSelected',
        value: this.item,
      });
      this.$store.commit('files/setSidebar', {
        key: 'open',
        value: true,
      });
    },
    expand(e) {
      let isTargetTitle =
        e.target.className === 'col-title' ||
        e.target.className === 'col-title-droppable';

      const { ctrlKey, shiftKey, metaKey } = e;

      if (!this.isMobile) {
        if (!this.clickTimeout) {
          this.$emit('expanded', this.item);
          this.$emit(
            'selected',
            { item: this.item, ctrlKey, shiftKey, metaKey },
            isTargetTitle
          );

          // doubleclick tolerance
          this.clickTimeout = setTimeout(() => {
            this.clickTimeout = null;
          }, 250);
        } else {
          clearTimeout(this.clickTimeout);
          this.clickTimeout = null;
          this.previewFile();
        }
      } else {
        this.$emit('expanded', this.item);
        this.$emit(
          'selected',
          { item: this.item, ctrlKey, shiftKey, metaKey },
          isTargetTitle
        );
      }
    },
    preview(e) {
      const isShiftPressed = e && e.shift;

      if (!this.picker) {
        this.$emit('preview', this.item, isShiftPressed);
      } else {
        this.select(e);
      }
    },
    copyFile() {
      this.$emit('copyFile', this.item);
    },
    versions() {
      this.$emit('versions', this.item);
    },
    recentlyModified(modDate) {
      return last24hours(modDate);
    },
    getShareInfo() {
      let share = 'Shared ';
      if (this.item.isshared && typeof this.item.isshared == 'string') {
        switch (this.item.isshared) {
          case 'private':
            share += 'privately';
            break;
          case 'public':
            share += 'publicly';
            break;
          case 'private_invalid':
            share = 'Invalid Private Share';
            break;
          default:
            share = 'Invalid Public Share';
        }
        return share;
      }
      return '';
    },
    onContextMenuOpen() {
      if (this.hasDirectLink && this.directLink === '') {
        this.fetchDirectLink();
      }
    },
    async fetchDirectLink(preventToast = false) {
      const response = await this.$store.dispatch('files/getDirectLink', {
        sharelocation: this.item.path,
      });

      const data = response.data;
      if (response.ok) {
        this.directLink = data.message;
        if (this.msTeams) {
          let directlink = {
            userId: this.user.peerid,
            botop: 'directlink',
            params: {
              path: this.item.path,
              linkUrl: this.directLink,
            },
          };
          microsoftTeams.initialize();
          microsoftTeams.tasks.submitTask(directlink);
          return true;
        }
      } else if (preventToast) {
        this.$toast.open({
          message: `<b>${this.$t('Failure')}</b><p role="alert">${
            data.message
          }</p>`,
          type: 'warning',
        });
      }
    },
    async copyDirectLink() {
      if (!this.directLink) {
        await this.fetchDirectLink();
      }

      if (window.isSecureContext) {
        // HTTPS or localhost - navicator.clipboard is available, so we can trigger copy without user interaction
        await navigator.clipboard.writeText(this.directLink);
        this.$toast.open({
          message: `<b>${this.$t('Success')}</b><p role="alert">${this.$t(
            'Direct Link copied'
          )}</p>`,
          type: 'success',
        });
      } else {
        //We need user interaction (click in the toast) in order to copy
        this.$toast.open({
          message: this.$t('Direct Link is ready! Click here to copy'),
          type: 'success',
          onClick: () => this.$copyText(this.directLink),
        });
      }
    },
  },
};
