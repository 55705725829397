<template>
  <div v-if="currentPage" class="container-fluid p-0" role="main">
    <div class="row no-gutters">
      <div class="col-lg-6 col-xs-12 vh-100 login__wrapper">
        <div class="login__component d-flex">
          <div class="login__header text-center">
            <img
              :src="logo"
              class="login__filecloud-logo"
              :alt="customization.PRODUCT_NAME"
            />
          </div>
          <div class="login__area">
            <div class="tab-headers d-flex justify-content-between">
              <!-- Tab headers -->
              <div class="">
                <h1 class="login__tabHeader--active" tabindex="-1">
                  {{ $t(pages[currentPage].label) }}
                </h1>
              </div>

              <div v-if="showSecondaryLink && !msTeams">
                <a
                  href="#"
                  class="link login__tabHeader--inactive"
                  @click="switchCurrentPage"
                >
                  <Icon name="plus-circle" />
                  {{ $t(pages[notCurrentPage].label) }}
                </a>
              </div>
            </div>

            <hr />

            <!-- Tab Contents (Login/New account/forgot password/TwoFa) -->
            <div
              ref="content-wrapper"
              class="
                row
                align-items-end
                transition-2s
                flex-column
                justify-content-start
              "
              :style="`min-height:${formHeight}`"
            >
              <transition
                name="fade"
                mode="out-in"
                @enter="transitionEnter"
                @before-enter="transitionBeforeEnter"
              >
                <component
                  :is="currentPageComp"
                  @changeLogin="loginMethod"
                ></component>
              </transition>
            </div>
          </div>

          <div class="login__footer">
            <div class="lang-text">
              <div class="py-1">
                <Icon class="fa-2x" name="globe-americas" />
              </div>
              <LanguageSwitcher class="mb-3" style="width: auto" />
            </div>
            <!-- upto two Login UI Additional Links to be displayed in user login screen -->
            <div v-if="getAdditionalLinks.length > 0" class="d-inline-flex">
              <a
                class="text-muted px-2"
                :href="getAdditionalLinks[1]"
                target="_blank"
                >{{ getAdditionalLinks[0] }}</a
              >
              <a
                class="text-muted px-3"
                :href="getAdditionalLinks[3]"
                target="_blank"
                >{{ getAdditionalLinks[2] }}</a
              >
            </div>
            <template v-if="!customization.HIDE_POWERED_BY">
              <a
                class="text-muted text-filecloud"
                :href="fcLink"
                target="_blank"
              >
                {{ $t('Powered by ')
                }}<span class="text-primary">{{ fcLabel }}</span>
              </a>
            </template>
            <TOSLink v-if="isHostedCloud" />
          </div>
        </div>
      </div>

      <div
        v-if="customization"
        class="col-lg-6 d-none d-lg-block bg-card vh-100"
      >
        <img
          v-if="!isMobile"
          :src="background"
          class="login__main-bg main-bg"
          :alt="customization.PRODUCT_NAME"
        />
        <a
          v-if="communityEdition"
          href="https://ce.filecloud.com/"
          target="_blank"
        >
          <CommunityEditionLogo class="community-logo" />
        </a>
      </div>
    </div>

    <vue-headful v-if="customization" :title="`${customization.WINDOWTITLE}`" />
  </div>
</template>

<script>
import LoginForm from '../components/Forms/LoginForm';
import Icon from 'common/components/Icon.vue';
import TOSLink from 'common/components/Login/TOSLink';
import LanguageSwitcher from '../components/Settings/LanguageSwitcher';
import PageNames from '../constants/pageNames';
import serverURL from 'common/filecloud/properties/serverURL';
import logo from '../mixins/logo.js';
import responsivenessMixin from '@/mixins/responsivenessMixin';
import { mapGetters } from 'vuex';
import CommunityEditionLogo from 'common/assets/community-edition.svg';

export default {
  name: 'Auth',
  components: {
    LoginForm,
    Icon,
    TOSLink,
    LanguageSwitcher,
    CommunityEditionLogo,
  },
  mixins: [logo, responsivenessMixin],
  data() {
    return {
      pages: PageNames,
      formHeight: null,
    };
  },
  computed: {
    ...mapGetters('core', ['isHostedCloud']),
    currentPage() {
      return this.$store.state.auth.pageId;
    },
    customization() {
      return this.$store.state.core.customization;
    },
    page() {
      const pageID = this.currentPage;
      let page;
      switch (pageID) {
        case 'ssoDirect':
          page = 'ssoDirect';
          break;
        case 'logintwofa':
          page = 'logintwofa';
          break;
        case 'twofausersetupsms':
          page = 'twofausersetupsms';
          break;
        case 'twofausersetupsmsverification':
          page = 'twofausersetupsmsverification';
          break;
        case 'twofausersetupduo':
          page = 'twofausersetupduo';
          break;
        case 'twofausersetuptotp':
          page = 'twofausersetuptotp';
          break;
        case 'twofausersetup':
          page = 'twofausersetup';
          break;
        case 'securedshare':
          page = 'securedshare';
          break;
        case 'tos':
          page = 'tos';
          break;
        default:
          page = this.currentPage;
      }
      return page;
    },
    notCurrentPage() {
      return this.currentPage === 'newAccount' ? 'login' : 'newAccount';
    },
    fcLink() {
      return !this.communityEdition
        ? 'https://www.filecloud.com/'
        : 'https://ce.filecloud.com/';
    },
    fcLabel() {
      return 'FileCloud';
    },
    currentPageComp() {
      let comp = this.pages[this.page].compName;
      if (comp == 'LoginForm') return 'LoginForm';
      if (comp == 'TermsOfService') {
        return () => import('../pages/TermsOfService');
      }
      return () => import(`../components/Forms/${comp}`);
    },
    showSecondaryLink() {
      if (!this.showNewAccount) return false;
      return (
        this.currentPage === 'login' ||
        this.currentPage === 'newAccount' ||
        this.currentPage === 'auth'
      );
    },
    showNewAccount() {
      if (this.customization) {
        return this.customization.showNewAccount();
      }
      return false;
    },
    background() {
      if (this.customization) {
        return this.customization.LOGINBG &&
          typeof this.customization.LOGINBG == 'string'
          ? serverURL.domainURL + this.customization.LOGINBG
          : this.images('./' + 'aurora.jpg');
      }
      return '';
    },
    communityEdition() {
      return this.customization && this.customization.isCommunityEdition();
    },
    getAdditionalLinks() {
      let additionalLink = [];
      let links = this.customization.ADDITIONALLOGINURLLINKS;
      if (this.customization && links && typeof links == 'string') {
        const regex = /\[(.*?)\]\((.*?)\)/g;
        const found = links.trim().match(regex);
        const linkscount = found ? (found.length > 2 ? 2 : found.length) : 0; //allow max of 2 links
        for (let i = 0; i < linkscount; i++) {
          additionalLink = additionalLink.concat(
            found[i].slice(1, -1).split('](')
          );
        }
      }
      return additionalLink;
    },
    msTeams() {
      return this.$store.state.auth.msteams;
    },
  },
  mounted() {
    const noRedirectPages = ['forceResetPassword', 'ssoDirect', 'tos']
    if (!noRedirectPages.includes(this.currentPage)) {
      this.$store.dispatch('auth/gotoPage', this.$route.name);
    }
    this.formHeight = this.$refs['content-wrapper'].clientHeight + 'px';
    this.checkQuery();
  },
  methods: {
    switchCurrentPage(pageId) {
      if (typeof pageId === 'string') {
        this.$store.state.auth.pageId = pageId;
      } else {
        this.$store.state.auth.pageId = this.notCurrentPage;
      }
    },
    switchToTwoFaPage(pageName) {
      this.$store.state.auth.pageId = pageName;
    },
    loginMethod(msg) {
      this.$store.state.auth.pageId = msg;
    },
    transitionBeforeEnter: function () {
      this.formHeight = 'auto';
    },
    transitionEnter(el, done) {
      this.formHeight = el.clientHeight + 'px';
      done();
    },
    checkQuery() {
      // #todo: Hash mode not passing params/query strings
      const query = new URLSearchParams(window.location.search);
      if (query.get('cmd') === 'verifyresetpassword') {
        this.$store.dispatch('auth/gotoPage', 'resetPassword');
      }
      if (query.get('shownewaccount') === '1') {
        this.switchCurrentPage('newAccount');
      }
      this.$store.state.auth.msteams = query.get('msteams');
    },
  },
};
</script>
<style>
.lang-text {
  color: #455c6b;
}
.community-logo {
  position: absolute;
  right: 38px;
  bottom: 32px;
}

.text-filecloud {
  font-size: 13px;
}
</style>
