<template>
  <div>
    <div v-if="!advancedActive">
      <InputBox
        :id="'subject'"
        v-model="form.subject"
        :label="$t('Subject')"
        :errors="errors"
        :focus="!advancedActive"
      />
      <Editor
        v-model="form.customMessage"
        class="form-group col-sm"
        :label="$t('Message')"
        :placeholder="$t(form.subject)"
      />
    </div>
    <div v-else>
      <div class="tab-wrapper">
        <div class="tab-header">
          <div
            v-if="existingUserTab"
            class="tab-header-title"
            :class="{ active: activeTab === 'existing' }"
            @click="existingTabSwitch()"
          >
            {{ $t('Existing Users') }}
          </div>
          <div
            v-if="newUserTab"
            class="tab-header-title"
            :class="{ active: activeTab === 'new' }"
            @click="activeTabSwitch()"
          >
            {{ $t('New Users') }}
          </div>
          <div
            v-if="existingLimitedUsersTab"
            class="tab-header-title"
            :class="{ active: activeTab === 'external' }"
            @click="existingLimitedTabSwitch()"
          >
            {{ $t('External Users') }}
          </div>
          <div
            v-if="newLimitedUsersTab"
            class="tab-header-title"
            :class="{ active: activeTab === 'newexternal' }"
            @click="newLimitedTabSwitch()"
          >
            {{ $t('New External Users') }}
          </div>
        </div>
      </div>
      <div v-if="activeTab === 'existing'" class="tab-content">
        <SelectUser
          v-if="loaded"
          :form="form"
          :label="$t('To')"
          :placeholder="$t('Search Users...')"
          :callback-hit="pushInviters"
          :callback-remove="removeInviter"
          :users="existingUsers"
          :auto-fill="true"
          :type="'tag'"
        />

        <InputBox
          id="subjectExisting"
          v-model="form.subjectExisting"
          :label="$t('Subject')"
          :placeholder="$t(form.subjectExisting)"
          :errors="errors"
          :focus="true"
        />
        <Editor
          v-model="form.content"
          class="form-group col-sm"
          :value="form.content"
          :show-toolbar="true"
          :label="$t('Message')"
        />
      </div>
      <div v-if="activeTab === 'new'" class="tab-content">
        <SelectUser
          v-if="loaded"
          :form="form"
          :label="$t('To')"
          :placeholder="$t('Search Users...')"
          :callback-hit="pushInviters"
          :callback-remove="removeInviter"
          :users="newUsers"
          :auto-fill="true"
          :type="'tag'"
        />

        <InputBox
          id="subjectNew"
          v-model="form.subjectNew"
          :label="$t('Subject')"
          :placeholder="$t(form.subjectNew)"
          :errors="errors"
          :focus="true"
        />
        <Editor
          v-model="form.contentNew"
          :value="form.contentNew"
          :show-toolbar="true"
          :label="$t('Message')"
        />
      </div>
      <div v-if="activeTab === 'external'" class="tab-content">
        <SelectUser
          v-if="loaded"
          :form="form"
          :label="$t('To')"
          :placeholder="$t('Search Users...')"
          :callback-hit="pushInviters"
          :callback-remove="removeInviter"
          :users="existingLimitedUsers"
          :auto-fill="true"
          :type="'tag'"
        />

        <InputBox
          id="subjectLimitedUsers"
          v-model="form.subjectExistingLimitedUsers"
          :label="$t('Subject')"
          :placeholder="$t(form.subjectExistingLimitedUsers)"
          :errors="errors"
          :focus="true"
        />
        <Editor
          v-model="form.contentExistingLimitedUsers"
          :value="form.contentExistingLimitedUsers"
          :show-toolbar="true"
          :label="$t('Message')"
        />
      </div>
      <div v-if="activeTab === 'newexternal'" class="tab-content">
        <SelectUser
          v-if="loaded"
          :form="form"
          :label="$t('To')"
          :placeholder="$t('Search Users...')"
          :callback-hit="pushInviters"
          :callback-remove="removeInviter"
          :users="newLimitedUsers"
          :auto-fill="true"
          :type="'tag'"
        />

        <InputBox
          id="subjectNewLimitedUsers"
          v-model="form.subjectNewLimitedUsers"
          :label="$t('Subject')"
          :placeholder="$t(form.subjectNewLimitedUsers)"
          :errors="errors"
          :focus="true"
        />
        <Editor
          v-model="form.contentNewLimitedUsers"
          :value="form.contentNewLimitedUsers"
          :show-toolbar="true"
          :label="$t('Message')"
        />
      </div>
    </div>
  </div>
</template>
<script>
import InputBox from 'common/components/InputBox';
import SelectUser from 'common/components/SelectUsers';
import Editor from 'common/components/Editor';
import serverURL from 'common/filecloud/properties/serverURL';
import Vue from 'vue';
import wysiwyg from 'vue-wysiwyg';

export default {
  components: {
    InputBox,
    Editor,
    SelectUser,
  },
  props: {
    users: {
      type: Array,
      required: true,
    },
    form: {
      type: Object,
      default: function () {},
    },
    advancedActive: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      errors: {},
      query: '',
      loaded: false,
      newUserTab: false,
      existingUserTab: false,
      existingLimitedUsersTab: false,
      newLimitedUsersTab: false,
      receivers: [],
      newUsers: [],
      existingUsers: [],
      existingLimitedUsers: [],
      newLimitedUsers: [],
      templates: [
        'addusertoshare',
        'addnewusertoshare',
        'addusertoshare_limited',
        'addnewusertoshare_limited',
      ],
      activeTab: 'existing',
      menubar: [
        ['bold', 'italic', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['image', 'code-block'],
      ],
    };
  },
  computed: {
    shareProperties() {
      return this.$store.state.share.shareProperties;
    },
  },
  created() {
    Vue.use(wysiwyg, {
      hideModules: { image: true },
    });
    setTimeout(() => {
      this.receivers = this.users;
      for (const userObj of this.receivers.entries()) {
        let user = userObj[1] || userObj;
        if (user.new) {
          this.newUsers.push(user);
        } else if (user.emailtemplate == this.templates[0]) {
          this.existingUsers.push(user);
        } else if (user.emailtemplate == this.templates[2]) {
          this.existingLimitedUsers.push(user);
        } else if (user.emailtemplate == this.templates[3]) {
          this.newLimitedUsers.push(user);
        }
      }
      this.newUserTab = this.newUsers && this.newUsers.length;
      this.existingUserTab = this.existingUsers && this.existingUsers.length;
      this.existingLimitedUsersTab =
        this.existingLimitedUsers && this.existingLimitedUsers.length;
      this.newLimitedUsersTab =
        this.newLimitedUsers && this.newLimitedUsers.length;
      if (
        !this.existingUserTab &&
        (this.newUserTab ||
          this.existingLimitedUsersTab ||
          this.newLimitedUsersTab)
      ) {
        if (this.newUserTab) {
          this.activeTabSwitch();
        } else if (this.existingLimitedUsersTab) {
          this.existingLimitedTabSwitch();
        } else if (this.newLimitedUsersTab) {
          this.newLimitedTabSwitch();
        }
      }
      if (this.activeTab == 'existing') {
        this.existingTabSwitch();
      }
      this.form.activeTab = this.activeTab;
      this.loaded = true;
    }, 1000);
  },
  methods: {
    async activeTabSwitch() {
      this.loaded = false;
      await this.getEmailTemplateForNewUser();
      this.activeTab = 'new';
      this.form.activeTab = this.activeTab;
      this.form.invitedEmails = this.form.newEmail;
      this.loaded = true;
    },
    async existingTabSwitch() {
      this.loaded = false;
      await this.getEmailTemplateForSharedUser();
      this.activeTab = 'existing';
      this.form.activeTab = this.activeTab;
      this.form.invitedEmails = this.form.existingEmail;
      this.loaded = true;
    },
    async existingLimitedTabSwitch() {
      this.loaded = false;
      await this.getEmailTemplateForLimitedUser();
      this.activeTab = 'external';
      this.form.activeTab = this.activeTab;
      this.form.invitedEmails = this.form.existingLimitedEmail;
      this.loaded = true;
    },
    async newLimitedTabSwitch() {
      this.loaded = false;
      await this.getEmailTemplateForNewLimitedUser();
      this.activeTab = 'newexternal';
      this.form.activeTab = this.activeTab;
      this.form.invitedEmails = this.form.newLimitedEmail;
      this.loaded = true;
    },
    getProfileImage(username) {
      return serverURL.domainURL + '/core/getprofileimage?username=' + username;
    },
    removeInviter(selected) {
      switch (this.activeTab) {
        case 'existing':
          for (let i = 0; i < this.existingUsers.length; i++) {
            if (selected.emailid === this.existingUsers[i].emailid) {
              this.existingUsers.splice(i, 1);
            }
          }
          break;
        case 'new':
          for (let i = 0; i < this.newUsers.length; i++) {
            if (selected.emailid === this.newUsers[i].emailid) {
              this.newUsers.splice(i, 1);
            }
          }
          break;
        case 'external':
          for (let i = 0; i < this.existingLimitedUsers.length; i++) {
            if (selected.emailid === this.existingLimitedUsers[i].emailid) {
              this.existingLimitedUsers.splice(i, 1);
            }
          }
          break;
        case 'newexternal':
          for (let i = 0; i < this.newLimitedUsers.length; i++) {
            if (selected.emailid === this.newLimitedUsers[i].emailid) {
              this.newLimitedUsers.splice(i, 1);
            }
          }
          break;
        default:
          break;
      }
    },
    pushInviters(selected) {
      let exists = false;

      for (let i = 0; i < this.receivers.length; i++) {
        if (selected.emailid === this.receivers[i].emailid) {
          exists = true;
        }
      }

      setTimeout(() => {
        if (!exists) {
          this.receivers.push(selected);
        }

        this.query = '';
      }, 200);
    },
    testHTML() {
      var win = window.open(
        '',
        'Title',
        'toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=522,height=490,top=' +
          (screen.height - 800) +
          ',left=' +
          (screen.width - 1200)
      );
      win.document.body.innerHTML = this.form.content;
    },
    testExportHTML() {
      var win = window.open(
        '',
        'Title',
        'toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=1022,height=490,top=' +
          (screen.height - 800) +
          ',left=' +
          (screen.width - 1200)
      );
      win.document.body.innerHTML = `<xmp>${this.form.content}</xmp>`;
    },
    async getEmailSubject(param) {
      if (this.users.length > 0) {
        let response = await this.$store.dispatch('share/getEmailSubject', {
          param: 'CUSTOMIZATION_EMAIL_SUBJECT_' + param,
          toemailid: this.users[0].emailid,
        });
        if (response.ok) {
          switch (param) {
            case this.templates[0]:
              this.form.subjectExisting = response.data.setting.value;
              this.form.subject = this.form.subjectExisting;
              break;
            case this.templates[1]:
              this.form.subjectNew = response.data.setting.value;
              this.form.subject = this.form.subjectNew;
              break;
            case this.templates[2]:
              this.form.subjectExistingLimitedUsers =
                response.data.setting.value;
              this.form.subject = this.form.subjectExistingLimitedUsers;
              break;
            case this.templates[3]:
              this.form.subjectNewLimitedUsers = response.data.setting.value;
              this.form.subject = this.form.subjectNewLimitedUsers;
              break;
          }
        }
      }
    },
    async getEmailTemplateForSharedUser() {
      this.getEmailSubject(this.templates[0]);
      if (this.users.length > 0 && !this.form.content) {
        let template = await this.$store.dispatch('share/getEmailTemplate', {
          templatename: 'CUSTOMIZATION_TEMPLATE_addusertoshare_body_tmpl.txt',
          keepuserplaceholders: 1,
          sharename: this.shareProperties.sharename,
          shareurl: this.shareProperties.shareurl,
        });
        if (template.ok) {
          this.form.content = template.data;
        }
      }
    },
    async getEmailTemplateForNewUser() {
      this.getEmailSubject(this.templates[1]);
      if (this.users.length > 0 && !this.form.contentNew) {
        let template = await this.$store.dispatch('share/getEmailTemplate', {
          templatename:
            'CUSTOMIZATION_TEMPLATE_addnewusertoshare_body_tmpl.txt',
          keepuserplaceholders: 1,
          sharename: this.shareProperties.sharename,
          shareurl: this.shareProperties.shareurl,
        });
        if (template.ok) {
          this.form.contentNew = template.data || this.form.subject;
        }
      }
    },
    async getEmailTemplateForLimitedUser() {
      this.getEmailSubject(this.templates[2]);
      if (this.users.length > 0 && !this.form.contentExistingLimitedUsers) {
        let template = await this.$store.dispatch('share/getEmailTemplate', {
          templatename:
            'CUSTOMIZATION_TEMPLATE_addusertoshare_body_limited_tmpl.txt',
          keepuserplaceholders: 1,
          sharename: this.shareProperties.sharename,
          shareurl: this.shareProperties.shareurl,
        });
        if (template.ok) {
          this.form.contentExistingLimitedUsers =
            template.data || this.form.subject;
        }
      }
    },
    async getEmailTemplateForNewLimitedUser() {
      this.getEmailSubject(this.templates[3]);
      if (this.users.length > 0 && !this.form.contentNewLimitedUsers) {
        let template = await this.$store.dispatch('share/getEmailTemplate', {
          templatename:
            'CUSTOMIZATION_TEMPLATE_addnewusertoshare_body_limited_tmpl.txt',
          keepuserplaceholders: 1,
          sharename: this.shareProperties.sharename,
          shareurl: this.shareProperties.shareurl,
        });
        if (template.ok) {
          this.form.contentNewLimitedUsers = template.data || this.form.subject;
        }
      }
    },
  },
};
</script>
