import _ from 'lodash';
import sanitize from 'sanitize-filename';
import qs from 'query-string';

import { asyncForEach } from './objects.js';

export function getFileType(name) {
  const map = [
    {
      name: 'pdf',
      extensions: ['pdf'],
    },
    {
      name: 'ai',
      extensions: ['ai'],
    },
    {
      name: 'cad',
      extensions: [
        '3dm',
        '3ds',
        'a',
        'asm',
        'asm',
        'asm',
        'brd',
        'catpart',
        'catproduct',
        'cgr',
        'collaboration',
        'dae',
        'dgn',
        'dlv3',
        'dwf',
        'dwfx',
        'dwg',
        'dwt',
        'dxf',
        'emode',
        'exp',
        'f2d	',
        'dwg',
        'f3d',
        'fbx',
        'g',
        'gbxml',
        'glb',
        'gltf',
        'iam',
        'idw',
        'ifc',
        'ige',
        'iges',
        'igs',
        'ipt',
        'iwm',
        'jt',
        'max',
        'model',
        'mpf',
        'msr',
        'neu',
        'neu',
        'nwc',
        'nwd',
        'obj',
        'par',
        'pdf',
        'pmlprj',
        'pmlprjz',
        'prt',
        'prt',
        'psm',
        'psmodel',
        'rvt',
        'sab',
        'sat',
        'sch',
        'session',
        'skp',
        'sldasm',
        'sldprt',
        'ste',
        'step',
        'stl',
        'stla',
        'stlb',
        'stp',
        'stpz',
        'vue',
        'wire',
        'x_b',
        'x_t',
        'xas',
        'xpr',
      ],
    },
    {
      name: 'video',
      extensions: ['mp4', 'webm', 'ogv', 'm4v', 'mpg', 'wmv', 'avi', 'mov'],
    },
    {
      name: 'audio',
      extensions: ['mp3', 'ogg', 'wav', 'm4a'],
    },
    {
      name: 'img',
      extensions: [
        'png',
        'jpg',
        'jpeg',
        'gif',
        'jfif',
        'tif',
        'tiff',
        'psd',
        'sgi',
        'tga',
      ],
    },
    {
      name: 'zip',
      extensions: ['zip', 'rar', '7z', 'gz'],
    },
    {
      name: 'txt',
      extensions: ['txt', 'md', 'html', 'htm', 'log'],
    },
    {
      name: 'word',
      extensions: ['doc', 'docx', 'odt', 'odg'],
    },
    {
      name: 'excel',
      extensions: ['xls', 'xlsx', 'xlsm', 'ods'],
    },
    {
      name: 'powerpoint',
      extensions: ['ppt', 'pptx', 'odp'],
    },
    {
      name: 'dicom',
      extensions: ['dcm'],
    },
    {
      name: 'visio',
      extensions: ['vsd', 'vsdx'],
    },
    // { // commented due to the issue with safari browsers
    //   name: 'eml',
    //   extensions: ['eml'],
    // },
  ];

  if (name !== '') {
    const fileName = name.toString().split('.');
    let extension = fileName[fileName.length - 1];
    if (extension.indexOf('_') !== -1) {
      extension = extension.replace(/_.*$/, ''); //remove anything after '_' in the extension - added in backend for path collisions
    }

    const format = _.find(map, { extensions: [extension.toLowerCase()] });

    if (format) {
      return format.name;
    }
  }

  return 'unknown';
}

// check if it's possible to edit a certain file extension
export function canEditFileType(ext, platform, allowQuickEditAll = false) {
  if (allowQuickEditAll && platform === 'desktop') return true;

  const map = {
    desktop: [
      'txt',
      'md',
      'doc',
      'docx',
      'ppt',
      'pptx',
      'xls',
      'xlsx',
      'pub',
      'one',
      'xlsb',
      'xlsm',
      'dotx',
      'pst',
      'mpp',
      'pptm',
      'vsd',
      'xltx',
      'ppsx',
      'xlam',
      'docm',
      'dot',
      'ppsm',
      'potx',
      'pps',
      'mpt',
      'xlw',
      'xlb',
      'dotm',
      'pot',
      'xlt',
      'svd',
      'vss',
      'mpd',
      'accdt',
      'xltm',
      'xl',
      'xlm',
      'adp',
      'cst',
      'mdt',
      'mdw',
      'xsf',
      'sldx',
      'vdx',
      'ppa',
      'xlc',
      'pdf',
      'rvt',
      'odt',
      'odg',
      'odp',
      'ods',
      'idd',
      'psd',
      'msg',
      'sql',
    ],
    collabora: [
      'ods',
      'xlsb',
      'xlsm',
      'xlsx',
      'odp',
      'ppsx',
      'pptx',
      'pptm',
      'vsdx',
      'docm',
      'docx',
      'odt',
      'fodt',
      'fods',
      'fodp',
      'odg',
      'fodg',
      'odc',
      'odm',
      'wopitest',
    ],
    office: ['docx', 'pptx', 'xlsx', 'xlsm', 'odt', 'odg', 'ods', 'csv', 'odp'],
    web: ['txt', 'md', 'html', 'htm', 'log'],
    onlyoffice: [
      'docx',
      'pptx',
      'xlsx',
      'xlsm',
      'odt',
      'rtf',
      'ods',
      'csv',
      'odp',
    ],
    google: ['docx', 'odt', 'odg', 'xlsx', 'xlsm', 'ods', 'pptx', 'odp'],
  };
  return (
    typeof ext == 'string' &&
    map[platform] &&
    map[platform].indexOf(ext.toLowerCase()) > -1
  );
}

export function isExtensionBlackListed(extension) {
  let retVal = false;
  const blacklist = ['exe', 'msi', 'bin', 'command', 'sh', 'bat', 'crx', 'bash', 'csh', 'fish', 'ksh', 'zsh'];
  if (blacklist.includes(extension)) {
    retVal = true;
  }
  return retVal;
}

// applies the /wiki.get path to the images
export function applyWikiImages(html, path) {
  let tmp = document.createElement('DIV');
  tmp.innerHTML = html;

  // parse images
  let images = tmp.getElementsByTagName('IMG');

  for (var i = 0; i < images.length; i++) {
    let image = images[i].attributes[0].value;

    // if it's not an external image
    if (!isValidURL(image)) {
      images[i].attributes[0].value = `${
        process.env.NODE_ENV === 'development' ? '/api' : ''
      }/core/wiki.get${path}/${image}?redirect=0`;
    }
  }

  return tmp.innerHTML;
}

export function isValidURL(str) {
  var pattern = new RegExp(
    '^(https?:\\/\\/)' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ); // fragment locator
  return !!pattern.test(str);
}

export function isOffice(ext) {
  const officeFileTypes = ['word', 'powerpoint', 'excel'];
  return officeFileTypes.indexOf(ext) > -1;
}

export function getFileExtension(name) {
  const fileName = name.split('.');
  return fileName[fileName.length - 1];
}

// load script to page
export async function loadScript(url) {
  return new Promise((resolve) => {
    let script = document.createElement('script');
    script.onload = () => {
      resolve(true);
    };
    script.async = true;
    script.src = url;
    document.head.appendChild(script);
  });
}

// returns base64 image if file is compatible
export function parseFileThumb(file) {
  return new Promise((resolve) => {
    const fileName = file.name;

    // check if file is valid for thumb generation
    if (isImg(fileName)) {
      let reader = new FileReader();

      reader.onloadend = function () {
        resolve(reader.result);
      };

      reader.readAsDataURL(file);
    } else {
      resolve('');
    }
  });
}

export function isImg(fileName) {
  if (!fileName) return false;

  const format = fileName.split('.');
  const extension = format[format.length - 1].toLowerCase();

  const extensions = ['gif', 'png', 'jpg', 'jpeg'];

  return extensions.indexOf(extension) > -1 ? extension : null;
}

export function sanitizeFileName(name) {
  return sanitize(name);
}

// return rich files list (with thumbs!)
export async function retrieveFiles(files) {
  let output = [];

  await asyncForEach(files, async (file) => {
    // if it's a unique file
    if (!_.find(output, { name: sanitizeFileName(file.name) })) {
      // if it's an actual file, process it
      if (file instanceof File) {
        const blob = file.slice(0, file.size, file.type);
        file = new File([blob], sanitizeFileName(file.name), {
          type: file.type,
        });

        let thumb = await parseFileThumb(file);

        if (thumb) {
          file.thumb = thumb;
        }
      }

      output.push(file);
    }
  });

  return output;
}

export function chunkFile(file) {
  // changed to 1000 instead of 1024
  var chunkSize = 1000 * 1000 * 10; //5 MB Chunk size
  var fileSize = file.size;
  var currentChunk = 1;
  var totalChunks = Math.ceil(fileSize / chunkSize, chunkSize);

  var chunks = [];

  while (currentChunk <= totalChunks) {
    var offset = (currentChunk - 1) * chunkSize;
    var currentFilePart = file.slice(offset, offset + chunkSize);

    chunks.push(
      new File([currentFilePart], sanitizeFileName(file.name), {
        type: file.type,
      })
    );

    currentChunk++;
  }

  return chunks;
}

export function traverseFileTree(item, path = '', callback) {
  path = path || '';
  if (item.isFile) {
    // Get file
    item.file(function (file) {
      callback({ file, path });
    });
  } else if (item.isDirectory) {
    // Get folder contents
    var dirReader = item.createReader();
    dirReader.readEntries(function (entries) {
      for (var i = 0; i < entries.length; i++) {
        traverseFileTree(entries[i], path + item.name + '/', callback);
      }
    });
  }
}

export function getFileFromEntry(entry) {
  return new Promise((resolve) => {
    entry.file((file) => {
      resolve(file);
    });
  });
}

export function getEntriesFromFolder(folder) {
  return new Promise((resolve) => {
    const dirReader = folder.createReader();
    let allEntries = [];

    // read path recursively to get all entries
    const readFunction = (entries) => {
      if (entries.length === 0) {
        resolve(allEntries);
      } else {
        for (let i = 0; i < entries.length; i++) {
          allEntries.push(entries[i]);
        }
        dirReader.readEntries(readFunction);
      }
    };

    // execute path reading
    dirReader.readEntries(readFunction);
  });
}

// get files from event
export function getFilesFromEvent(e) {
  let files = [];

  let droppedItems = e.dataTransfer.items;

  for (var i = 0; i < droppedItems.length; i++) {
    var item = droppedItems[i].webkitGetAsEntry();

    if (item) {
      traverseFileTree(item, null, ({ file }) => {
        files.push(file);
        // console.log("GOT FILE", path, file.name)
      });
    }
  }

  return files;
}

export function bytesToSize(bytes, decimals = 2) {
  if (bytes == 0) return '0 Bytes';
  var k = 1024,
    dm = decimals <= 0 ? 0 : decimals || 2,
    sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
    i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export function bytesToSpeed(bytes, decimals = 2) {
  if (bytes == 0) return '0 Bps';
  var k = 1024,
    dm = decimals <= 0 ? 0 : decimals || 2,
    sizes = [
      'Bps',
      'Kbps',
      'Mbps',
      'Gbps',
      'Tbps',
      'Pbps',
      'Ebps',
      'Zbps',
      'Ybps',
    ],
    i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export function getParams() {
  const query =
    window.location.search || window.location.href.split('?')[1] || '';
  return qs.parse(query);
}

export function readCookie(name) {
  var nameEQ = name + '=';
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

export function readTextFromBlobAsync(blob) {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();

    reader.onloadend = () => {
      resolve(reader.result);
    };

    reader.onerror = reject;
    reader.readAsText(blob);
  });
}

export function sendUnlockFileBeacon(path) {
  if (!navigator.sendBeacon) return;

  const urlPath = '/api/core/unlock';
  const formData = new FormData();

  formData.append('path', path);

  navigator.sendBeacon(urlPath, formData);
}

export function getTimeout(interval) {
  // A very heavy timeout
  return new Promise((resolve, reject) => {
    setTimeout(function () {
      resolve();
    }, interval);
  });
}

export function getViewerForZipNestedPreview(filetype = '') {
  const map = [
    {
      name: 'Office',
      types: ['word', 'excel', 'powerpoint'],
    },
    {
      name: 'Img',
      types: ['img'],
    },
    {
      name: 'Pdf',
      types: ['pdf'],
    },
    {
      name: 'Txt',
      types: ['txt'],
    },
  ];

  const preview = _.find(map, { types: [filetype.toLowerCase()] });

  if (preview) {
    return preview.name;
  }

  return 'DefaultView';
}

export function getFileNameFromPath(path = '') {
  return path.split('/').pop() || '';
}
