import fileListItemMixin from 'mixins/fileListItemMixin';
import lockReadMixin from 'mixins/lockReadMixin';
import responsivenessMixin from 'mixins/responsivenessMixin';
import Themed from './../../Themed';
import FileListItemColorDot from '../FileListItemColorDot';
import LockIcon from '../partials/LockIcon';
import ContextMenu from '../ContextMenu';
import fileActions from 'constants/fileActions';
import { PREVIEWABLEDOC_CLOUD } from 'common/utils/fileExtensions';
import InputBox from 'common/components/InputBox';
import DropDownList from 'common/components/DropDownList';
import DateDisplay from 'common/components/DateDisplay';
import GoogleSheets from 'common/assets/icons/fcgoogle-sheets.svg';
import GoogleDocs from 'common/assets/icons/fcgoogle-docs.svg';
import GoogleSlides from 'common/assets/icons/fcgoogle-slides.svg';
import OfficeOnline from 'common/assets/office.svg';
import Checkbox from 'common/components/Checkbox';
import FileIcon from 'common/components/FileIcon';
import Icon from 'common/components/Icon';
import Alert from 'common/components/Alert';
import { sizes, thumbnailMap } from 'constants/sizes';
import 'vue-context/dist/css/vue-context.css';
import { canEditFileType, getParams, getFileType } from 'common/utils/files';
import { WOPIClients, GoogleDocsFormats } from 'constants/WOPI.js';
import { Drag, Drop } from 'vue-drag-drop';
import { VPopover } from 'v-tooltip';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import _ from 'lodash';
import { mapGetters, mapState, mapActions } from 'vuex';
dayjs.extend(relativeTime);
import FileItemStarAdd from './FileItemStarAdd';
import FileItemInfoIcons from './FileItemInfoIcons';
import FileItemOptionsContextMenu from './FileItemOptionsContextMenu';

import * as microsoftTeams from '@microsoft/teams-js';

export default {
  components: {
    LockIcon,
    FileItemStarAdd,
    FileItemInfoIcons,
    FileItemOptionsContextMenu,
    Drag,
    Drop,
    Checkbox,
    FileIcon,
    Icon,
    Themed,
    Alert,
    ContextMenu,
    FileListItemColorDot,
    InputBox,
    VPopover,
    DropDownList,
    DateDisplay,
    GoogleSheets,
    GoogleDocs,
    GoogleSlides,
    OfficeOnline,
  },
  mixins: [fileListItemMixin, responsivenessMixin, lockReadMixin],
  props: {
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
    isPicker: Boolean,
    // currently selected items
    selection: {
      type: Array,
      default: () => {
        return [];
      },
    },
    expanded: {
      type: Boolean,
      default: false,
    },
    hasCustomList: {
      type: Boolean,
      default: false,
    },
    hasSearchResults: {
      type: Boolean,
      default: false,
    },
    hasSelection: {
      type: Boolean,
      default: false,
    },
    isSharedByMe: {
      type: Boolean,
      default: false,
    },
    isPublicShare: {
      type: Boolean,
      default: false,
    },
    isBackupFolderSibling: {
      type: Boolean,
      default: false,
    },
    disableHover: {
      type: Boolean,
      default: false,
    },
    disableHoverContext: {
      type: Boolean,
      default: false,
    },
    fromHome: {
      type: Boolean,
      default: false,
    },
    isKbFocused: {
      type: Boolean,
      default: false,
    },
    isCompactMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isDragActive: false,
      clickTimeout: null,
      isRenaming: false,
      newName: '',
      isDeleting: false,
      isInRecycleFolder: false,
      hasFocus: false,
      directLink: '',
      draggingOver: false,
      counter: 0,
      showDelConf: false,
      isEllipsisActive: false,
      fileActions,
      encryptedFolderPassword: '',
      renameShare: false,
      isEncryptedFolderAlertEnabled: false,
      passwordType: 'password',
      isSharingRootFolder: false,
      currentEncryptedFolderOp: 'preview',
      renameFromContext: false,
      renameItem: false,
      renameShareOut: false,
      isParentValidEncryptedFolder: false,
      WOPIClients,
      GoogleDocsFormats,
    };
  },
  computed: {
    ...mapGetters('core', [
      'isLockDisabled',
      'WOPIClient',
      'isGoogleDocsEnabled',
      'isWOPIEditEnabled',
      'isWebEditEnabled',
      'getEditorClientName',
      'isLimitedUser',
      'allowQuickEditAll',
    ]),
    ...mapGetters('files', ['getLayout', 'getIsGrid']),
    ...mapState('files', ['layoutType']),
    hoverMenu() {
      return (
        !this.isPicker &&
        !this.isMobile &&
        !this.isTablet &&
        !this.disableHoverContext
      );
    },
    sizeInfo() {
      return (
        !this.hasCustomList &&
        !this.getIsGrid &&
        !this.isMobile &&
        !this.isPicker
      );
    },
    searchSizeInfo() {
      return (
        this.hasCustomList &&
        this.hasSearchResults &&
        !this.getIsGrid &&
        !this.isMobile &&
        !this.isPicker
      );
    },
    searchLastModifyInfo() {
      return (
        this.hasCustomList &&
        this.hasSearchResults &&
        typeof this.item.modified == 'string' &&
        !this.isMobile
      );
    },
    desktopEditorClientName() {
      return this.getEditorClientName(this.item);
    },
    docPreview() {
      return (
        !this.customization.ENABLEDOCPREVIEW &&
        PREVIEWABLEDOC_CLOUD.includes(this.item.ext)
      );
    },
    mobileMenu() {
      return this.$store.getters['files/getMobileMenuState']();
    },
    msTeams() {
      return this.$store.state.auth.msteams;
    },
    highlightText() {
      return this.item.highlight
        .replace(/<hlt[^>]*>/g, '<strong>')
        .replace(/<\/hlt>/g, '</strong>');
    },
    hasHighlight() {
      return !_.isEmpty(this.item.highlight);
    },
    hasDirectLink() {
      // If secure flag is set,
      // then password protected share view
      // Does not have direct link when in password protected
      // Disable DL then
      if (!this.item.fullfilename) return;
      let { secure } = getParams();
      return (
        (this.item.fullfilename.startsWith('/EXTERNAL') ||
          this.isPublicShare ||
          this.item.fullfilename.startsWith('/SHARED')) &&
        _.isEmpty(secure) &&
        !this.shouldHideShareDirectLinkOption
      );
    },
    isShareDisabled() {
      return this.$store.state.core.systemstatus.SHAREMODE === 3;
    },
    shouldHideShareDirectLinkOption() {
      return (
        this.$store.state.core.systemstatus.HIDESHAREDIRECTLINKOPTION === 1
      );
    },
    uploading() {
      return this.$store.state.loading['file.upload'];
    },
    isDirectLinkReady() {
      return (
        this.directLink && !this.$store.state.loading['files/getDirectLink']
      );
    },
    user() {
      return this.$store.state.auth.user;
    },
    encryptedFolderPasswords() {
      return this.$store.state.files.encryptedFolderPasswords;
    },
    filesMeta() {
      return this.$store.state.files.filesMeta;
    },
    parentPath() {
      let dirpath =
        this.item.type === 'dir' && this.filesMeta.encryptedfolder === 0
          ? this.item.path
          : this.item.dirpath;

      if (!this.isPublicShare && !this.isSharedWithMe) {
        return this.item.type === 'dir' && this.filesMeta.encryptedfolder === 0
          ? dirpath
          : dirpath.substring(0, dirpath.length - 1);
      } else {
        return this.isPublicShare ? this.path : this.item.path;
      }
    },
    isSelected() {
      return _.findIndex(this.selection, { id: this.item.id }) > -1;
    },
    thumb() {
      return this.item.format && this.item.format === 'img' ? 'THUMB' : null;
    },
    thumbSize() {
      const thumbName = thumbnailMap[this.layoutType] || thumbnailMap['list'];
      return sizes[thumbName];
    },
    itemsSelected() {
      return this.selection ? this.selection.length : 0;
    },
    canEdit() {
      return this.$store.state.core.systemstatus.ENABLEWEBEDIT;
    },
    formWithAppends() {
      return {
        passwordAppends: [
          {
            icon: this.passwordType === 'password' ? 'eye' : 'eye-slash',
            iconBold: true,
            action: this.togglePasswordType,
          },
        ],
      };
    },
    isOffice() {
      if (!this.item || !this.item.format) return false;
      const officeFileTypes = ['word', 'powerpoint', 'excel'];
      return officeFileTypes.indexOf(this.item.format) > -1;
    },
    customization() {
      return this.$store.state.core.customization;
    },
    systemStatus() {
      return this.$store.state.core.fullSystemStatus;
    },
    metaKey() {
      return window.navigator.platform === 'MacIntel' ? 'Cmd' : 'Ctrl';
    },
    mobileDateFormat() {
      if (this.item.modifiedepochutc > 0) {
        return dayjs(this.item.modifiedepochutc * 1000).fromNow();
      } else return '';
    },
    validEncryptedFolderPass() {
      return (
        this.encryptedFolderPassword.length > 4 ||
        this.isParentValidEncryptedFolder
      );
    },
    backupPath() {
      return this.$store.state.core.systemstatus.BACKUPPATH;
    },
    isBackupFolder() {
      if (this.isBackupFolderSibling || this.hasCustomList)
        return this.item.fullfilename === this.backupPath;
      return false;
    },
    isSharedWithMeHome() {
      return this.$route.fullPath === '/expl-tabl./SHARED';
    },
    metaSets() {
      return this.$store.state.core.metaSets;
    },
    currentEncryptedHeader() {
      return this.$store.state.files.currentEncryptedHeader;
    },
    isLockedByOthers() {
      return !!(this.item.locked && this.item.lockuserid !== this.user.profile);
    },
    isImplicitlyLocked() {
      return this.item.implicitlock;
    },
    isReadLocked() {
      return this.isLockedByOthers && this.item.lockreadlock;
    },
    path() {
      const { pathMatch: path } = this.$router.history.current.params;
      return `/${path ? path : this.user.peerid}`;
    },
    isSharedWithMe() {
      return this.path.indexOf('/SHARED') > -1;
    },
    isNonSharedRootFolder() {
      return (
        this.item.isroot &&
        typeof this.item.isshared !== 'string' &&
        !this.isSharedWithMe
      );
    },
    encryptedFolderOperation() {
      let operation = {
        label: '',
        title: '',
        content: '',
      };

      switch (this.currentEncryptedFolderOp) {
        case 'preview':
          operation.label = this.$t('Open');
          operation.title = this.$t('Access Encrypted Content');
          operation.content = this.$t(
            'Enter your password to access encrypted content.<br/><br/>You will not be able to access the content if an incorrect password is provided.'
          );
          break;
        case 'download':
          operation.label = this.$t('Download');
          operation.title = this.$t('Download Encrypted Content');
          operation.content = this.$t(
            'Enter your password to download the encrypted content.<br/><br/>You will not be able to access the content if an incorrect password is provided.'
          );
          break;
        case 'delete':
          operation.label = this.$t('Delete');
          operation.title = this.$t('Delete Encrypted Content');
          operation.content = this.$t(
            'Enter your password to delete the encrypted content.<br/><br/>You will not be able to delete the content if an incorrect password is provided.'
          );
          break;
        case 'rename':
          operation.label = this.$t('Rename');
          operation.title = this.$t('Rename Encrypted Content');
          operation.content = this.$t(
            'Enter your password to rename the encrypted content.<br/><br/>You will not be able to rename the content if an incorrect password is provided.'
          );
          break;
        case 'share':
          operation.label = this.$t('Share');
          operation.title = this.$t('Share Encrypted Content');
          operation.content = this.$t(
            'Enter your password to share the encrypted content.<br/><br/>You will not be able to rename the content if an incorrect password is provided.'
          );
          break;
      }

      return operation;
    },
    googleAppsClient() {
      return this.GoogleDocsFormats[
        this.itemFormat(this.item.ext || this.item.name)
      ];
    },
    editorClientLength() {
      //this.isWebEditEnabled
      let clients = 0;

      // WOPI
      if (
        this.isWOPIEditEnabled &&
        this.WOPIClient &&
        canEditFileType(this.item.ext, this.WOPIClient.key)
      ) {
        clients++;
      }

      //Google Apps
      if (
        this.isGoogleDocsEnabled &&
        this.isOffice &&
        this.googleAppsClient &&
        this.isWOPIEditEnabled
      )
        clients++;

      //Web Edit
      if (this.canEditFileType(this.item.ext, 'web') && this.isWOPIEditEnabled)
        clients++;

      //Desktop edit
      if (
        this.isWebEditEnabled &&
        this.canEditFileType(
          this.item.ext,
          'desktop',
          this.allowQuickEditAll
        ) &&
        this.item.showquickedit
      )
        clients++;

      return clients;
    },
    communityEdition() {
      return this.customization && this.customization.isCommunityEdition();
    },
  },
  watch: {
    item(newItem, oldItem) {
      if (this.isDeleting && newItem.name !== oldItem.name) {
        this.isDeleting = false;
      }
    },
    'item.name'() {
      this.$nextTick(() => {
        this.isEllipsisActive = this.evaluateIsEllipsisActive();
      });
    },
    $route(to) {
      const nextPath = `/${to.params.pathMatch}`;
      this.isInRecycleFolder = nextPath.indexOf('recyclebin') > -1;
    },
    isKbFocused(isFocused) {
      if (isFocused) {
        this.$nextTick(() => {
          const elToFocus =
            this.$refs.selectCheckbox || this.$refs.titlendetails;
          elToFocus.focus();
        });
      }
    },
  },
  mounted() {
    this.isInRecycleFolder = this.$route.fullPath.indexOf('recyclebin') > -1;

    this.isEllipsisActive = this.evaluateIsEllipsisActive();
  },
  methods: {
    ...mapActions('files', [
      'openDeleteConfirmationModal',
      'closeDeleteConfirmationModal',
    ]),
    downloadPDF() {
      this.$store.dispatch('files/downloadPDF', this.item);
    },
    changeFocus(bool) {
      this.hasFocus = bool;
    },
    hijriDate({ modifiedepochutc, modifiediso }) {
      return typeof modifiedepochutc == 'number' && modifiedepochutc;
    },
    itemFormat(ext) {
      return getFileType(ext);
    },
    setIsDeleting(isDeleting) {
      this.isDeleting = isDeleting;
    },
    itemIcon({ encryptedfolder, locked }) {
      const encryptedIcon = encryptedfolder ? 'fas fa-key' : '';
      const lockedIcon = locked ? 'fas fa-lock' : '';
      const icon = encryptedIcon || lockedIcon;
      return icon;
    },
    separatePaths(item) {
      return item.path.split('/').filter((e) => e !== '');
    },
    setFocus(focused) {
      this.hasFocus = focused;
    },
    contextMenu(e) {
      this.hasFocus = true;
      this.$emit('context-menu', { e, entry: this });
    },
    async requestForDRMFile() {
      this.$store.dispatch('files/exportAsDRMFilesTX', {
        items: [this.item],
      });
    },
    async metaColor(color) {
      const payload = {
        fullpath: this.item.path,
        setid: this.metaSets.setId,
        attribute0_attributeid: this.metaSets.attributeId,
        attribute0_value: color.value,
        attributes_total: 1,
      };
      const response = await this.$store.dispatch(
        'files/saveMetadataAttr',
        payload
      );
      if (response.ok) {
        this.$set(this.item, 'colortag', color.value);
        const msg = this.item.type === 'dir' ? 'Folder' : 'File';
        const successMessage = this.$t('Success');
        this.$toast.open({
          message: `<b>${successMessage}</b>
                    <p role="alert">${msg} <b>${this.item.name}</b> is color tagged with <b>${color.label}</b></p>`,
          type: 'success',
        });
      } else {
        this.$toast.open({
          message: `<b>${this.$t('Error')}</b><p role="alert">${
            response.data.message
          }</p>`,
          type: 'error',
        });
      }
    },
    togglePasswordType() {
      this.passwordType =
        this.passwordType === 'password' ? 'text' : 'password';
    },
    async removeMetaColor() {
      const res = await this.$store.dispatch('files/removeMetadataSet', {
        path: this.item.path,
        setid: this.metaSets.setId,
      });
      if (res.ok) {
        const successMessage = this.$t('Success');
        const msg = this.item.type === 'dir' ? 'Folder' : 'File';
        this.$toast.open({
          message: `<b>${successMessage}</b>
                    <p role="alert">Color tag is removed from ${msg} <b>${this.item.name}</b></p>`,
          type: 'success',
        });
        this.item.colortag = 'transparent';
      } else {
        this.$toast.open({
          message: `<b>${this.$t('Error')}</b>
                    <p role="alert">${res.error}</p>`,
          type: 'error',
        });
      }
    },
    triggerAction(action, item, param) {
      this.hasFocus = false;
      this.$emit('toggleMoreOptions', false);
      if (!this[action]) {
        console.warn(`${action} not implemented`);
        return;
      }
      this[action](param);
    },
    toggleMoreOptions(param) {
      this.$emit('toggleMoreOptions', param);
    },
    select(e) {
      const { ctrlKey, shiftKey, metaKey } = e;
      this.$emit('selected', { item: this.item, ctrlKey, shiftKey, metaKey });
    },
    async openSidebarForFile() {
      this.$emit('clearSelection');

      this.$store.commit('files/setSidebar', {
        key: 'selected',
        value: [this.item],
      });
      this.$store.dispatch('files/setSelectedItem', this.item);
      this.$store.dispatch('files/showItemDetailsSidebar', this.item);
      this.$store.commit('files/setSidebar', {
        key: 'lastSelected',
        value: this.item,
      });
      this.$store.commit('files/setSidebar', {
        key: 'open',
        value: true,
      });
    },
    expand(e) {
      let isTargetTitle =
        e.target.className === 'col-title' ||
        e.target.className === 'col-title-droppable' ||
        this.getIsGrid;

      const { ctrlKey, shiftKey, metaKey } = e;

      if (!this.isMobile) {
        if (!this.clickTimeout) {
          this.$emit('expanded', this.item);
          this.$emit(
            'selected',
            { item: this.item, ctrlKey, shiftKey, metaKey },
            isTargetTitle
          );

          // doubleclick tolerance
          this.clickTimeout = setTimeout(() => {
            this.clickTimeout = null;
          }, 250);

          if (this.getIsGrid && !this.isTablet && !shiftKey) {
            this.previewFile();
          }
        } else {
          clearTimeout(this.clickTimeout);
          this.clickTimeout = null;
          if (!this.isTablet) {
            this.previewFile();
          }
        }
      } else {
        this.$emit('expanded', this.item);
        this.$emit(
          'selected',
          { item: this.item, ctrlKey, shiftKey, metaKey },
          isTargetTitle
        );
      }
    },
    preview(e) {
      if (!this.isRenaming) {
        const isShiftPressed = e && e.shift;

        if (!this.picker) {
          this.$emit('preview', this.item, isShiftPressed);
        } else {
          this.select(e);
        }
      }
    },
    async browse() {
      if (this.item.encryptedfolder !== 1) {
        await this.$store.dispatch('files/removeEncryptionHeader');
      }
      this.$emit(
        'browsed',
        this.item.type !== 'dir' ? this.item.dirpath : this.item
      );
    },
    async previewFile(event) {
      if (!this.isRenaming) {
        const isShiftPressed = event && event.shiftKey;

        if (this.item.type === 'dir') {
          await this.browse();
        } else {
          this.preview({
            shift: isShiftPressed,
          });
        }

        this.$nextTick(() => {
          this.encryptedFolderPassword = '';
          this.isEncryptedFolderAlertEnabled = false;
        });
      }
    },
    async saveEncryptedFolderPass() {
      await this.$store.dispatch('files/saveEncryptedFolderPass', {
        folder: this.parentPath,
        password: this.encryptedFolderPassword,
      });
    },
    async proceedEncryptedFolderOp() {
      if (!this.validEncryptedFolderPass) return;

      if (!this.isParentValidEncryptedFolder) {
        await this.saveEncryptedFolderPass();
      }

      switch (this.currentEncryptedFolderOp) {
        case 'preview':
          if (this.item.type === 'dir') {
            await this.browse();
          } else {
            this.$emit('downloadFile', this.item);
          }
          break;
        case 'download':
          this.$emit('downloadFile', this.item);
          break;
        case 'delete':
          await this.deleteOperation();
          break;
        case 'rename':
          await this.renameOperation();
          break;
        case 'share':
          await this.shareFileOp();
          break;
      }

      this.isEncryptedFolderAlertEnabled = false;

      setTimeout(() => {
        if (this.currentEncryptedFolderOp !== 'rename' && !this.renameShare) {
          this.encryptedFolderPassword = '';
        } else {
          this.currentEncryptedFolderOp = 'preview';
        }
      }, 1000);
    },
    async checkSecureFolderToken(defaultOp = true) {
      if (defaultOp) {
        this.currentEncryptedFolderOp = 'preview';
      }

      if (this.systemStatus['encryptedfolderpasswordsession']) {
        const hasTokenSaved =
          this.encryptedFolderPasswords[this.item.path] !== undefined;

        if (hasTokenSaved) {
          this.encryptedFolderPassword =
            this.encryptedFolderPasswords[this.item.path];

          if (this.encryptedFolderPassword) {
            await this.$store.dispatch('files/saveEncryptedFolderPass', {
              folder: this.parentPath,
              password: this.encryptedFolderPassword,
            });
            await this.proceedEncryptedFolderOp();
          }
        } else {
          let foundPass = null;
          Object.entries(this.encryptedFolderPasswords).forEach(
            ([key, value]) => {
              if (this.item.path.includes(key)) {
                foundPass = value;
              }
            }
          );

          if (foundPass) {
            await this.$store.dispatch('files/setEncryptionHeader', foundPass);
            this.isParentValidEncryptedFolder = true;
            await this.proceedEncryptedFolderOp();
          } else {
            this.isEncryptedFolderAlertEnabled = true;
            setTimeout(() => {
              document.getElementById('encryptedFolderPassID').focus();
            }, 500);
          }
        }
      } else {
        if (
          this.currentEncryptedHeader !== this.encryptedFolderPassword ||
          this.encryptedFolderPassword.length === 0
        ) {
          this.isEncryptedFolderAlertEnabled = true;
          setTimeout(() => {
            document.getElementById('encryptedFolderPassID').focus();
          }, 500);
        } else {
          await this.proceedEncryptedFolderOp();
        }
      }
    },
    deleteFile() {
      this.openDeleteConfirmationModal([
        {
          label: this.$t('Delete'),
          outline: false,
          danger: true,
          callback: () => {
            this.doDeleteFile();
            this.closeDeleteConfirmationModal();
          },
        },
      ]);
    },
    quickEditFile() {
      this.$router.push({
        name: 'webeditor',
        query: { filepath: this.item.path, fromhome: this.fromHome },
      });
    },
    openInGoogleDocs() {
      this.quickEditWopi({ googleEdit: true });
    },
    quickEditWopi(params) {
      let hasError = false;

      if (this.item.canupload !== 1) {
        // if powerpoint, locked by others and readonly, show warning
        if (
          this.isOffice &&
          this.isLockedByOthers &&
          !this.isImplicitlyLocked
        ) {
          hasError = true;
        }
      }

      if (hasError) {
        const title = this.$t('Error');
        const message = this.$t('Editing is blocked as the file is locked.');
        this.$toast.open({
          message: `<b>${title}</b><p role="alert">${message}</p>`,
          type: 'warning',
        });
      } else {
        this.item.googleEdit = params?.googleEdit || false;
        this.$store.dispatch('files/wopiEdit', this.item);
        delete this.item.googleEdit;
      }
    },
    async downloadFile() {
      if (this.item['encryptedfolder']) {
        this.currentEncryptedFolderOp = 'download';
        await this.checkSecureFolderToken(false);
      } else {
        await this.$store.dispatch('files/removeEncryptionHeader');
        this.$emit('downloadFile', this.item);
      }
    },
    async fetchDirectLink(preventToast = false) {
      const response = await this.$store.dispatch('files/getDirectLink', {
        sharelocation: this.item.path,
      });

      const data = response.data;
      if (response.ok) {
        this.directLink = data.message;
        if (this.msTeams) {
          let directlink = {
            userId: this.user.peerid,
            botop: 'directlink',
            params: {
              path: this.item.path,
              linkUrl: this.directLink,
            },
          };
          microsoftTeams.initialize();
          microsoftTeams.tasks.submitTask(directlink);
          return true;
        }
      } else if (!preventToast) {
        this.$toast.open({
          message: `<b>${this.$t('Failure')}</b><p role="alert">${
            data.message
          }</p>`,
          type: 'warning',
        });
      }
    },
    async copyDirectLink() {
      if (!this.directLink) {
        await this.fetchDirectLink();
      }

      if (window.isSecureContext) {
        // HTTPS or localhost - navicator.clipboard is available, so we can trigger copy without user interaction
        await navigator.clipboard.writeText(this.directLink);
        this.$toast.open({
          message: `<b>${this.$t('Success')}</b><p role="alert">${this.$t(
            'Direct Link copied'
          )}</p>`,
          type: 'success',
        });
      } else {
        //We need user interaction (click in the toast) in order to copy
        this.$toast.open({
          message: this.$t('Direct Link is ready! Click here to copy'),
          type: 'success',
          onClick: () => this.$copyText(this.directLink),
        });
      }
    },
    lockFile() {
      this.$emit('lockFile', this.item);
    },
    unlockFile() {
      this.$emit('unlockFile', this.item);
    },
    restoreFile() {
      this.$emit('restoreFile', this.item);
    },
    moveFile() {
      // toggle selection
      if (this.isSelected) {
        this.select({});
      }

      this.$emit('moveFile', this.item);
    },
    notifications() {
      this.$root.$emit('pathRuleNotification', this.item.path);
    },
    runQuickEdit() {
      this.$emit('runQuickEdit', this.item);
    },
    commentFile() {
      this.$emit('commentFile', this.item);
    },
    // rename
    async onClickRename(renameItem = false, renameShare = false) {
      this.renameItem = renameItem;
      this.renameShare = renameShare;
      this.renameFromContext = true;

      if (this.item['encryptedfolder']) {
        if (this.renameItem) {
          await this.renameOperation();
        } else {
          this.currentEncryptedFolderOp = 'rename';
          await this.checkSecureFolderToken(false);
        }
      } else {
        await this.$store.dispatch('files/removeEncryptionHeader');
        await this.renameOperation();
      }
    },

    async onCancelRename() {
      this.isRenaming = false;
      this.$root.$emit(
        'announceScreenReader',
        this.$t('File rename cancelled')
      );
    },

    async renameOperation() {
      if (!this.renameFromContext) {
        if (!this.isRenaming) return;

        this.isRenaming = false;

        if (this.item.name !== this.newName) {
          // toggle selection
          if (this.isSelected) {
            this.select({});
          }

          const oldItemPath = this.item.path;

          const response = await this.$store.dispatch('files/renameFile', {
            path: this.item.dirpath,
            id: this.item.id,
            name: this.item.name,
            newname: this.newName,
            item: this.item,
            fromHome: this.fromHome || this.hasSearchResults,
          });

          if (!response.ok && this.item.encryptedfolder === 1) {
            await this.$store.dispatch(
              'files/dropEncryptedFolderpass',
              this.item.path
            );
            this.encryptedFolderPassword = '';
          } else {
            if (!this.fromHome) {
              this.$emit('refreshFolder');
            } else {
              await this.$store.dispatch(
                'files/favoritesInNamedList',
                'default'
              );
              await this.$store.dispatch('files/favoritesInNamedList');
            }

            if (this.item.encryptedfolder === 1) {
              const hasTokenSaved =
                this.encryptedFolderPasswords[oldItemPath] !== undefined;

              if (hasTokenSaved) {
                await this.$store.dispatch(
                  'files/dropEncryptedFolderpass',
                  oldItemPath
                );

                await this.$store.dispatch('files/saveEncryptedFolderPass', {
                  folder: this.item.path,
                  password: this.encryptedFolderPassword,
                });
              }
            }
          }

          this.$root.$emit(
            'announceScreenReader',
            this.$t('File renamed to {newname} successfully', {
              newname: this.newName,
            })
          );
          if (this.renameShare) {
            await this.$store.dispatch('share/openShare', {
              ...this.item,
              renameShare: true,
            });
            await this.$store.dispatch('share/setUpdateSharePropertiesValue', {
              sharename: this.newName,
            });
            await this.$store.dispatch('share/updateShare');
            this.$store.dispatch('files/shareInfoForFile');
          }

          if (this.item.type !== 'dir') {
            setTimeout(() => {
              this.$refs.fileIcon.refresh();
            }, 500);
          }
        }
      } else {
        if (!_.isEmpty(this.item.isshared) && this.renameItem != true) {
          await this.$emit('renameShare', this.item);
          return;
        } else {
          setTimeout(() => {
            this.isRenaming = true;
            this.newName = this.item.name;

            this.$nextTick(() => {
              this.$refs.filenameInput.focus();

              // find file . index
              const extIndex = this.item.name.lastIndexOf('.');

              if (extIndex > 0 && this.item.type !== 'dir') {
                this.$refs.filenameInput.setSelectionRange(0, extIndex);
              } else {
                this.$refs.filenameInput.select();
              }
            });
          }, 500);
        }
      }
    },

    async onRename() {
      if (this.item['encryptedfolder']) {
        this.currentEncryptedFolderOp = 'rename';
        this.renameFromContext = false;
        await this.checkSecureFolderToken(false);
      } else {
        await this.$store.dispatch('files/removeEncryptionHeader');
        this.renameFromContext = false;
        await this.renameOperation();
      }
    },
    async deleteOperation() {
      this.isDeleting = true;

      // toggle selection
      if (this.isSelected) {
        this.select({});
      }

      await this.$store.dispatch('files/deleteFile', {
        item: this.item,
        callback: (response) => {
          this.isDeleting = false;

          if (!response.ok && this.item.encryptedfolder === 1) {
            this.$store.dispatch(
              'files/dropEncryptedFolderpass',
              this.item.path
            );
            this.encryptedFolderPassword = '';
          } else {
            const hasTokenSaved =
              this.encryptedFolderPasswords[this.item.path] !== undefined;

            if (hasTokenSaved) {
              this.$store.dispatch(
                'files/dropEncryptedFolderpass',
                this.item.path
              );
            }

            if (this.fromHome) {
              this.$store.dispatch('files/favoritesInNamedList', 'default');
              this.$store.dispatch('files/favoritesInNamedList');
            }
            this.$emit('refresh');
          }
        },
      });

      this.$root.$emit(
        'announceScreenReader',
        this.$t('File deleted successfully')
      );
    },
    async doDeleteFile() {
      if (this.item['encryptedfolder']) {
        this.currentEncryptedFolderOp = 'delete';
        await this.checkSecureFolderToken(false);
      } else {
        await this.$store.dispatch('files/removeEncryptionHeader');
        await this.deleteOperation();
      }
    },
    async toggleStarred(
      item,
      isStarred = typeof item.favoritelistid === 'string'
    ) {
      item.isStarring = true;
      let endPoint = isStarred ? 'files/unsetfavorite' : 'files/setfavorite';
      const response = await this.$store.dispatch(endPoint, {
        name: item.fullfilename,
      });

      if (response.ok) {
        if (this.hasCustomList) {
          this.$store.dispatch('files/favoritesInNamedList', 'default');
          this.$store.dispatch('files/favoritesInNamedList');
        } else {
          if (this.mobileMenu.open) {
            this.$store.commit('files/setMobileMenu', {
              key: 'favorite',
              value: !isStarred,
            });
          }
          this.$emit('refresh');
        }

        this.$root.$emit(
          'announceScreenReader',
          isStarred
            ? this.$t('File removed from favorites successfully')
            : this.$t('File add to favorites successfully')
        );
      } else {
        item.isStarring = false;
        let errorMessage = this.$t('Something went wrong');
        if (response.data && response.data.message) {
          errorMessage = response.data.message;
        }

        this.$toast.open({
          message: `<b>${this.$t(
            'Error'
          )}</b><p role="alert">${errorMessage}</p>`,
          type: 'error',
        });
      }
    },
    async shareFile(direct) {
      if (this.item['encryptedfolder']) {
        this.currentEncryptedFolderOp = 'share';
        await this.checkSecureFolderToken(false);
      } else {
        await this.$store.dispatch('files/removeEncryptionHeader');
        await this.shareFileOp(direct);
      }
    },
    async shareFileOp(direct) {
      if (this.isNonSharedRootFolder && !direct) {
        this.$emit('shareNonSharedRootFolder', this.item);
      } else {
        await this.$store.dispatch('share/openShare', this.item);
      }
    },
    onDragStart(transferData, e) {
      this.$store.dispatch('files/setDraggingFile', true);
    },
    onDragEnter(transferData, e) {
      if (this.item.type === 'dir' && transferData) {
        e.preventDefault();
        e.stopPropagation();

        this.counter++;

        this.draggingOver = true;
      }
    },
    onDragLeave(transferData, e) {
      if (this.item.type === 'dir' && transferData) {
        e.preventDefault();
        e.stopPropagation();

        this.counter--;

        if (this.counter === 0) {
          this.draggingOver = false;
        }
      }
    },
    onDragOver(transferData, e) {
      if (this.item.type === 'dir' && transferData) {
        e.preventDefault();
        e.stopPropagation();
      }
    },
    async onDropFile(ext, e) {
      // if there is extension data, it's a move event
      if (this.item.type === 'dir' && ext) {
        e.preventDefault();
        e.stopPropagation();

        if (ext.length > 0) {
          await ext.forEach((file) => {
            this.$emit('moveFile', file, this.item.path);
          });
        } else {
          await this.$emit('moveFile', ext, this.item.path);
        }

        setTimeout(() => {
          this.$emit('clearSelect');
        }, 200);

        this.draggingOver = false;
      }
    },
    onDragEnd() {
      this.$store.dispatch('files/setDraggingFile', false);
      this.draggingOver = false;
    },
    canEditFileType,
    openMobileMenu(e) {
      let item = this.item;
      let isInRecycleFolder = this.isInRecycleFolder;
      let limitedUser = this.isLimitedUser;
      let isPublicShare = this.isPublicShare;
      let systemStatus = this.$store.state.core.systemstatus;
      let customization = this.customization;
      let msteams = this.msTeams;
      let hasSearchResults = this.hasSearchResults;

      if (e) {
        this.select(e);
      }

      this.$store.commit('files/setMobileMenu', {
        key: 'favorite',
        value: typeof item.favoritelistid === 'string',
      });
      this.$store.commit('files/setMobileMenu', {
        key: 'open',
        value: true,
      });
      this.$store.commit('files/setMobileMenu', {
        key: 'item',
        value: item,
      });
      this.$store.commit('files/setMobileMenu', {
        key: 'component',
        value: this,
      });
      this.$store.commit('files/setMobileMenu', {
        key: 'actions',
        value: this.fileActions.filter(
          (action) => action.action !== 'runQuickEdit'
        ),
      });
      this.$store.commit('files/setMobileMenu', {
        key: 'target',
        value: {
          item,
          isInRecycleFolder,
          isLimitedUser: limitedUser,
          isPublicShareView: isPublicShare,
          systemStatus: systemStatus,
          customization,
          msteams,
          hasSearchResults,
        },
      });
      this.$store.commit('files/setMobileMenu', {
        key: 'isRoot',
        value: false,
      });

      this.$store.commit('files/showMobileOverlay');
    },
    evaluateIsEllipsisActive() {
      let element = this.$refs['file-title'].$el;
      return element.clientWidth < element.scrollWidth;
    },
    lockMessage(action) {
      let message = action ? this.$t(action) + this.$t(' disabled. ') : '';
      let readAllowed = this.isReadLocked
        ? '(read blocked) by '
        : '(read allowed) by ';
      return (
        message +
        this.$t('This file is locked ' + readAllowed) +
        this.item.lockuserid
      );
    },
    triggerShowMoreOptions() {
      this.$refs.showMoreButton.click();
    },
    sendForApproval() {
      this.$emit('sendForApproval', this.item);
    },
    webEditOptionsClick(action, params) {
      this[action](params);
    },
  },
};
