<template>
  <div class="inline-icon" :class="iconType">
    <div v-if="noFile" class="file-icon">
      <div class="file-format file-format--multifiles">
        <noIcon />
      </div>
      <i v-if="subicon" class="subicon" :class="subicon" />
    </div>
    <div
      v-else-if="computedFormat !== 'multifiles'"
      class="file-icon"
      :class="{
        'file-icon--img': thumb,
        'file-icon--workflow': computedFormat === 'workflow',
      }"
    >
      <div v-if="(!thumb || thumb === '') && !iconSrc">
        <div
          v-if="computedFormat"
          class="file-format"
          :class="{ [`file-format--${computedFormat}`]: computedFormat }"
        >
          <component :is="`${computedFormat}Icon`" />
        </div>
        <Base class="base-icon" />
      </div>
      <div v-else>
        <img
          v-if="!iconSrc || iconSrc === ''"
          :key="version"
          :src="blob + '&v=' + version"
          :alt="name"
        />
        <img v-else :src="iconSrc" alt="" />
      </div>
      <i v-if="subicon" class="subicon" :class="subicon" />
    </div>
    <div v-else class="file-icon">
      <multifilesIcon
        class="file-format"
        :class="{ [`file-format--${computedFormat}`]: computedFormat }"
      />
      <i v-if="subicon" class="subicon" :class="subicon" />
    </div>
  </div>
</template>
<script>
import Base from '../assets/file/base.svg';
import pdfIcon from '../assets/file/format-pdf.svg';
import txtIcon from '../assets/file/format-text.svg';
import zipIcon from '../assets/file/format-zip.svg';
import imgIcon from '../assets/file/format-img.svg';
import audioIcon from '../assets/file/format-audio.svg';
import videoIcon from '../assets/file/format-video.svg';
import wordIcon from '../assets/file/format-word.svg';
import excelIcon from '../assets/file/format-excel.svg';
import cadIcon from '../assets/file/format-cad.svg';
import visioIcon from '../assets/file/format-visio.svg';
import aiIcon from '../assets/file/format-ai.svg';
import emlIcon from '../assets/file/format-eml.svg';
import multifilesIcon from '../assets/file/multifiles.svg';
import powerpointIcon from '../assets/file/format-powerpoint.svg';
import dicomIcon from '../assets/file/format-dicom.svg';
import workflowIcon from '../assets/file/format-workflow.svg';
import noIcon from '../assets/file/no-file.svg';
import { PREVIEWABLEDOC_CLOUD } from '../utils/fileExtensions';
import { getFileType, getFileExtension } from '../utils/files.js';

export default {
  components: {
    Base,
    pdfIcon,
    txtIcon,
    unknownIcon: txtIcon,
    zipIcon,
    audioIcon,
    imgIcon,
    videoIcon,
    wordIcon,
    excelIcon,
    cadIcon,
    visioIcon,
    multifilesIcon,
    powerpointIcon,
    aiIcon,
    dicomIcon,
    noIcon,
    workflowIcon,
    emlIcon,
  },
  props: {
    name: {
      type: String,
      default: '',
    },
    noFile: {
      type: Boolean,
      default: false,
    },
    path: {
      type: String,
      default: '',
    },
    format: {
      type: String,
      default: '',
    },
    size: {
      type: Number,
      default: 180,
    },
    item: {
      type: Object,
      default: () => {},
    },
    iconSrc: {
      type: String,
      default: '',
    },
    subicon: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      blob: null,
      currentFormat: null,
      version: 0,
    };
  },
  computed: {
    computedFormat() {
      return this.format && this.format !== ''
        ? this.format
        : getFileType(this.name);
    },
    systemStatus() {
      return this.$store.state.core && this.$store.state.core.fullSystemStatus;
    },
    isThumbAllowed() {
      return (
        (this.computedFormat && this.computedFormat === 'img') ||
        (this.systemStatus &&
          this.systemStatus['docthumbsenabled'] == 1 &&
          PREVIEWABLEDOC_CLOUD.includes(getFileExtension(this.name)))
      );
    },
    thumb() {
      if (this.isThumbAllowed && this.path) {
        return this.$store.getters['files/getFileThumb'](
          this.path,
          this.item && this.item.modifiedepoch ? this.item.modifiedepoch : 0,
          this.size
        );
      } else {
        return '';
      }
    },
    iconType() {
      return (!this.thumb || this.thumb === '') && !this.iconSrc
        ? 'inline-icon--scaled'
        : 'inline-icon--fluid';
    },
  },
  watch: {
    thumb: function (newVal) {
      if (newVal) {
        this.loadBlob();
      }
    },
  },
  mounted() {
    this.loadBlob();
  },
  methods: {
    // load blob to memory
    async loadBlob() {
      if (this.thumb && this.thumb !== '') {
        this.blob = this.thumb;
      }
    },
    refresh() {
      this.version++;
    },
  },
};
</script>
