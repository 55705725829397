<template>
  <div>
    <input
      v-model="field.value"
      :disabled="metadata.write !== 1 || readOnly"
      type="text"
      class="form-control meta-input form-control-sm mb-2"
      :required="metadata.required === 1"
      @keypress="checkDecimal($event, field.value)"
      @change="onFieldChange"
    />
  </div>
</template>

<script>
import dataTypeMixin from './_dataTypeMixin';

export default {
  mixins: [dataTypeMixin],
  methods: {
    checkDecimal(event, value) {
      const keyCode = event.keyCode ? event.keyCode : event.which;

      if (keyCode != 46) {
        if (keyCode < 48 || keyCode > 57) {
          event.preventDefault();
        }
      } else {
        if (value.toString().indexOf('.') > -1) {
          event.preventDefault();
        }
      }
    },
  },
};
</script>
