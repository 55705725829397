<template>
  <!-- Hover menu-->
  <div class="file-list-actions" @contextmenu.prevent="contextMenu" @click.stop>
    <div
      v-if="
        (!isCompactMode && hasCustomList) ||
        (isCompactMode && !fromHome) ||
        !isCompactMode
      "
      class="btn-group actuals"
      role="cell"
    >
      <span>
        <button
          v-tooltip="
            item.type === 'dir' || item.ext === 'zip'
              ? $t('Open') + ' [Enter]'
              : $t('Preview') + ' [Enter]'
          "
          class="btn btn-sm btn-icon"
          :aria-label="
            item.type === 'dir'
              ? $t('Open') + ' [Enter]'
              : $t('Preview') + ' [Enter]'
          "
          :disabled="docPreview || item.encryptedfolder === 1"
          @click="$emit('previewFile')"
        >
          <Icon name="eye" family="fal" />
          <Icon class="icon-overlay" name="eye" family="fas" />
        </button>
      </span>
      <span v-if="hasCustomList">
        <button
          v-tooltip="$t('Go to location')"
          class="btn btn-sm btn-icon"
          :aria-label="$t('Go to location')"
          @click="$emit('browse')"
        >
          <span class="invisible">{{ $t('Go to location') }}</span>
          <Icon name="folder-open" family="fal" />
          <Icon class="icon-overlay" name="folder-open" family="fas" />
        </button>
      </span>
      <span>
        <button
          v-tooltip="
            isReadLocked ? lockMessage('Download') : $t('Download') + ' [D]'
          "
          class="btn btn-sm btn-icon"
          :aria-label="$t('Download') + ' [D]'"
          :disabled="
            isReadLocked ||
            !item.candownload ||
            (item.type === 'dir' && customization.DISABLEFOLDERDOWNLOAD === 1)
          "
          @click="$emit('downloadFile')"
        >
          <span class="invisible">{{
            isReadLocked ? lockMessage('Download') : $t('Download') + ' [D]'
          }}</span>
          <Icon name="download" family="fal" />
          <Icon class="icon-overlay" name="download" family="fas" />
        </button>
      </span>
      <span v-if="canEditFile">
        <!-- Multiple edit clients -->
        <VPopover
          v-if="editorClientLength > 1"
          @show="
            () => {
              $emit('changeFocus', true);
            }
          "
        >
          <button
            v-tooltip="$t('Edit Online')"
            :aria-label="$t('Edit Online')"
            :disabled="!!(customization.ALLOWQUICKEDITALL && isExtensionBlackListed(item.ext))"
            class="btn btn-sm btn-icon"
          >
            <span class="invisible">{{ $t('Edit Online') }}</span>

            <Icon name="edit" family="fal" />
            <Icon class="icon-overlay" name="edit" family="fas" />
          </button>
          <Alert
            slot="popover"
            button-type="primary"
            class="p-0 webedit-popover"
          >
            <div class="list-group list-group-flush cursor-pointer">
              <!-- Desktop -->
              <span
                v-if="
                  isWebEditEnabled &&
                  canEditFileType(item.ext, 'desktop', allowQuickEditAll) &&
                  item.showquickedit &&
                  !(customization.ALLOWQUICKEDITALL && isExtensionBlackListed(item.ext))
                "
                v-close-popover
                class="list-group-item list-group-item-action text-left"
                @click="$emit('runQuickEdit')"
              >
                <span class="d-flex align-items-center">
                  <div class="fc-desktop-icon pr-1" />
                  {{
                    $t('file_list.web_edit.open_client', {
                      client: desktopEditorClientName,
                    })
                  }}
                </span>
              </span>

              <!-- Web edit -->
              <span
                v-if="isWOPIEditEnabled && 
                canEditFileType(item.ext, 'web') &&
                !(customization.ALLOWQUICKEDITALL && isExtensionBlackListed(fileExtension))"
                v-close-popover
                class="list-group-item list-group-item-action text-left"
                @click="$emit('quickEditFile')"
              >
                <span>
                  <Icon name="edit" family="fal" class="pr-1" />
                  {{ $t('Edit Online') }}
                </span>
              </span>

              <!-- Google Apps -->
              <span
                v-if="
                  isWOPIEditEnabled &&
                  isGoogleDocsEnabled &&
                  canEditFileType(item.ext, 'google')
                "
                v-close-popover
                class="list-group-item list-group-item-action text-left"
                @click="openInGoogleDocs"
              >
                <span class="d-flex align-items-center">
                  <div :class="googleAppsClient.iconClass" />
                  <span>{{ $t(googleAppsClient.fullLabel) }}</span>
                </span>
              </span>
              <span
                v-if="
                  isWOPIEditEnabled &&
                  WOPIClient &&
                  canEditFileType(item.ext, WOPIClient.key)
                "
                v-close-popover
                class="list-group-item list-group-item-action text-left"
                @click="quickEditWopi"
              >
                <span class="d-flex align-items-center">
                  <div :class="WOPIClient.iconClass" />
                  <span>{{ $t(WOPIClient.fullLabel) }}</span>
                </span>
              </span>
            </div>
          </Alert>
        </VPopover>
        <!-- Web Edit -->
        <span
          v-else-if="isWOPIEditEnabled && canEditFileType(item.ext, 'web')"
          list-only
        >
          <button
            v-tooltip="$t('Edit Online')"
            :aria-label="$t('Edit Online')"
            class="btn btn-sm btn-icon"
            @click="$emit('quickEditFile')"
          >
            <span class="invisible">{{ $t('Edit Online') }}</span>
            <Icon name="edit" family="fal" />
            <Icon class="icon-overlay" name="edit" family="fas" />
          </button>
        </span>

        <!-- Desktop Editor -->
        <span
          v-else-if="
            isWebEditEnabled &&
            canEditFileType(item.ext, 'desktop', allowQuickEditAll) &&
            item.showquickedit
          "
          list-only
        >
          <button
            v-tooltip="
              `<i class='fc-desktop-icon'></i>${$t(
                'file_list.web_edit.open_client',
                {
                  client: desktopEditorClientName,
                }
              )}`
            "
            :aria-label="
              $t('file_list.web_edit.open_client', {
                client: desktopEditorClientName,
              })
            "
            :disabled="!!(customization.ALLOWQUICKEDITALL && isExtensionBlackListed(item.ext))"
            class="btn btn-sm btn-icon"
            @click="$emit('runQuickEdit')"
          >
            <span class="invisible">{{
              $t('file_list.web_edit.open_client', {
                client: desktopEditorClientName,
              })
            }}</span>
            <Icon name="edit" family="fal" />
            <Icon class="icon-overlay" name="edit" family="fas" />
          </button>
        </span>

        <!-- WOPI Client -->
        <span
          v-else-if="
            isWOPIEditEnabled &&
            WOPIClient &&
            canEditFileType(item.ext, WOPIClient.key)
          "
          list-only
        >
          <button
            v-tooltip="
              `<i class='${WOPIClient.iconClass}'></i> ${$t(
                WOPIClient.fullLabel
              )}`
            "
            :aria-label="$t(WOPIClient.fullLabel)"
            class="btn btn-sm btn-icon"
            @click="quickEditWopi"
          >
            <span class="invisible">{{ $t(WOPIClient.fullLabel) }}</span>
            <Icon name="edit" family="fal" />
            <Icon class="icon-overlay" name="edit" family="fas" />
          </button>
        </span>

        <!-- Google Docs -->
        <span
          v-else-if="
            isWOPIEditEnabled &&
            isGoogleDocsEnabled &&
            canEditFileType(item.ext, 'google')
          "
          list-only
        >
          <button
            v-tooltip="
              `<i class='${googleAppsClient.iconClass}'></i> ${$t(
                googleAppsClient.fullLabel
              )}`
            "
            class="btn btn-sm btn-icon"
            @click="openInGoogleDocs"
          >
            <span class="invisible">{{ $t(WOPIClient.fullLabel) }}</span>
            <Icon name="edit" family="fal" />
            <Icon class="icon-overlay" name="edit" family="fas" />
          </button>
        </span>
      </span>

      <!-- Web Edit -->
      <span v-else-if="!canEditFile" list-only>
        <button disabled class="btn btn-sm btn-icon">
          <span class="invisible">{{ $t('Edit is disabled') }}</span>
          <Icon name="edit" family="fal" />
        </button>
      </span>
      <span v-if="!isInRecycleFolder && systemstatus.sharemode != 3">
        <VPopover :disabled="!isNonSharedRootFolder">
          <button
            v-tooltip="
              isShareDisabled || !item.isshareable
                ? $t('Share is disabled')
                : $t('Share') + ' [.]'
            "
            :aria-label="
              isShareDisabled ? $t('Share is disabled') : $t('Share') + ' [.]'
            "
            :disabled="isShareDisabled || !item.isshareable"
            class="btn btn-sm btn-icon"
            @click="
              () => (isNonSharedRootFolder ? null : this.$emit('shareFile'))
            "
          >
            <Icon name="share-alt" family="fal" />
            <Icon class="icon-overlay" name="share-alt" family="fas" />
          </button>
          <Alert
            v-if="isNonSharedRootFolder"
            slot="popover"
            ref="alert"
            button-type="primary"
            :buttons="[
              {
                label: `Share`,
                callback: () => {
                  this.$emit('shareFile', true);
                },
              },
              {
                label: `Cancel`,
                outline: true,
                callback: () => {
                  isDeleting = false;
                },
              },
            ]"
          >
            <span role="alert">
              {{ $t('Are you sure you want to share a root folder?') }}
            </span>
          </Alert>
        </VPopover>
      </span>
      <span v-if="isInRecycleFolder">
        <button
          v-tooltip="$t('Restore')"
          class="btn btn-sm btn-icon"
          :aria-label="$t('Restore')"
          @click="restoreFile(item)"
        >
          <Icon name="trash-undo" family="fal" />
          <Icon class="icon-overlay" name="trash-undo" family="fas" />
        </button>
      </span>

      <VPopover
        v-if="!isInRecycleFolder"
        :disabled="!hasDirectLink || isPublicShare"
        @show="onDirectLinkPopoverShow"
        @apply-hide="$emit('changeFocus', false)"
      >
        <button
          v-tooltip="
            hasDirectLink ? $t('Direct Link') : $t('Direct Link is disabled')
          "
          :disabled="!hasDirectLink || isPublicShare"
          :aria-label="
            hasDirectLink || !isPublicShare
              ? $t('Direct Link')
              : $t('Direct Link is disabled')
          "
          class="btn btn-sm btn-icon"
          list-only
        >
          <Icon name="link" family="fal" />
          <Icon class="icon-overlay" name="link" family="fas" />
        </button>
        <Alert
          v-if="hasDirectLink"
          slot="popover"
          button-type="primary"
          :buttons="[
            {
              label: `Copy Link`,
              callback: function () {
                copyDirectLink();
              },
            },
            { label: `Cancel`, outline: true, callback: function () {} },
          ]"
        >
          <span role="alert">
            <span v-if="!isDirectLinkReady">
              <Loader full loading />
              {{ $t('Loading Direct Link') }}
            </span>
            <span v-else>
              {{ $t('Direct Link is Ready') }}
            </span>
          </span>
        </Alert>
      </VPopover>

      <span list-only>
        <button
          v-tooltip="
            isLockedByOthers
              ? lockMessage('Copy')
              : $t('Copy') + ' [' + metaKey + '+ C]'
          "
          class="btn btn-sm btn-icon"
          :aria-label="$t('Copy') + ' [' + metaKey + '+ C]'"
          :disabled="
            isLockedByOthers ||
            (item.isroot && item.type === 'dir') ||
            !item.candownload ||
            !user.authenticated ||
            item.encryptedfolder === 1
          "
          @click="$emit('copyFile')"
        >
          <Icon name="clone" family="fal" />
          <Icon class="icon-overlay" name="clone" family="fas" />
        </button>
      </span>
      <span list-only>
        <button
          v-tooltip="
            isLockedByOthers
              ? lockMessage('Move')
              : $t('Move') + ' [' + metaKey + '+ X]'
          "
          class="btn btn-sm btn-icon"
          :aria-label="$t('Copy') + ' [' + metaKey + '+ C]'"
          :disabled="
            isLockedByOthers ||
            ((item.isroot || !item.canupload) && !isInRecycleFolder) ||
            item.encryptedfolder === 1
          "
          @click="$emit('moveFile')"
        >
          <Icon name="long-arrow-right" family="fal" />
          <Icon class="icon-overlay" name="long-arrow-right" family="fas" />
        </button>
      </span>
      <VPopover
        :open="showDelConf"
        :disabled="
          isLockedByOthers ||
          item.isroot === 1 ||
          (!item.canupload && !isInRecycleFolder) ||
          (systemstatus.clearrecyclebin === 0 && isInRecycleFolder)
        "
        @show="
          () => {
            $emit('changeFocus', true);
            isDeleteAlertEnabled = true;
          }
        "
        @hide="
          () => {
            isDeleteAlertEnabled = false;
          }
        "
      >
        <button
          v-tooltip="
            isLockedByOthers
              ? lockMessage('Delete')
              : isInRecycleFolder
              ? $t('Permanently Delete') + ' [Del]'
              : $t('Delete') + ' [Del]'
          "
          class="btn btn-sm btn-icon"
          :aria-label="
            isInRecycleFolder
              ? $t('Permanently Delete') + ' [Del]'
              : $t('Delete') + ' [Del]'
          "
          list-only
          :disabled="
            isLockedByOthers ||
            item.isroot === 1 ||
            (!item.canupload && !isInRecycleFolder) ||
            (systemstatus.clearrecyclebin === 0 && isInRecycleFolder)
          "
        >
          <Icon name="trash-alt" family="fal" />
          <Icon class="icon-overlay" name="trash-alt" family="fas" />
        </button>
        <Alert
          slot="popover"
          button-type="danger"
          :visible="isDeleteAlertEnabled"
          :buttons="[
            {
              label: `Delete`,
              callback: function () {
                $emit('doDeleteFile');
              },
            },
            { label: `Cancel`, outline: true, callback: function () {} },
          ]"
        >
          <span role="alert">
            {{
              item.encryptedfolder === 1
                ? $t('Are you sure you want to permanently delete')
                : $t('Are you sure you want to delete')
            }}
            {{ item.name }}?
          </span>
        </Alert>
      </VPopover>
      <VPopover
        v-if="!isInRecycleFolder && item.locked"
        :disabled="
          isLockedByOthers ||
          (item.isroot === 1 && !item.dirpath.startsWith('/SHARED/')) ||
          isPublicShare ||
          isLimitedUser ||
          !item.showlockunlock ||
          item.encryptedfolder === 1
        "
        @show="
          () => {
            $emit('changeFocus', true);
          }
        "
        @update:open="
          (isOpen) => {
            isUnlockAlertOpen = isOpen;
          }
        "
      >
        <button
          v-tooltip="
            isLockedByOthers ? lockMessage('Unlock') : $t('Unlock') + ' [L]'
          "
          :disabled="
            isLockedByOthers ||
            (item.isroot && !item.dirpath.startsWith('/SHARED/')) ||
            isPublicShare ||
            isLimitedUser ||
            !item.showlockunlock ||
            item.encryptedfolder === 1
          "
          :aria-label="$t('Unlock') + ' [L]'"
          class="btn btn-sm btn-icon"
          list-only
        >
          <Icon name="unlock" family="fal" />
          <Icon class="icon-overlay" name="unlock" family="fas" />
        </button>
        <Alert
          slot="popover"
          button-type="danger"
          :visible="isUnlockAlertOpen"
          :buttons="[
            {
              label: $t('Unlock'),
              callback: function () {
                $emit('unlockFile');
              },
            },
            { label: `Cancel`, outline: true, callback: function () {} },
          ]"
        >
          <span role="alert">
            {{ $t('Are you sure you want to unlock') + ' ' }}
            {{ item.name }}?
          </span>
        </Alert>
      </VPopover>
      <span v-if="!isInRecycleFolder && !item.locked" list-only>
        <button
          v-tooltip="$t('Lock') + ' [L]'"
          class="btn btn-sm btn-icon"
          :aria-label="$t('Lock') + ' [L]'"
          :disabled="
            (item.isroot && !item.dirpath.startsWith('/SHARED/')) ||
            isPublicShare ||
            isLimitedUser ||
            !item.showlockunlock ||
            item.encryptedfolder === 1 ||
            isLockDisabled
          "
          @click="$emit('lockFile')"
        >
          <Icon name="lock" family="fal" />
          <Icon class="icon-overlay" name="lock" family="fas" />
        </button>
      </span>
    </div>
    <div
      v-if="!isInRecycleFolder && !isMobile && !isTablet"
      class="btn-group more-options"
      role="cell"
    >
      <VPopover
        ref="popover"
        :popover-class="'dropdown'"
        :class="'sample'"
        :container="'body'"
        boundaries-element="viewport"
        :placement="'right-start'"
        :offset="3"
        @show="
          () => {
            $emit('changeFocus', true);
            contextMenuOpen = true;
            this.$emit('toggleMoreOptions', true);
          }
        "
        @hide="
          () => {
            this.$emit('toggleMoreOptions', false);
            contextMenuOpen = false;
            $emit('changeFocus', false);
          }
        "
      >
        <button
          ref="showMoreButton"
          v-tooltip="$t('More')"
          :aria-label="$t('More')"
          class="btn btn-sm btn-icon"
        >
          <Icon name="ellipsis-h" family="far" />
          <Icon class="icon-overlay" name="ellipsis-h" family="fas" />
        </button>
        <template slot="popover">
          <ContextMenu
            class="dropdown-wrapper"
            :child="{ item: this.item }"
            :local="true"
            :is-in-recycle-folder="isInRecycleFolder"
            :is-limited-user="isLimitedUser"
            :is-public-share-view="isPublicShare"
            :is-msteams="msteams"
            :open="contextMenuOpen"
            :hasSearchResults="hasSearchResults"
            :is-compact-mode="isCompactMode"
            @onItemContextClick="triggerAction"
            @open="onContextMenuOpen"
          />
        </template>
      </VPopover>
    </div>
  </div>
  <!-- /Hover menu-->
</template>

<script>
import responsivenessMixin from '@/mixins/responsivenessMixin';
import ContextMenu from '../ContextMenu';
import Alert from 'common/components/Alert';
import Loader from 'common/components/Loader';


import Icon from 'common/components/Icon';
import { VPopover } from 'v-tooltip';
import { mapState, mapGetters } from 'vuex';
import { GoogleDocsFormats } from '../../../constants/WOPI.js';
import { canEditFileType, getFileType, isExtensionBlackListed } from 'common/utils/files';
export default {
  components: { Icon, ContextMenu, VPopover, Alert, Loader },
  mixins: [responsivenessMixin],

  props: {
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
    hasCustomList: {
      type: Boolean,
      default: false,
    },
    fromHome: {
      type: Boolean,
      default: false,
    },
    docPreview: {
      type: Boolean,
      default: false,
    },
    isReadLocked: {
      type: Boolean,
      default: false,
    },
    isInRecycleFolder: {
      type: Boolean,
      default: false,
    },
    isShareDisabled: {
      type: Boolean,
      default: false,
    },
    isPublicShare: {
      type: Boolean,
      default: false,
    },
    isDeleting: {
      type: Boolean,
      default: false,
    },
    isLockedByOthers: {
      type: Boolean,
      default: false,
    },
    isLockDisabled: {
      type: Boolean,
      default: false,
    },
    hasDirectLink: {
      type: Boolean,
      default: false,
    },
    directLink: {
      type: String,
      default: '',
    },
    isWOPIEditEnabled: {
      type: Boolean,
      default: false,
    },
    isWebEditEnabled: {
      type: Boolean,
      default: false,
    },
    isSharedWithMe: {
      type: Boolean,
      default: false,
    },
    editorClientLength: {
      type: Number,
      default: 0,
    },
    desktopEditorClientName: {
      type: String,
      default: '',
    },
    isCompactMode: {
      type: Boolean,
      default: false,
    },
    hasSearchResults: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      showDelConf: false,
      contextMenuOpen: false,
      isUnlockAlertOpen: false,
      isDeleteAlertEnabled: false,
      GoogleDocsFormats,
    };
  },
  computed: {
    ...mapState('core', ['customization', 'systemstatus']),
    ...mapState('auth', ['user', 'msteams']),
    ...mapGetters('core', [
      'isLimitedUser',
      'WOPIClient',
      'isGoogleDocsEnabled',
      'isCommunityEdition',
      'allowQuickEditAll',
    ]),
    canEditFile() {
      return (
        !this.isCommunityEdition &&
        !this.isInRecycleFolder &&
        (this.isWOPIEditEnabled || this.isWebEditEnabled) &&
        this.systemstatus?.ENABLEWEBEDIT &&
        !(this.isLockedByOthers && !this.item.implicitlock) &&
        this.item.candownload &&
        this.item.canupload &&
        this.item.type === 'file' &&
        ((this.WOPIClient &&
          this.canEditFileType(this.item.ext, this.WOPIClient.key)) ||
          (this.isWebEditEnabled &&
            this.canEditFileType(
              this.item.ext,
              'desktop',
              this.allowQuickEditAll
            ) &&
            this.item.showquickedit) ||
          (this.canEditFileType(this.item.ext, 'google') &&
            this.isGoogleDocsEnabled &&
            this.googleAppsClient) ||
          this.canEditFileType(this.item.ext, 'web'))
      );
    },
    googleAppsClient() {
      return this.GoogleDocsFormats[
        getFileType(this.item.ext || this.item.name)
      ];
    },
    isNonSharedRootFolder() {
      return (
        this.item.isroot &&
        typeof this.item.isshared !== 'string' &&
        !this.isSharedWithMe
      );
    },
    metaKey() {
      return window.navigator.platform === 'MacIntel' ? 'Cmd' : 'Ctrl';
    },
    isDirectLinkReady() {
      return (
        this.hasDirectLink && !this.$store.state.loading['files/getDirectLink']
      );
    },
    shouldHideShareDirectLinkOption() {
      return this.systemstatus.HIDESHAREDIRECTLINKOPTION === 1;
    },
  },
  methods: {
    isExtensionBlackListed,
    canEditFileType,
    lockMessage(action) {
      let message = action ? this.$t(action) + this.$t(' disabled. ') : '';
      let readAllowed = this.isReadLocked
        ? '(read blocked) by '
        : '(read allowed) by ';
      return (
        message +
        this.$t('This file is locked ' + readAllowed) +
        this.item.lockuserid
      );
    },
    triggerAction(action, item, param) {
      this.$refs.popover.hide();
      this.$emit('triggerAction', action, item, param);
    },
    async copyDirectLink() {
      this.$emit('triggerAction', 'copyDirectLink');
    },
    onContextMenuOpen() {
      if (
        this.hasDirectLink &&
        this.directLink === '' &&
        !this.isPublicShare &&
        !this.shouldHideShareDirectLinkOption
      ) {
        this.$emit('fetchDirectLink', true);
      }
    },
    onDirectLinkPopoverShow() {
      this.$emit('changeFocus', true);
      if (!this.directLink) {
        this.$emit('fetchDirectLink');
      }
    },
    openInGoogleDocs() {
      this.quickEditWopi({ googleEdit: true });
    },
    quickEditWopi(params) {
      let hasError = false;

      if (this.item.canupload !== 1) {
        // if powerpoint, locked by others and readonly, show warning
        if (
          this.canEditFileType(this.item.ext, 'google') &&
          this.isLockedByOthers &&
          !this.isImplicitlyLocked
        ) {
          hasError = true;
        }
      }

      if (hasError) {
        const title = this.$t('Error');
        const message = this.$t('Editing is blocked as the file is locked.');
        this.$toast.open({
          message: `<b>${title}</b><p role="alert">${message}</p>`,
          type: 'warning',
        });
      } else {
        this.item.googleEdit = params?.googleEdit || false;
        this.$store.dispatch('files/wopiEdit', this.item);
        delete this.item.googleEdit;
      }
    },
    restoreFile() {
      this.$emit('restoreFile', this.item);
    },
  },
};
</script>

<style lang="scss" scoped>
.file-list-item.file-list-item--file {
  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }
}
</style>
<style>
.m-text {
  clear: both;
  white-space: nowrap;
}
.popover {
  opacity: 1;
}
.content-highlight {
  font-size: 13px;
  font-weight: 400;
  margin: 0;
  padding: 10;
  float: right;
  line-height: 21px;
}
.small-chevron {
  font-size: 10px;
}
.pointer-link {
  cursor: pointer !important;
}
.item-list--icon {
  position: relative;
}
.hover .item-list--icon {
  display: none;
}
.locked-item .file-icon {
  opacity: 0.35;
  filter: grayscale(100%);
}
.more-options {
  position: relative;
}
</style>
