var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.hideNav)?_c('nav',{staticClass:"navbar navbar-main flex-md-nowrap p-0 timed-drop fixed-top",attrs:{"id":"navbar","role":"navigation"}},[_c('a',{directives:[{name:"a11ybutton",rawName:"v-a11ybutton",value:(_vm.skipToMainContent),expression:"skipToMainContent"}],staticClass:"skip-nav",attrs:{"tabindex":"0"},on:{"click":_vm.skipToMainContent}},[_vm._v(" "+_vm._s(_vm.$t('Skip to main content'))+" ")]),_c('div',[_c('a',{staticClass:"navbar-brand mr-0 pl-4",attrs:{"tabindex":"-1"}},[_c('div',{directives:[{name:"a11ybutton",rawName:"v-a11ybutton",value:(_vm.gotoDashboard),expression:"gotoDashboard"}],staticClass:"nav__filecloud-logo",attrs:{"tabindex":"0"},on:{"click":_vm.gotoDashboard}},[_c('img',{attrs:{"src":_vm.logo,"alt":_vm.customization.PRODUCT_NAME,"height":"35px"}})])])]),_c('div',{staticClass:"navbar navbar-main flex-md m-0 p-0 row fixed float-right width-search justify-content-end"},[_c('SearchBar',{staticClass:"float-right"}),_c('v-popover',{staticClass:"bell-notif",attrs:{"auto-hide":_vm.autoHideNotifications,"popover-class":"notif-popover z-index-333330","aria-label":_vm.$t('Alerts and Notifications'),"offset":"16","role":"button","open":!_vm.notificationsClosed},on:{"show":() => {
          _vm.notificationsClosed = false;
        },"hide":() => {
          _vm.notificationsClosed = true;
        }}},[_c('div',{staticClass:"tooltip-target",attrs:{"tabindex":"0"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return (() => {
            _vm.notificationsClosed = false;
          }).apply(null, arguments)}}},[_c('div',{staticClass:"icon-wrapper"},[(_vm.hasNewToasts || _vm.hasNewActions)?_c('span',{staticClass:"badge"}):_vm._e(),_c('Icon',{staticClass:"fa notification-icon",attrs:{"name":"bell"}})],1)]),_c('Notifications',{staticClass:"tooltip-content notification-popover",attrs:{"slot":"popover","is-visible":!_vm.notificationsClosed},on:{"closeNotifications":_vm.notifications},slot:"popover"})],1),_c('div',{staticClass:"pb-1 text-truncate text-right mt3p pr-3",attrs:{"role":"group"}},[_c('DropDownList',{attrs:{"dropdown-class":"user-menu","items":_vm.userActions,"target":{
          systemStatus: _vm.systemStatus,
          isHighContrast: _vm.customization.HIGH_CONTRAST_MODE,
          isDRMExportHidden: _vm.customization.HIDESECUREDOCS,
          isDRMExportDisabled: _vm.fullSystemStatus.isdrmenabled !== 1,
          isNoTechSupport: _vm.isNoTechSupport,
          isGroupAdmin: _vm.isGroupAdmin,
          isEncryptedFolderEnabled: _vm.fullSystemStatus.encryptedfolders === 1,
          actionsCount: _vm.hasNewActions,
          isFileCloud: _vm.customization.PRODUCT_NAME === 'FileCloud',
          isOnboardingActive: _vm.isOnboardingActive,
          isLimitedUser: _vm.isLimitedUser,
          isLockDisabled: _vm.isLockDisabled,
        }},on:{"triggerDDAction":_vm.triggerAction}},[_c('button',{staticClass:"btn btn-default shadow-none d-flex dropdown-btn menu-button"},[_c('Avatar',{attrs:{"size":"xs"}}),_c('span',{staticClass:"user-dropdown pl-2 pr-1"},[_vm._v(_vm._s(_vm.user.displayname)+" ")]),_c('Icon',{staticClass:"text-black-5 pt-1",attrs:{"name":"caret-down","family":"fas"}})],1)])],1)],1)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }