var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group col-sm",class:{ [`is-invalid`]: _vm.errors[_vm.id] }},[(_vm.label)?_c('label',{staticClass:"mb-2",class:{ [`is-optional`]: _vm.optional },attrs:{"for":_vm.id}},[_vm._v(" "+_vm._s(_vm.label)+_vm._s(_vm.required ? '*' : '')+" "),(_vm.optional)?_c('small',[_vm._v(_vm._s(_vm.$t('Optional')))]):_vm._e(),_vm._t("info")],2):_vm._e(),_c('div',_vm._g({staticClass:"input-group",class:{
      ['borderless']: _vm.borderless,
      [`input-group-${_vm.formSize}`]: _vm.formSize,
      [`input-group-file`]: _vm.type === 'file',
    }},_vm.type === 'file' ? { click: _vm.onFocusUpload } : {}),[_vm._l((_vm.prepends),function(prepend,index){return _c('div',{key:index + '-prepend',staticClass:"input-group-prepend",on:{"click":function($event){prepend.action ? prepend.action() : _vm.defaultAction()}}},[_c('Icon',{ref:prepend.ref ? prepend.ref : `${prepend.icon}-icon-ref`,refInFor:true,class:`input-group-text ${
          prepend.iconBold ? 'font-weight-bold' : ''
        } ${prepend.action ? 'cursor-pointer' : ''} border-0`,attrs:{"role":"presentation","family":"fal","name":prepend.icon,"animation":prepend.animation}})],1)}),_c('input',{directives:[{name:"shortkey",rawName:"v-shortkey.focus",value:(_vm.focusShortkey),expression:"focusShortkey",modifiers:{"focus":true}}],ref:_vm.id,staticClass:"form-control border-0",attrs:{"id":_vm.id,"type":_vm.type,"accept":_vm.accept,"placeholder":_vm.placeholder,"aria-label":_vm.placeholder,"required":_vm.required,"aria-required":_vm.required,"autocomplete":_vm.customAutoComplete ? _vm.customAutoComplete : _vm.isAutoComplete ? 'on' : 'off',"disabled":_vm.disabled,"readonly":_vm.readonly},domProps:{"value":_vm.type !== 'file' ? _vm.value : ''},on:{"focus":_vm.onFocus,"blur":_vm.onBlur,"keyup":function($event){return _vm.$emit('keyup', $event)},"input":_vm.updateValue,"click":_vm.defaultAction}}),(_vm.type === 'file' && _vm.currentSelection.length > 0)?_c('div',{staticClass:"files-selection"},[_vm._v(" "+_vm._s(_vm.currentSelection[0].name)+" ")]):_vm._e(),(_vm.$slots['input-append'])?_c('div',{staticClass:"input-group-append",on:{"click":_vm.addFocus}},[_vm._t("input-append")],2):_vm._e(),_vm._l((_vm.appends),function(append,index){return _c('div',{key:index + '-append',staticClass:"input-group-append",attrs:{"tabindex":"0","data-popover":""},on:{"click":function($event){append.action ? append.action() : _vm.defaultAction()},"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.preventDefault();append.action ? append.action() : _vm.defaultAction()},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"]))return null;$event.preventDefault();append.action ? append.action() : _vm.defaultAction()}]}},[_c('Icon',{ref:append.ref ? append.ref : `${append.icon}-icon-ref`,refInFor:true,class:`input-group-text  ${
          append.iconBold ? 'font-weight-bold' : ''
        }  ${append.action ? 'cursor-pointer' : ''} border-0`,attrs:{"title":append.title,"aria-label":append.title,"family":"fal","name":append.icon}})],1)}),(_vm.type === 'file')?_c('div',{staticClass:"input-group-append"},[(_vm.currentSelection.length > 0)?_c('button',{staticClass:"btn btn-cancel",attrs:{"type":"button"},on:{"click":_vm.onCancelUpload,"focus":_vm.onFocus}},[_c('Icon',{attrs:{"family":"fas","name":"times"}})],1):_c('button',{staticClass:"btn btn-upload",attrs:{"type":"button"},on:{"click":_vm.onFocusUpload,"focus":_vm.onFocus}},[_vm._v(" "+_vm._s(_vm.$t('Upload'))+" ")])]):_vm._e(),(_vm.$slots['default'])?_c('div',{staticClass:"input-group-append"},[_vm._t("default")],2):_vm._e()],2),(_vm.errors[_vm.id])?_c('small',{staticClass:"form-text text-danger"},[_vm._v(_vm._s(_vm.errors[_vm.id].message))]):(_vm.description)?_c('small',{staticClass:"form-text"},[_vm._v(_vm._s(_vm.description))]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }