import Vue from 'vue';
import Vuex from 'vuex';

// modules
import core from './modules/core.js'; // auth and general app handling
import files from './modules/files.js';
import loading from './modules/loading.js'; // automatically handles ajax request loading state
import themes from './modules/themes.js'; // themes support
import auth from './modules/auth.js'; // auth logic
import share from './modules/share.js'; // auth logic
import workflows from './modules/workflows.js'; // workflows
import actions from './modules/actions.js'; // actions
import workers from './modules/workers.js'; // webworkers
import miniAdmin from './modules/mini-admin.js'; // mini-admin
import onboarding from './modules/onboarding.js'; // onboarding

// common modules
// Dynamically importing modules in common may cause vue-dev-tools to break
import zipViewer from 'common/store/zip-viewer.js'; // zip viewer

Vue.use(Vuex);

const store = new Vuex.Store({
  namespaced: true,
  modules: {
    core,
    auth,
    files,
    loading,
    themes,
    share,
    workflows,
    actions,
    workers,
    miniAdmin,
    onboarding,
    zipViewer,
  },
});

// handle state loading
store.subscribeAction({
  before: (action) => {
    store.commit('loading/set', { type: action.type, status: true });
  },
  after: (action) => {
    store.commit('loading/set', { type: action.type, status: false });
  },
});

store.subscribe((mutation) => {
  const [module] = mutation.type.split('/');
  if (module === 'onboarding' || module == 'loading') return;
  store.dispatch('onboarding/onboardingTrigger', {
    type: mutation.type,
    payload: mutation.payload,
    store,
  });
});

export default store;
