export const sharePermission = [
  {
    label: 'View + Download',
    allowpublicupload: 0,
    allowpublicviewonly: 0,
    allowpublicuploadonly: 0,
    hideForEncryptedFolder: false,
  },
  {
    label: 'View + Download + Upload',
    allowpublicupload: 1,
    allowpublicviewonly: 0,
    allowpublicuploadonly: 0,
    hideForEncryptedFolder: false,
  },
  {
    label: 'View + Upload',
    allowpublicupload: 1,
    allowpublicviewonly: 1,
    allowpublicuploadonly: 0,
    hideForEncryptedFolder: false,
  },
  {
    label: 'View Only',
    allowpublicupload: 0,
    allowpublicviewonly: 1,
    allowpublicuploadonly: 0,
    hideForEncryptedFolder: true,
  },
  {
    label: 'Upload Only',
    allowpublicupload: 0,
    allowpublicviewonly: 0,
    allowpublicuploadonly: 1,
    hideForEncryptedFolder: true,
  },
];

export default sharePermission;
