<template>
  <!-- eslint-disable vue/no-v-html -->
  <div>
    <!-- logical operator -->
    <LogicalOperator
      v-if="query.logicalOperator && index != 0"
      v-model="query.logicalOperator"
      :labels="labels"
      @imput="save"
      @change="$emit('change')"
    />
    <!-- logical operator -->
    <div
      :class="{ 'not-editing': !editing }"
      class="vqb-rule card"
      @mouseover="hover = true"
      @mouseleave="hover = false"
    >
      <QueryDisplay
        v-if="!editing"
        class="expression-container"
        :query="query"
        @editing="edit"
      />
      <div v-else class="form-inline">
        <!-- List of operands (optional) -->
        <v-select
          v-model="query.operand"
          class="w-100"
          label="label"
          :options="ruleTypes.operands"
        />
        <div class="mt-2 w-100 d-flex flex-wrap align-items-center">
          <v-select
            class="operator-select"
            v-model="query.operator"
            label="label"
            :options="filtredOperators"
          />
          <div
            class="mt-2 d-flex"
            v-if="
              !specialParsingRules.includes(query.operator.ruleString) &&
              !ruleInType.includes(query.operator.ruleString)
            "
          >
            <span class="ml-2 mr-1" :class="{ positive: !query.negation }"
              >{{ $t('True') }}
            </span>
            <SwitchToggle
              style="padding: 0"
              v-model="query.negation"
              v-tooltip="{
                content: 'Negate expression',
              }"
              class="logical mr-2"
            />
            <span :class="{ negative: query.negation }">{{ $t('False') }}</span>
          </div>
          <span class="text-muted mt-1" v-if="isFlagRule">
            <i v-if="query.operator.description">
              <Icon
                name="info-circle"
                family="fal"
                class="ml-2 cursor-pointer"
                v-tooltip="$t(query.operator.description)"
              />
            </i>
          </span>
        </div>
        <!-- input -->
        <input
          v-if="!isFlagRule"
          v-model="query.value"
          class="form-control mt-2 rule-select"
          type="text"
          :placeholder="
            query.operator.example
              ? query.operator.example
              : labels.textInputPlaceholder
          "
        />
        <span
          class="text-muted mt-1"
          v-if="query.operator.example && !isFlagRule"
        >
          {{ `Example: ${query.operator.example}` }}
          <i @click="copyCode">
            <Icon
              name="copy"
              family="fal"
              class="ml-2 cursor-pointer"
              v-tooltip="$t('Copy')"
            />
          </i>
          <i v-if="query.operator.description">
            <Icon
              name="info-circle"
              family="fal"
              class="ml-2 cursor-pointer"
              v-tooltip="$t(query.operator.description)"
            />
          </i>
        </span>

        <BottomButtons @editing="save" @cancel="cancel" />
      </div>
      <span v-show="hover" class="trash-button" @click="remove">
        <Icon name="trash-alt" family="fas" />
      </span>
    </div>
  </div>
</template>

<script>
import QueryBuilderRule from '../components/QueryBuilderRule';
import Icon from 'common/components/Icon';
import QueryDisplay from './partials/QueryDisplay';
import BottomButtons from './partials/BottomButtons.vue';
import LogicalOperator from './partials/LogicalOperator.vue';
import SwitchToggle from 'common/components/SwitchToggle';
import {
  specialParsingRules,
  ruleInType,
} from 'common/utils/queryBuilderRules';

export default {
  components: {
    Icon,
    QueryDisplay,
    BottomButtons,
    LogicalOperator,
    SwitchToggle,
  },
  extends: QueryBuilderRule,
  data() {
    return {
      specialParsingRules,
      ruleInType,
      editing: false,
      hover: false,
      new: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.card {
  border: 1px solid var(--border-color-light);
}
.rule-select {
  width: 100%;
}
.expression-container {
  cursor: pointer;
  padding: 10px 15px 10px 0;
}
.vqb-rule.card.not-editing {
  background-color: transparent;
  margin: 5px 0;
  padding: 0 15px;
  border: 0 !important;
}
.trash-button {
  display: none;
  cursor: pointer;
  position: absolute;
  right: 8px;
  top: 10px;
  width: 20px;
  text-align: center;
  border-radius: 3px;
  color: var(--text-mid-dark);
  &:hover {
    transition: background 0.3s ease;
    background: rgba($color: #000000, $alpha: 0.15);
  }
}
.not-editing .trash-button {
  display: block;
}
.query-builder-logic-operator {
  padding-left: 15px;
}
.positive {
  color: #58ae01;
}
.negative {
  color: #d42128;
}
.v-select {
  background: var(--bg-light);
}
.operator-select {
  min-width: 235px;
}
</style>
