import Vue from 'vue';
import { DynamicScroller, DynamicScrollerItem } from 'vue-virtual-scroller';
import _ from 'lodash';
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';
import 'vue-context/dist/css/vue-context.css';
import { Drop } from 'vue-drag-drop';
import VueContext from 'vue-context';
import Icon from 'common/components/Icon';
import FileIcon from 'common/components/FileIcon';
import Checkbox from 'common/components/Checkbox';
import InputBox from 'common/components/InputBox';
import FileListLoader from 'common/components/FileListLoader';
import FileItemLoader from 'common/components/FileItemLoader';
import PaginationIcon from 'common/assets/pagination.svg';
import FileGridItem from '../components/FileList/FileListItem/FileItemGrid';
import FileListItem from '../components/FileList/FileListItem/FileItemList';
import SharedByMe from '../components/FileList/FileListItemSharedFiles';
import ContextMenu from '../components/FileList/ContextMenu';
import DialogModal from 'common/components/DialogModal';
import FilePickerModal from '../components/Modals/FilePicker';
import PropertiesModal from '../components/Modals/Properties';
import LockFileModal from '../components/Modals/LockFile';
import FileVersionsModal from '../components/Modals/FileVersions';
import SendForApprovalModal from '../components/Modals/SendForApprovalModal';
import Radiobox from 'common/components/Radiobox';
import Loader from 'common/components/Loader';
import DropDownList from 'common/components/DropDownList';
import newFileActions from '../constants/newFileActions';
import fileActions from '../constants/fileActions';
import selectedFileActions from '../constants/selectedFileActions';
import responsivenessMixin from '@/mixins/responsivenessMixin';
import { navigationShortcuts } from '../constants/shortcuts';
import { encodeAll } from 'common/utils/URLUtils';
import { scrollTo } from 'common/utils/animations';
import dayjs from 'dayjs';

import {
  itemsPerPage,
  averageItemHeight,
  navbarHeight,
  averagePageHeight,
  filelistMinimumWidth,
  averageGridItemWidth,
  averageGridItemHeight,
  filelistScrollBuffer,
} from '../constants/listing';
import { mapGetters, mapState } from 'vuex';

export default {
  components: {
    DynamicScroller,
    DynamicScrollerItem,
    Drop,
    Icon,
    Checkbox,
    InputBox,
    FileListLoader,
    FileItemLoader,
    FileGridItem,
    FileListItem,
    SharedByMe,
    PaginationIcon,
    DialogModal,
    FileVersionsModal,
    SendForApprovalModal,
    ContextMenu,
    DropDownList,
    FilePickerModal,
    FileIcon,
    PropertiesModal,
    VueContext,
    Radiobox,
    LockFileModal,
    Loader,
  },
  mixins: [responsivenessMixin],
  props: {
    initialPath: {
      type: String,
      default: '',
    },
    initialPathName: {
      type: String,
      default: '',
    },
    lockInitial: {
      type: Boolean,
      default: false,
    },
    grid: {
      type: Boolean,
      default: false,
    },
    hasMultiSelect: {
      type: Boolean,
      default: false,
    },

    isPicker: {
      type: Boolean,
      default: false,
    },

    hasSidebarStore: {
      type: Boolean,
      default: false,
    },
    hasHistory: {
      type: Boolean,
      default: false,
    },
    hasCustomList: {
      type: Boolean,
      default: false,
    },
    customFileList: {
      type: Array,
      default: () => [],
    },
    customListName: {
      type: String,
      default: '',
    },
    customListAction: {
      type: String,
      default: null,
    },
    headerText: {
      type: String,
      default: null,
    },
    headerIcon: {
      type: String,
      default: '',
    },
    headerCollapsed: {
      type: Boolean,
      default: false,
    },
    sticky: {
      type: Boolean,
      default: false,
    },
    isSharedByMe: {
      type: Boolean,
      default: false,
    },
    isRecycleBin: {
      type: Boolean,
      default: false,
      required: false,
    },
    isLockedFiles: {
      type: Boolean,
      default: false,
    },
    isDRMAccessFiles: {
      type: Boolean,
      default: false,
    },
    isPublicShareView: {
      type: Boolean,
      default: false,
    },
    disableKbNavigation: {
      type: Boolean,
      default: false,
    },
    folderOnly: {
      type: Boolean,
      default: false,
    },
    hideEncryptedFolders: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const { DEFAULT_SORT_BY } = this.$store.state.core.customization;
    const DEFAULT_SORT_DIR = this.$store.getters['core/defaultSortDir'];

    return {
      mounted: false,
      currentPath: this.initialPath,
      currentPathName: this.initialPathName,
      breadcrumb: [],
      loadingTimeout: null,
      loadedSections: [0, 1],
      currentPage: 1,
      offset: 0,
      inTransition: { value: 0 },
      selectedFiles: [],
      lastSelectedFile: null,
      currentExpanded: null,
      lastExpanded: null,
      isDragActive: false,
      isAutomaticallyScrolling: false,
      isExpanding: false,
      expandingTimeout: null,
      shouldFadeScrolling: false,
      isPaginationOpen: false,
      filterItem: '',
      errors: {},
      sortbyName: false,
      itemToDelete: null,
      itemToRename: null,
      lockModal: false,
      versions: [],
      versionsModal: false,
      versionsFile: null,
      isPickerModalOpen: true,
      isEditingFile: true,
      newItemName: '',
      newItemMimeType: '',
      isCreatingNewItem: false,
      isCreatingNewFolder: false,
      newItemError: null,
      hasFocus: false,
      currentSharingRootFolder: {},
      columnLabels: {},
      filterTimeout: '',
      filterValue: '',
      sort: {
        field: DEFAULT_SORT_BY ? DEFAULT_SORT_BY : 'name',
        type: DEFAULT_SORT_DIR ? DEFAULT_SORT_DIR : 1,
      },
      lastFocused: null,
      fileOperationLoader: false,
      showDirectLinkModal: false,
      directLink: '',
      clearDeletedLoader: false,
      newFileActions,
      fileActions,
      selectedFileActions,
      toastUnLockSuccess: {
        message: `<b>
          ${this.$t('Success')}
          </b><p role="alert">
          ${this.$t('Lock removed successfully.')}
          </p>`,
        type: 'success',
      },
      toastUnLockFail: {
        message: `<b>
          ${this.$t('Warning')}
          </b><p role="alert">
          ${this.$t('Unable to unlock the file')}
          </p>`,
        type: 'warning',
      },
      headerOffset: null,
      pendingFilesToUpload: [],
      wrapperObserver: null,
      wrapperSize: null,
    };
  },
  computed: {
    ...mapState('core', ['customization', 'fullSystemStatus']),
    ...mapState('core', ['isMobile', 'isTablet']),
    ...mapGetters('core', ['isLimitedUser']),
    ...mapGetters('files', [
      'getFilesGridInPath',
      'getPathMeta',
      'getFilesInPath',
      'getLayout',
      'getIsGrid',
    ]),
    ...mapState('files', ['paths', 'transactions', 'layoutType']),
    ...mapState('auth', ['user']),
    shortkeys() {
      const isTourRunning = this.$store.getters['onboarding/getCurrentStep'];

      return !isTourRunning ? navigationShortcuts : {};
    },
    totalPages() {
      return this.meta ? Math.ceil(this.meta.total / itemsPerPage) : 0;
    },
    itemsPerRow() {
      if (!this.mounted) return 6;
      const gridItemWidth = this.getLayout.width
        ? this.getLayout.width
        : averageGridItemWidth;
      const itemsPerRow = this.wrapperSize / gridItemWidth;
      if (itemsPerRow < 1) {
        return 1;
      }
      return Math.floor(itemsPerRow);
    },
    files() {
      if (!this.mounted) return [];

      if (this.hasCustomList || this.isSharedByMe) {
        return this.customFileList.length
          ? this.customFileList
              .map((item) => {
                return {
                  ...item,
                  height: averageItemHeight,
                  isStarring: false,
                };
              })
              .filter((item) => {
                return this.filterValue !== ''
                  ? item.sharedobjname.toString().includes(this.filterValue)
                  : item;
              })
          : [];
      }
      if (this.getIsGrid) {
        const items = this.getFilesGridInPath(
          this.currentPath,
          this.itemsPerRow
        );
        return items;
      } else {
        let items = this.$store.getters['files/getFilesInPath'](
          this.currentPath
        );
        items = items.map((item) => {
          return {
            ...item,
            height: averageItemHeight,
            isStarring: false,
          };
        });

        return this.folderOnly
          ? items.filter((item) =>
              this.hideEncryptedFolders
                ? item.type === 'dir' && item.encryptedfolder !== 1
                : item.type === 'dir'
            )
          : items;
      }
    },
    actualFiles() {
      if (this.isSharedByMe) {
        return this.customFileList;
      } else {
        return this.getFilesInPath(this.currentPath);
      }
    },
    meta() {
      return this.getPathMeta(this.currentPath);
    },
    selectedCount() {
      let selected = this.selectedFiles.length;
      let self = this;
      return selected
        ? selected > 1
          ? `${selected} ${self.$t('items selected')}`
          : `${selected} ${self.$t(' item selected')}`
        : '';
    },
    singleSelection() {
      return this.selectedFiles.length === 1;
    },
    multipleFileSelected() {
      return this.selectedFiles.length > 1;
    },
    multipleEncryptedFolderSelected() {
      return false; //to refactor in 23.1 removed due unused attribute
    },
    hasEncryptedFolderSelected() {
      return false; //to refactor in 23.1 removed due unused attribute
    },
    transactionsBar() {
      return this.$store.state.files.transactionsBar;
    },
    systemStatus() {
      return this.$store.state.core.systemstatus;
    },
    isSharedPage() {
      return this.$store.state.files.sharePageActive;
    },
    isTeamFoldersPage() {
      return (
        this.currentFile.dirpath === '/SHARED' ||
        this.currentFile.path === '/SHARED'
      );
    },
    isInRecycleFolder() {
      return this.$route.fullPath.indexOf('recyclebin') > -1;
    },
    showDRMListFilterOption() {
      return this.$store.state.files.drmListFilterParams.drmid == null;
    },
    currentEncryptedHeader() {
      return this.$store.state.files.currentEncryptedHeader;
    },
    isCompactMode() {
      return (
        !this.isMobile &&
        !this.isTabled &&
        this.wrapperSize <= filelistMinimumWidth
      );
    },
    filelistScrollBuffer() {
      return filelistScrollBuffer;
    },
  },
  watch: {
    currentPage: function (newVal) {
      clearTimeout(this.loaderTimeout);

      this.loaderTimeout = setTimeout(() => {
        // assign new section to load
        const section = newVal - 1;

        // prevent redundant calls for data that is loaded
        const { loadedSections } = this.paths[this.currentPath]?.meta;
        if (loadedSections.indexOf(section) === -1) {
          // choose action to dispatch
          const action =
            this.hasCustomList && this.customListAction !== null
              ? this.customListAction
              : 'files/loadSection';

          // dispatch of load next section
          this.$store.dispatch(action, {
            path: this.currentPath,
            section,
            sort: this.sort,
            filter: this.filterValue,
          });
        }
      }, 0); // to be executed in next event cycle
    },
    loading: function (newVal) {
      if (newVal === false) {
        setTimeout(() => {
          let clientHeight = document.querySelector('.file-list')?.clientHeight;
          let scrollHeight = window.innerHeight;

          this.$root.$emit('onFileListLoaded', clientHeight >= scrollHeight);
        }, 1000);
      }
    },
  },
  mounted() {
    this.mounted = true;
    (this.isPicker ? this.$refs.wrapper : window).addEventListener(
      'scroll',
      _.throttle(this.onScroll.bind(this), 40),
      false
    );
    window.addEventListener('paste', this.onPaste);
    this.wrapperSize = this.$refs.wrapper.offsetWidth;
    // everytime the wrapper size changes, force recalculation of itemsPerRow.
    this.wrapperObserver = new ResizeObserver(
      _.throttle(() => {
        this.wrapperSize = this.$refs.wrapper.offsetWidth;
      }, 200)
    );
    this.wrapperObserver.observe(this.$refs.wrapper);
  },
  beforeDestroy() {
    window.removeEventListener('paste', this.onPaste);
    (this.isPicker ? this.$refs.wrapper : window).removeEventListener(
      'scroll',
      this.onScroll.bind(this)
    );
    this.wrapperObserver.disconnect();
  },
  methods: {
    contextMenu({ e, entry }) {
      if (this.isPicker) return;

      if (this.lastFocused) {
        this.lastFocused.setFocus(false);
      }

      if (this.multipleEncryptedFolderSelected) return;

      this.lastFocused = entry;

      const { item } = entry;

      const index = _.findIndex(this.selectedFiles, { name: item.name });

      if (index === -1) {
        this.onEmitSelect([item]);
      }

      this.$refs.menu.open(e, entry);
    },
    emptyContextMenu(e) {
      if (
        e.target.className === 'file-list-content' &&
        this.meta &&
        this.meta.canupload &&
        !this.isPicker
      ) {
        this.$refs.menu.open(e);
      }
    },
    async onPaste(e) {
      const { items } = e.clipboardData;

      if (items) {
        let files = [];

        for (var i = 0; i < items.length; i++) {
          let file = items[i].getAsFile();
          if (file) {
            files.push(
              new File(
                [file],
                `clipboard-${dayjs().format('YYYY-MM-DD-hhmmss')}.png`,
                { type: file.type }
              )
            );
          }
        }

        if (files.length > 0) {
          e.preventDefault();
          e.stopPropagation();

          if (this.filesMeta?.encryptedfolder === 1) {
            this.pendingFilesToUpload = files;
            await this.checkEncryptedToken('uploadFile');
          } else {
            await this.$store.dispatch('files/upload', {
              items: files,
              path: this.currentPath,
            });
          }
        }
      }
    },
    onLockFile(item) {
      this.$refs.lockFileModal.open(item);
    },
    openPropertiesModal() {
      this.$refs.propertiesModal.open();
    },
    async onRestoreFile(item) {
      this.fileOperationLoader = true;
      const path =
        item.dirpath.indexOf('._cloud') > -1
          ? item.dirpath.replace('/._cloud/recyclebin', '')
          : item.dirpath.replace('recyclebin/', '');

      await this.$store.dispatch('files/moveFile', {
        item: item,
        to: path,
      });

      this.onClearSelect();
      await this.$store.dispatch('files/list', this.currentPath);
      this.fileOperationLoader = false;
    },
    async onVersions(item) {
      this.$refs.fileVersionsModal.open(item);
    },
    onScroll(e) {
      if (!this.$refs.wrapper || this.isAutomaticallyScrolling) return;

      const scrollY = this.isPicker ? e.target.scrollTop : window.scrollY;

      const offsetTop = this.isPicker
        ? this.$refs.wrapper.getBoundingClientRect().top
        : this.$refs.wrapper.getBoundingClientRect().top + window.scrollY;

      let page = 0;

      if (this.getIsGrid) {
        const averageGridPageHeight =
          itemsPerPage * (averageGridItemHeight / this.itemsPerRow);
        page = Math.ceil(
          (scrollY - offsetTop - averageItemHeight + navbarHeight) /
            averageGridPageHeight
        );
      } else {
        page = Math.ceil(
          (scrollY -
            offsetTop -
            averageItemHeight -
            navbarHeight +
            this.$store.state.core.windowHeight) /
            averagePageHeight
        );
      }
      if (page > 0 && page !== this.currentPage) {
        this.currentPage = page;
      }
    },
    scrollTo(page) {
      // avoid registering decimal numbers
      let pageNumber = Math.round(page);
      // fix unaccepted values
      if (pageNumber <= 0) pageNumber = 1;
      if (pageNumber >= this.totalPages) pageNumber = this.totalPages;

      const offsetTop = this.isPicker
        ? this.$refs.wrapper.getBoundingClientRect().top
        : this.$refs.wrapper.getBoundingClientRect().top + window.scrollY;

      this.isAutomaticallyScrolling = true;

      if (
        pageNumber - this.currentPage >= 5 ||
        this.currentPage - pageNumber >= 5
      ) {
        this.shouldFadeScrolling = true;
      }

      this.currentPage = pageNumber;

      let position = 0;

      if (this.getIsGrid) {
        const averageGridPageHeight =
          itemsPerPage * (averageGridItemHeight / this.itemsPerRow);

        position = (pageNumber - 1) * averageGridPageHeight + offsetTop;
      } else {
        position = (pageNumber - 1) * averagePageHeight + offsetTop;
      }

      if (pageNumber == 1) position = 0;

      scrollTo(position, 500, () => {
        this.isAutomaticallyScrolling = false;
        this.shouldFadeScrolling = false;
      });
    },
    togglePagination() {
      this.isPaginationOpen = !this.isPaginationOpen;
    },
    onClickOutsidePagination() {
      this.isPaginationOpen = false;
    },
    onSelect({ item, shiftKey, metaKey }, isTargetTitle) {
      metaKey = !shiftKey;
      if (
        isTargetTitle &&
        this.selectedFiles.length === 1 &&
        item.id !== this.selectedFiles[0].id
      ) {
        this.selectedFiles = [];
      }
      if (this.isPicker) {
        if (item.type === 'dir') {
          this.browse(item);
          this.onEmitSelect([]);
        } else {
          this.onEmitSelect([]);
        }

        return;
      }

      this.$store.dispatch('files/setSelectedItem', item);
      this.$store.dispatch('files/showItemDetailsSidebar', item);

      if ((!shiftKey && !metaKey) || !this.hasMultiSelect) {
        if (
          this.selectedFiles.length === 1 &&
          this.selectedFiles[0] &&
          this.selectedFiles[0].name === item.name
        ) {
          if (!this.picker) {
            this.onEmitSelect([]);
          }
        } else {
          this.onEmitSelect([item]);
        }

        this.lastSelectedFile = item;

        this.$store.commit('files/setSidebar', {
          key: 'lastSelected',
          value: item,
        });
        return;
      }
      if (metaKey) {
        const selectedIndex = _.findIndex(this.selectedFiles, {
          name: item.name,
        });

        if (selectedIndex > -1) {
          Vue.delete(this.selectedFiles, selectedIndex);
        } else {
          this.selectedFiles.push(item);
        }

        this.$store.commit('files/setSidebar', {
          key: 'lastSelected',
          value: item,
        });
        this.lastSelectedFile = item;
        this.onEmitSelect(this.selectedFiles);
      }
      if (shiftKey) {
        const initialFile = this.lastSelectedFile
          ? this.lastSelectedFile
          : this.actualFiles[0];

        let selectedFiles = [];
        let inBetween = false;

        if (initialFile.name !== item.name) {
          this.actualFiles.forEach((file) => {
            if (file.name === initialFile.name || file.name === item.name) {
              inBetween = !inBetween;
            }

            if (
              inBetween &&
              file.name !== initialFile.name &&
              file.name !== item.name
            ) {
              selectedFiles.push(file);
            }
          });

          selectedFiles.push(initialFile);
          selectedFiles.push(item);
        } else {
          selectedFiles = [item];
        }

        this.onEmitSelect(selectedFiles);
      }
    },
    onCancelCreateItem() {
      this.isCreatingNewItem = false;
      this.newItemName = '';
    },
    async onChooseFile(e, callback = null) {
      const selectedFiles = e.target.files;

      if (selectedFiles.length > 0) {
        await this.$store.dispatch('files/upload', {
          items: selectedFiles,
          path: this.currentPath,
        });
        this.$refs.file.value = null;
        callback && callback();
      }
    },
    onChooseFolder(e, callback = null) {
      const selectedFiles = [...e.target.files];

      selectedFiles.forEach((file) => {
        const uploadPath = file.webkitRelativePath
          ? '/' +
            file.webkitRelativePath.substring(
              0,
              file.webkitRelativePath.lastIndexOf('/')
            )
          : this.currentPath;

        this.$store.dispatch('files/upload', {
          items: [file],
          path: this.currentPath,
          uploadpath: uploadPath,
        });
        callback && callback();
      });

      this.$refs.folder.value = null;
    },
    triggerAction(action, param) {
      this[action](param);
    },
    toggleGrid() {
      this.$emit('toggleGrid');
    },
    onItemContextClick(action, item, param) {
      if (this.selectedFiles.length === 1) {
        if (item) item[action](param);
      } else {
        this.executeBulk(action);
      }
    },
    onEmptyContextClick(action, params) {
      switch (action) {
        case 'openUpload':
          this.openFilePicker();
          break;
        case 'openFolderUpload':
          this.openFolderPicker();
          break;
        case 'openNewItem':
          this.openNewItem(params.suffix, params.mime);
          break;
        default:
          this[action](params);
      }
    },
    async openFilePicker() {
      if (this.filesMeta.encryptedfolder === 1) {
        await this.checkEncryptedToken('uploadFile');
      } else {
        this.$refs.file.click();
      }
    },
    async openFolderPicker() {
      if (this.filesMeta.encryptedfolder === 1) {
        await this.checkEncryptedToken('uploadFolder');
      } else {
        this.$refs.folder.click();
      }
    },
    async deleteFolderOp() {
      await this.$store.dispatch('files/deleteFile', {
        item: this.currentFile,
        callback: async (response) => {
          if (!response.ok && this.currentFile.encryptedfolder === 1) {
            await this.$store.dispatch(
              'files/dropEncryptedFolderpass',
              this.currentFile.path
            );
          } else {
            this.onClearSelect();
            this.$root.$emit(
              'announceScreenReader',
              this.$t('Folder deleted successfully')
            );

            await this.$store.dispatch('files/removeEncryptionHeader');
            await this.refreshCurrentFolder();
          }
        },
      });
    },
    async shareFileOp() {
      if (
        this.currentFile.isroot &&
        this.currentFile.type === 'dir' &&
        typeof this.currentFile.isshared !== 'string'
      ) {
        this.currentSharingRootFolder = this.currentFile;
        this.sharingNonSharedRootFolder = true;
      } else {
        this.$store.dispatch('share/openShare', this.currentFile);
      }
    },
    async downloadFileOp() {
      // download actual file if it's a single file selection
      const selectedFiles = [...this.selectedFiles];
      if (selectedFiles.length === 1 && selectedFiles[0].type !== 'dir') {
        this.onDownloadFile(selectedFiles[0]);
      } else {
        for (let i = 0; i < selectedFiles.length; i++) {
          if (
            !selectedFiles[i].candownload ||
            (selectedFiles[i].type === 'dir' &&
              this.customization.DISABLEFOLDERDOWNLOAD === 1)
          ) {
            return;
          }
        }
        const encryptedFolders = selectedFiles.find((file) => {
          return file.encryptedfolder === 1;
        });

        await this.$store.dispatch(
          encryptedFolders !== undefined
            ? 'files/downloadMultipleWithStatus'
            : 'files/downloadMultiple',
          {
            items: selectedFiles,
            path: this.currentPath,
          }
        );
      }
      this.$root.$emit('announceScreenReader', this.$t('Download has started'));
    },
    async checkEncryptedToken(operation) {
      let hasTokenSaved = false;
      this.currentEncryptedPasswordAction = operation;
      if (this.fullSystemStatus['encryptedfolderpasswordsession']) {
        hasTokenSaved =
          this.encryptedFolderPasswords[this.currentPath] !== undefined;

        if (!hasTokenSaved) {
          let foundPass = null;
          Object.entries(this.encryptedFolderPasswords).forEach(
            ([key, value]) => {
              if (this.currentPath.includes(key)) {
                foundPass = value;
              }
            }
          );

          if (foundPass) {
            await this.$store.dispatch('files/setEncryptionHeader', foundPass);
            await this.proccessEncryptedFolderAction(foundPass, operation);
          } else {
            this.$refs.openEncryptedFolderModal.open();
          }
        } else {
          await this.proccessEncryptedFolderAction(
            this.encryptedFolderPasswords[this.currentPath],
            operation
          );
        }
      } else {
        this.$refs.openEncryptedFolderModal.open();
      }
    },
    async proccessEncryptedFolderAction(password, operation = 'browse') {
      if (this.fullSystemStatus['encryptedfolderpasswordsession']) {
        const hasTokenSaved =
          this.encryptedFolderPasswords[this.currentPath] !== undefined;

        if (!hasTokenSaved) {
          await this.$store.dispatch('files/saveEncryptedFolderPass', {
            folder: this.currentFile.path,
            password: password,
          });
        }
      } else {
        await this.$store.dispatch('files/setEncryptionHeader', password);
      }

      switch (operation) {
        case 'browse':
          await this.browse(this.currentFile);
          break;
        case 'download':
          await this.downloadFileOp();
          break;
        case 'downloadFolder':
          await this.$store.dispatch('files/downloadMultipleWithStatus', {
            items: this.currentFile,
            path: this.currentPath,
          });
          break;
        case 'uploadFile':
          if (this.pendingFilesToUpload.length === 0) {
            this.$refs.file.click();
          } else {
            await this.$store.dispatch('files/upload', {
              items: this.pendingFilesToUpload,
              path: this.currentPath,
            });
            this.pendingFilesToUpload = [];
          }
          break;
        case 'uploadFolder':
          this.$refs.folder.click();
          break;
        case 'delete':
        case 'deleteFolder':
          await this.deleteFolderOp();
          break;
        case 'shareFolder':
          await this.shareFileOp();
          break;
        default:
          await this.browse(this.currentFile);
          break;
      }

      this.isAskingEncryptedPassword = false;
    },
    cancelEncryptedFolderAction(operation) {
      this.isAskingEncryptedPassword = false;

      if (operation === 'browse') {
        this.folderHasError = true;
        this.folderErrorMessage = this.$t('Unauthorized');
        this.$toast.open({
          message:
            '<b>' +
            this.$t('Error') +
            '</b><p role="alert">' +
            this.$t('Unauthorized') +
            '</p>',
          type: 'error',
        });

        //reset files in memory to avoid showing wrong data
        if (this.files.length > 0) {
          this.$store.commit('files/dropFiles', {
            path: this.currentPath,
          });
        }
      }
    },
    async refreshCurrentFolder() {
      if (!this.fileOperationLoader) this.fileOperationLoader = true;

      await this.$store.dispatch('files/list', {
        path: this.currentPath,
        sort: this.sort,
      });
      this.fileOperationLoader = false;
    },
    async unlockFile(item) {
      const response = await this.$store.dispatch('files/unlock', {
        path: item.fullfilename,
      });
      if (response.ok) {
        this.$toast.open(this.toastUnLockSuccess);
        this.$emit('refresh');
      } else {
        this.$toast.open(this.toastUnLockFail);
      }
    },
    calcDownloadPermissionsForFileSelection() {
      if (this.selectedFiles.length == 0) {
        return;
      }

      for (let i = 0; i < this.selectedFiles.length; i++) {
        if (
          !this.selectedFiles[i].candownload ||
          (this.selectedFiles[i].type === 'dir' &&
            this.customization.DISABLEFOLDERDOWNLOAD === 1)
        ) {
          // found a file that can't be downloaded... so download button is noshow
          this.selectedFilesDownloadable = false;
          return;
        }
      }

      // #todo : change this to higher order fn after testing
      //return this.selectedFiles.some(file=>!file.candownload).length ? false : true
      this.selectedFilesDownloadable = true;
    },
    onExpand(item) {
      clearTimeout(this.expandingTimeout);
      this.currentExpanded = item.id;
    },
    async onEmitSelect(selected) {
      this.selectedFiles = selected;
      this.$emit('selected', selected);

      // calculate download permissions
      this.calcDownloadPermissionsForFileSelection();

      if (!this.isPicker) {
        if (selected.length == 1) {
          await this.fetchCurrentFileData(selected[0]);
        } else if (selected.length == 0) {
          await this.fetchCurrentFileData();
        }
        this.$store.commit('files/setSidebar', {
          key: 'selected',
          value: selected,
        });
      }
    },
    async runQuickEdit(path) {
      this.$toast.open({
        message:
          '<b>' +
          this.$t('Editing requires Desktop Edit App') +
          '</b><p role="alert">' +
          (this.customization.PRODUCT_NAME === 'FileCloud'
            ? this.$t(
                'Ensure FileCloud Desktop Edit is running to quickly edit files'
              )
            : this.$t(
                'Ensure desktop edit app is running to quickly edit files'
              )) +
          '</p>',
        type: 'info',
      });
      const filePath =
        _.isObject(path) && path.showquickedit === 1 ? path.fullfilename : path;

      setTimeout(function () {
        window.open(
          `http://127.0.0.1:34320/webedit?html=1&path=${encodeAll(filePath)}`,
          'Quick Edit',
          'width=500,height=600'
        );
      }, 1500);
    },
    sendForApproval(item) {
      if (this.$refs.sendForApprovalModal) {
        this.$refs.sendForApprovalModal.open(item);
      } else {
        console.warn('Unable to open Send For Approval modal');
      }
    },
  },
};
