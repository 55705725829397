<template>
  <div></div>
</template>

<script>
import _ from 'lodash';
import { flagRules } from 'common/utils/queryBuilderRules';

export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ['query', 'index', 'rule', 'labels', 'depth', 'ruleTypes'],
  data() {
    return {
      flagRules,
      savedQuery: {},
      toast: {
        message:
          '<b>' +
          this.$t('Success') +
          '</b><p role="alert">' +
          this.$t('Example copied successfully') +
          '.</p>',
        type: 'success',
      },
    };
  },

  computed: {
    isFlagRule() {
      return this.flagRules.includes(this.query.operator.ruleString);
    },
    filtredOperators() {
      return this.ruleTypes.operators.filter(
        (o) => o.type == this.query.operand
      );
    },
    operand() {
      return this.query.operand;
    },
    selectOptions() {
      if (typeof this.rule.choices === 'undefined') {
        return {};
      }

      // Nest items to support <optgroup> if the rule's choices have
      // defined groups. Otherwise just return a single-level array
      return this.rule.choices.reduce(function (groups, item, index) {
        let key = item['group'];
        if (typeof key !== 'undefined') {
          groups[key] = groups[key] || [];
          groups[key].push(item);
        } else {
          groups[index] = item;
        }

        return groups;
      }, {});
    },

    hasOptionGroups() {
      return this.selectOptions.length && Array.isArray(this.selectOptions[0]);
    },
  },
  watch: {
    operand: function () {
      this.query.operator = this.filtredOperators[0];
    },
  },

  mounted() {
    // Set a default value for these types if one isn't provided already
    if (this.query.value === null) {
      this.editing = true;
      this.new = true;
      this.query.operator = this.filtredOperators[0];
    }
  },
  methods: {
    save() {
      if (_.isEmpty(this.query.value) && !this.isFlagRule) {
        this.$toast.open({
          message: `<b>${this.$t('Error')}</b><p>${this.$t(
            "Value can't be empty"
          )}</p>`,
          type: 'error',
        });
      } else {
        this.editing = false;
        this.new = false;
        this.$emit('change');
      }
    },
    edit() {
      this.editing = true;
      this.savedQuery = _.cloneDeep(this.query);
    },
    remove() {
      this.$emit('child-deletion-requested', this.index);
    },
    copyCode() {
      navigator.clipboard.writeText(this.query.operator.example);
      this.$toast.open(this.toast);
    },
    cancel() {
      this.editing = false;
      this.query.value = this.savedQuery.value;
      this.query.operand = this.savedQuery.operand;
      this.query.operator = this.savedQuery.operator;
      if (this.new) {
        this.remove();
      }
    },
  },
};
</script>
