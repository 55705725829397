import { mapGetters, mapState } from 'vuex';
import _ from 'lodash';

export default {
  computed: {
    ...mapGetters('auth', ['twofamethod']),
    ...mapState('core', [ 'fullSystemStatus', ]),
    isSendingCode() {
      return this.$store.state.loading['auth/send2faotp'];
    },
    logingIn() {
      return this.$store.state.loading['auth/logintwofa'];
    },
    InfoBoxMessage() {
      let message = {
        type: 'info',
        message: '',
        attemptMessage:'',
      };
      if (this.authErrors && typeof this.authErrors === 'string') {
        message = {
          type: 'alert',
          message: this.authErrors,
        };
      } else if (
        this.errors &&
        !_.isEmpty(this.errors) &&
        typeof this.errors === 'string'
      ) {
        message = {
          type: 'alert',
          message: this.errors,
          attemptMessage: (localStorage.getItem("attempt") > (this.noOfAttempts-1) ? "Your account has been locked due to " + this.noOfAttempts + " failed attempts. It will be unlocked after " + this.lockOutTime + " minutes" : 
          "Attempts remaining: " + (this.noOfAttempts - localStorage.getItem("attempt")) + ". After " + this.noOfAttempts + " consecutive attempts, account will be locked."),
        };
      } else if (this.info) {
        message = {
          type: 'info',
          message: this.info,
        };
      }
      return message;
    },
    noOfAttempts() {
      return this.fullSystemStatus.passwordlockoutattempts;
    },
    lockOutTime() {
      return this.fullSystemStatus.passwordlockoutlength;
    },
  },
  data() {
    return {
      minimumTimeout: 10,
      timeoutInterval: null,
      timeout: 0,
      errors: {},
    };
  },
  methods: {
    startCountdown(timeout) {
      clearInterval(this.timeoutInterval);
      this.timeout = timeout;
      this.timeoutInterval = setInterval(() => {
        this.timeout--;
      }, 1000);
    },
    parseTimeoutFromString(error) {
      const secondsMatch = error.match(/([\d]*)( seconds)/);
      if (secondsMatch) {
        return secondsMatch[1];
      }
      const minutesMatch = error.match(/([\d]*)( minutes)/);
      if (minutesMatch) {
        return minutesMatch[1] * 60;
      }
      return 0;
    },
    async sendCode(e, page) {
      if (e) {
        e.preventDefault();
        e.stopPropagation();
      }
      this.errors = {};
      const { ok, data, error } = await this.$store.dispatch(
        'auth/send2faotp',
        {
          userid: this.form.userid,
          token: this.form.token,
        }
      );
      if (ok) {
        const timeout = this.calculateMinumumTimeout(data?.info?.timeout);
        this.startCountdown(timeout);
      } else {
        if (error.startsWith('The resend timeout is in effect')) {
          this.startCountdown(this.parseTimeoutFromString(error));
          this.errors = this.$t(
            'Resend limit for time period has been reached. Please try again in a few minutes.'
          );
        } else if (error.startsWith('Your account is locked.')) {
          this.startCountdown(this.parseTimeoutFromString(error));
          this.errors = this.$t(
            'Your account is locked. Try again in a few minutes.'
          );
        } else {
          this.$store.dispatch('auth/throwLoginError', {
            error: this.$t('Error sending code'),
            redirect: page == 'LoginTwoFaForm' ? false : 'twofausersetupsms',
          });
        }
      }
      this.form.code = '';
    },
    async onSubmit(e) {
      e.preventDefault();
      e.stopPropagation();
      this.errors = {};
      if (this.logingIn) return;
      const response = await this.$store.dispatch('auth/logintwofa', this.form);
      const { ok, error } = response;

      if (!ok) {
        if (
          typeof error == 'string' &&
          error.startsWith('Your account is locked.')
        ) {
          this.startCountdown(this.parseTimeoutFromString(error));
          this.errors = this.$t(
            'Your account is locked. Try again in a few minutes.'
          );
        } else {
          this.errors =
            typeof error == 'string' ? { code: { message: error } } : error;
        }
      } else {
        await this.$store.dispatch('auth/relogin', response);
      }
    },
    calculateMinumumTimeout(timeout) {
      return timeout > this.minimumTimeout ? timeout : this.minimumTimeout;
    },
  },
  watch: {
    timeout() {
      if (this.timeout <= 0) {
        clearInterval(this.timeoutInterval);
        this.errors = {};
      }
    },
  },
};
