<template>
  <li class="nav-item nav-item-expandable">
    <Drop
      class="nav-link active"
      :class="{ 'pl-1 d-flex': fileType == 'file' }"
      @drop="onDropFile"
    >
      <Icon
        v-if="level > 0 && fileType == 'dir' && !loading && !icon"
        v-a11ybutton.native="(e) => e.target.click()"
        family="fas"
        :name="expanded ? 'chevron-down' : 'chevron-right'"
        class="nav-chevron cursor-pointer"
        tabindex="0"
      />
      <Icon v-if="icon && !loading" :name="icon" family="fas" />
      <div
        v-if="loading"
        class="spinner-border mr-1"
        :class="{ 'spinner-size': level > 0 }"
      ></div>
      <FileIcon
        v-if="level > 0 && showIcons && fileType !== 'dir'"
        :name="title"
      />
      <span
        ref="file-title"
        v-tooltip.right="isEllipsisActive ? $t(title) : ''"
        v-a11ybutton="gotoPath"
        tabindex="0"
        class="text-truncate cursor-pointer"
        :data-path="pathFolder"
        @click="gotoPath"
        @mouseover="() => (isEllipsisActive = evaluateIsEllipsisActive())"
      >
        {{ $t(title) }} </span
      ><span class="sr-only">(current)</span>
      <Icon
        v-if="!iconColor && level == 0"
        v-a11ybutton.native="(e) => e.target.click()"
        tabindex="0"
        :name="!expanded ? 'caret-down' : 'caret-up'"
        class="caret"
        family="fas"
      />
      <span
        v-if="iconColor"
        class="colored-dot"
        :style="iconColor ? 'background: ' + iconColor : ''"
      ></span>
    </Drop>
  </li>
</template>

<script>
import Vue from 'vue';
import Icon from 'common/components/Icon';
import FileIcon from 'common/components/FileIcon.vue';
import { Drop } from 'vue-drag-drop';
import VueClipboard from 'vue-clipboard2';
import { navigateToEncodedURL, encodeURL } from 'common/utils/URLUtils';
VueClipboard.config.autoSetContainer = true;
Vue.use(VueClipboard);

export default {
  name: 'FolderNavigation',
  components: {
    Icon,
    FileIcon,
    Drop,
  },
  props: {
    icon: {
      default: 'chevron-right',
      type: String,
    },
    title: {
      default: 'Item',
      type: String,
    },
    iconColor: {
      default: '',
      type: String,
    },
    level: {
      default: null,
      type: Number,
    },
    pathFolder: {
      default: '',
      type: String,
    },
    pathParent: {
      default: '',
      type: String,
    },
    items: {
      default: function () {
        return [];
      },
      type: Array,
    },
    dotsMap: {
      default: function () {
        return {};
      },
      type: Object,
    },
    viewAllPath: {
      default: '',
      type: String,
    },
    expanded: {
      default: false,
      type: Boolean,
    },
    showIcons: {
      default: false,
      type: Boolean,
    },
    loading: {
      default: false,
      type: Boolean,
    },
    showTooltip: {
      default: true,
      type: Boolean,
    },
    fileType: {
      default: '',
      type: String,
    },
    getItemsCallback: {
      default: function () {},
      type: Function,
    },
    item: {
      default: () => {},
      type: Object,
    },
    simpleLink: {
      type: String,
      default: null,
    },
    onlyToggle: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      currentItem: [],
      itemExpanded: false,
      copiedPath: false,
      isEllipsisActive: false,
      toast: {
        message:
          '<b>' +
          this.$t('Success') +
          '</b><p role="alert">' +
          this.$t('Path copied successfully') +
          '.</p>',
        type: 'success',
      },
    };
  },
  computed: {
    newName() {
      let rename = this.$store.state.files.renamed;
      if (
        rename &&
        rename.dirpath + '/' == this.pathParent &&
        this.title == rename.oldName
      ) {
        this.title = rename.newName;
        return rename.newName;
      }
      return '';
    },
  },
  mounted() {
    this.itemExpanded = this.expanded;
  },
  methods: {
    async gotoPath(event) {
      this.$store.dispatch('files/removeEncryptionHeader');
      if (this.onlyToggle) {
        this.expand(this.getItemsCallback ? this.getItemsCallback : null);
        return;
      }
      if (event) event.stopPropagation();
      let title = this.title;
      if (this.simpleLink) {
        const encodedSimpleLink = encodeURL(this.simpleLink);
        this.$router.push(`/${encodedSimpleLink}`);
      }
      this.currentItem.push({ name: title, selected: true });
      if (this.level != 0) {
        if (this.fileType == 'dir') {
          let item = await this.$store.dispatch(
            'files/getSingleFileInfo',
            decodeURIComponent(this.pathFolder)
          );
          item = item.data.entry;

          this.$store.commit('files/addToStore', {
            key: 'currentFile',
            value: item,
          });

          navigateToEncodedURL(this.$router, this.pathFolder, '/expl-tabl.');
        } else {
          this.$preview.open([this.item], 0);
        }
      }
    },
    copyPath(path) {
      this.copiedPath = true;
      setTimeout(() => {
        this.copiedPath = false;
      }, 1000);
      this.$copyText(path);
      this.$toast.open(this.toast);
    },
    expand(callback) {
      this.itemExpanded = !this.itemExpanded;
      if (this.itemExpanded === true && callback !== null) {
        callback();
      }
    },
    async onDropFile(ext, e) {
      e.preventDefault();
      e.stopPropagation();

      let path = e.target.dataset.path;

      // if there is extension data, it's a move event
      if (ext) {
        if (ext.length > 0) {
          await ext.forEach((file) => {
            this.$store.dispatch('files/moveFile', {
              item: file,
              to: path,
            });
          });
          setTimeout(() => {
            this.$refs.fileList.refreshCurrentFolder();
          }, 200);
        } else {
          await this.$store.dispatch('files/moveFile', {
            item: ext,
            to: path,
          });
          setTimeout(() => {
            this.$refs.fileList.refreshCurrentFolder();
          }, 200);
        }
        // move to path and upload
      }
    },
    evaluateIsEllipsisActive() {
      let element = this.$refs['file-title'];
      return element.clientWidth < element.scrollWidth;
    },
  },
};
</script>
<style scoped>
.spinner-size {
  height: 13px !important;
  margin: 0px 0 1px -5px;
  width: 13px !important;
  color: var(--text-dark);
}
</style>
