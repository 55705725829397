var render = function render(){var _vm=this,_c=_vm._self._c;return _c('DialogModal',{staticClass:"dialog-modal-table workflow-wizard",attrs:{"color":"gray","position":"top","size":"small","visible":_vm.isOpen,"title":_vm.$t('Create Encrypted Folder'),"buttons":[
    {
      label: _vm.$t('Close'),
      outline: true,
      callback: _vm.closeModal,
    },
    {
      label: _vm.$t('Create'),
      outline: false,
      disabled: !_vm.isValid,
      callback: _vm.createFolder,
    },
  ]},on:{"close":_vm.closeModal}},[(_vm.isCreatingFolder)?_c('Loader',{attrs:{"loading":_vm.isCreatingFolder,"full":""}}):_c('div',[_c('div',{staticClass:"sub-body"},[_c('form',{staticClass:"form"},[_c('div',{staticClass:"row mb-4"},[_c('div',{staticClass:"col-md-2 d-flex justify-content-center align-items-center"},[_c('h1',[_c('Icon',{staticClass:"fs-1",attrs:{"name":"key"}})],1)]),_c('div',{staticClass:"col-md-10"},[_vm._v(" "+_vm._s(_vm.$t( 'Files stored in this folder are encrypted using your password.' ))),_c('br'),_vm._v(" "+_vm._s(_vm.$t( 'The encrypted files can only be accessed in the future if the same password is provided.' ))),_c('br'),_c('br'),_vm._v(" "+_vm._s(_vm.$t( 'Encrypted folders are only available via the web browser and not available via other clients or mobile apps.' ))+" ")])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 form-group",class:{
              'is-invalid': _vm.error.name.length > 0,
            }},[_c('InputBox',{ref:"folderName",staticClass:"p-0",attrs:{"id":"folderNameID","label":_vm.$t('Folder Name'),"type":"text","aria-label":_vm.$t('Folder Name'),"placeholder":_vm.$t('Name')},on:{"input":_vm.clearErrors},model:{value:(_vm.secureFolder.name),callback:function ($$v) {_vm.$set(_vm.secureFolder, "name", $$v)},expression:"secureFolder.name"}})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6 form-group",class:{
              'is-invalid':
                _vm.error.password.length > 0 || _vm.error.passConf.length > 0,
            }},[_c('InputBox',{staticClass:"p-0",attrs:{"label":_vm.$t('Folder Password'),"type":_vm.passwordType,"aria-label":_vm.$t('Password'),"autocomplete":"off","placeholder":_vm.$t('Password'),"appends":_vm.formWithAppends.passwordAppends,"onpaste":"return false;","ondrop":"return false;"},on:{"input":_vm.clearErrors,"blur":_vm.validatePass},model:{value:(_vm.secureFolder.password),callback:function ($$v) {_vm.$set(_vm.secureFolder, "password", $$v)},expression:"secureFolder.password"}})],1),_c('div',{staticClass:"col-md-6 form-group",class:{
              'is-invalid': _vm.error.passConf.length > 0,
            }},[_c('InputBox',{staticClass:"p-0",attrs:{"label":_vm.$t('Confirm Password'),"type":_vm.passwordType,"aria-label":_vm.$t('Confirm Password'),"autocomplete":"off","placeholder":_vm.$t('Confirm Password'),"errors":{},"appends":_vm.formWithAppends.passwordAppends,"onpaste":"return false;","ondrop":"return false;"},on:{"input":_vm.clearErrors,"blur":_vm.validatePass},nativeOn:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.createFolder.apply(null, arguments)}},model:{value:(_vm.secureFolder.passConf),callback:function ($$v) {_vm.$set(_vm.secureFolder, "passConf", $$v)},expression:"secureFolder.passConf"}})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[(_vm.error.name.length > 0)?_c('ul',{staticClass:"text-danger error"},_vm._l((_vm.error.name),function(err,index){return _c('li',{key:index},[_vm._v(" "+_vm._s(err)+" ")])}),0):_vm._e(),(_vm.error.password.length > 0)?_c('ul',{staticClass:"text-danger error"},_vm._l((_vm.error.password),function(err,index){return _c('li',{key:index},[_vm._v(" "+_vm._s(err)+" ")])}),0):_vm._e()])])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }