export default {
  props: {
    field: {
      type: Object,
      required: true,
    },
    metadata: {
      type: Object,
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onFieldChange() {
      const changed = this.field.value != this.field.oldValue;
      this.$emit('onFieldChange', {
        id: this.field.attributeid,
        changed,
      });
    },
  },
};
