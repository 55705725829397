import Vue from 'vue';
import { handleArrayResponse } from '../../utils/dataUtils';

export default {
  namespaced: true,
  state: {
    shareDialogOpen: false,
    shareDialogReadonly: false,
    emailShareLinkDialogOpen: false,
    shareProperties: {},
    updateShareProperties: {},
    shareHistory: [],
    shareFile: {},
    isSharedAlready: false,
    usersInShare: [],
    exportedUsers: [],
    exportedUsersLocation: '',
    groupsInShare: [],
  },
  mutations: {
    set(state, { key, value }) {
      Vue.set(state, key, value);
    },
    merge(state, { key, value }) {
      state[key] = { ...state[key], ...value };
    },
  },
  actions: {
    resetUpdateSharePropertiesValue(context) {
      context.commit('set', { key: 'updateShareProperties', value: {} });
    },

    setUpdateSharePropertiesValue(context, payload) {
      context.commit('merge', {
        key: 'updateShareProperties',
        value: payload,
      });
    },

    removeUpdateSharePropertiesValue(context, key) {
      const newUpdateShareProperties = {
        ...context.state.updateShareProperties,
      };
      delete newUpdateShareProperties[key];

      context.commit('set', {
        key: 'updateShareProperties',
        value: newUpdateShareProperties,
      });
    },

    async openShare(context, file) {
      const isShared = !!file.shareid;
      let response = {};

      // If shareProps are sent, skip the api request
      if (!isShared) {
        response = await this.state.core.client.post(
          'app/websharepro/quickshare',
          { sharelocation: file.path }
        );

        //Handle share rejected error
        // todo: Add i18n and heading 'error'
        if (response.data && response.data.meta) {
          context.dispatch('cancelShare', false);
          Vue.$toast.open({
            message:
              '<p role="alert">' +
              response.data.meta.message +
              '</p><b>&nbsp;</b>',
            type: 'warning',
          });
        }
      } else {
        response = {
          ok: true,
          data: { share: { ...file } },
        };
      }

      if (response.ok) {
        const data = response.data;
        if (typeof data.meta !== 'undefined') {
          const meta = data.meta;
          const message = meta.message;
          return { status: false, message: message };
      }

        if (data?.share?.defaultdisallowsharechange == 1) {
          context.commit('set', {
            key: 'shareDialogDisallowChange',
            value: true,
          });

          context.commit('set', {
            key: 'shareDialogReadonly',
            value: true,
          });
        }
        if (file?.renameShare !== true) {
          context.commit('set', { key: 'shareDialogOpen', value: true });
        }
        // If Share Props are present, default to true
        let isSharedAlready = isShared
          ? true
          : typeof file.isshared === 'string';

        let shareProperties = response.data.share;
        Object.keys(shareProperties).forEach((key) => {
          if (typeof shareProperties[key] == 'object')
            shareProperties[key] = null;
        });

        context.commit('set', { key: 'shareFile', value: file });
        context.commit('set', {
          key: 'isSharedAlready',
          value: isSharedAlready,
        });
        context.commit('set', {
          key: 'shareProperties',
          value: { ...shareProperties, isSharedAlready },
        });
        if (!isShared && !this.state.files.sharePageActive) {
          context.commit(
            'files/setRoot',
            {
              key: 'currentFile',
              value: context.state.shareFile,
            },
            { root: true }
          );
          context.commit('merge', {
            key: 'shareFile',
            value: { isshared: 'public' },
          });
          this.dispatch('files/shareInfoForFile');
        }
        response.isSharedAlready = isSharedAlready;
      }
      return response;
    },

    async openReadonlyShare(context, payload) {
      const shareInfoResponse = await this.dispatch(
        'files/getShareInfoByShareid',
        {
          path: payload.path,
          shareid: payload.shareid,
        }
      );

      let share = null;

      if (shareInfoResponse.ok) {
        share = shareInfoResponse.data.share;
      }

      const isShared = !!share?.shareid;
      let response = {};

      // If shareProps are sent, skip the api request
      if (!isShared) {
        Vue.$toast.open({
          message: '<p role="alert">Share not found</p><b>&nbsp;</b>',
          type: 'warning',
        });
      } else {
        response = {
          ok: true,
          data: { share: { ...share } },
        };
      }

      if (response.ok) {
        const data = response.data;
        if (typeof data.meta !== 'undefined') {
          const meta = data.meta;
          const message = meta.message;
          return { status: false, message: message };
        }

        context.commit('set', { key: 'shareDialogReadonly', value: true });
        context.commit('set', { key: 'shareDialogOpen', value: true });

        // If Share Props are present, default to true
        let isSharedAlready = isShared
          ? true
          : typeof share.isshared === 'string';

        let shareProperties = response.data.share;
        Object.keys(shareProperties).forEach((key) => {
          if (typeof shareProperties[key] == 'object')
            shareProperties[key] = null;
        });

        context.commit('set', { key: 'shareFile', value: share });
        context.commit('set', {
          key: 'isSharedAlready',
          value: isSharedAlready,
        });
        context.commit('set', {
          key: 'shareProperties',
          value: { ...shareProperties, isSharedAlready },
        });
      }
    },

    async removeShare(context, shareID) {
      let file = context.state.shareProperties;
      const response = await this.state.core.client.get(
        'app/websharepro/deleteshare',
        { shareid: shareID || file.shareid }
      );
      if (response.ok) {
        if (!this.state.files.sharePageActive)
          this.state.share.shareFile.isshared = {};
        context.dispatch('cancelShare');
      }
      return response;
    },

    async updateShare(context, preventClose) {
      // updateShare
      let response = { ok: true };
      let sharepassword = context.state.updateShareProperties.sharepassword;

      const payload = setupPayload(context);

      if (Object.keys(payload).length) {
        response = await this.state.core.client.post(
          'app/websharepro/updateshare',
          payload
        );
      }
      this.dispatch('files/shareInfoForFile');
      if (response.data.meta && response.data.meta.error) {
        return { ok: false, error: response.data.meta.error };
      }
      if (!preventClose) {
        context.dispatch('cancelShare');
        if (this.state.files.sharePageActive) {
          this.dispatch('files/shares');
        }
      } else {
        let { isSharedAlready } = context.state.shareProperties;
        context.commit('set', {
          key: 'shareProperties',
          value: { ...response.data.share, isSharedAlready, sharepassword },
        });
      }
      return response;

      function setupPayload(innerContext) {
        const { shareProperties, updateShareProperties } = innerContext.state;

        if (Object.keys(updateShareProperties).length) {
          updateShareProperties.shareid = shareProperties.shareid;
        }
        if (updateShareProperties.sharesizelimit) {
          updateShareProperties.sharesizelimit =
            updateShareProperties.sharesizelimit * 1000;
        }
        return updateShareProperties;
      }
    },

    async getShareForId(context, payload) {
      const response = await this.state.core.client.post(
        'app/websharepro/getshareforid',
        payload
      );
      let sharepassword = context.state.updateShareProperties.sharepassword;
      context.commit('set', {
        key: 'shareProperties',
        value: { ...response.data.share, sharepassword },
      });
      return response.ok;
    },

    async cancelShare(context, resetCurrentFile = true) {
      // Just close the share Dialog and reset the state props
      context.commit('set', { key: 'shareFile', value: {} });
      context.commit('set', { key: 'shareDialogOpen', value: false });
      context.commit('set', { key: 'shareDialogReadonly', value: false });
      context.commit('set', { key: 'shareProperties', value: {} });
      context.commit('set', { key: 'shareHistory', value: [] });
      context.commit('set', { key: 'updateShareProperties', value: {} });
      context.commit('set', { key: 'isSharedAlready', value: false });

      if (resetCurrentFile) {
        context.commit('files/resetCurrentFile', null, { root: true });
      }
    },

    async getShareActivity(context, payload) {
      const response = await this.state.core.client.post(
        'core/getshareactivityforshare',
        payload
      );
      if (response.ok && response.data && response.data.shareactivity) {
        let shareactivity = response.data.shareactivity;
        if (shareactivity.length == undefined) {
          shareactivity = [shareactivity];
        }
        context.commit('set', { key: 'shareHistory', value: shareactivity });
      }
    },

    async checkUsersInShare(context, shareid) {
      const { data } = await this.state.core.client.post(
        'core/getuseraccessforshare',
        {
          shareid,
        }
      );
      return handleArrayResponse(data.user);
    },

    async checkGroupsInShare(context, shareid) {
      const { data } = await this.state.core.client.post(
        'core/getgroupaccessforshare',
        {
          shareid,
        }
      );
      return handleArrayResponse(data.group);
    },

    async getUsersInShare({ commit }, shareid) {
      if(!shareid?.shareid) return [];
      const { ok, data } = await this.state.core.client.get(
        'app/websharepro/getuseraccessforshare',
        shareid
      );
      if (ok) {
        const users = handleArrayResponse(data.user);
        commit('set', { key: 'usersInShare', value: users });
        return users;
      }
      return [];
    },
    async getGroupsInShare({ commit }, shareid) {
      if(!shareid?.shareid) return [];
      const { ok, data } = await this.state.core.client.get(
        'app/websharepro/getgroupaccessforshare',
        shareid
      );
      if (ok) {
        const shareGrp = handleArrayResponse(data.group);
        commit('set', { key: 'groupsInShare', value: shareGrp });
        return shareGrp;
      }
      return [];
    },

    async publicShareAuth(context, payload) {
      const response = await this.state.core.client.post(
        'core/getpublicshareauthinfo',
        payload
      );
      return response;
    },
    async searchUsers(context, payload) {
      const response = await this.state.core.client.post(
        'core/searchprofiles',
        payload
      );
      return response;
    },
    async addUsersToShare(context, payload) {
      const response = await this.state.core.client.post(
        'core/adduserstoshare',
        payload
      );
      return response;
    },
    async addSharePermissions(context, payload) {
      const response = await this.state.core.client.post(
        'app/websharepro/setallowpublicaccess',
        payload
      );
      this.dispatch('files/shareInfoForFile');
      this.state.share.shareFile.isshared = payload.allowpublicaccess
        ? 'public'
        : 'private';
      return response;
    },
    async getRandomPassword(context) {
      const response = await this.state.core.client.get(
        'core/getrandompassword'
      );
      return response;
    },
    async getSharePassword(context, payload) {
      return await this.state.core.client.post(
        'core/getsharepassword',
        payload
      );
    },
    async getEmailSubject(context, payload) {
      const response = await this.state.core.client.post(
        'core/getemailsubject',
        payload
      );
      return response;
    },
    async getEmailTemplate(context, payload) {
      const response = await this.state.core.client.postPlain(
        'core/getemailtemplate',
        payload
      );
      return response;
    },
    async searchGroups(context, payload) {
      return this.state.core.client.post('core/searchgroups', payload);
    },
    async addGroupToShare(context, payload) {
      const response = await this.state.core.client.post(
        'core/addgrouptoshare',
        payload
      );
      return response;
    },
    async setUserAccessforShare(context, payload) {
      const response = await this.state.core.client.post(
        'app/websharepro/setuseraccessforshare',
        payload
      );
      return response;
    },
    async deleteUserfromShare(context, payload) {
      const response = await this.state.core.client.post(
        'app/websharepro/deleteuserfromshare',
        payload
      );
      return response;
    },
    async setGroupAccessforShare(context, payload) {
      const response = await this.state.core.client.post(
        'app/websharepro/setgroupaccessforshare',
        payload
      );
      return response;
    },
    async deleteGroupfromShare(context, payload) {
      const response = await this.state.core.client.post(
        'app/websharepro/deletegroupfromshare',
        payload
      );
      return response;
    },
    async getSampleAnonymousUploadForm(context, payload) {
      const response = await this.state.core.client.getPlain(
        'core/getuploadform?shareid=' + payload
      );
      return response;
    },
    async sendEmail(context, payload) {
      return await this.state.core.client.post(
        'app/websharepro/sendsharetoemail',
        payload
      );
    },
    async updateShareLink(context, payload) {
      let { editedShareUrl, shareUrlSecond } = payload;
      const response = await this.state.core.client.post(
        'app/websharepro/updatesharelink',
        payload
      );
      if (response.ok) {
        let shareurl = context.state.shareProperties.shareurl;
        shareurl = shareurl.replace(shareUrlSecond, editedShareUrl);
        context.state.shareProperties.shareurl = shareurl;
      }
      return response;
    },
    async isNotificationFilterSetForSharePath(context, payload) {
      const response = await this.state.core.client.post(
        'core/isnotificationfilterset',
        payload
      );
      return response;
    },
    async addNotificationFilterForSharePath(context, payload) {
      const response = await this.state.core.client.post(
        'core/addnotificationfilter',
        payload
      );
      return response;
    },
    async removeNotificationFilterForSharePath(context, payload) {
      const response = await this.state.core.client.post(
        'core/removenotificationfilter',
        payload
      );
      return response;
    },
    async leaveShare(context, payload) {
      const response = await this.state.core.client.post(
        'core/leaveshare',
        payload
      );
      return response;
    },

    async exportShareUsers(context) {
      const usersToExport = context.state.usersInShare.map((user) => user.name);
      context.commit('set', {
        key: 'exportedUsers',
        value: usersToExport,
      });

      context.commit('set', {
        key: 'exportedUsersLocation',
        value:
          context.state.shareFile.sharelocation || context.state.shareFile.path,
      });
    },
    async importShareUsers(context, shareId) {
      const usersToAdd = context.state.exportedUsers
        .filter(
          (user) =>
            !context.state.usersInShare.some(
              (userInshare) => userInshare.name === user
            )
        )
        .join(',');

      const disableShareKey = this.state.core.customization.DISABLESHAREDIALOG;

      const payload = {
        sendemail: disableShareKey !== 2 ? 1 : 0,
        shareid: shareId,
        users: usersToAdd,
      };
      return await context.dispatch('addUsersToShare', payload);
    },
  },
};
