var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{ rtl: _vm.useRtl },attrs:{"id":"app","role":_vm.core.ready ? false : 'main'}},[_c('theme-provider',{attrs:{"theme":_vm.$store.getters['themes/themeScheme']}},[_c('Loader',{attrs:{"title":_vm.randomHelp,"loading":!_vm.core.ready || _vm.loading,"full":""}}),(_vm.core.ready)?_c(_vm.banner,{tag:"component"}):_vm._e(),(_vm.core.ready)?_c(_vm.componentLoader,{tag:"component",attrs:{"is-password-protected":_vm.isPasswordProtected,"is-public-share-view":_vm.isPublic}}):_vm._e(),(
        _vm.core.ready &&
        _vm.systemStatus &&
        _vm.systemStatus.TOURENABLED &&
        _vm.customization &&
        _vm.customization.userCustomizationLoaded &&
        _vm.auth.user &&
        _vm.auth.user.authenticated &&
        !_vm.isOnAuthPage &&
        !_vm.loading
      )?_c(_vm.onboarding,{tag:"component"}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }