<template>
  <DialogModal
    v-if="isOpen"
    :visible="isOpen"
    class="dialog-modal-filepicker"
    :title="$t(folderPicker ? 'Select Destination Folder' : 'Select Preview')"
    :buttons="[
      {
        label: $t(folderPicker ? 'New Folder' : ''),
        outline: true,
        left: true,
        disabled: !meta || (meta && meta.isroot == 1) || !folderPicker,
        callback: createFolder.bind(this),
      },
      {
        label: $t('Cancel'),
        outline: true,
        callback: onCancel,
      },
      {
        label: $t(buttonLabel),
        disabled: !canSubmit,
        callback: onSubmit,
        primary: true,
      },
    ]"
    color="gray"
    position="top"
    @close="onCancel"
  >
    <template v-if="accept.length" v-slot:left-button-hint>
      <v-popover>
        <div v-tooltip="accept.join(', ')" class="dialog-modal-filepicker-hint">
          <Icon name="info-circle" family="fas" />
          <span>{{
            $tc('file_list.share.suppored_preview_types', accept.length)
          }}</span>
        </div>
      </v-popover>
    </template>
    <ol class="breadcrumb mb-3">
      <li
        class="breadcrumb-item active fc-explorer--home"
        @click="breadcrumb.length === 1 ? browse('') : null"
      >
        <Icon v-if="breadcrumb.length <= 1" family="fas" name="home" />
        <DropDownList
          v-else
          :container="'.dialog-modal-visible'"
          :items="modifiedBreadCrumb"
          :action-name="'browse'"
          :param-name="'path'"
          @triggerDDAction="onDropDownAction"
        >
          <span class="d-flex">
            <Icon family="fas" name="home" />
            <Icon family="fas" name="sort-down" class="dropdown-trigger" />
          </span>
        </DropDownList>
      </li>
      <li
        v-for="(item, index) in modifiedBreadCrumb"
        :key="item.path"
        class="breadcrumb-item"
        :class="{ active: index === breadcrumb.length - 1 }"
      >
        <Icon family="fas" name="chevron-right" class="breadcrumb-seperator" />
        <a
          v-if="breadcrumb.length > 1 && index < breadcrumb.length - 1"
          v-a11ybutton="() => browse(item.path)"
          class="breadcrumb-text"
          tabindex="0"
          @click="browse(item.path)"
          >{{ item.name }}</a
        >
        <span v-else class="breadcrumb-text" v-html="item.name"></span>
      </li>
    </ol>
    <div v-if="displayReadOnlyHeader" class="fc-explorer--header-read-only">
      <Icon name="exclamation-triangle" family="fas" />
      <h4>{{ $t('This folder is read-only') }}</h4>
    </div>
    <component
      :is="folderPicker ? 'FileList' : 'pickerFileList'"
      v-if="initialPath || initialPath == ''"
      ref="fileList"
      :initial-path="initialPath"
      :is-public-share-view="isPublicShare"
      :keep-selection="keepSelection"
      :folder-only="folderPicker"
      hide-encrypted-folders
      is-picker
      @browsed="onBrowse"
      @select="fileSelect"
    />
  </DialogModal>
</template>
<script>
import _ from 'lodash';
import DialogModal from 'common/components/DialogModal';

import Icon from 'common/components/Icon';
import DropDownList from 'common/components/DropDownList';

export default {
  components: {
    //Loader,
    DialogModal,
    Icon,
    DropDownList,
  },
  props: {
    visible: Boolean,
    folderPicker: {
      type: Boolean,
      default: true,
    },
    isPublicShare: Boolean,
    keepSelection: Boolean,
    accept: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isOpen: false,
      breadcrumb: [],
      currentPath: null,
      item: {},
      initialPath: null,
      type: null,
      callback: null,
      file: {},
    };
  },
  computed: {
    buttonLabel() {
      return _.capitalize(this.type);
    },
    networkFolderName() {
      return this.$store.state.core.systemstatus.NETWORKFOLDERNAME;
    },
    user() {
      return this.$store.state.auth.user;
    },
    lastChildBreadCrumb() {
      let breadcrumb = this.modifiedBreadCrumb;
      let lastItem = breadcrumb.length === 1 ? 0 : breadcrumb.length - 1;
      return breadcrumb[lastItem];
    },
    modifiedBreadCrumb() {
      if (!this.breadcrumb.length) return [];
      let breadcrumb = this.breadcrumb;

      if (this.user && this.user.authenticated && this.folderPicker) {
        breadcrumb.unshift({
          name: this.$t('All Files'),
          path: '',
        });

        let firstObjName = breadcrumb[0].name;
        if (firstObjName === 'SHARED') {
          firstObjName = this.$t('Shared with Me');
        } else {
          firstObjName =
            firstObjName === 'EXTERNAL'
              ? typeof this.networkFolderName === 'string'
                ? this.networkFolderName + ''
                : this.$t('Network Shares')
              : firstObjName;
        }
        breadcrumb[0].name = firstObjName;
      }

      return breadcrumb;
    },
    meta() {
      return this.$store.getters['files/getPathMeta'](this.currentPath);
    },
    loading() {
      return this.$store.state.loading['files/list'];
    },
    displayReadOnlyHeader() {
      return this.loading
        ? false
        : this.meta && !this.meta.isroot && !this.meta.canupload;
    },
    canSubmit() {
      if (
        this.type === 'select' &&
        !this.folderPicker &&
        this.unsupportedExtensionSelected
      ) {
        return false;
      }

      return !(
        !this.meta ||
        (this.meta &&
          (this.meta.isroot || !this.meta.canupload) &&
          this.type !== 'select')
      );
    },
    unsupportedExtensionSelected() {
      return _.isEmpty(this.file) ? true : !this.accept.includes(this.file.ext);
    },
  },
  watch: {
    isOpen(_isOpen) {
      if (_isOpen === false) {
        this.$emit('hidepicker');
      }
    },
  },
  beforeCreate: function () {
    this.$options.components.FileList = require('../FileList.vue').default;
    this.$options.components.pickerFileList =
      require('../pickerFileList.vue').default;
  },
  methods: {
    fileSelect(path) {
      this.file = path != undefined ? path : null;
      this.isOpen = true;
    },
    open(type, path, callback) {
      this.initialPath = path.replace(/\/$/, ''); // remove extra slash
      this.currentPath = this.initialPath;
      this.type = type;
      this.callback = callback;
      this.isOpen = true;
    },
    select(path, callback) {
      this.currentPath = path || '';
      this.initialPath = path || '';
      this.type = 'select';
      this.callback = callback;
      this.isOpen = true;
    },
    onSubmit() {
      let callPath = this.folderPicker
        ? this.currentPath
        : this.file.path.replace(this.initialPath, '');
      if (this.folderPicker || this.accept.includes(this.file.ext)) {
        this.callback(callPath);
        this.currentPath = null;
        this.item = null;
        this.isOpen = false;
      } else {
        this.$toast.open({
          message: `<b>${this.$t('Error')}</b><p role="alert">
          ${this.$t('Please select a previewable file.')}</p>`,
          type: 'warning',
        });
      }
    },
    onCancel() {
      this.$emit('refreshFolder');
      this.isOpen = false;
    },
    onBrowse({ breadcrumb, path }) {
      this.breadcrumb = breadcrumb;
      this.currentPath = path;
    },
    browse(path) {
      this.$refs.fileList.browse(path);
    },
    createFolder() {
      this.$refs.fileList.openNewFolder();
    },
    onDropDownAction(action, params) {
      this.browse(params);
    },
  },
};
</script>
<style scoped>
::v-deep .file-list-wrapper {
  overflow-x: hidden !important;
}
</style>
