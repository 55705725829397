import {
  canEditFileType,
  getFileType,
  isExtensionBlackListed,
} from 'common/utils/files';

import { WOPIClients, GoogleDocsFormats } from './WOPI';

export default [
  {
    name: ({ item, editor }) =>
      editor && editor !== 'Pick an app' ? `Desktop (${editor})` : 'Desktop',
    iconClass: () => 'fc-desktop-icon',
    action: 'runQuickEdit',
    shortKey: '',
    listOnly: false,
    disabled: ({ item, isInRecycleFolder, isLockedByOthers, customization }) =>
      item.type === 'dir' ||
      isLockedByOthers ||
      !(item.candownload === 1) ||
      !item.showquickedit ||
      isInRecycleFolder ||
      item.encryptedfolder === 1 ||
      !!(customization.ALLOWQUICKEDITALL && isExtensionBlackListed(item.ext)),
    hidden: ({ customization, msteams, item }) =>
      customization.ENABLEWEBEDIT !== 1 ||
      msteams ||
      !item.canupload ||
      item.type !== 'file' ||
      !canEditFileType(item.ext, 'desktop', customization.ALLOWQUICKEDITALL),
  },
  {
    name: 'Edit Online',
    icon: 'edit',
    action: 'quickEditFile',
    shortKey: '',
    listOnly: false,
    disabled: ({
      item,
      customization,
      systemStatus,
      isInRecycleFolder,
      isLockedByOthers,
      isImplicitlyLocked,
    }) => {
      return !(
        !isInRecycleFolder &&
        customization.ENABLEWOPIEDIT &&
        systemStatus.ENABLEWEBEDIT &&
        !(isLockedByOthers && !isImplicitlyLocked) &&
        item.candownload &&
        canEditFileType(item.ext, 'web')
      );
    },
    hidden: ({ item, customization, msteams }) =>
      !canEditFileType(item.ext, 'web') ||
      !customization.ENABLEWOPIEDIT ||
      !item.canupload ||
      msteams,
  },
  {
    name: ({ item }) => GoogleDocsFormats[getFileType(item.ext)].label,
    icon: 'edit',
    iconClass: ({ item }) => {
      return GoogleDocsFormats[getFileType(item.ext)].iconClass;
    },
    action: 'openInGoogleDocs',
    shortKey: '',
    listOnly: false,
    disabled: ({
      item,
      isInRecycleFolder,
      isPublicShareView,
      systemStatus,
      customization,
      isLockedByOthers,
    }) =>
      item.type === 'dir' ||
      isLockedByOthers ||
      !customization.ENABLEWOPIEDIT ||
      item.candownload !== 1 ||
      isPublicShareView ||
      isInRecycleFolder ||
      item.encryptedfolder === 1,
    hidden: ({ item, systemStatus, customization, msteams }) =>
      !customization.ENABLEWOPIEDIT ||
      customization.ENABLEGOOGLEDOCS !== 1 ||
      !canEditFileType(item.ext, 'office') ||
      !item.canupload ||
      msteams,
  },
  {
    name: ({ customization }) => WOPIClients[customization.WOPICLIENT].label,
    icon: 'edit',
    iconClass: ({ customization }) => {
      return WOPIClients[customization.WOPICLIENT].iconClass;
    },
    action: 'quickEditWopi',
    shortKey: '',
    listOnly: false,
    disabled: ({
      item,
      isInRecycleFolder,
      customization,
      systemStatus,
      isLockedByOthers,
      isImplicitlyLocked,
    }) => {
      const WOPIClient = WOPIClients[customization.WOPICLIENT];

      return !(
        !isInRecycleFolder &&
        customization.ENABLEWOPIEDIT &&
        systemStatus.ENABLEWEBEDIT &&
        !(isLockedByOthers && !isImplicitlyLocked) &&
        item.candownload &&
        WOPIClient &&
        canEditFileType(item.ext, WOPIClient.key)
      );
    },
    hidden: ({ item, customization, msteams }) => {
      const WOPIClient = WOPIClients[customization.WOPICLIENT];

      return (
        !WOPIClient ||
        !canEditFileType(item.ext, WOPIClient.key) ||
        !customization.ENABLEWOPIEDIT ||
        !item.canupload ||
        msteams
      );
    },
  },
];
