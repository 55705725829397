<template>
  <div class="date-picker-wrapper">
    <input
      :id="customInput"
      :value="dateFormatted"
      type="text"
      class="date-picker-input form-control"
      :placeholder="dateTimeFormat"
      :disabled="disabled"
    />
    <em class="date-picker-icon">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1024 1024"
        width="1em"
        height="1em"
      >
        <path
          d="M940.218182 107.054545h-209.454546V46.545455h-65.163636v60.50909H363.054545V46.545455H297.890909v60.50909H83.781818c-18.618182 0-32.581818 13.963636-32.581818 32.581819v805.236363c0 18.618182 13.963636 32.581818 32.581818 32.581818h861.090909c18.618182 0 32.581818-13.963636 32.581818-32.581818V139.636364c-4.654545-18.618182-18.618182-32.581818-37.236363-32.581819zM297.890909 172.218182V232.727273h65.163636V172.218182h307.2V232.727273h65.163637V172.218182h176.872727v204.8H116.363636V172.218182h181.527273zM116.363636 912.290909V442.181818h795.927273v470.109091H116.363636z"
        ></path>
      </svg>
    </em>
    <NewDatePicker
      v-model="checked"
      :element="customInput"
      :type="displayDateTime"
      :min="disabledToDate"
      :color="storeColor"
      :display-format="dateTimeFormat"
      :input-format="inputFormat"
      :format="serverFormat"
      :locale-config="translations"
      :calendar="calendarType"
      :disable="disabledDate"
      :auto-submit="true"
      :disabled="disabled"
      @close="$emit('change', checked)"
    />
  </div>
</template>

<script>
import dayjs from 'dayjs';
import dateFormatsMixin from 'common/mixins/dateTimeFormatMixin';
import NewDatePicker from 'vue-datetime-js';
var customParseFormat = require('dayjs/plugin/customParseFormat');
dayjs.extend(customParseFormat);
const invalidDates = ['Invalid Date', 'Invalid date', NaN];

export default {
  components: {
    NewDatePicker,
  },
  mixins: [dateFormatsMixin],
  props: {
    value: [String, Number, Date],
    onlyDate: Boolean,
    dateUtc: Boolean,
    disabled: [Boolean],
    disabledDate: {
      type: String,
      default: undefined,
    },
    serverFormatType: Boolean,
    noMinDate: [Boolean],
    customInput: {
      type: String,
      default: 'my-custom-input',
    },
    startDate: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      checked: this.value,
    };
  },
  computed: {
    displayDateTime() {
      return this.onlyDate ? 'date' : 'datetime';
    },
    storeColor() {
      if (this.$store.state.core == undefined) {
        return '#016aae';
      }
      return this.$store.state.core.customization.THEME_COLOR;
    },
    now() {
      if (this.noMinDate) return '';
      const d = new Date();
      return dayjs(d).format(this.dateTimeFormat);
    },
    disabledToDate() {
      const d = this.startDate ? this.startDate : this.now;
      return this.serverFormat === 'MMM DD, YYYY '
        ? dayjs(d, this.serverFormat).format('MM/DD/YYYY ')
        : dayjs(d, this.dateTimeFormat).format(this.serverFormat);
    },
    serverFormat() {
      return this.serverFormatType
        ? 'YYYY-MM-DD HH:mm:SS'
        : this.dateTimeFormat;
    },
    gregorianDate() {
      const date = dayjs(this.checked, this.serverFormat).format(this.dateTimeFormat)
      return invalidDates.includes(date) ? this.checked : date;
    },
    dateFormatted() {
      if (this.checked == '') return this.checked;
      return this.calendarType === 'hijri'
        ? this.convertToHijri(dayjs(this.checked, this.serverFormat))
        : this.gregorianDate;
    },
    inputFormat() {
      return this.serverFormat === 'MMM DD, YYYY '
        ? 'MM/DD/YYYY '
        : this.serverFormat;
    },
    translations() {
      return {
        hijri: {
          lang: {
            submit: 'Select',
            cancel: 'Cancel',
            now: 'Now',
            nextMonth: 'Next month',
            prevMonth: 'Previous month',
          },
        },
      };
    },
  },
  watch: {
    // this checks are mandatory to prevent endless cycle
    value(newVal, oldVal) {
      if (newVal !== oldVal) this.checked = newVal;
    },
    checked(newVal, oldVal) {
      if (newVal !== oldVal) this.$emit('input', newVal);
    },
  },
  methods: {
    convertToHijri(date) {
      const toHijriDate = this.dateUtc
        ? date
        : dayjs(date, this.dateTimeFormat).format('YYYY/MM/DD HH:mm:ss');
      return this.dateToDisplay(toHijriDate);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .vpd-month-label {
  min-width: 135px;
  width: calc(100% - 90px);
}
</style>
