<template>
  <div
    ref="wrapper"
    v-click-outside="() => (hasFocus = false)"
    class="file-list-wrapper"
    @click="() => (hasFocus = true)"
  >
    <div class="file-list">
      <FileListPickerHeader
        v-if="isPicker && (files.length || filterValue !== '' || loading)"
        :sort="sort"
        :meta="meta"
        :error="errors"
        @onFilter="onFilter"
        @onSort="onSort"
      />
      <RecycleScroller
        v-slot="{ item }"
        class="file-list-body"
        :items="files"
        key-field="id"
        size-field="height"
        page-mode
      >
        <div class="file-list-row" style="height: 100px">
          <FileItemLoader
            v-if="
              !item.type &&
              !isLockedFiles &&
              !isDRMAccessFiles &&
              !isAclpermissionFiles
            "
          />
          <FileListItem
            :key="componentKey"
            :ref="`listitem` + item.id"
            :item="item"
            :is-picker="isPicker"
            :selection="selection"
            :disable-hover-context="true"
            @selected="checked"
            @browsed="browse"
          />
        </div>
      </RecycleScroller>
    </div>
  </div>
</template>
<script>
import { RecycleScroller } from 'vue-virtual-scroller';
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';

import FileListPickerHeader from './FileList/FileListHeaders/FileListPickerHeader';
import { averageItemHeight } from '../constants/listing';
import FileItemLoader from 'common/components/FileItemLoader';
import FileListItem from '../components/FileList/FileListItem';

export default {
  name: 'FileList',
  components: {
    RecycleScroller,
    FileListPickerHeader,
    FileItemLoader,
    FileListItem,
  },
  props: {
    initialPath: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isPicker: false,
      currentPath: this.initialPath,
      mounted: false,
      selection: [],
      componentKey: 0,
    };
  },
  computed: {
    user() {
      return this.$store.state.auth.user;
    },
    files() {
      if (!this.mounted) return [];
      const items = this.$store.getters['files/getFilesInPath'](
        this.currentPath
      );
      return items.map((item) => {
        return {
          ...item,
          height: averageItemHeight,
          isStarring: false,
        };
      });
    },
  },
  mounted() {
    this.mounted = true;
    let path = this.currentPath;
    this.$store.dispatch('files/list', { path: path });
    if (path !== '/') {
      const { name, breadcrumb } = this.parsePath(path);
      this.$emit('browsed', { path, name, breadcrumb });
    }
  },
  methods: {
    checked({ item }) {
      if (this.selection.length == 0) {
        this.selection.push(item);
      } else {
        this.selection[0] = this.selection[0].id == item.id ? [] : item;
      }
      this.$emit('select', this.selection[0]);
      this.componentKey += 1;
    },
    parsePath(path) {
      let [root, type, ...dirTree] = path.split('/');

      let breadcrumb = [];
      let currentRoute = `/${type}`;

      breadcrumb = [
        ...dirTree.map((dir) => {
          currentRoute += `/${dir}`;

          return {
            name: dir,
            path: currentRoute,
          };
        }),
      ];

      if (this.isPublicShareView) {
        breadcrumb = breadcrumb.slice(3, breadcrumb.length);

        breadcrumb.unshift({
          name: `Shared with me (${path.split('/').slice(0, 4)[3]})`,
          path: path.split('/').slice(0, 4).join('/'),
          isPublicShareRoot: true,
        });
      }

      return {
        breadcrumb,
      };
    },
    async browse(item) {
      this.folderHasError = false;
      this.folderErrorMessage = '';

      let path = null;
      if (typeof item !== 'string') {
        path = item.path || item.filepath;
      } else {
        path = item;
      }

      if (path !== '/') {
        const { name, breadcrumb } = this.parsePath(path);

        if (name === '._cloud') {
          return this.browse(path.replace('/._cloud', ''));
        }

        this.isScrolling = true;
        this.currentPath = path;
        this.breadcrumb = breadcrumb;
        this.currentPathName = name;
        this.$emit('browsed', { path, name, breadcrumb });
        // remove current expanded file
        await this.$store.dispatch('files/list', {
          path,
          sort: this.sort,
        }); // get initial files if not in custom list action
      }
    },
  },
};
</script>
