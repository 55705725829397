var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('DialogModal',{ref:"inviteUser",attrs:{"stick-top":false,"title":_vm.dialogTitle,"visible":_vm.isShown,"size":'small',"contain-form":_vm.isEditingEmail && !_vm.advancedEditingEmail,"class-name":'invite-dialog',"buttons":_vm.buttonArray,"footer-class":"flex-wrap","back-button":_vm.isEditingEmail,"back-button-callback":_vm.backCallback,"tabbed":_vm.advancedEditingEmail && _vm.isEditingEmail,"initial-focus-element":"#userSearch .form-control"},on:{"close":_vm.hideModal,"userFeedback":_vm.onUserFeedbackSubjectConfirm},scopedSlots:_vm._u([{key:"left-button-hint",fn:function(){return [(!_vm.isEditingEmail && _vm.context.context === 'share')?_c('div',{staticClass:"send-edit-email"},[_c('p',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
            _vm.disableShareKey === 2 && this.$t('Sending email is not allowed')
          ),expression:"\n            disableShareKey === 2 && this.$t('Sending email is not allowed')\n          "}],staticClass:"d-flex align-items-center p-0 m-0"},[_c('Checkbox',{attrs:{"id":"sendEmail","label":"Send Email","disabled":_vm.disableShareKey === 2},on:{"change":_vm.updateSendEmail},model:{value:(_vm.form.sendEmail),callback:function ($$v) {_vm.$set(_vm.form, "sendEmail", $$v)},expression:"form.sendEmail"}}),_c('label',{staticClass:"text-nowrap px-2 my-0"},[_vm._v(_vm._s(_vm.$t('Send Email')))]),(_vm.disableShareKey === 0 && _vm.userInvites.length > 0)?_c('a',{directives:[{name:"a11ybutton",rawName:"v-a11ybutton",value:(_vm.startEditingEmail),expression:"startEditingEmail"}],staticClass:"px-2",attrs:{"tabindex":"0"},on:{"click":_vm.startEditingEmail}},[_vm._v(_vm._s(_vm.$t('Edit')))]):_vm._e()],1)]):_vm._e()]},proxy:true}])},[_c('Loader',{attrs:{"loading":_vm.loading,"full":""}}),(_vm.shareProperties && !_vm.isEditingEmail)?_c('div',{staticClass:"inviteUserWrapper"},[_c('h6',[_vm._v(_vm._s(_vm.$t('Add email address or account')))]),_c('VueTypeaheadBootstrap',{ref:"userAutocomplete",staticClass:"mb-4",attrs:{"id":"userSearch","data":_vm.users,"serializer":(item) => item.emailid || item.username,"show-all-results":true,"placeholder":_vm.$t('Search Users...')},on:{"hit":function($event){return _vm.getInvites($event)}},scopedSlots:_vm._u([{key:"suggestion",fn:function({ data, htmlText }){return [_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"col-1 d-flex justfy-content-center mx-1 p-0"},[(data.new)?_c('Icon',{staticClass:"text-black fa-2x",attrs:{"name":"user-plus","family":"fas"}}):_c('Avatar',{attrs:{"user":data.username,"size":"share"}})],1),_c('div',{class:_vm.systemStatus.CREATEACCOUNTONSHAREINVITE == 1
                  ? 'col-md-9'
                  : 'col-md-11'},[_c('span',{staticClass:"user-name",domProps:{"innerHTML":_vm._s(htmlText)}}),_c('br'),(!data.new && data.username)?_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(data.displayname === data.username ? data.displayname : data.displayname + ' (' + data.username + ')'))]):(data.new)?_c('small',{staticClass:"text-black"},[(!_vm.systemStatus.CREATEACCOUNTONSHAREINVITE)?_c('span',[_vm._v(" "+_vm._s(_vm.$t( 'The creation of a new user account is not permitted while inviting a new user to access your share.' ) + ' ' + _vm.$t( 'You may contact your administrator to permit new user account creation.' ))+" ")]):_c('span',[_vm._v(_vm._s(_vm.$t( 'For eligible email id, a new account will be created and an email will be sent to ' ) + ' ' + data.emailid + _vm.$t(' with password to login.'))+" ")])]):_vm._e(),_c('br'),(data.phone && data.phone.length > 0)?_c('small',{staticClass:"text-dark"},[_vm._v("Phone: "+_vm._s(data?.phone))]):_vm._e()]),(
                !_vm.isMobile &&
                !_vm.isTablet &&
                _vm.systemStatus.CREATEACCOUNTONSHAREINVITE == 1
              )?_c('span',{staticClass:"float-right btn btn-sm btn-outline-primary btn-block col-2"},[_vm._v(" "+_vm._s(data.new ? _vm.$t('Invite') : _vm.$t('Add'))+" ")]):_vm._e()])]}}],null,false,4039414613),model:{value:(_vm.query),callback:function ($$v) {_vm.query=$$v},expression:"query"}},[_c('template',{slot:"append"},[_c('v-popover',[_c('button',{ref:"btnSearch",staticClass:"btn btn-primary btn-group fa fa-search search-btn tooltip-target"}),_c('Alert',{attrs:{"slot":"popover","button-type":"primary","buttons":_vm.btns},slot:"popover"},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.$t(_vm.message))}})])],1)],1)],2),_c('h6',{staticClass:"pt-2"},[_vm._v(_vm._s(_vm.$t('Invited Users')))]),_c('ul',{staticClass:"list-group"},[_vm._l((_vm.userInvites),function(selUser,index){return _c('li',{key:selUser.emailid,staticClass:"list-group-item"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('Avatar',{attrs:{"user":selUser.username,"unregistered-user":selUser.displayname === '',"size":"share"}}),_c('div',{staticClass:"col-md-10 user-info-pop"},[_c('h6',{staticClass:"pb-1"},[_vm._v(" "+_vm._s(selUser.displayname || selUser.emailid)+" "),_c('v-popover',{staticClass:"d-inline",attrs:{"placement":"right"},on:{"update:open":(isOpen) => {
                      _vm.userInfoVisible = isOpen;
                    }}},[_c('button',{ref:"btnInfo",refInFor:true,staticClass:"btn btn-sm py-0"},[_c('Icon',{staticClass:"tooltip-target text-muted",attrs:{"name":"info-circle","family":"fas"}})],1),_c('template',{staticClass:"p-3",slot:"popover"},[_c('UserInfo',{attrs:{"user":selUser},on:{"addToShareList":_vm.addToShareList}})],1)],2)],1),(selUser.displayname)?_c('div',{staticClass:"user-email"},[_vm._v(" "+_vm._s(selUser.emailid)+" ")]):_vm._e()]),_c('button',{staticClass:"float-right btn btn-sm btn-icon fa fa-times text-muted ml-4 col-1",on:{"click":function($event){return _vm.removeUser(index)}}})],1)])}),(_vm.userInvites.length == 0)?_c('li',{staticClass:"list-group-item",staticStyle:{"height":"10rem"}},[_c('p',[_vm._v(_vm._s(_vm.$t('No user invited.')))])]):_vm._e()],2)],1):(_vm.isEditingEmail)?_c('EditEmail',{attrs:{"advanced-active":_vm.advancedEditingEmail,"advanced-email":_vm.advancedEmail,"users":_vm.userInvites,"form":_vm.form}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }